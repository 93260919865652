import { useEffect, useState } from 'react'
import { get_search_params } from '../utils'

function useDelayLoader(isRefetching, delay=200) {
  const search_params = get_search_params()
  const [delay_loader, set_delay_loader] = useState(true)
    // console.log(isRefetching, "isRefetching")
    // console.log(delay_loader, "delay_loader")
  useEffect(() => {
    //delay only when mode is named_wishlist to solve 2 time render issue whn product delete from shortlist
    if(search_params.mode !== "named_wishlist") return set_delay_loader(false)
    if(isRefetching) return set_delay_loader(true)
    const timer = setTimeout(() => {
        set_delay_loader(false)
    }, delay)

    return () => clearTimeout(timer)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetching])

  return delay_loader
}

export default useDelayLoader
