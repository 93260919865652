import { useRef, useState } from "react";
import "./OrderDetailsMenu.css";
import { HiOutlineDotsVertical } from "react-icons/hi";
import DropdownMenu from "./DropdownMenu";
import PastOrderInfoModal from "./PastOrderInfoModal/PastOrderInfoModal";
import QRCodeModal from "../../../../../Component/QRCodeModal/QRCodeModal";

function OrderDetailsMenu({ order_data }) {
  const [anchorEl, setAnchorEl] = useState(null);
  

 
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open_order_info_modal_ref = useRef();
  const open_qr_code_modal_ref = useRef(null);

  function handleOpenModal() {
    open_order_info_modal_ref.current?.open_modal();
  }

 

  const handle_open_QR_code_modal = () => {open_qr_code_modal_ref.current?.open_modal()};

  return (
    <div className="past-order-details-page-menu">
      <QRCodeModal title="Order QR Code" QRData={order_data?.order_url} ref={open_qr_code_modal_ref}/>
      <PastOrderInfoModal ref={open_order_info_modal_ref} order_data={order_data}/>
      <div
        className="dot-menu-wrapper"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <HiOutlineDotsVertical className="dot-menu" />
      </div>
      <DropdownMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleOpenModal={handleOpenModal}
        handle_open_QR_code_modal={handle_open_QR_code_modal}
        order_data={order_data}
      />
    </div>
  );
}

export default OrderDetailsMenu;
