import dayjs from "dayjs";
import React from "react";

function CustomOrderOtherDetails({order_list}) {

  const OrderDate = order_list?.order_date.split("-")
  const client_due_date = order_list?.client_due_date.split("-")
  
  const orderUpdatedDate = `${OrderDate[1]}-${OrderDate[0]}-${OrderDate[2]}`
  const clientUpdateDate = `${client_due_date[1]}-${client_due_date[0]}-${client_due_date[2]}`
  return <div className="custom-order-other-details">
      <ul>
          {order_list?.order_no && <li>
            <span>Order No</span>
            <span>{order_list?.order_no || "-"}</span>
          </li>}

          {order_list?.order_date && <li>
            <span>Order Date</span>
            <span>{dayjs(orderUpdatedDate).format("DD-MMM-YYYY") || "-"}</span>
          </li>}

          {order_list?.company_name && <li>
            <span>Client</span>
            <span>{order_list?.company_name || "-"}</span>
          </li>}

          {order_list?.place_by_whom  && <li>
            <span>Placed By</span>
            <span>{order_list?.place_by_whom || "-"}</span>
          </li>}

          {order_list?.distributor_company_name &&<li>
            <span>Distributor</span>
            <span>{order_list?.distributor_company_name || "-"}</span>
          </li>}

          {order_list?.reference_no  && <li>
            <span>Reference No</span>
            <span>{order_list?.reference_no || "-"}</span>
          </li>}

         

          {order_list?.quantity && <li>
            <span>Quantity</span>
            <span>{order_list?.quantity || "-"} {order_list?.quantity && order_list?.unit_of_measurement}</span>
          </li>}

          {order_list?.from_wt && order_list?.to_wt && <li>
            <span>Weight</span>
            <span>{order_list?.from_wt === order_list?.to_wt ? order_list?.from_wt : `${order_list?.from_wt} to ${order_list?.to_wt}`}</span>
          </li>}

          {order_list?.cat_name  && <li>
            <span>Category</span>
            <span>{order_list?.cat_name || "-"}</span>
          </li>}


          {order_list?.client_due_date && <li>
            <span>Delivery Due Date</span>
            <span>{dayjs(clientUpdateDate).format("DD-MMM-YYYY") || "-"}</span>
          </li>}

          {order_list?.client_due_date_days_left && <li>
            <span>Due in</span>
            <span>{order_list?.client_due_date_days_left} {parseInt(order_list?.client_due_date_days_left) > 1 ? `days` : `day`}</span>
          </li>}


          {
            order_list?.selected_value?.map((value, id) => (
              value?.field_value ?
              <li key={(value?.field_value || "")+id}>
                <span>{value.field_name}</span>
                <span>{value?.field_value || "-"}</span>
              </li> : null
            ))
          }
          
        </ul>  
  </div>;
}

export default CustomOrderOtherDetails;
