import React, { useContext } from 'react'
import CustomOrderCard from '../../CustomOrder/Components/CustomOrderCard'


import { useSelector } from 'react-redux'
import { get_custom_order_items } from '../../../redux/Reducer/reducer'
import { CustomOrderFileContext } from '../../CustomOrder/Context/CustomOrderContext'



function CustomNewOrderCard({set_add_items_by}) {
  const custom_order_items = useSelector(get_custom_order_items)
  
  const {file, setFile} = useContext(CustomOrderFileContext)

  if(Array.isArray(custom_order_items) && custom_order_items.length === 0) return null
  

  return (
    <div className="custom-order-body-cards-section">
            
          {
            custom_order_items.map((item, index) => <CustomOrderCard order={{...item, image_name: file}} key={`cards-${index}`} index={index} isItemList={true} setFile={setFile} set_add_items_by={set_add_items_by}/>)
          }
            
            
    </div>
  )
}

export default CustomNewOrderCard