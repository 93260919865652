export const PressReleaseData = [
    {
        "title": "Celebration of Artisanal Craftsmanship",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/301708947085.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/News Article-01.pdf"
    },
    {
        "title": "Kalasha launches Bespoke Collection",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/291708947049.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/News Article-02.pdf"
    },
    {
        "title": "6th Anniversary Celebrations",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/281708946998.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/News Article-03.pdf"
    },
    {
        "title": "6 Senses Collection Launch",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/271708946937.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/News Article-04.pdf"
    },
    {
        "title": "6th Anniversary Celebrations",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/261708946884.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/News Article-05.pdf"
    },
    {
        "title": "6th Anniversary Celebrations",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/251708946549.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/News Article-09.pdf"
    },
    {
        "title": "Luxury Handcrafted Jewels",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/241654248303.jpeg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/handicrafted.pdf"
    },
    {
        "title": "The Scintillating Affair of Jewels",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/231654248270.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/affair.pdf"
    },
    {
        "title": "A Bride's Best Friend",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/221654248216.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/brides-best-friend.pdf"
    },
    {
        "title": "The Royal Indian Heritage",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/211654247949.jpeg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/royal-indian.pdf"
    },
    {
        "title": "The Timeless Allure",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/201654247744.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/coimbatore.pdf"
    },
    {
        "title": "Dazzling Jewellery",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/191621335081.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/dazzling-jewllery.pdf"
    },
    {
        "title": "Shining Bright",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/181621335058.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/shining-bright.pdf"
    },
    {
        "title": "Rakul Preeth wearing our jewellery for Magazinr Cover Shoot",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/171621335038.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/rakul-preet.pdf"
    },
    {
        "title": "Hebah Patel wearing our Jewellery for Magazine Cover Shoot",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/161621335010.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/hebah-patel.pdf"
    },
    {
        "title": "Adapting Quickly to New Changes is the Key to Growth",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/151621334941.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/rji-magazine.pdf"
    },
    {
        "title": "Best Ring Design - 2020 by IJ awards",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/141620399074.jpg",
        "link": "https://www.youtube.com/watch?v=QdETJ-9xAo4"
    },
    {
        "title": "Tulip magazine cover shoot",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/131620399031.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/nandini-rai.pdf"
    },
    {
        "title": "A glitter event by Kalasha. ",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/121620398919.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/glitter-event.pdf"
    },
    {
        "title": "Gold - Shines in gloom",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/111597057040.jpeg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/deccan-chronicle.pdf"
    },
    {
        "title": "Gold Demand During Pandemic",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/101597056423.jpeg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/retail-jewellers.pdf"
    },
    {
        "title": "Post By : IJ News Service",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/91588771545.jpeg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/8.pdf"
    },
    {
        "title": "kalasha fine jewllery now in bengaluru",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/71583752344.jpg",
        "link": "https://kalashajewels.com/press3.php"
    },
    {
        "title": "City gets a new Luxe jewellery destination",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/61583752327.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/2.pdf"
    },
    {
        "title": "A Customised jewellery design studio",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/51583752314.jpg",
        "link": "https://kalashajewels.com/press1.php"
    },
    {
        "title": "Entrepreneur India",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/41583752290.png",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/7.pdf"
    },
    {
        "title": "The Epitome of Hyderabadi Royalty",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/31583752275.png",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/pdf/6.pdf"
    },
    {
        "title": "Jewellery designed to Exudes poise",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/21583752257.jpg",
        "link": "https://kalashajewels.com/press5.php"
    },
    {
        "title": "A unit of capsgold",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/pressrelease/img/11583752235.jpg",
        "link": "https://www.entrepreneur.com/slideshow/316060#2"
    }
]