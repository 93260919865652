import React from "react";

import Loader from "../../../../Component/Loader";
import { IoCall, IoLocationSharp } from "react-icons/io5";
import "./JewelSmith_Contact.css";
import { copyToClipboard } from "../../../../utils";

function JewelSmithContact({ Location, isLoading }) {



  if (isLoading) {
    return <Loader isLoading />;
  }

  const location_array = Location?.location;

  
  

  const contact_no = [
    { name: "Anand Sangoi", phone: "+91 9821098898", whats_app_no: true },
    { name: "Chirag Sangoi", phone: "+91 9821099035", whats_app_no: true },
    { name: "Sales", phone: "+91 22 43470209", whats_app_no: false },
  ];

  return (
    <div className="contact-us jewelsmith-contact">
      <div className="contact-location">
        <section className="contact-details">
          <h2 style={{ color: "var(--highlight_color)" }}>Contact Us</h2>
          <section className="locations">
            {location_array?.map((location, index) => {
              const location_address = location.address?.split("\n");
              ;
              return (
                <div className="details" key={index}>
                  <h3>{location.title}</h3>

                  <div className="call">
                    <span className="label">
                      <IoCall />
                      <span>Contact No.: </span>
                    </span>

                    {contact_no.map((contact) => {
                      const wa_link = contact?.whats_app_no
                        ? `https://api.whatsapp.com/send?phone=${contact?.phone.replace(
                            /[+-\s]/g,
                            ""
                          )}&text=`
                        : `tel:${contact?.phone}`;
                      return (
                        <p key={contact.name} className="value">
                          <b>{contact.name}:</b>
                          <a
                           
                            href={wa_link}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              if (!contact?.whats_app_no && window.innerWidth > 576) {
                                e.preventDefault();
                                
                                copyToClipboard(contact.phone, "Number copied")
                              }
                            }}
                          >
                            {contact.phone}
                          </a>
                        </p>
                      );
                    })}

                    {/* <span className="value">{location.mobile}</span> */}
                  </div>

                  <div className="address">
                    <span className="label">
                      <IoLocationSharp />
                      <span>Address </span>
                    </span>

                    <span className="value">
                      {location_address?.map((p, i) => (
                        <p key={i}>{p}</p>
                      ))}
                    </span>
                  </div>

                  {location?.google_map_url && (
                    <a
                      href={location?.google_map_url}
                      target="_blank"
                      className="btn"
                      rel="noopener noreferrer"
                    >
                      Get Directions
                    </a>
                  )}
                </div>
              );
            })}
          </section>
        </section>
      </div>
    </div>
  );
}

export default JewelSmithContact;
