import React, { useEffect, useState, useRef } from 'react'
import Select from "react-select";
import { createSearchURLParams, get_informationFromSessionID, get_property_settings } from '../../Component/Helper';
import PaginationComp from '../../Component/Pagination/Pagination';
import useFetch from '../../Api/useFetch';
import { ApiList } from '../../Api/ApiList';
import {useLocation, useNavigate} from "react-router-dom"
import {useSelector} from "react-redux"
import { get_is_myc_web } from '../../redux/Reducer/reducer';
import InlineFilter from './Components/InlineFilter/InlineFilter';
import { getCustomValue } from '../../Custom/CompanyList';
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../Custom/CompanyCustomization';


function FilterSortingSection({setShowSideBar, showSideBar, is_pagination_active, is_myc_secure, page, totalProductCount, hasFilterParams, show_matrix_pagination_at_bottom}) {
   
   const primary_BG_color = get_property_settings("btn_primary_color");
  //  const get_HeaderHeight = `${sessionStorage.getItem("headerHeight") ? sessionStorage.getItem("headerHeight") + 'px' : '60px'}`;

  //   const [HeaderHeight, setHeaderHeight] = useState(get_HeaderHeight)
    const user_id = get_informationFromSessionID("userID") || ""
    const company_code = get_informationFromSessionID("companyCode")
    const [selectedSortOption, setSeletedSortOption] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const is_myc_web = useSelector(get_is_myc_web)
    const position = is_myc_web || window.innerWidth > 768 ? "position-sticky" : "position-relative"
    const filterSortingRef = useRef(null)
   const show_inline_matrix_filter_option = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_inline_matrix_filter_option)

    let searchParams = Object.fromEntries(
      new URLSearchParams(location.search)
    )
    const { data: sortOptions } = useFetch(ApiList.sortParams, 'sort-params', {company_code, user_id})
 
    useEffect(()=>{
    
      sessionStorage.setItem("filterSortingHeight", filterSortingRef?.current?.offsetHeight || 0)
      
      const defaultSort = sortOptions?.find(sort => sort.default === "Yes")
      if(sortOptions && !searchParams?.sort){
        setSeletedSortOption(defaultSort)
      }else {
        if(selectedSortOption?.value === searchParams?.sort) return;
        if(sortOptions){
          const selectedSort = sortOptions?.find(sortOpt => sortOpt.value === searchParams.sort)
          if(selectedSort){

            setSeletedSortOption(selectedSort)
          }else{
            setSeletedSortOption(defaultSort)
          }
       }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOptions, searchParams?.sort])

    
    
    const HeaderMenuHeight = sessionStorage.getItem("HeaderMenu")
    
  return (
    <section
        className={`ec-page-content w-100 mx-auto filter-section ${position}`}
        style={{ zIndex: "6", top: HeaderMenuHeight ? HeaderMenuHeight + "px" : '0px'}}
        ref={filterSortingRef}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="ec-shop-rightside col-lg-12 col-md-12 p-0">
              <div className="ec-pro-list-top d-flex  justify-content-between">
                <div className={`col-md-2 col-lg-3 justify-content-start ec-grid-list align-items-center ${show_inline_matrix_filter_option ? "d-lg-none d-flex" : "d-flex"}`}>
                  <div className="ec-gl-btn ">
                    {hasFilterParams && <button
                      className="btn filter-by sidebar-toggle-icon w-100 d-lg-none d-flex"

                      onClick={() => {
                        if(is_myc_secure && !user_id) return navigate("/login")
                        setShowSideBar(!showSideBar)
                      }}
                    >
                      <div>
                        <i className="fi-rr-filter"></i>
                      </div>
                      <div className=" align-self-center mx-1">
                        <span>Filter</span>
                      </div>
                    </button>}
                  </div>
                </div>

                <div className={show_inline_matrix_filter_option ? "col-lg-9 col-md-6" : "col-md-6"}>
                {is_pagination_active && !show_matrix_pagination_at_bottom ? <PaginationComp clsName='top-header-filtered-pagination d-none d-md-flex' size='medium' page={page} totalProductCount={totalProductCount}/> : show_inline_matrix_filter_option ? <InlineFilter /> : null}
                </div>

                <div className="col-md-3 ec-sort-select">
                  <span className="sort-by d-lg-block d-none">Sort by</span>
                  
                  <div className="select-wrapper">
                    <Select
                      defaultValue={null}
                      value={selectedSortOption}
                      options={sortOptions}
                      isSearchable={false}
                      isClearable={false}
                      onChange={(option) => {
                        if(is_myc_secure && !user_id) return navigate("/login")
                        setSeletedSortOption(option)
                        const url = createSearchURLParams('matrix-page', {...searchParams, sort: option.value})
                        navigate(url)
                      }}
                      className="react-select-container r-container"
                      classNamePrefix="react-select r-select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        border: "none",
                        colors: {
                          ...theme.colors,
                          primary25: (primary_BG_color && primary_BG_color.replace("rgb", "rgba").replace(")", ",0.2)") )|| "inherit",
                          primary: primary_BG_color,
                        },
                      })}

                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default FilterSortingSection;
