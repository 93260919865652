import { forwardRef, useImperativeHandle, useState } from "react";
import MUIModal from "../MUIModal/MUIModal";
import { IoMdClose } from "react-icons/io";
import "./QRCodeModal.css"
import QRCode from "react-qr-code";

function QRCodeModal({QRData, title}, ref) {

    const [open_QR_code_modal, set_open_QR_code_modal] = useState(false);
    const handle_close_QR_code_modal =  () => set_open_QR_code_modal(false);
  
    const handle_open_MUI_modal = () => set_open_QR_code_modal(true);
  
    useImperativeHandle(ref, () => ({
      open_modal: () => handle_open_MUI_modal(),
    }));

  return (
    <MUIModal
      className="QR-code-modal"
      open_MUI_modal={open_QR_code_modal}
      handle_close_MUI_modal={handle_close_QR_code_modal}
      style={{ p: 0, width: "min(400px, 90%)" }}
    >
      <header>
        <h3>{title}</h3>
        <IoMdClose className="close-icon" onClick={handle_close_QR_code_modal}/> 
      </header>

      <section className="QR-code-modal-body">
        <QRCode
        value={QRData}
        size={window.innerWidth > 768 ? 300 : 256}
        >

        </QRCode>
      </section>
    </MUIModal>
  )
}

export default forwardRef(QRCodeModal)
