import React, { useEffect, useState } from "react";
import { IoLocationSharp, IoCall } from "react-icons/io5";
import { ApiList } from "../../../Api/ApiList";
import { fetch_data_from_api } from "../../../Api/APICalls";
import Loader from "../../../Component/Loader";

function ContactLocation() {
  const [loading, setLoading] = useState(false);
  const [locations, setLocation] = useState(null);

  async function getLocation() {
    setLoading(true);
    const location_response = await fetch_data_from_api(
      ApiList.Location,
      "location",
      { device: "web" }
    );
    setLocation(location_response?.location);
    setLoading(false);
  }

  useEffect(() => {
    getLocation();
  }, []);

  if (!Array.isArray(locations) || loading) {
    return <Loader isLoading={true} />;
  }

  return (
    <div className="contact-location">
      <section className="contact-details">
        <h2>Contact Us</h2>
        <section className="locations">
          {locations?.map((location, index) => {
            
            const location_address = location.address.split('\n')
            
            return <div className="details" key={index}>
              <h3>{location.title}</h3>
              <div className="address">
                <span className="label">
                  <IoLocationSharp />
                  <span>Address </span>
                </span>

                <span className="value">{location_address.map((p, i) => (
                  <p key={i}>{p}</p>
                ))}</span>
              </div>

              <div className="call">
                <span className="label">
                  <IoCall />
                  <span>Contact No.: </span>
                </span>

                <span className="value">{location.mobile}</span>
              </div>

              {location?.google_map_url && (
                <a
                  href={location?.google_map_url}
                  target="_blank"
                  className="btn"
                  rel="noopener noreferrer"
                >
                  Get Directions
                </a>
              )}
            </div>;
          })}
        </section>
      </section>
    </div>
  );
}

export default ContactLocation;
