import HeaderBottom from "./HeaderBottom";
import HeaderTop from "./HeaderTop";
import "../../../CSS/HomeCSS/Header.css";
import HeaderMenu from "./HeaderMenu";
import HeaderMobileMenu from "./components/MobileMenu/HeaderMobileMenu";
import React, { useEffect, useRef, useState } from "react";
import { get_bulk_settings, get_informationFromSessionID } from "../../Helper";
import { getAllSettings, getCatMaster, getUserDetails, get_is_myc_web } from "../../../redux/Reducer/reducer";
import { useSelector } from "react-redux";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { useQuery } from "@tanstack/react-query";
import { ApiList } from "../../../Api/ApiList";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";

function HomeHeader({ clsName = "" }) {
  const headerRef = useRef(null);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  // let {cdn, company_logo} = useSelector((state) => get_properties_from_settings(state, ["cdn", "company_logo"])) || {}
  // cdn = cdn || get_property_settings("cdn");
  // company_logo = company_logo || get_property_settings("company_logo");
  const settings = useSelector(getAllSettings)
  let {cdn, company_logo} =  get_bulk_settings(["cdn", "company_logo"], settings) || get_bulk_settings(["cdn", "company_logo"])
  const logoImg = `${cdn}uploads/company_logo/${company_logo}`;
  const is_myc_web = useSelector(get_is_myc_web);
  const master = useSelector(getCatMaster);
  const userID = useSelector(getUserDetails)?.userID || get_informationFromSessionID("userID")
  let hide_menu_when_user_not_loggedin = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.hide_menu_when_user_not_loggedin)
  hide_menu_when_user_not_loggedin = !userID && hide_menu_when_user_not_loggedin
  const category_query = useQuery({
    queryKey: ["category", master, userID],
    queryFn: () => fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
      which_master: master,
    }),
    initialData: null,
    enabled: !is_myc_web
  });




  function resizeWindow() {
    if(headerRef.current){

      sessionStorage.setItem("headerHeight", headerRef.current.offsetHeight);
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerRef.current.offsetHeight}px`
      );
    }
  }

  // const handleResize = useCallback(() => resizeWindow, [])

  useEffect(() => {
    
    
    window.addEventListener("resize", resizeWindow);
    sessionStorage.setItem("headerHeight", headerRef.current?.offsetHeight);
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerRef.current?.offsetHeight || 0}px`
    );

    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (openMobileMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [openMobileMenu]);

  if(category_query.isLoading) {
    return null
  }

  

  return (
    <>
    <section className="outerHeader" ref={headerRef} onLoad={() => {
        resizeWindow()
    }}>
      {is_myc_web || window.innerWidth <= 992  ? null : (
        <HeaderTop
          setOpenMobileMenu={setOpenMobileMenu}
          clsName="d-none d-lg-block"
          logoImg={logoImg}
        />
      )}
      <header className={`ec-header main-header ${clsName}`}>
        {/* {is_myc_web || window.innerWidth >= 992 ? null : (
          <HeaderTop
            setOpenMobileMenu={setOpenMobileMenu}
            clsName="d-block d-lg-none"
          />
        )} */}
        {openMobileMenu && (
          <div
            className="ec-side-cart-overlay"
            style={{ display: "block" }}
          ></div>
        )}
        <HeaderBottom logoImg={logoImg} setOpenMobileMenu={setOpenMobileMenu} />
        {/* {is_myc_web ? null : <HeaderMenu categories={category_query?.data}/>} */}
        <HeaderMobileMenu
          setOpenMobileMenu={setOpenMobileMenu}
          openMobileMenu={openMobileMenu}
          categories={category_query?.data}
        />
      </header>
    </section>
    {is_myc_web || hide_menu_when_user_not_loggedin ? null : <HeaderMenu categories={category_query?.data}/>}
    </>
  );
}

export default HomeHeader;
