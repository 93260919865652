export const get_currency_details_from_local = () => {
    return JSON.parse(localStorage.getItem("selected_currency"))
}

export const set_currency_details_to_local = (currency_details) => {
    localStorage.setItem("selected_currency", JSON.stringify(currency_details))
}


function formatPriceWithRupee(price, currencySymbol) {
    const numericPrice = parseFloat(price) || 0;
    if (isNaN(numericPrice)) {
      return "";
    }
    return `${currencySymbol ? "₹" : ""}${new Intl.NumberFormat("en-IN").format(
      price
    )}`;
    
  }

  const currency_convertion = (price, currencySymbol, selected_currency) => {
        if(selected_currency?.id === "3"){ //for USD
            const rate = parseFloat(selected_currency.rate)
            return {price: Math.round(Number(price) / rate), currencySymbol: currencySymbol ? "$" : "", format: selected_currency?.ios_locale.replace("_", "-")}
        }else{
            const rate = parseFloat(selected_currency.rate)
            return {price: Math.round(Number(price) / rate), currencySymbol: currencySymbol ? "$" : "", format: selected_currency?.ios_locale.replace("_", "-")}
        }
  }

  function formatPriceWithOtherCurrency(price, currencySymbol, selected_currency){
    const numericPrice = parseFloat(price) || 0;
    if (isNaN(numericPrice)) {
      return "";
    }
    const convertion_price = currency_convertion(price, currencySymbol, selected_currency)
    return `${convertion_price.currencySymbol}${new Intl.NumberFormat(convertion_price.format || "en-US").format(
        convertion_price.price
      )}`;
  }

  export function formatPrice(price, currencySymbol = true, selected_currency){
    
    if(selected_currency && selected_currency.id !== "1"){
        return formatPriceWithOtherCurrency(price, currencySymbol, selected_currency)
    }else{
        return formatPriceWithRupee(price, currencySymbol)
    }
  }