import AboutUsSection from '../About_Us_Section';



function JEWELSMITH_Home_About_Us_Comp() {
  return (
    <AboutUsSection title="What We Do ">
        <div className="igje-about-comp-info">
            <p>Jewelsmith is a gold jewellery designing and manufacturing house with over three decades of 
experience. We specialize in high-quality casted and handcrafted 22k gold jewellery. Our diverse 
product line includes full wedding sets comprising long harams, necklace sets, bajubands, 
odiyanams, bangles, and a wide range of intricately designed pendant sets, jumkhies, ear studs, 
rings, and bracelets. Additionally, we have a separate section dedicated to lightweight jewellery 
and also offer a stunning collection of antique jewellery. </p> 

<p>Our manufacturing is carried out with stringent quality control in a fully equipped and modern 
factory in the heart of Mumbai. The process includes castings, 3D printing, and intricate 
handwork, ensuring each piece is crafted to perfectio</p>

<p>We proudly serve prestigious clients, including reputed jewellery stores across India, and export 
our products to clients in the Middle East, Southeast Asia, and the USA. </p>
        </div>
    </AboutUsSection>
  )
}

export default JEWELSMITH_Home_About_Us_Comp