import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import WishlistInputModal from "./WishlistInputModal";
import MUIDeleteModal from "../../../Component/MUIDeleteModal/MUIDeleteModal";
import { fetch_data_from_api } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import {
  get_property_settings,
  ToastError,
  ToastSuccess,
} from "../../../Component/Helper";
import { useQueryClient } from "@tanstack/react-query";
import {
  set_inCartWishList,
  set_trigger_cart_btn,
  set_trigger_shortlist_btn,
  setConstants,
} from "../../../redux/Reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import AddRandomProductInputModal from "./AddRandomProductInputModal";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";

export default function NamedWishListCardMenu({
  openCardMenu,
  anchorEl,
  handleCloseCardMenu,
  data,
}) {
  let { wishlist_name, id, product_count } = data;

  product_count = !!Number(product_count);

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const shortlist_label = get_property_settings("shortlist_label");

  const constants = useSelector((state) => state?.reducer?.constants);

  /*---- RENAMED WISHLIST ------------------------------*/
  const [openWishlistInputModal, setOpenWishlistInputModal] = useState(false);
  const handleCloseWishlistInputModal = () => setOpenWishlistInputModal(false);
  const handleOpenWishlistInputModal = () => setOpenWishlistInputModal(true);
  function handle_rename_shortlist() {
    handleCloseCardMenu();
    handleOpenWishlistInputModal();
  }

  /*---- DELETE WISHLIST ------------------------------*/
  const [openMUIdeleteModal, setOpenMUIdeleteModal] = useState(false);
  const handleCloseMUIdeleteModal = () => setOpenMUIdeleteModal(false);
  const handleOpenMUIdeleteModal = () => setOpenMUIdeleteModal(true);
  async function handle_delete_wishlist() {
    try {
      const delete_wishlist_response = await fetch_data_from_api(
        ApiList.delete_wishlist,
        ApiList.delete_wishlist,
        {
          wishlist_name,
          named_wishlist_master_id: id,
        }
      );
      if (
        delete_wishlist_response?.ack === "1" ||
        delete_wishlist_response?.ack === 1
      ) {
        ToastSuccess(delete_wishlist_response?.msg);
        queryClient.invalidateQueries({
          queryKey: ["named_wishlist"],
          exact: true,
        });
        queryClient.invalidateQueries({ queryKey: ["named_wishlist_initial"] });
        if(window.innerWidth < 576){
          dispatch(set_trigger_shortlist_btn(true));
        }
        dispatch(set_inCartWishList({
          in_cart_id_dm: delete_wishlist_response.in_cart_id_dm,
          in_cart_id_im: delete_wishlist_response.in_cart_id_im,
          in_wishlist_id_dm: delete_wishlist_response.in_wishlist_id_dm,
          in_wishlist_id_im: delete_wishlist_response.in_wishlist_id_im,
        }))
        //queryClient.invalidateQueries({ queryKey: ["login"], exact: true });
      } else if (
        delete_wishlist_response?.ack === "0" ||
        delete_wishlist_response?.ack === 0
      ) {
        ToastError(delete_wishlist_response?.error);
      }
    } catch (e) {
      ToastError("Somthing went wrong");
    } finally {
      handleCloseMUIdeleteModal();
    }
  }

  /*---- REMOVE ALL PRODUCT FROM WISHLIST ------------------------------*/
  async function handle_remove_all_product_from_wishlist() {
    handleCloseCardMenu();
    try {
      const remove_all_product_from_wishlist_response =
        await fetch_data_from_api(
          ApiList.empty_wishlist,
          ApiList.empty_wishlist,
          {
            named_wishlist_master_id: id,
          }
        );

      if (
        remove_all_product_from_wishlist_response?.ack === "1" ||
        remove_all_product_from_wishlist_response?.ack === 1
      ) {
        ToastSuccess(remove_all_product_from_wishlist_response?.msg);
        queryClient.invalidateQueries({
          queryKey: ["named_wishlist"],
          exact: true,
        });
        queryClient.invalidateQueries({ queryKey: ["named_wishlist_initial"] });
        if(window.innerWidth < 576){
          dispatch(set_trigger_shortlist_btn(true));
        }
       
        dispatch(set_inCartWishList({
          in_cart_id_dm: remove_all_product_from_wishlist_response?.in_cart_id_dm,
          in_cart_id_im: remove_all_product_from_wishlist_response?.in_cart_id_im,
          in_wishlist_id_dm: remove_all_product_from_wishlist_response?.in_wishlist_id_dm,
          in_wishlist_id_im: remove_all_product_from_wishlist_response?.in_wishlist_id_im,
        }))
        //queryClient.invalidateQueries({ queryKey: ["login"] });
      } else if (
        remove_all_product_from_wishlist_response?.ack === "0" ||
        remove_all_product_from_wishlist_response?.ack === 0
      ) {
        ToastError(remove_all_product_from_wishlist_response?.error);
      }
    } catch (e) {
      ToastError("Somthing went wrong");
    } finally {
      handleCloseMUIdeleteModal();
    }
  }

  /*---- ADD ALL PRODUCT TO CART ------------------------------*/
  async function handle_all_product_to_cart() {
    handleCloseCardMenu();
    try {
      const all_product_to_cart_response = await fetch_data_from_api(
        ApiList.wishlist_addToCartAll,
        ApiList.wishlist_addToCartAll,
        {
          named_wishlist_master_id: id,
          table: "temp_cart",
          session_id: "",
        }
      );

      if (
        all_product_to_cart_response?.ack === "1" ||
        all_product_to_cart_response?.ack === 1
      ) {
        ToastSuccess(all_product_to_cart_response?.msg);
        if (window.innerWidth < 576) dispatch(set_trigger_cart_btn(true));
        dispatch(
          setConstants({ ...constants, count: {...constants.count, count: all_product_to_cart_response?.cart_total?.count} })
        );
        if(window.innerWidth < 576){
          dispatch(set_trigger_cart_btn(true));
        }
        queryClient.invalidateQueries({
          queryKey: ["named_wishlist"],
          exact: true,
        });
        
      } else if (
        all_product_to_cart_response?.ack === "0" ||
        all_product_to_cart_response?.ack === 0
      ) {
        ToastError(all_product_to_cart_response?.error);
      }
    } catch (e) {
      ToastError("Somthing went wrong");
    } finally {
      handleCloseMUIdeleteModal();
    }
  }

  /*---- RANDOM PRODUCT TO CART ------------------------------*/
  const show_random_product_option_in_named_wishlist = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_random_product_option_in_named_wishlist)
  const [openRandomProductInputModal, setOpenRandomProductInputModal] =
    useState(false);
  const handleCloseRandomProductInputModal = () =>
    setOpenRandomProductInputModal(false);
  const handleOpenRandomProductInputModal = () =>
    setOpenRandomProductInputModal(true);
  

  
 
  return (
    <div>
      {openWishlistInputModal && (
        <WishlistInputModal
          openWishlistInputModal={openWishlistInputModal}
          handleCloseWishlistInputModal={handleCloseWishlistInputModal}
          wishlist_name={wishlist_name}
          named_wishlist_master_id={id}
        />
      )}

      {openMUIdeleteModal && (
        <MUIDeleteModal
          openMUIdeleteModal={openMUIdeleteModal}
          item_name={wishlist_name}
          handleCloseMUIdeleteModal={handleCloseMUIdeleteModal}
          handle_delete={handle_delete_wishlist}
        />
      )}

      {openRandomProductInputModal && (
        <AddRandomProductInputModal
        openRandomProductInputModal={openRandomProductInputModal}
        handleCloseRandomProductInputModal={handleCloseRandomProductInputModal}
        data={data}
        />
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openCardMenu}
        onClose={handleCloseCardMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="named-wishlist-card-menu"
      >
        <MenuItem onClick={handle_rename_shortlist}>
          Rename {shortlist_label}
        </MenuItem>

        {product_count ? (
          <MenuItem onClick={handle_all_product_to_cart}>
            Add all product to Cart
          </MenuItem>
        ) : null}

        {product_count && show_random_product_option_in_named_wishlist ? (
          <MenuItem onClick={() => {
            handleCloseCardMenu();
            handleOpenRandomProductInputModal()
          }}>
            Add random product to Cart
          </MenuItem>
        ) : null}

        {product_count ? (
          <MenuItem onClick={handle_remove_all_product_from_wishlist}>
            Remove all product from {shortlist_label}
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            handleCloseCardMenu();
            handleOpenMUIdeleteModal();
          }}
        >
          Delete {shortlist_label}
        </MenuItem>
      </Menu>
    </div>
  );
}
