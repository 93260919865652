import React from "react";
import PhoneInput from "react-phone-input-2";
import { get_property_settings, set_informationToSessionID } from "../../../Component/Helper";

function Telephone({ onChange, value, paramsDataIndex , disabled}) {
  const country_name_shortcode = get_property_settings("country_name_shortcode")

  

  return (
    <PhoneInput
      disabled={disabled}
      inputClass="number-input fieldValue"
      buttonClass="contryCodeDropDown"
      placeholder=""
      value={value}
      country={country_name_shortcode ? country_name_shortcode.toLowerCase() : 'in'}
      masks={{ in: ".........." }}
      inputProps={{
        tabIndex: paramsDataIndex + 1
      }}
      onChange={(phoneNumber, country) => {
        set_informationToSessionID({country_code: country.dialCode})
        onChange(phoneNumber)
      }}
    />
  );
}

export default Telephone;
