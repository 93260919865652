import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";
import { oms_wight_calculation } from "../../custom_order_utils";

function ButtonsInput({
  field,
  add_items_by,
  production_category,
  size_chart,
  className
}) {
  const {
    field_name,
    short_code,
    required_field,
    control_value,
    control_type,
    default_select,
    is_enable,
  } = field || {};
  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useFormContext();

  const size_watch = useWatch({ control, name: "Size" || "size" });
  const typeOfInput =
    control_type?.toLowerCase() === "multiselectdropdown"
      ? "checkbox"
      : "radio";
  const calculate_oms_weight_based_on_design_no = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.calculate_oms_weight_based_on_design_no
  );
  useEffect(() => {
    if (control_type.toLowerCase() === "multiselectdropdown") {
      if (default_select) {
        let value = "";
        if (default_select.includes(",")) {
          value = default_select.split(",");
        } else {
          value = default_select;
        }
        setValue(short_code, value);
      }
    } else {
      if (default_select) {
        setValue(short_code, default_select);
      } else {
        setValue(short_code, control_value.length ? control_value[0] : "");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_select, control_value]);

  useEffect(() => {
    if (
      !calculate_oms_weight_based_on_design_no ||
      add_items_by !== "by design no" ||
      !size_watch
    )
      return;

    const updated_weight = oms_wight_calculation(
      production_category,
      size_chart,
      watch
    );
    setValue("single_wt", updated_weight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size_watch]);

  return (
    <div className="form-input-group">
      <label>
        <span>{field_name}</span>
        {required_field === "1" && <span className="required">*</span>}
      </label>
      <div className={`button-group ${className}`}>
        {control_value?.map((value) => (
          <React.Fragment key={value}>
            <input
              type={typeOfInput}
              style={{ display: "none" }}
              id={short_code + value}
              name={short_code}
              value={value}
              {...register(short_code, {
                required: {
                  value: required_field === "1",
                  message: `${field_name} is required`,
                },
                value:
                  default_select || control_value.length
                    ? control_value[0]
                    : "",
              })}
              disabled={is_enable === "0"}
            />
            <label
              htmlFor={short_code + value}
              
            >
              {value}
            </label>
          </React.Fragment>
        ))}
      </div>
      {errors[short_code] && (
        <p className="error-input">{errors[short_code]?.message}</p>
      )}
    </div>
  );
}

export default ButtonsInput;
