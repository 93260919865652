import ReactSlider from "../../../../../Component/ReactSlider/ReactSlider"


function SpecialJewelleryCard({title, images, info}) {
  return (
    <div className="special-jewellery-card">
        <header>
            <h2>{title}</h2>
        </header>

        <div className="card-wrapper">
            <div className="image-slider">
                <ReactSlider additionalSetting={{fade: true, infinite: true, autoplay: true, autoplaySpeed: 5000}} className="speical-jewellery-slider">
                    {
                        images.map((img, index) => <img src={img} key={`spl-img-${index}`} alt="jewel-pic"/>)
                    }
                </ReactSlider>
            </div>
            <div className="info">
                {info}
            </div>
        </div>
    </div>
  )
}

export default SpecialJewelleryCard