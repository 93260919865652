import { MenuItem } from '@mui/material'
import React from 'react'
import { get_userData_from_activeUser } from '../../../../../../Helper'

function UserDetails({handleClose}) {

  const userData = get_userData_from_activeUser() || {}

  return (
    <MenuItem onClick={handleClose} className="menu-list user-profile">
          <div className="intial">
            {userData?.full_name ? userData?.full_name[0] : "G"}
          </div>
          
          <div className="user-detail">
            <p className="wel-text">Welcome,</p>
            <p className="user-name">{userData?.full_name || "Guest"}</p>
          </div>
        </MenuItem>
  )
}

export default UserDetails