import React from "react";
import { get_bulk_settings } from "../../../Component/Helper";
import noImage from "../../../assets/images/no_image_available.png";
import FullScreenImageSlider from "../../../Component/FullScreenImageSlider/FullScreenImageSlider";
import { MdOutlineZoomIn } from "react-icons/md";

function OrderAccordationCard({
  mfg_code,
  image_path,
  design_files,
  params,
  product_name,
  status_details,
}) {
  const { cdn, default_image } =
    get_bulk_settings(["cdn", "default_image"]) || {};

  const defaultImg = default_image
    ? `${cdn}uploads/default_image/${default_image}`
    : noImage;
  const img = design_files ? `${cdn}${image_path}${design_files}` : defaultImg;

  let fields = [];
  if (Array.isArray(params) && params.length > 0) {
    fields = Object.values(params[0]);
  }

  return (
    <div className="order-accordation-card">
      <div className="image-section" style={{ backgroundImage: `url(${img})` }}>
        <FullScreenImageSlider
          imagesArr={[img.replace("thumb", "zoom")]}
          showThumbnailPreview={false}
        >
          <button className="cart-zoom-image-btn">
            <MdOutlineZoomIn />
          </button>
          <img src={img} alt="pic-cart" />
        </FullScreenImageSlider>
      </div>

      <div className="detail-section">
        {product_name && <div className="full top">{product_name}</div>}
        {mfg_code && <div className="full top">{mfg_code}</div>}
        {Array.isArray(status_details) && status_details.length > 0 && (
          <div className="full top status-group">
            {status_details?.map((status) => (
              <span
                style={{
                  backgroundColor: status?.background_color,
                  color: status?.foreground_color,
                }}
                className="status"
              >
                {status?.status_name}
              </span>
            ))}
          </div>
        )}
        {fields.map((field) => {
          const { label, selected_value, display_width } = field;
          return (
            <div
              key={label}
              className={display_width === "s" ? "half" : "full"}
            >
              <span>{label}: </span>
              <span>{selected_value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OrderAccordationCard;
