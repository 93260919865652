import { useEffect } from "react";
import "./History.css";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";



function History() {
  const imgURL = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/history/history-image.jpg"

  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className="history-page">
        <header>
            <h2
            
            >History</h2>
        </header>
        <article>
            <div className="left-section">
                <p>
                With over 118 year of experience in business CapsGold pvt ltd. stands as the unbeatable market leader in bullion trading since establishment. In the year 1901, Shri. Chanda Papaiah father of Shri. Chanda Anjaiah started a general store by the name M/s Chanda Anjaiah Parameshwar (CAP). Shri. Chanda Anjaiah migrated to Hyderabad city in the year 1925 and started working as an accountant cum cashier at Ramnath Seth - a merchant for a salary of Rs.15 per month. In the year 1940 he continued the family business by diversifying into gold and jewellery trade with an investment of just Rs. 40.
                </p>
                <p>
                Then his sons Shri. Chanda Parameshwar Rao and Shri. Chanda Narashimha Rao joined CAP in the year 1960 and by 1973 the duo has re-structured policies of the group and created effective guidelines in improving the wholesale business of gold and silver. The 3rd generation sons of the duo has entered into the business and expanded it in all possible horizons. They all played a key role in turning a family business into what it is now! Since then, CAP has ascended to success step by step in the jewellery trade by sticking to its values of accountability, integrity and reliability which have endowed it with acclaim and distinction. M/s Chanda Anjaiah Parameshwar (CAP) was renamed as CapsGold Pvt. Ltd., in the year 2009 and is one of the leading gold and silver bullion merchants in India.
                </p>
                <p>
                In the year 2017 KALASHA FINE JEWELS the flagship store of CapsGold was introduced to the world of luxurious fine heritage jewellery under the management of 3rd and 4th generations of Chanda family. Since then the brand as evolved as one of its kind serving the clients the best of jewellery in all categories. Today, buoyed by ever-increasing growth rate and expanding the client base, CAP group is aiming to make its presence felt global by initiating strategically located subsidiaries in India and abroad. Kalasha is the perfect blend of age-old tradition and modern elegance. Experience handcrafted jewellery at its best at Kalasha Fine Jewels.
                </p>
            </div>
            <div className="right-section">
            <img src={imgURL} alt="" />
            </div>
        </article>
    </section>
  )
}

export default History