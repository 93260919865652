import React from 'react'

function FormLabel({paramsData, show_childs_base_on_dependant_shortcodes, depended_child}) {
  return (
    <>
    {
        paramsData?.condition_shortcode === "" ?
          (
            <>
              <label className="label">{paramsData.field_name}</label>
              {paramsData?.required === "1" ? (
                <span className="required-field">*</span>
              ) : null}
            </>
          )
          : paramsData?.condition_shortcode !== "" &&
            show_childs_base_on_dependant_shortcodes &&
            depended_child?.child_short_code.includes(paramsData?.short_code)
            ?
            (
              <>
                <label className="label">{paramsData.field_name}</label>
                {depended_child?.required === "1" ? (
                  <span className="required-field">*</span>
                ) : null}
              </>
            ) : null
      }
      </>
  )
}

export default FormLabel
