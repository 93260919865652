import { useEffect, useState } from "react";
import shipping_billing_params from "./components/shipping_billing_params.json";
import "./style/ShippingBilling_style.css";
import Formfields from "./components/Formfields";
import { useForm } from "react-hook-form";
import {
  createOrder,
  get_payment_gateway_details,
  setFormDataValue,
  vaildate_mobileNo,
} from "./ShippingBillingHelper";
import { useLocation } from "react-router-dom";
import { ToastError } from "../../Component/Helper";
import { useSelector } from "react-redux";
import { get_selected_currency } from "../../redux/Reducer/reducer";

function ShippingBilling() {
  const [hide_shipping_details, set_hide_shipping_details] = useState(true);
  const selected_currency = useSelector(get_selected_currency);
  const location = useLocation();
  
  const TEST_AMOUNT = true;
  
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const AMOUNT = TEST_AMOUNT ? 1 : Number(location?.state?.amount.replace(/,/g, ""));
 
  const onSubmit = async (formData) => {
    // const AMOUNT = Number(location?.state?.amount.replace(/,/g, ""));

    const CURRENCY =
      selected_currency?.currency && selected_currency?.currency === "Rupee"
        ? "INR"
        : selected_currency?.currency && selected_currency?.currency === "USD"
        ? "USD"
        : "INR";
        
    const is_vaildate_mobileNo = vaildate_mobileNo(formData, setError);

    if (is_vaildate_mobileNo.status) {
      is_vaildate_mobileNo.errors.forEach((err) => {
        setError(...err);
      });
      return;
    }

    
    const modify_form_data = {
      ...setFormDataValue(formData),
      order_remarks: "",
      amount: AMOUNT,
    };
    
    
    // return console.log(modify_form_data)
    
    
    /******* RAZORPAY CREATE ORDER ******************************/

    const order = await createOrder(AMOUNT, CURRENCY);

    if (order?.error) {
      return ToastError(order?.error?.description || "Somthing went wrong");
    } else {
      get_payment_gateway_details(order, modify_form_data, formData?.adhaar_card_image, formData?.pan_card_image);
    }
  };

  

  return (
    <section
      className="ShippingBilling_Section"
      onLoad={() => window.scrollTo(0, 0)}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Formfields
          header="Billing Details"
          fields={shipping_billing_params?.billing}
          control={control}
          register={register}
          errors={errors}
          AMOUNT={Number(location?.state?.amount.replace(/,/g, "")) || AMOUNT}
        />

        <div className="copy_to_shipping">
          <input
            type="checkbox"
            className="copy_to_shipping_input"
            id="copy_to_shipping"
            checked={hide_shipping_details}
            onChange={() => set_hide_shipping_details((pre) => !pre)}
          />
          <label htmlFor="copy_to_shipping">
          Same as shipping details
          </label>
        </div>

        {!hide_shipping_details && (
          <Formfields
            header="Shipping Details"
            fields={shipping_billing_params?.shipping}
            control={control}
            register={register}
            errors={errors}
          />
        )}

        <button className="submit-btn" type="submit">
          Proceed to Payment
        </button>
      </form>
    </section>
  );
}

export default ShippingBilling;
