import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import "./AllOrderModal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(400px, 90%)",
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AllOrderModal({
  openAllOrderModal,
  handleCloseAllOrderModal,
  handleUserProfileMenuClose = () => {},
  custom_order_label
}) {
 

  useEffect(() => {
    document.body.classList.add("hide-user-profile-dropdown");
  }, []);

  return (
    <Modal
      open={openAllOrderModal}
      onClose={() => {
        handleCloseAllOrderModal();
        handleUserProfileMenuClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="all-orders-modal"
    >
      <Box sx={style}>
        <header>
          <h3>All Order</h3>
          <IoMdClose className="close-btn" onClick={() => {
            handleCloseAllOrderModal();
            handleUserProfileMenuClose();
          }}/>
        </header>
        <ul>
          <li li onClick={() => {
            handleCloseAllOrderModal();
            handleUserProfileMenuClose();
          }}>
            <Link to="/all-order">Web Order</Link>
          </li>
          <li onClick={() => {
            handleCloseAllOrderModal();
            handleUserProfileMenuClose();
          }}>
            <Link to={`/${custom_order_label.toLowerCase().replace(/\s/g, "-")}`}>
              {custom_order_label}
            </Link>
          </li>
        </ul>
      </Box>
    </Modal>
  );
}

export default AllOrderModal;
