import React, { useEffect, useRef, useState } from "react";
import {
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings,
  ToastSuccess,
} from "../../../../Component/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_fsv_products,
  get_fsv_products_calc,
  get_fsv_quantity,
  get_fsv_remark,
  get_fsv_selected_product,
  get_fsv_selected_subProducts,
  get_fsv_sets_quantity,
  get_inCartWishList,
  getCatMaster,
  set_trigger_cart_btn,
  set_trigger_shortlist_btn,
  setConstants,
} from "../../../../redux/Reducer/reducer";
import { set_cart_params } from "../../Helper";
import { fetch_data_from_api, fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { ApiList } from "../../../../Api/ApiList";
import NamedWishlistPopUp from "../../../../Component/NamedWishlistPopUp/NamedWishlistPopUp";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { handle_remove_product_from_named_wishlist } from "../../../NamedWishlist/named_wishlist_helper";
import { get_search_params } from "../../../../utils";

function FSVFooter({handle_close_fsv_matrix_modal = null, set_ATC_button_label}) {
  const navigate = useNavigate();
  const FSVFooterRef = useRef();
  const selected_subProducts = useSelector(get_fsv_selected_subProducts);
  const selectedProduct = useSelector(get_fsv_selected_product);
  const products_and_subproducts = useSelector(get_fsv_products);
  const product_calc = useSelector(get_fsv_products_calc);
  const remarks = useSelector(get_fsv_remark) || "";
  const quantity = useSelector(get_fsv_quantity) || "1";
  const sets_quantity = useSelector(get_fsv_sets_quantity);
  const dispatch = useDispatch();
  const master = useSelector(getCatMaster)
  const inCartWishList = useSelector(get_inCartWishList);
  const inCartWishlist = master === "design_master" ? "in_wishlist_id_dm" : master === "inventory_master" ? "in_wishlist_id_im" : "";
  const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development";
  const {mode} = get_search_params()
  const named_wishlist_mode = mode === "named_wishlist"
  const shortlist_label = get_property_settings("shortlist_label").toUpperCase()
  const location = useLocation()
  const cartData = location.state?.cartData;
  const show_update_cart_btn = mode === "homescreen" && !!cartData
  
  /* NamedWishlistPopUp*/
  const { enable_shortlist, show_named_wishlist_app } = get_bulk_settings([
    "enable_shortlist",
    "show_named_wishlist_app",
  ]);
  const [openNmaedWishlistpopupModal, setOpenNmaedWishlistpopupModal] =
    useState(false);
  const handleCloseWishlistpopupModal = () =>
    setOpenNmaedWishlistpopupModal(false);
  const handleOpenseWishlistpopupModal = () =>
    setOpenNmaedWishlistpopupModal(true);
  const show_named_wishlist =
    enable_shortlist === "Yes" && show_named_wishlist_app === "Yes";

    const queryClient = useQueryClient()
    
    const named_wishlist_query = useQuery({
      queryKey: ["named_wishlist"],
      queryFn: () =>
        fetch_data_from_api_from_query(ApiList.get_named_wishlist_list, "named_wishlist", {
          product_id: selectedProduct[`${master}_id`]
        }),
      enabled: show_named_wishlist
    });

    async function handleNamedWishlist () {
      const userId = get_informationFromSessionID("userID");
      if (!userId) {
        return navigate("/login");
      }else if(inCartWishList?.[inCartWishlist]?.length > 0 &&
        inCartWishList?.[inCartWishlist]?.includes(selectedProduct.design_master_id)){
          await handle_remove_product_from_named_wishlist(selectedProduct[`${master}_id`], master)
          queryClient.invalidateQueries({ queryKey: ["named_wishlist_initial"] });
          queryClient.invalidateQueries({ queryKey: ['login'] })
        }else{

          handleOpenseWishlistpopupModal();
        }
    }

  async function addToCart(type) {
    const userId = get_informationFromSessionID("userID");
    if (!userId) {
      return navigate("/login");
    }

    const add_to_cart_params = [];
    const random = cartData ? cartData?.random :  "";

    if (selected_subProducts?.length > 0) {
      selected_subProducts.forEach((subProd) => {
        products_and_subproducts.forEach((prod) => {
          if (prod.category_name === subProd) {
            add_to_cart_params.push(
              set_cart_params(
                prod,
                product_calc[prod.category_name],
                type,
                quantity,
                remarks,
                master,
                sets_quantity,
                random
              )
            );
          }
        });
      });
    } else {
      add_to_cart_params.push(
        set_cart_params(
          selectedProduct,
          product_calc[selectedProduct.category_name],
          type,
          quantity,
          remarks,
          master,
          sets_quantity,
          random
        )
      );
    }

    IS_DEVELOPMENT_MODE && console.log(add_to_cart_params);
    const encoded_add_to_cart_params = encodeURI(
      JSON.stringify(add_to_cart_params)
    )
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/%5B/g, "[")
      .replace(/%5D/g, "]");
    const API_ENDPOINT = show_update_cart_btn ? `${ApiList.cart}/${ApiList.updateCart}` : `${ApiList.cart}/${ApiList.addToCart}`
    const params = show_update_cart_btn ? {
      Update_Cart: encoded_add_to_cart_params
    } : {
      Add_Cart: encoded_add_to_cart_params,
    }
    const Add_Cart_Wishlist_Response = await fetch_data_from_api(
      API_ENDPOINT,
      "add-to-cart",
      params
    );

    if (Add_Cart_Wishlist_Response.constructor === Object) {
      if (window.innerWidth <= 575) {
        type === "cart" && dispatch(set_trigger_cart_btn(true));
        type === "wishlist" && dispatch(set_trigger_shortlist_btn(true));
      } else {
        ToastSuccess(Add_Cart_Wishlist_Response.msg);
      }

      dispatch(
        setConstants({
          count: Add_Cart_Wishlist_Response?.cart_total,
        })
      );

      queryClient.invalidateQueries({ queryKey: ["login", userId], exact: true});
     setTimeout(() => {
      if(!!set_ATC_button_label){

        set_ATC_button_label()
      }
     }, 200)

      if(!!handle_close_fsv_matrix_modal){
        handle_close_fsv_matrix_modal()
      }
    }
  }

  useEffect(() => {
    if (!FSVFooterRef?.current) return;
    sessionStorage.setItem(
      "FSVFooterHeight",
      FSVFooterRef?.current?.offsetHeight
    );
  }, []);

  return (
    !show_update_cart_btn ? (
      <div
      className="ec-single-qty d-flex position-sticky bottom-0 bg-white fsv-footer"
      ref={FSVFooterRef}
    >
       {/* NAMED LIST POPUP / MODAL */}
       {show_named_wishlist && openNmaedWishlistpopupModal ? (
        <NamedWishlistPopUp
          openNmaedWishlistpopupModal={openNmaedWishlistpopupModal}
          handleCloseWishlistpopupModal={handleCloseWishlistpopupModal}
          data={named_wishlist_query?.data}
          product_id = {selectedProduct[`${master}_id`]}
        />
      ) : null}
      {named_wishlist_mode ? null : <div>
        <div className="ec-single-cart my-3 text-end cart-footer-btn">
          <button
            className="btn secondary_button_border btn_secondary_foreground_color btn_secondary_color border_radius btn-secondary"
            onClick={async () => {
              if (show_named_wishlist) {
                handleNamedWishlist()
              } else {
                addToCart("wishlist");
              }
            }}
          >
           {show_named_wishlist && inCartWishList?.[inCartWishlist]?.includes(selectedProduct.design_master_id) ? "REMOVE" : `ADD TO ${shortlist_label}` }
          </button>
        </div>
      </div>}
      <div className="cart-footer-btn-wrapper">
        <div className="ec-single-cart my-3 text-end cart-footer-btn">
          <button
            className="btn btn-primary button_background_color"
            onClick={() => addToCart("cart")}
          >
            ADD TO CART
          </button>
        </div>
      </div>
    </div>
    ) : (
      <div
      className="ec-single-qty d-flex position-sticky bottom-0 bg-white fsv-footer"
      ref={FSVFooterRef}
    >
      <button className="btn btn-primary button_background_color update-cart-btn"
      onClick={() => addToCart("cart")}
      >Update Cart</button>
    </div>
    )
    
  );
}

export default FSVFooter;
