import { useFormContext } from "react-hook-form";
import "../InputField.css";
import dayjs from "dayjs";


function DatePicker({fields}) {
    const {register, formState: {errors}} = useFormContext()
    const {field_name, short_code, required, value} = fields || {};
  return (
    <div className='input-group textfield'>
        <label>{field_name}{required === "1" ? <span className="required">*</span> : null}</label>
        <input type="date" {...register(short_code, {
          required: {
            value: required === "1",
            message: `${field_name} is required`
          },
          value: value === "0000-00-00" ? "" : dayjs(value).format('YYYY-MM-DD')
          
        })}/>
        {errors[short_code] ? <p className="error-text">{errors[short_code]?.message}</p> : null}
    </div>
  )
}

export default DatePicker