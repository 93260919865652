
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { ApiList } from "../../../../Api/ApiList";
import HomeBanner from "../../../../Component/HomeComponents/HomeBanner/HomeBanner";
import "./KalashaOnlineLoginPage.css";
import Loader from "../../../../Component/Loader";
import LoginContainer from "../../Components/LoginContainer";
import { useState } from "react";

function KalashaOnlineLoginPage() {
  const [showOptContainer, setShowOptContainer] = useState(false);
    const homescreen_query = useQuery({
        queryKey: ["homescreen"],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.homeScreen, "homescreen", {
            device: "web",
          }),
        placeholderData: (previousData, previousQuery) => previousData,
        staleTime: Infinity,
      });

      if(homescreen_query.isLoading){
        return <Loader isLoading={true}/>
      }

  return (
    <section className="kalasha-online-login-page">
        
          <HomeBanner brandingData={homescreen_query?.data?.branding} />
        
        <div className="login-container-wrapper">
            <header><h2>{showOptContainer ? "Verify OTP" : "Login to View Our Collection"}</h2></header>
            <LoginContainer showOptContainer={showOptContainer} setShowOptContainer={setShowOptContainer}/>
        </div>
    </section>
  )
}

export default KalashaOnlineLoginPage
