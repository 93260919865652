import React, { useRef } from "react";
import { FaWhatsapp } from "react-icons/fa";
import WhatsAppModal from "../../WhatsAppModal/WhatsAppModal";

function WhatsappForSmallDevice({whatsAppNo, encodedMessage, showInMobile, whatsApp_contact_count}) {
  const open_whatsapp_modal_ref = useRef(null)
  if(!showInMobile){
    return null
  }
  
  return (
    <div className="ec-nav-panel-icons d-flex d-sm-none">
      {whatsApp_contact_count > 1 && <WhatsAppModal
        encodedMessage={encodedMessage}
        ref={open_whatsapp_modal_ref}
      />}
      <a
        className="toggle-cart ec-header-btn ec-side-toggle header-buttons"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?phone=${whatsAppNo}&text=${encodedMessage}`}
        onClick={(e) => {
          if (
            whatsApp_contact_count > 1 &&
            open_whatsapp_modal_ref.current
          ) {
            e.preventDefault();

            open_whatsapp_modal_ref.current?.open_modal();
          }
        }}
      >
        <FaWhatsapp className="footer-icons" />
      </a>

      <span className="footer-icons-text">WhatsApp</span>
    </div>
  );
}

export default WhatsappForSmallDevice;
