
import { useEffect } from "react";
import "./KALASHGOLD_About_Us.css";
import { get_bulk_settings } from "../../../Component/Helper";

function KALASHGOLD_About_Us() {

  const {about_us_page_image, cdn} = get_bulk_settings(["about_us_page_image", "cdn"])

  const img = `${cdn}uploads/about_us_page_image/${about_us_page_image}`

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div className="aboutUs">
      <header>
        <h2>About Us</h2>
        
      </header>

      <section>
        <div className="image-section">
            <img src={img} alt="" />
        </div>
        <div className="details-section">
            {/* <h3>Lorem ipsum dolor sit ?</h3> */}
            <p>Established in 2003, KALASH GOLD AND ORNAMENTS Pvt. Ltd. is a leading manufacturer of Turkish, Italian and CZ gold jewellery.
            </p>

           <p>
           With an extensive R&D team working with 200+ in-house creative artisans , our designs are updated daily to fulfil market demands. We manufacture jewellery in 10kt, 14kt, 18kt and 22kt gold and have the capability to produce other carats on order basis with minimum MOQs. Ranging from heavy weight necklaces, chains, bangles & bracelets, to light weight rings, studs & earrings - we boast a large inventory of categories.
           </p>

           <p>
           Headquartered in Mumbai, we serve clients pan-India & export internationally to 5 continents. We have been working with top corporate retail brands that have multiple stores across India and abroad. Additionally we also work with local wholesalers and retailers .
           </p>

           <p>
           Due to our expertise in the manufacturing process, we are able to deliver large quantities and at the same time fulfil individual customised orders for our customers. Keeping our customers at the centre of all our activities, we strive to provide the best after sales services. Consequently we have been rewarded with the most loyal customer base.
           </p>
        </div>
      </section>
    </div>
  )
}

export default KALASHGOLD_About_Us
