import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

function ShowOptionBtn({setDefaultShowOption, DEFAULT_SHOW_OPTIONS, OptionLength, defaultShowOption}) {
    if(OptionLength <=  DEFAULT_SHOW_OPTIONS) return null
    
    const number_show_product = OptionLength - DEFAULT_SHOW_OPTIONS
    
  return (
    <div className='show-option-btn'
    onClick={() => setDefaultShowOption((preValue) => {
        if(preValue.includes(DEFAULT_SHOW_OPTIONS)){
            return [0]
        }else{
            return [0, DEFAULT_SHOW_OPTIONS]
        }
    })}
    >
       {defaultShowOption.length === 1 ? <FaChevronUp/> : <FaChevronDown/> }
      {defaultShowOption.length === 1 ?  <span>Show Less</span> : <span>{number_show_product} More</span> }
      
    </div>
  )
}

export default ShowOptionBtn
