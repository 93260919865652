

export const ManagementData = [
    {
        image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Sreenivas-Sir.png",
        details: {
            name: "Mr. Sreenivas Rao Chanda",
            designation: "Director",
            info: "Following the footsteps of his grandfather and father Shri. Chanda Parameshwar Rao, joined CAP in 1987 and slowly commenced retailing of jewellery and trading of old gold jewellery in Andhra Pradesh. He played a key role in initiating import of gold in south India through NA under OGL and SIL (schemes by govt of India) during 1996."
        }
    },

    {
        image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Srinivas-Sir.png",
        details: {
            name: "Mr. Srinivas Chanda",
            designation: "Director",
            info: "Srinivas Rao son of Shri. Chanda Narashima Rao joined the business in the year 1994. After retailing of jewellery and trading of old gold jewellery established a retail jewellery outlet in the name of M/s CHANDA JEWELLERS in General Bazaar, Secunderabad."
        }
    },

    {
        image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Venkat-Sir.png",
        details: {
            name: "Mr. Venkatesh Chanda",
            designation: "Managing Director",
            info: "Seeing family involved in jewellery trading Venkatesh son of Shri Chanda Parameshwar Rao has joined CAP in the year 1999. He has strengthened the company by effectively handling financial and marketing aspects. He is the one who has transformed the company from family business CAP to CapsGold Pvt. Ltd. in the year 2009."
        },

    },

    {
        image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Sudieer-Sir.png",
            details: {
                name: "Mr. Sudhir Chanda",
                designation: "Director",
                info: "Son of Shri Chanda Parameshwar Rao, Sudhir had joined the family business in the year 2000. Working under the guidance of his father and brothers, he has created his own mark in the business and played a significant role in handling assets of the company. He holds a key role in establishing the jewellery flagship store Kalasha Fine Jewels"
            },
    },

    {
        image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Abhishek-Sir.png",
            details: {
                name: "Mr. Abhishek Chanda",
                designation: "Director",
                info: "Inspired by family legacy, Chanda Abhishek has joined the family business in the year 2011. Under his guidance company adapted “Change Management” and made technological advancements to match evolving market dynamics. Was instrumental in expanding the bullion business to Karnataka & Tamilnadu. Played a vital role in diversification by setting up jewellery flagship retail store “Kalasha Fine Jewels” in Hyderabad and also expansion of retail stores in Vijayawada & Bengaluru."
            },
    },

    {
        image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Akhil.png",
            details: {
                name: "Mr. Akhil Chanda",
                designation: "Director",
                info: "Being motivated by the footsteps of his Father and Grandfathers, Mr. Akhil Chanda – Son of Mr. Venkatesh Chanda joined CapsGold in the year 2020. Under his leadership, CapsGold is undergoing major changes to make it more User-Friendly and accessible to large potential audience. He has been instrumental in expanding the Bullion Delivery branches across India and initiating the Door Delivery Service to all clients. He plays a vital role setting up of CapsGold Precious Metals Refinery which is going to be South India’s Largest Refinery in Maheshwaram,Telangana."
            },
    },

    {
        image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Ankith.png",
            details: {
                name: "Mr. Ankith Chanda",
                designation: "Director",
                info: "Having recently joined the company, Mr. Chanda Ankith son of Mr. Srinivas Chanda brings his dynamic youth to the business. He is quickly proving to be brilliant in taking the company to new levels and adapting to latest technologies."
            },
    },
]