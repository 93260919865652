import "./UPSSingleCard.css";

function UPSSingleCard({title, desc, img}) {
  return (
    <div className="ec_ser_content ec_ser_content_1 col-sm-12 col-md-4 col-lg-3 py-lg-4 ups-single-card">
        <div className="ec_ser_inner justify-content-start ">
            <div className="ec-service-image">
                <img src={img} alt={title}/>
            </div>
            <div className="ec-service-desc">
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>
        </div>
    </div>
  )
}

export default UPSSingleCard
