import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  get_bulk_settings,
  get_userData_from_activeUser,
  show_custom_order_menu,
  ToastError,
  ToastSuccess,
} from "../../Component/Helper";
import { ApiList } from "../../Api/ApiList";
import { useQuery } from "@tanstack/react-query";
import {
  fetch_data_from_api,
  fetch_data_from_api_from_query,
} from "../../Api/APICalls";
import Loader from "../../Component/Loader";
import CustomOrderOtherDetails from "./Components/CustomOrderOtherDetails";
import CustomOrderDetailsStatus from "./Components/CustomOrderDetailsStatus";
import "./CustomOrderDetails.css";
import CustomOrderDetailImage from "./Components/CustomOrderDetailImage";
import NoProduct from "../../Component/NoProduct/NoProduct";
import { BeatLoader } from "react-spinners";

function CustomOrderDetails() {
  const { order_no } = useParams();
  const [cancellingOrder, setCancelingOrder] = useState(false);
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );

  const show_kam_module_custom_order = show_custom_order_menu();
  const user_data = get_userData_from_activeUser();
  const MobileNavBarHeight = sessionStorage.getItem("MobileNavBarHeight")
    ? sessionStorage.getItem("MobileNavBarHeight") + "px"
    : "0px";
  const navigate = useNavigate();
  const {
    append_kam_path_folder,
    Jewelkam_order_listing_row_size,
    user_role_id_for_karigar,
    kam_module_title,
  } = get_bulk_settings([
    "append_kam_path_folder",
    "Jewelkam_order_listing_row_size",
    "user_role_id_for_karigar",
    "kam_module_title",
  ]);

  const is_karigar = user_role_id_for_karigar === user_data?.user_type;

  const kam_order_list_response = useQuery({
    queryKey: ["kam_order_list", order_no],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${append_kam_path_folder}/${ApiList.kam_order_list}`,
        "kam_order_list",
        {
          order_no: order_no || "",
          is_karigar:
            user_role_id_for_karigar === user_data?.user_type ? "yes" : "no",
          client_master_id: user_data?.client_id || "",
          session_id: 0,
          sort: 0,
          page: 0,
          status: searchParams?.status || "",
          page_size: Jewelkam_order_listing_row_size,
        }
      ),
    enabled: show_kam_module_custom_order.status,
  });

  async function handleCancelOrder() {
    setCancelingOrder(true);
    const custom_order_cancel_response = await fetch_data_from_api(
      `${append_kam_path_folder}/${ApiList.jewelkam_cancel_order}`,
      "jewelkam_cancel_order",
      {
        order_id: kam_order_list_response?.data?.order_list[0]?.id,
        status: "6",
        device_type: "web",
        session_id: 0,
      }
    );

    if (custom_order_cancel_response?.ack === "1") {
      setCancelingOrder(false);
      setTimeout(() => {
        navigate(`/${kam_module_title.replace(/\s/g, "-").toLowerCase()}`, {
          state: { refetch: true },
        });
        ToastSuccess(custom_order_cancel_response?.msg);
      }, 1000);
    } else if (custom_order_cancel_response?.ack === "0") {
      setCancelingOrder(false);
      ToastError(custom_order_cancel_response?.error);
    }
    setCancelingOrder(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="custom-order-details-container">
      <header>
        <h2 className="custom-order-details-title title ui title-ui">Order Details</h2>
      </header>
      {kam_order_list_response?.isLoading ||
      !kam_order_list_response?.isSuccess ||
      !kam_order_list_response?.data ? (
        <Loader isLoading={true} />
      ) : kam_order_list_response?.data?.ack === "0" ? (
        <NoProduct
          hideHomeBtn={true}
          errMsg={kam_order_list_response?.data?.error}
        />
      ) : (
        <section className="custom-order-details-body">
          {kam_order_list_response?.data?.order_list[0]?.images?.length > 0 && (
            <CustomOrderDetailImage
              images={kam_order_list_response?.data?.order_list[0]?.images}
              image_path={
                kam_order_list_response?.data?.order_list[0]?.image_path
              }
            />
          )}

          <div className="custom-order-details-body-detail-section">
            <CustomOrderOtherDetails
              order_list={kam_order_list_response?.data?.order_list[0]}
            />
            {kam_order_list_response?.data?.order_list[0]?.status_log?.length >
              0 && (
              <CustomOrderDetailsStatus
                status_details={
                  kam_order_list_response?.data?.order_list[0]?.status_log
                }
              />
            )}

            {!is_karigar && window.innerWidth > 992 && (
              <div className="cancel-order-btn-container inner-btn sticky">
                <button
                  onClick={handleCancelOrder}
                  disabled={cancellingOrder}
                  style={{ opacity: cancellingOrder ? "0.8" : "1" }}
                >
                  {cancellingOrder ? (
                    <BeatLoader color="rgb(255,255,255)" />
                  ) : (
                    "Cancel Order"
                  )}
                </button>
              </div>
            )}
          </div>
        </section>
      )}

      {!is_karigar &&
      kam_order_list_response?.data?.order_list &&
      window.innerWidth < 992 ? (
        <div
          className="cancel-order-btn-container sticky"
          style={{ bottom: MobileNavBarHeight }}
        >
          <button onClick={handleCancelOrder}>Cancel Order</button>
        </div>
      ) : null}
    </div>
  );
}

export default CustomOrderDetails;
