import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(450px, 85%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

function OTPModal({openOTPModal, otpRes, saveEnquire}) {
    const [otp, setOtp] = useState("")
    const [error, setError] = useState("")

    useEffect(() => {
      if(otp.length !== 6 ) return
      
      if(otp === otpRes || Number(otp) === Number(otpRes)){
        
        setError("")
        saveEnquire()
      }else{
        setError("Invaild OTP")
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp])

  return (
    <Modal
        open={openOTPModal}
        // onClose={handleOTPModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="enquiry-form-modal"
      >
        <Box sx={style}>
            <h3 className="OTP-title">Verify OTP</h3>
        <OTPInput
        numInputs={6}
        value={otp}
        onChange={setOtp}
        renderInput={(props) => <input type="number" {...props} />}
        inputStyle="Otp-inputs"
        containerStyle="Otp-container"
        inputType="text"
        />
        {error && <p className="error">{error}</p>}
        </Box>
    </Modal>
  )
}

export default OTPModal