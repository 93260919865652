import React from "react";
import PhoneInput from "react-phone-input-2";
import { get_property_settings, set_informationToSessionID } from "../../../Component/Helper";

function Phonefield({ formParams, clsName = "", errors, onChange, value }) {
  const country_name_shortcode = get_property_settings("country_name_shortcode")

  return (
    <span className={clsName}>
      <label className="label">
        {formParams.field_name}
        {formParams?.required === "1" && (
          <span className="required-field">*</span>
        )}
      </label>
      <PhoneInput
        inputClass="number-input fieldValue"
        buttonClass="contryCodeDropDown"
        placeholder=""
        value={value}
        country={
          country_name_shortcode ? country_name_shortcode.toLowerCase() : "in"
        }
        masks={{ in: ".........." }}
        inputProps={{
          // tabIndex: tabIndex + 1,
        }}
        onChange={(phoneNumber, country) => {
          set_informationToSessionID({ country_code: country.dialCode });
          onChange(phoneNumber);
        }}
      />

      <div className="required-field">
      {errors[formParams?.short_code]?.message || ""}
      </div>
    </span>
  );
}

export default Phonefield;
