import { useNavigate } from "react-router-dom";
import { get_property_settings } from "../../../Component/Helper";
import CustomOrderTotalFooter from "./CustomOrderTotalFooter";

function NewOrder({ setShowStepModal, is_karigar, total, ack }) {
  const kam_module_title = get_property_settings("kam_module_title")
    ?.replace(/\s/g, "-")
    ?.toLowerCase();
  let oms_add_multiple_item_app = get_property_settings(
    "oms_add_multiple_item_app"
  );
  oms_add_multiple_item_app = oms_add_multiple_item_app === "Yes";

  const MobileNavBarHeight = sessionStorage.getItem("MobileNavBarHeight")
    ? sessionStorage.getItem("MobileNavBarHeight") + "px"
    : "0px";
  const navigate = useNavigate();

  function handleNewOrderRedirection() {
    if (oms_add_multiple_item_app) {
      navigate(`/${kam_module_title}/new-${kam_module_title}`);
    } else {
      
      setShowStepModal(true);
    }
  }
  
  return (
    <footer
      style={{ bottom: MobileNavBarHeight }}
    >
      
      <CustomOrderTotalFooter total={total} handleNewOrderRedirection={handleNewOrderRedirection} is_karigar={is_karigar} ack={ack}/>
    </footer>
  );
}

export default NewOrder;
