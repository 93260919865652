import { useQuery } from "@tanstack/react-query";
import { ApiList } from "../../../Api/ApiList";
import {
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
} from "../../../Component/Helper";
import ButtonsInput from "./Inputs/ButtonsInput";
import ButtonsWithInput from "./Inputs/ButtonsWithInput";
import DateInput from "./Inputs/DateInput";
import FileInput from "./Inputs/FileInput";
import TextInput from "./Inputs/TextInput";
import TextInputWithText from "./Inputs/TextInputWithText";
import TextareaInput from "./Inputs/TextareaInput";
import WeightInputs from "./Inputs/WeightInputs";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import Loader from "../../../Component/Loader";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  get_custom_order_items,
  set_custom_order_design_by_image_arr,
} from "../../../redux/Reducer/reducer";
import SelectDropDownInputWithButton from "./Inputs/SelectDropDownInputWithButton";
import InputReadOnlyDesignNo from "./Inputs/InputReadOnlyDesignNo";
import SelectDropDownInput from "../../CustomNewOrder/Components/Inputs/SelectDropDownInput";


function CustomOrderModalStepTwo({ cat_id, add_items_by }) {
  const append_kam_path_folder = get_property_settings(
    "append_kam_path_folder"
  );
  let oms_add_multiple_item_app = get_property_settings(
    "oms_add_multiple_item_app"
  );
  oms_add_multiple_item_app = oms_add_multiple_item_app === "Yes";
  let oms_web_app_catalogue_selection_based_on = get_property_settings(
    "oms_web_app_catalogue_selection_based_on"
  );
  // oms_web_app_catalogue_selection_based_on = "dropdown"

  const userData = get_userData_from_activeUser();
  const user_id = get_informationFromSessionID("userID") || "";
  const { setValue, reset } = useFormContext();
  const dispatch = useDispatch();
  const api_end_point_baseon_order = oms_add_multiple_item_app
    ? ApiList.fetch_category_wise_fields_list
    : `${append_kam_path_folder}/${ApiList.fetch_fields_list}`;
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const added_items = useSelector(get_custom_order_items);
  

  let design_no_of_added_item = "";
  if (add_items_by === "by design no" && searchParams?.update_id) {
    design_no_of_added_item =
      added_items[searchParams?.update_id]?.design_no || "";
  }

  const items_add_by_by_designno_and_image =
    add_items_by === "by design no" &&
    (searchParams?.oms_app_add_by_design_no || design_no_of_added_item)
      ? {
          oms_app_add_by_design_no:
            searchParams?.oms_app_add_by_design_no || design_no_of_added_item,
        }
      : { cat_id };

  const product_category_response = useQuery({
    queryKey: [
      "fetch_fields_list",
      add_items_by,
      items_add_by_by_designno_and_image,
    ],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${api_end_point_baseon_order}`,
        "fetch_fields_list",
        {
          session_id: 0,
          // cat_id,
          client_type: userData?.client_type || "",
          user_id,
          ...items_add_by_by_designno_and_image,
        }
      ).then((res) => {
        if (
          add_items_by === "by design no" &&
          searchParams?.oms_app_add_by_design_no &&
          res?.image_fields &&
          Array.isArray(res?.image_fields)
        ) {
          dispatch(
            set_custom_order_design_by_image_arr({
              design_no: searchParams?.oms_app_add_by_design_no,
              payload: res?.image_fields,
            })
          );
        }

        return res;
      }),
    enabled: !!cat_id || add_items_by === "by design no",
  });

  useEffect(() => {
    if(!searchParams?.update_id || !product_category_response.isSuccess) return
    added_items.forEach((item, index) => {
      if (index.toString() === searchParams?.update_id) {
        for (const key in item) {
          if (key !== "design_no" || key !== "cat_id") {
            
            setValue(key, item[key]);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.update_id, product_category_response.isSuccess]);

  useEffect(() => {
    //remove all error whenever component mount
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (
    product_category_response.isLoading ||
    !product_category_response.isSuccess
  ) {
    return <Loader isLoading={true} />;
  }

  return (
    <div className="product-form">
      <FileInput image_fields={product_category_response.data?.image_fields} />
      {add_items_by === "by design no" && <InputReadOnlyDesignNo />}
      {/* ------ 1. default_fields ------------------------ */}
      {product_category_response?.data?.default_fields &&
        product_category_response?.data?.default_fields?.map((field) => {
          const { control_type, short_code, show_custom_input } = field || {};

          if (control_type.toLowerCase() === "quantity_with_unit") {
            return <TextInputWithText key={short_code} field={field} />;
          } else if (
            control_type.toLowerCase() === "rangetextfield" &&
            short_code === "wt"
          ) {
            return <WeightInputs key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "textarea") {
            return <TextareaInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "date_picker") {
            return <DateInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "textfield") {
            return <TextInput key={short_code} field={field} />;
          } else if (
            control_type.toLowerCase() === "dropdown" &&
            show_custom_input === "1"
          ) {
            return <ButtonsWithInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "dropdown" || control_type.toLowerCase() === "multiselectdropdown") {
            if(oms_web_app_catalogue_selection_based_on === "dropdown" && control_type.toLowerCase() !== "multiselectdropdown"){

            }else{

              return <ButtonsInput key={short_code} field={field} className={oms_web_app_catalogue_selection_based_on === "button" ? "dropdown-button" : "dropdown-buttonflow"}/>;
            }
          } else if (control_type.toLowerCase() === "behalf_client_id") {
            return (
              <SelectDropDownInputWithButton key={short_code} field={field} />
            );
          } else {
            return null;
          }
        })}
      {/* ------ 2. category_fields ------------------------ */}
      {product_category_response?.data?.category_fields &&
        product_category_response?.data?.category_fields?.map((field) => {
          const { control_type, short_code, show_custom_input } = field || {};

          if (control_type.toLowerCase() === "quantity_with_unit") {
            return (
              <TextInputWithText
                key={short_code}
                field={field}
                add_items_by={add_items_by}
                production_category={
                  product_category_response?.data?.category_fields
                }
                size_chart = {product_category_response?.data?.size_chart}
              />
            );
          } else if (
            control_type.toLowerCase() === "rangetextfield" &&
            short_code === "wt"
          ) {
            return <WeightInputs key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "textarea") {
            return <TextareaInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "date_picker") {
            return <DateInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "textfield") {
            return <TextInput key={short_code} field={field} />;
          } else if (
            control_type.toLowerCase() === "dropdown" &&
            show_custom_input === "1"
          ) {
            return <ButtonsWithInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "dropdown" || control_type.toLowerCase() === "multiselectdropdown") {
              if(oms_web_app_catalogue_selection_based_on === "dropdown" && control_type.toLowerCase() !== "multiselectdropdown"){
                return <SelectDropDownInput key={short_code} field={field} add_items_by={add_items_by} production_category={
                  product_category_response?.data?.category_fields
                }
                size_chart = {product_category_response?.data?.size_chart}/>
              }else{
                return <ButtonsInput key={short_code} field={field} add_items_by={add_items_by}
                production_category={
                  product_category_response?.data?.category_fields
                }
                size_chart = {product_category_response?.data?.size_chart}
                className={oms_web_app_catalogue_selection_based_on === "button" ? "dropdown-button" : "dropdown-buttonflow"}
                />;
              }
            
          } else {
            return null;
          }
        })}

      {/* ------ 3. general_fields ------------------------ */}
      {product_category_response?.data?.general_fields &&
        product_category_response?.data?.general_fields?.map((field) => {
          const { control_type, short_code, show_custom_input } = field || {};

          if (control_type.toLowerCase() === "textarea") {
            return <TextareaInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "textfield") {
            return <TextInput key={short_code} field={field} />;
          } else if (
            control_type.toLowerCase() === "dropdown" &&
            show_custom_input === "1"
          ) {
            return <ButtonsWithInput key={short_code} field={field} />;
          } else if (control_type.toLowerCase() === "dropdown") {
            return <ButtonsInput key={short_code} field={field} />;
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default CustomOrderModalStepTwo;
