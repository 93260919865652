import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import {
  set_inCartWishList,
  ToastError,
  ToastSuccess,
} from "../../../Component/Helper";
import { useQueryClient } from "@tanstack/react-query";
import { fetch_data_from_api } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import { get_inCartWishList, set_trigger_cart_btn, setConstants } from "../../../redux/Reducer/reducer";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(400px, 90%)",
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AddRandomProductInputModal({
  openRandomProductInputModal,
  handleCloseRandomProductInputModal,
  data,
}) {
  
  let { wishlist_name, id, product_count } = data || {};
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const queryClient = useQueryClient();
  const  dispatch = useDispatch()
  const inCartWishList = useSelector(get_inCartWishList);
  const constants = useSelector((state) => state?.reducer?.constants);

  const onSubmit = async (formData) => {
    const { random_product_count } = formData || {};

    try {
      const add_random_product_response = await fetch_data_from_api(
        ApiList.AddToCartRandom,
        ApiList.AddToCartRandom,
        { 
          named_wishlist_master_id: id,
          table: "temp_cart",
          session_id: "",
          random_product_count: random_product_count.toString() || "",
         }
      );
      if (
        add_random_product_response?.ack === "1" ||
        add_random_product_response?.ack === 1
      ) {
        ToastSuccess(add_random_product_response.msg);
        queryClient.invalidateQueries({
          queryKey: ["named_wishlist"],
          exact: true,
        });
        
        dispatch(
          setConstants({ ...constants, count: {...constants.count, count: add_random_product_response?.cart_total?.count} })
        );
        if(window.innerWidth < 576){
          dispatch(set_trigger_cart_btn(true));
        }
        dispatch(set_inCartWishList({
          ...inCartWishList,
          in_cart_id_dm: add_random_product_response?.in_cart_id_dm,
          in_cart_id_im: add_random_product_response?.in_cart_id_im,
          in_wishlist_id_dm: add_random_product_response?.in_wishlist_id_dm,
          in_wishlist_id_im: add_random_product_response?.in_wishlist_id_im,
        }));
        
        
      } else if (
        add_random_product_response?.ack === "0" ||
        add_random_product_response?.ack === 0
      ) {
        ToastError(add_random_product_response.error);
      }
    } catch (e) {
      
      //ToastError("Something went wrong");
    } finally {
      handleCloseRandomProductInputModal();
    }
    
  };

  return (
    <Modal
      open={openRandomProductInputModal}
      onClose={handleCloseRandomProductInputModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="create-delete-wishlist-modal"
      disableAutoFocus
    >
      <Box sx={style}>
        <div className="create-wishlist-section">
          <header style={{ flexDirection: "column", alignItems: "center" }}>
            <h3 style={{ marginBottom: "0.5em" }}>
              Add random product to cart
            </h3>
            <p>
              Please enter the number of random products you would like to add
              to your cart from "{wishlist_name}"
            </p>
          </header>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <input
              type="number"
              placeholder={`Enter number`}
              {...register("random_product_count", {
                required: {
                  value: true,
                  message: `Please enter number`,
                },
                min: {
                  value: 1,
                  message: "Number cannot be less than 1",
                },
                max: {
                  value: Number(product_count),
                  message: `Number cannot be greater than ${product_count}`,
                },
                valueAsNumber: true,
              })}
            />
            {errors.random_product_count && (
              <p className="error-text">
                {errors.random_product_count.message}
              </p>
            )}
            <div className="btns">
              <button type="submit">Add to Cart</button>
              <button
                type="button"
                onClick={handleCloseRandomProductInputModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
}

export default AddRandomProductInputModal;
