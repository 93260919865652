import {  memo, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import ImageSlider from "./Components/ImageSlider";
import "./FullScreenImageSlider.css";


function FullScreenImageSlider({children, imagesArr, imagePath, showThumbnailPreview = true}) {
  const OPEN_DIRECTION = "bottom";
  
  

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if(!open){
      sessionStorage.removeItem("active_order_details_slide")
    }
    setState({ ...state, [anchor]: open });
  };

 
  const FullScreenImageSliderWindow = (OPEN_DIRECTION) => (
    <Box
      sx={{
        width:
          OPEN_DIRECTION === "top" || OPEN_DIRECTION === "bottom"
            ? "auto"
            : 250,
      }}
      role="presentation"
      // onClick={toggleDrawer(OPEN_DIRECTION, false)}
      // onKeyDown={toggleDrawer(OPEN_DIRECTION, false)}
    >
      <section className="full-screen-image-container">
        <div className="close-btn" onClick={toggleDrawer(OPEN_DIRECTION, false)}>&times;</div>
        <ImageSlider imagesArr={imagesArr} imagePath={imagePath} showThumbnailPreview={showThumbnailPreview}/>
      </section>
    </Box>
  );

  return (
    <section>
      <div onClick={toggleDrawer(OPEN_DIRECTION, true)}>
        {children}
      </div>
      <SwipeableDrawer
        anchor={OPEN_DIRECTION}
        open={state[OPEN_DIRECTION]}
        onClose={toggleDrawer(OPEN_DIRECTION, false)}
        onOpen={toggleDrawer(OPEN_DIRECTION, true)}
      >
        {FullScreenImageSliderWindow(OPEN_DIRECTION)}
      </SwipeableDrawer>
    </section>
  );
}

export default memo(FullScreenImageSlider);
