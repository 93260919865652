import { createContext, useState } from "react";

export const CustomOrderFileContext = createContext();

function CustomOrderContext({children}) {
  const [file, setFile] = useState([]);

  return (
    <CustomOrderFileContext.Provider value={{ file, setFile }}>
      {children}
    </CustomOrderFileContext.Provider>
  );
}

export default CustomOrderContext;

