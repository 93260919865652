import React from 'react'
import { useFormContext } from 'react-hook-form';

function ButtonsOnly({field}) {
    const { field_name, short_code, required_field, control_value, default_select } = field || {};
    const { register, formState: {errors} } = useFormContext() 
   
  return (
    <div className="form-input-group">
      <label htmlFor={short_code}>
        {field_name}
        {required_field === "1" && <span className="required">*</span>}
      </label>
      <div className="buttons">
        {control_value?.map((value) => {
          return (
            <React.Fragment key={value}>
              <input
                type="radio"
                id={short_code+value}
                style={{ display: "none" }}
                value={value}
                defaultChecked={default_select === value}
                className="radio-input"
                {...register(short_code, {
                  required: {
                    value: required_field === "1",
                    message: `${field_name} is required`
                  }
                })}
              />
              <label htmlFor={short_code+value} name={short_code} className='button-label'>{value}</label>
            </React.Fragment>
          );
        })}

        
      </div>
      {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default ButtonsOnly