import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import Divider from '@mui/material/Divider';
import InputOption from "./Components/InputOption";
import RangeOption from "./Components/RangeOption";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_is_myc_web, setInlineAppliedFilter } from "../../../../../redux/Reducer/reducer";
import {
  applied_filtered_params,
  apply_filter_form_Params,
  is_url_has_form_input_keys,
} from "../../../FilterSideBarOptions/FilterHelper";
import {
  createSearchURLParams,
  delete_informationFromSessionID,
  get_informationFromSessionID,
  set_informationToSessionID,
} from "../../../../../Component/Helper";
import { useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";

function CustomizedMenuList({
  open,
  anchorEl,
  handleClose,
  option,
  optionData,
  allOptionData,
}) {
  const { setValue, getValues } = useFormContext();

  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const is_myc_web = useSelector(get_is_myc_web);
  const filterParams = get_informationFromSessionID("filterParams");
  const clearRangeRef = useRef(null)
  const mendetory =
    option.field === "design_status" &&
    allOptionData["design_status"].length === 1;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const queryClient = useQueryClient()

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  function handleApplyFilter() {
    const data = getValues();
    
    const companyCode = get_informationFromSessionID("companyCode");
    const decryptCompanyName = atob(companyCode);

    const page = "0";
    const named_wishlist_params =
      searchParams.mode === "named_wishlist" ? searchParams : {};
    const defaultParams = {
      cat_id: searchParams?.cat_id || "0",
      featured_id: searchParams?.featured_id || "",
      mode: is_myc_web
        ? "catalogue_filter" 
        : searchParams?.mode === "featuredBased"
        ? "featuredBased_filter"
        : searchParams?.mode || "filter",
      sort: searchParams?.sort || "0",
      img_type: searchParams?.img_type || "",
      wishlist_id: searchParams?.wishlist_id || "",
      ...named_wishlist_params
    };

    const filter_form_Params = apply_filter_form_Params(data);

    set_informationToSessionID({
      filterParams: { ...defaultParams, ...filter_form_Params },
    });

    //const default_value = is_default_value(option.field, fieldType, optionData, data[option.field])
    let fieldName = option.field;
    if (fieldName.includes("price") && decryptCompanyName === "KALASHAONLINE") {
      fieldName = `kalasha_${fieldName}`;
    }

    dispatch(
      setInlineAppliedFilter({
        type: "apply",
        payload: { [fieldName]: data[fieldName] },
      })
    );


    navigate(
      createSearchURLParams("matrix-page", {
        ...defaultParams,
        ...filter_form_Params,
        page,
      }),
      { state: { isAppliedFilter: true } }
    );

    
    // if(searchParams?.mode === "all"){
    //   setTimeout(() => {
        
    //     queryClient.invalidateQueries({ queryKey: ["matrix-count"] })
    //     }, 500)
      
    // }

    handleClose();
  }
  
  function handleClearFilter(setDefault = null) {
    if(clearRangeRef.current){
      clearRangeRef.current.clearRange()
    }
    const companyCode = get_informationFromSessionID("companyCode");

    const decryptCompanyName = atob(companyCode);

    const field = option?.field;
    optionData.forEach((data) => {
      if (data?.type === "range") {
        const default_min_value = Number(data[`min_${field}`]);
        const default_max_value = Number(data[`max_${field}`]);
        if (field.includes("price") && decryptCompanyName === "KALASHAONLINE") {
          setValue(`kalasha_${field}`, {
            minmax: [default_min_value, default_max_value]
          });
        } else {
          setValue(field, { minmax: [default_min_value, default_max_value] });
        }
      } else if (field === "design_status") {
        if (optionData.length === 1) {
          setValue(field, data?.selected === "1" ? data?.id : "");
        } else {
          if (setDefault === "setDefault") {
            const default_design_status = optionData
              .filter((option) => option?.selected)
              .map((option) => option?.id);
            setValue(field, default_design_status);
          } else {
            setValue(field, [optionData[0]?.id]);
          }
        }
      } else {
        setValue(field, data?.selected === "1" ? data?.id : "");
      }
    });
  }

  useEffect(() => {
    //When FilterParam Appy and redirect from FSV to matrix
    
    if (
      (filterParams && location?.pathname?.includes("product-page")) ||
      is_url_has_form_input_keys(searchParams)
    ) {
      const filtered_params_value = applied_filtered_params(searchParams);
      
      for (const key in filtered_params_value) {
        
        const is_mendatory =
          key === "design_status" &&
          allOptionData["design_status"]?.length === 1;
        
        const is_defaultRange = (function(){
          if(key.includes("price") && key.includes("_")){
              const edited_key = key.replace(/^\w+_/g, "") //remove first word "kalasha_"
              return allOptionData[edited_key][0]?.type === "range" &&
          filtered_params_value[key].minmax[0] ===
            allOptionData[edited_key][0][`min_${edited_key}`] &&
          filtered_params_value[key].minmax[1] ===
            allOptionData[edited_key][0][`max_${edited_key}`];
          }else{
            if(!allOptionData[key]) return null
            return allOptionData[key][0]?.type === "range" &&
            filtered_params_value[key].minmax[0] ===
              allOptionData[key][0][`min_${key}`] &&
            filtered_params_value[key].minmax[1] ===
              allOptionData[key][0][`max_${key}`];
          }
          
        })()
          
        if(searchParams?.mode === "product_search_online") continue;
        if (
          (key === "design_status" && !is_mendatory) ||
          (allOptionData[key.includes('price') ? "price" : key][0]?.type === "range" && !is_defaultRange) ||
          (key !== "design_status" && !key.includes('price'))
        ) {
          if(!is_defaultRange){
            
            dispatch(
              setInlineAppliedFilter({
                type: "apply",
                payload: { [key]: filtered_params_value[key] },
              })
            );
          }
        }
      }

      if (filtered_params_value) {
        for (const key in filtered_params_value) {
          setValue(key, filtered_params_value[key]);
        }
      }
    } else {
      handleClearFilter("setDefault");
      delete_informationFromSessionID("filterParams");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.cat_id, open]);

  return (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      className="inline-filter-menu-list"
    >
      {optionData.map((data, index) => {
        if (data?.type === "range") {
          return (
            <MenuItem disableRipple className="menuItem" key={index}>
              <RangeOption data={data} field={option?.field} ref={clearRangeRef}/>
            </MenuItem>
          );
        } else {
          const mendatory_field =
            option?.field === "design_status" &&
            optionData.length === 1 &&
            data?.selected === "1";

          return (
            <MenuItem disableRipple className="menuItem" key={index}>
              <InputOption
                data={data}
                field={option?.field}
                mendatory_field={mendatory_field}
                is_multiselect={option?.is_multiselect === "1"}
              />
            </MenuItem>
          );
        }
      })}

      {!mendetory && (
        <div className="menulist-footer">
          <button className="clear-btn" onClick={handleClearFilter}>
            Clear
          </button>
          <button className="apply-btn" onClick={handleApplyFilter}>
            Apply
          </button>
        </div>
      )}
    </StyledMenu>
  );
}

export default CustomizedMenuList;
