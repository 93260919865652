import React, { useCallback, useEffect, useMemo, useState } from "react";
import MobileSingleMenuList from "./Components/MobileSingleMenuList";
import { Link } from "react-router-dom";
import {
  closeMenuWhenClickedOutside,
  createSearchURLParams,
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
  logOutUser,
} from "../../../../Helper";
import ModalDialog from "../../../../ModalDialog";
import { useSelector } from "react-redux";
import { get_is_myc_web } from "../../../../../redux/Reducer/reducer";
import BrowseByCategory from "../../BrowseByCategory";
import { getCustomValue } from "../../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../../Custom/CompanyCustomization";
import SocialLink from "../SocialLink";
import { RiArrowRightSLine } from "react-icons/ri";
import "./HeaderMobileMenu.css";
import CustomMenuLink from "./Components/CustomMenuLink";
import CallUs from "./Components/CallUs";
import { append_all_product_menu_in_categories } from "../../../../../utils";


function HeaderMobileMenu({ setOpenMobileMenu, openMobileMenu, categories }) {
  const social_link = get_property_settings("social_link");
  const static_pages_group_title = get_property_settings(
    "static_pages_group_title"
  );

  

  const userID = get_informationFromSessionID("userID");
  

  const showBrowseByCategory =
    userID &&
    get_userData_from_activeUser().show_design === "Yes" &&
    get_userData_from_activeUser().show_inventory === "Yes";

  const is_myc_web = useSelector(get_is_myc_web);

  const userName = get_userData_from_activeUser()?.full_name;

  const [showModal, setShowModal] = useState(false);

  const [showCurrency, setShowCurrency] = useState(false);

  const custom_menu = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.custom_menu);

  const handleMouseClick = useCallback(
    (e) => {
      closeMenuWhenClickedOutside(e, showCurrency, setShowCurrency, [
        "dropdown-toggle",
        "dropdown-item",
        "eci-caret-down",
      ]);
    },
    [showCurrency]
  );

  useEffect(() => {
    window.addEventListener("click", handleMouseClick);
    return () => {
      window.removeEventListener("click", handleMouseClick);
    };
  }, [showCurrency, handleMouseClick]);

  
  
  const category_list = useMemo(() => append_all_product_menu_in_categories(categories), [categories])
  
  return (
    <div
      id="ec-mobile-menu"
      className={`ec-side-cart ec-mobile-menu header-mobile-menu ${
        openMobileMenu ? "ec-open" : ""
      }`}
    >
      
      {showModal && (
        <ModalDialog
          show={showModal}
          setShow={setShowModal}
          action={logOutUser}
          toastMsg={"Your Successfully logout"}
          setOpenMobileMenu={setOpenMobileMenu}
        />
      )}
      <div className="ec-menu-title">
        <header>
          <span className="menu_title main-header">
            <span>{userID ? "Welcome" : "Categories"}</span>
            <button
              className="ec-close"
              onClick={() => setOpenMobileMenu(false)}
            >
              ×
            </button>
          </span>
          {userID ? (
            <div className="user-name">
              <span>{userName}</span>
              <Link
                to="/edit-profile"
                className="edit-btn"
                onClick={() => setOpenMobileMenu(false)}
              >
                Edit
              </Link>
            </div>
          ) : null}
        </header>
      </div>
      <div className="ec-menu-inner">
        <div className="ec-menu-content">
          {is_myc_web ? null : (
            <ul>
              <li>
                <Link
                  to="/home"
                  onClick={() => setOpenMobileMenu(false)}
                  className="general-link"
                >
                  <span>Home</span>
                  <RiArrowRightSLine className="r-arrow" />
                </Link>
              </li>
              {/* --- CUSTOME MENU WITH NO STATIC GROUP -------------- */}
              {Array.isArray(custom_menu) ? (
                <CustomMenuLink
                  custom_menu={custom_menu.filter(menu => !menu.static_group)}
                  setOpenMobileMenu={setOpenMobileMenu}
                  static_group_link={false}
                />
              ) : null}

              {showBrowseByCategory && !is_myc_web  ? (
                <li className="browse-by-category-mobile">
                  <h3>Browse by Category</h3>
                  <BrowseByCategory />
                </li>
              ) : (
                <li>
                  <h3 className="main-header-title">Browse by Category</h3>
                </li>
              )}
              {Array.isArray(category_list) &&
                category_list?.map((category) =>
                  category.show_subcat === "Yes" &&
                  category.subcategory.length > 0 ? (
                    <MobileSingleMenuList
                      key={category.id}
                      category={category}
                      setOpenMobileMenu={setOpenMobileMenu}
                    />
                  ) : (
                    <li key={category.id} className="category-menu">
                      <Link
                        to={createSearchURLParams("matrix-page", {
                          cat_id: category.id,
                          mode: category?.cat_name.toLowerCase() === "all products" ? "all" : "filter",
                          page: 0,
                        })}
                        onClick={() => setOpenMobileMenu(false)}
                        className="categoryLinks"
                      >
                        <span>{category?.cat_name}</span>
                        <span className="product-count">
                          {category?.product_count} 
                        </span>
                      </Link>
                    </li>
                  )
                )}
              {/* --- CUSTOME MENU WITH STATIC GROUP -------------- */}
              {custom_menu && (
                <li>
                  <h3 className="main-header-title">
                    {static_pages_group_title}
                  </h3>
                </li>
              )}
              {Array.isArray(custom_menu) ? (
                <CustomMenuLink
                  custom_menu={custom_menu.filter(menu => menu.static_group)}
                  setOpenMobileMenu={setOpenMobileMenu}
                  static_group_link={true}
                />
              ) : null}

              <CallUs />
            </ul>
          )}
        </div>

        {/* -- Social Start -- */}
        <div className="header-res-lan-curr">
          <div className="header-res-social">
            <div className="header-top-social">
              <ul className="mb-0">
                {Array.isArray(social_link) &&
                  social_link?.map((link) => (
                    <SocialLink social_link={link} key={link.short_code} />
                  ))}
              </ul>
            </div>
          </div>
          {/* -- Social End -- */}
        </div>
      </div>
    </div>
  );
}

export default React.memo(HeaderMobileMenu);
