import { useContext, useState } from "react";

import { get_property_settings, ToastError, ToastSuccess } from "../../../Component/Helper";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { get_custom_order_design_by_image_arr, set_custom_order } from "../../../redux/Reducer/reducer";
import { custom_order_save_images, get_plain_values_from_form_data } from "../custom_order_utils";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { CustomOrderFileContext } from "../Context/CustomOrderContext";

function CustomOrderModalFooter({ setShowStepModal, add_items_by, category_name, cat_id }) {
  const { trigger, getValues, watch, setError } = useFormContext();
  const [addingMultiItem, setAddingMultiItem] = useState(false);
  const [addingSingleItem, setAddingSingleItem] = useState(false);
  
  const {setFile} = useContext(CustomOrderFileContext) || {}
  const dispatch = useDispatch();

  let oms_add_multiple_item_app = get_property_settings(
    "oms_add_multiple_item_app"
  );
  oms_add_multiple_item_app = oms_add_multiple_item_app === "Yes";

  let allow_more_than_one_item_in_order_oms = get_property_settings("allow_more_than_one_item_in_order_oms")
  allow_more_than_one_item_in_order_oms = allow_more_than_one_item_in_order_oms === "Yes";

  const primaryColor = get_property_settings('btn_primary_color')
  const secondaryColor = get_property_settings('button_unselected_bg_color')
  const syod_image_required = get_property_settings("syod_image_required") === "Yes"

  const location = useLocation();

  const custom_order_design_by_image_arr = useSelector(get_custom_order_design_by_image_arr)
  

  

  const { update_id, oms_app_add_by_design_no } = Object.fromEntries(
    new URLSearchParams(location.search)
  );
  const navigate = useNavigate();

  async function handleAddItem(type) {
    const is_vaild = await trigger();
    
    if(!is_vaild) return
   
    if(type === "add-repeat-item"){
      setAddingMultiItem(true)
    }else{
      setAddingSingleItem(true)
    }
    
    
    
    let data = getValues();
    data = get_plain_values_from_form_data(data)
   if(!is_vaild || custom_order_design_by_image_arr.length === 0){
    if (!is_vaild  || (data.image_name?.length === 0 && syod_image_required) || (!data.image_name || data.image_name?.length > 5)) {
      if(type === "add-repeat-item"){
        setAddingMultiItem(false)
      }else{
        setAddingSingleItem(false)
      }

      if((custom_order_design_by_image_arr.length === 0 && syod_image_required && data.image_name?.length === 0) || (custom_order_design_by_image_arr.length === 0 && syod_image_required && !data.image_name)){
        setError("image_name", {
          type: "required",
          message: "Please add atleast one image",
        })
       
        ToastError("Please add atleast one image")
      }

      return
    };
  }
    
    if (type === "add-repeat-item") {
      const design_no = add_items_by === "by design no" && oms_app_add_by_design_no ? oms_app_add_by_design_no : ""
      setFile(pre => [...pre, data.image_name])
      
      let category = {}
      if(add_items_by !== "by design no"){
        category = {category_name}
      }
      dispatch(set_custom_order({ type: "add_item", payload: {...data, image_name: [], design_no, cat_id: cat_id || "", ...category} }));

      ToastSuccess("Item added successfully");
      setTimeout(() => {

        setAddingMultiItem(false);
      }, 50)

    } else if (type === "add-item") {
      const design_no = add_items_by === "by design no" && oms_app_add_by_design_no ? oms_app_add_by_design_no : ""
      setFile(pre => [...pre, data.image_name])

      let category = {}
      if(add_items_by !== "by design no"){
        category = {category_name}
      }
     
      dispatch(set_custom_order({ type: "add_item", payload: {...data, image_name: [], design_no, cat_id: cat_id || "", ...category} }));
      setTimeout(() => {
        
        setAddingSingleItem(false);
        navigate(document.location.pathname)
        document.body.style.overflow = "auto";
        setShowStepModal(false);
      }, 50)
    } else {
    }
    
  }

  async function handleUpdateOrder() {
    const is_vaild = await trigger();
    let data = getValues();
    data = get_plain_values_from_form_data(data)
    if(!is_vaild || custom_order_design_by_image_arr.length === 0)
    {if ((!is_vaild || syod_image_required) && (data.image_name?.length === 0 || syod_image_required) && (!data.image_name || data.image_name?.length > 5)) {
      if((syod_image_required && data.image_name?.length === 0) || (syod_image_required && !data.image_name)){
        setError("image_name", {
          type: "required",
          message: "Please add atleast one image",
        })

        //ToastError("Please add atleast one image")
      }

        return 
    }}
    const design_no = add_items_by === "by design no" && oms_app_add_by_design_no ? oms_app_add_by_design_no : ""
    
    const updated_data = custom_order_save_images(data);
    dispatch(
      set_custom_order({
        type: "update_item",
        payload: { index: Number(update_id), data: {...updated_data, design_no} },
      })
    );
    
    
   
    
    // setFile(pre => pre.map((img, index) => {
    //     if(Number(update_id) === index){
    //      return data.image_name
    //     }else{
    //       return img
    //     }
    // }))

    const image_name = watch("image_name")
   
    setFile((preVal) => {
      return preVal.map((segment, segmentIndex) => {
          if(segmentIndex === Number(update_id)){
            return image_name
          }else{
            return segment
          }
      })
    })

    navigate(location.pathname);
    setShowStepModal(false);
  }

  //Trigger onsubmit function in CustomOrderModal file (single custome order)
  function PlaceOrderBtn() {
    return <button className="order-btn">Place Order</button>;
  }

  function UpdateOrderBtn() {
    return (
      <button className="order-btn" onClick={handleUpdateOrder}>
        Update Item
      </button>
    );
  }

  function AddItemBtns() {
    //onClick={() => handleAddItems("add-repeat-item")}
    return (
      <>
        {allow_more_than_one_item_in_order_oms && <button
          className="order-btn border-btn"
          type="submit"
          value="add-repeat-item"
          onClick={() => handleAddItem("add-repeat-item")}
          disabled={addingMultiItem}
        >
          {
            addingMultiItem ? <BeatLoader color={primaryColor}/> : "Add & Repeat"
          }
          
        </button>}
        <button
          className="order-btn"
          type="submit"
          value="add-item"
          onClick={() => handleAddItem("add-item")}
          disabled={addingSingleItem}
        >
          {
            addingSingleItem ? <BeatLoader color={secondaryColor}/> : "Add Item"
          }
          
        </button>
      </>
    );
  }

  return (
    <div
      className={`custom-order-modal-footer ${
        oms_add_multiple_item_app ? "multi-order" : ""
      }`}
    >
      {oms_add_multiple_item_app && !update_id ? (
        <AddItemBtns />
      ) : update_id ? (
        <UpdateOrderBtn />
      ) : (
        <PlaceOrderBtn />
      )}
    </div>
  );
}

export default CustomOrderModalFooter;
