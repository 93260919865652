import * as React from "react";
import Button from "@mui/material/Button";
import MUDropDownMenu from "./Components/MUDropDownMenu";
import IconMenu from "./Components/IconMenu";
import "./UserProfileDropDownMenu.css";


export default function UserProfileDropDownMenu({ clsName = "" }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const is_mobile_navigation_menu = clsName === "mobile-nav-menu";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    document.body.style.overflow = "auto";
    setAnchorEl(null);
    setTimeout(() => {
      document.body.classList.remove("hide-user-profile-dropdown")  
    }, 200)
  };




  return (
    <div className={`user-profile-drop-down-menu ${clsName}`}>
      
      
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="mui-wrapper"
      >
        <IconMenu />
      </Button>

      <MUDropDownMenu
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        is_mobile_navigation_menu={is_mobile_navigation_menu}
       />
    </div>
  );
}
