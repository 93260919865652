import React from 'react'
import {Link} from "react-router-dom";
import { createSearchURLParams } from '../../Helper';




function SingleCategoryCard({cat_name, subcategory, cat_id}) {
   const ParentMenu_Redirection = () => {
        if(Array.isArray(subcategory) && subcategory.length > 0){
            
            return createSearchURLParams('home', {cat_id})
          }else{
            
           return createSearchURLParams('matrix-page', {cat_id, mode:'filter', page: 0})
          }
    }

    const ChildMenu_Redirection = (cat) => {
        if(Array.isArray(cat?.subcategory) && cat?.subcategory.length > 0){
            return createSearchURLParams('home', {cat_id: cat?.id})
            
          }else{
            
            return createSearchURLParams('matrix-page', {cat_id: cat?.id, mode:'filter', page: 0})
          }
    }

    return (
        <div className="block">
            
            <Link className="footer-cat-title" style={{cursor: "pointer"}}
            to={ParentMenu_Redirection()}
            
            >{cat_name} : </Link>
            {
                subcategory.map((cat, index) => (
                <span key={index}>
                    
                    <Link to={ChildMenu_Redirection(cat)}
                    className='categoryLinks'
                    
                    >{cat.cat_name}</Link>
                    {index !== subcategory.length - 1 && <span> | </span>}
                </span>
                ))
            }
            
        </div>
    )
}

export default SingleCategoryCard
