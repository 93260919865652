import React, { useCallback, useMemo, useState } from "react";

import { HiOutlineDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";
import NamedWishListCardMenu from "./NamedWishListCardMenu";
import {
  createSearchURLParams,
  get_bulk_settings,
  get_designStatus,
  ToastError,
} from "../../../Component/Helper";
import noImg from "../../../assets/images/no_image_available.png";
import MasterListModal from "./MasterListModal/MasterListModal";

function NamedWishListCard({ data }) {
  const {
    wishlist_name,
    product_count,
    image_data,
    id,
    design_data,
    inventory_data,
  } = data;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openCardMenu = Boolean(anchorEl);
  const handleOpenCardMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseCardMenu = () => {
    setAnchorEl(null);
  };

  const { default_image, cdn } = get_bulk_settings(["cdn", "default_image"]);

  /* Master List Modal Open / Close */
  const [openMasterListModal, setOpenMasterListModal] = useState(false);
  const handleCloseMasterListModal = () => setOpenMasterListModal(false);
  const handleOpenMasterListModal = () => setOpenMasterListModal(true);

  const defaultImg = default_image
    ? `${cdn}uploads/default_image/${default_image}`
    : noImg;

  const previewImage = Array.from({ length: 3 }, (_, i) => {
    return image_data[i]
      ? image_data[i]
      : image_data[i] === ""
      ? defaultImg
      : null;
  });

  const which_master = useMemo(() => {
    if (
      Number(design_data?.product_count) > 0 &&
      Number(inventory_data?.product_count) > 0
    ) {
      return null;
    } else if (Number(design_data?.product_count) > 0) {
      return {
        table: design_data?.which_master,
        design_status: get_designStatus(design_data?.which_master),
      };
    } else {
      return {
        table: inventory_data?.which_master,
        inventory_status: get_designStatus(inventory_data?.which_master),
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const default_params = {
    mode: "named_wishlist",
    cat_id: "",
    wishlist_id: id,
    page: 0,
    sort: 0,
    };

  const redirection_to_matrix = useCallback((master) => {
    let other_params = which_master ? which_master : {};
    return createSearchURLParams("matrix-page", {
      ...default_params, ...other_params,
    })
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [which_master]);

  return (
    <div className="named-wishlist-card">
      {openMasterListModal && (
        <MasterListModal
          handleCloseMasterListModal={handleCloseMasterListModal}
          openMasterListModal={openMasterListModal}
          listData={data}
          default_params={default_params}
        />
      )}
      <Link
        to={redirection_to_matrix()}
        className="images-section"
        onClick={(e) => {
          if (product_count === "0" || product_count === 0) {
            e.preventDefault();
            ToastError("Please add product");
          } else if (!which_master) {
            e.preventDefault();
            handleOpenMasterListModal()
          }
        }}
      >
        {previewImage.map((img, index) => (
          <div className="image" key={`nimg-${index}`} style={{ backgroundImage: `url(${img})` }}>
            {img ? <img src={img} alt={`nimg-${index}`} /> : null}
          </div>
        ))}
      </Link>
      <div className="details-section">
        <div className="detail">
          <h3 className="named-title">{wishlist_name}</h3>
          <p className="named-product-count">
            <span>{product_count} </span>
            <span>Product(s)</span>
          </p>
        </div>
        <div
          className="menu"
          id="basic-button"
          aria-controls={openCardMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openCardMenu ? "true" : undefined}
          onClick={handleOpenCardMenu}
        >
          <HiOutlineDotsVertical />
        </div>
      </div>
      <NamedWishListCardMenu
        openCardMenu={openCardMenu}
        anchorEl={anchorEl}
        handleCloseCardMenu={handleCloseCardMenu}
        data={data}
      />
    </div>
  );
}

export default NamedWishListCard;
