import CryptoJS from "crypto-js";
import {
  ToastError,
  get_informationFromSessionID,
} from "../Component/Helper";
import axios from "axios";
import { ACTIONS } from "./useFetch";
import { data_filter_function } from "./Filter_API_Data";
import { check_is_myc_web } from "../utils";
import {v4 as uuidv4} from 'uuid';

const activeProdOne = false;
const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development"
const TESTING_LIVE = window.location.origin.includes("staging.jewelflow.link")
export const BASE_URL = IS_DEVELOPMENT_MODE && !activeProdOne ? "https://demo.jewelflow.pro/online_api/v3/" : "https://demo.jewelflow.pro/online_api/v3/";
// export const BASE_URL = "https://prodone.jewelflow.pro/online_api/v3/";
// export const PROXY_BASE_URL = IS_DEVELOPMENT_MODE ? "https://v2.jewelflow.pro" : "https://node.jewelflow.link";
export const PROXY_BASE_URL = "https://node.jewelflow.link";


export const axiosInstance = axios.create({
  baseURL: BASE_URL, // Set your base URL
});

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return encrypted;
};

export const decryptData = (data) => {
  
  const encrypted = data;
  const decrypted = CryptoJS.AES.decrypt(
    encrypted,
    process.env.REACT_APP_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  
  return JSON.parse(decrypted);
};

export async function fetch_data_from_api_from_query(
  api_endpoint,
  proxy_url_endpoint,
  params = {}
) {
  const company_code = get_informationFromSessionID("companyCode") || "";
  const user_id = get_informationFromSessionID("userID") || "";
  const catalogue_id = get_informationFromSessionID("catalogueID") || "";
  const is_myc_web = check_is_myc_web()
  const utm_session_id = uuidv4().toUpperCase();
  const api_params = {
    //Default params
    company_code,
    user_id,
    catalogue_id,
    utm_source_project: is_myc_web ? "myc" : "website",
    //params
    utm_session_id,
    ...params,
  };

  const api_url = BASE_URL + api_endpoint;

  //For Testing Purpose
  const URL = `${api_url}/?${new URLSearchParams(api_params).toString()}`;
  (IS_DEVELOPMENT_MODE || TESTING_LIVE) && console.log(URL);

  const proxy_url = `${PROXY_BASE_URL}/api-call/?${proxy_url_endpoint}`;
  const encrypted_parameters = {
    params: encryptData(api_params),
    api_url: encryptData(api_url),
  };

  const response = await axiosInstance.post(proxy_url, encrypted_parameters);

  if (response.status === 200) {
    const decryptedData = decryptData(response.data);

    const filter_data = data_filter_function(decryptedData, api_endpoint);
    (IS_DEVELOPMENT_MODE || TESTING_LIVE) && console.log(filter_data);
    return filter_data;
  }
}

export async function fetch_data_from_api(
  api_endpoint,
  proxy_url_endpoint,
  params = {},
  dispatch
) {
  if (!api_endpoint || !proxy_url_endpoint) return;

  if (dispatch) {
    dispatch({ type: ACTIONS.API_REQUEST });
  }
  const company_code = get_informationFromSessionID("companyCode") || "";
  const user_id = get_informationFromSessionID("userID") || "";
  const catalogue_id = get_informationFromSessionID("catalogueID") || "";
  const is_myc_web = check_is_myc_web()
  const utm_session_id = uuidv4().toUpperCase();
  const api_params = {
    //Default params
    company_code,
    user_id,
    catalogue_id,
    utm_source_project: is_myc_web ? "myc" : "website",
    //params
    ...params,
    utm_session_id
  };

  const api_url = BASE_URL + api_endpoint;

  //For Testing Purpose
  const URL = `${api_url}/?${new URLSearchParams(api_params).toString()}`;
  (IS_DEVELOPMENT_MODE || TESTING_LIVE) && console.log(URL);

  const proxy_url = `${PROXY_BASE_URL}/api-call/?${proxy_url_endpoint}`;
  const encrypted_parameters = {
    params: encryptData(api_params),
    api_url: encryptData(api_url),
  };

  try {
    const response = await axiosInstance.post(proxy_url, encrypted_parameters);
    
    if (response.status === 200) {
      const decryptedData = decryptData(response.data);

      if (!decryptedData?.error) {
        const filter_data = data_filter_function(decryptedData, api_endpoint);
        (IS_DEVELOPMENT_MODE || TESTING_LIVE) && console.log(filter_data);
        if (dispatch) {
          dispatch({ type: ACTIONS.FETCH_DATA, payload: filter_data });
        } else {
          
          return filter_data;
        }
      } else {
        return decryptedData;
      }
    } else {
      return response?.data;
    }
  } catch (error) {
    if (dispatch) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: decryptData(error?.response?.data),
      });
    } else {
      const HTML_REGEX =
        /<(?=.*? .*?\/ ?>|br|hr|div|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/g;
      if (HTML_REGEX.test(error?.response?.data)) {
        return { statusCode: 500, message: "server error" };
      } else {
        const error_res = decryptData(error?.response?.data);
        
        if (typeof error_res === "string") {
          ToastError(error_res);
          return error_res;
        }
        ToastError(decryptData(error?.response?.data));
        // return decryptData(error?.response?.data)
      }
    }
  }
}


//console.log(decryptData('U2FsdGVkX19Hr74Wq4pkiTOVm4rINxHoL/blhjiW1B9LmtgcOOgdQkQ14B3sNSx8aUNIFi+gDKPpCUJPS8790dUpER1a0JpBKBqmkpXESE+K9cCwB6eEVG1SQTydSVpuO8kHDfwAjMjcqgODF4NF3lt8Mg9aXt/IyLuMsVGbsjU67XBksiruVGsjVTLwL+9znIYkkH2xWkLVv1p9u8Vliqigg2sTIvzGdliBRK4vuuZNfkXJBG7cF6Edvbo95BlBLiptPgP6Wb4QX+6oy+z6EQ=='))