import React from 'react'

import { get_bulk_settings } from '../../../../Component/Helper';
import "./LiveRateBar.css";
import DateTimeUpdate from './Components/DateTimeUpdate';
import { Link } from 'react-router-dom';
import useLiveRateReact from '../../../../Hooks/useLiveRateReact/useLiveRateReact';



function LiveRateBarReact() {
    
    const {live_rate_homescreen_default_shortcode} = get_bulk_settings(["live_rate_homescreen_default_shortcode"])
  
    

      const { live_rate_data } = useLiveRateReact(live_rate_homescreen_default_shortcode);
      
      
    if(!live_rate_data){
        return null
    }


  return (
    <Link className='live-rate-updated-bar' to={"/live-rates"}>
       
        <span className='rate'>{`${live_rate_data?.display_name} RATE: `}{live_rate_data?.bid_rate}</span>
        <DateTimeUpdate />
    </Link>
  )
}

export default LiveRateBarReact