import React, { useMemo } from "react";
import Select from "react-select";
import {
  createSelectArrayForReactSelect,
  get_property_settings,
} from "../../../../../Component/Helper";

function RangeDropdownSelect({
  clsName,
  paramsData,
  paramsDataIndex,
  Controller,
  control,
  disabled
}) {
  const primary_BG_color = get_property_settings("btn_primary_color");
  const from_options = useMemo(() => {
    return [
      { label: "From", value: "" },
      ...createSelectArrayForReactSelect(paramsData?.control_value),
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const to_options = useMemo(() => {
    return [
      { label: "To", value: "" },
      ...createSelectArrayForReactSelect(paramsData?.control_value),
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsName}>
      <Controller
        control={control}
        name={`${paramsData?.short_code}_from`}
        rules={{
          required: {
            value: paramsData?.required === "1",
            message: "This field is required",
          },
        }}
        render={({ field }) => {
          return (
            <Select
              isDisabled={disabled}
              options={from_options}
              defaultValue={from_options[0]}
              className="react-select-container r-container"
              classNamePrefix="react-select r-select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25:
                    (primary_BG_color &&
                      primary_BG_color
                        .replace("rgb", "rgba")
                        .replace(")", ",0.2)")) ||
                    "inherit",
                  primary: primary_BG_color,
                },
              })}
              isSearchable={false}
              {...field}
            />
          );
        }}
      />

      <Controller
        control={control}
        name={`${paramsData?.short_code}_to`}
        rules={{
          required: {
            value: paramsData?.required === "1",
            message: "This field is required",
          },
        }}
        render={({ field }) => {
          return (
            <Select
              isDisabled={disabled}
              options={to_options}
              className="react-select-container r-container"
              classNamePrefix="react-select r-select"
              defaultValue={to_options[0]}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25:
                    (primary_BG_color &&
                      primary_BG_color
                        .replace("rgb", "rgba")
                        .replace(")", ",0.2)")) ||
                    "inherit",
                  primary: primary_BG_color,
                },
              })}
              isSearchable={false}
              placeholder="To"
              {...field}
            />
          );
        }}
      />
    </div>
  );
}

export default RangeDropdownSelect;
