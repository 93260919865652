import { useLayoutEffect, useState } from "react";
import { get_bulk_settings, get_informationFromSessionID } from "../../Helper";
import NamedWishlistPopUp from "../../NamedWishlistPopUp/NamedWishlistPopUp";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import { handle_remove_product_from_named_wishlist } from "../../../Pages/NamedWishlist/named_wishlist_helper";
import { getCatMaster, set_inCartWishList, set_trigger_shortlist_btn } from "../../../redux/Reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import NamedWishlistDropMenu from "./NamedWishlistDropMenu";
import { ScrollToTopCustom } from "../../../utils";
import { useNavigate } from "react-router-dom";

function ShortListIcon({
  inCartWishList,
  item,
  Get_Product_ID_Property_Name,
  handle_cart_and_wishlist,
  show_named_wishlist,
  searchParams,
  matrix_data_count,
}) {
  const { matrix_shortlist_button_position, matrix_cart_button_position } =
    get_bulk_settings([
      "matrix_shortlist_button_position",
      "matrix_cart_button_position",
    ]) || {};
  const order =
    matrix_shortlist_button_position === "topleft" &&
    matrix_cart_button_position === "topright"
      ? "order-1"
      : matrix_shortlist_button_position === "topright" &&
        matrix_cart_button_position === "topleft"
      ? "order-2"
      : "";

  const is_named_wishlist_mode = searchParams.mode === "named_wishlist";
  const wishlist_id = is_named_wishlist_mode
    ? searchParams?.wishlist_id || ""
    : "";
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const openCardMenu = Boolean(anchorEl);
  const handleOpenCardMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseCardMenu = () => {
    setAnchorEl(null);
  };

  const master = useSelector(getCatMaster);
  const queryClient = useQueryClient();
  const dispatch = useDispatch()

  /* NamedWishlistPopUp*/
  const [openNmaedWishlistpopupModal, setOpenNmaedWishlistpopupModal] =
    useState(false);
   
  const handleCloseWishlistpopupModal = (_, scrollYPOS) => {
   
    setOpenNmaedWishlistpopupModal(false)
    
    // if(scrollYPOS === 0){
      
    //   ScrollToTopCustom("instant")
    //   ScrollToTopCustom("instant", 200)
    // }
   
    
    
  }
  const handleOpenseWishlistpopupModal = () => {

  
      setOpenNmaedWishlistpopupModal(true);
    
  }
 
  const named_wishlist_query = useQuery({
    queryKey: ["named_wishlist"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.get_named_wishlist_list,
        "named_wishlist",
        {
          product_id: item[Get_Product_ID_Property_Name()],
        }
      ),
    enabled: show_named_wishlist,
  });

  useLayoutEffect(() => {
    // if(!openNmaedWishlistpopupModal && window.scrollY === 0) {
      
    //     ScrollToTopCustom("instant")
    //     ScrollToTopCustom("instant", 200)
      
     
    // }
  }, [openNmaedWishlistpopupModal])

  async function handleNamedWishlist (action) {
    const userID = get_informationFromSessionID("userID")
    

    if(!userID) {
      navigate("/login")
    }

    else if(action === "add"){

      handleOpenseWishlistpopupModal();
    }else{ // for remove named wishlit
      const productID = Get_Product_ID_Property_Name();
      const remove_product_from_named_wishlist_response = await handle_remove_product_from_named_wishlist(
        item[productID],
        master
      );
      
      await queryClient.invalidateQueries({ queryKey: ["named_wishlist_initial"] });
      if(remove_product_from_named_wishlist_response?.ack === "1" || remove_product_from_named_wishlist_response?.ack === 1){
        dispatch(set_inCartWishList({
          in_cart_id_dm: remove_product_from_named_wishlist_response?.in_cart_id_dm,
          in_cart_id_im: remove_product_from_named_wishlist_response?.in_cart_id_im,
          in_wishlist_id_dm: remove_product_from_named_wishlist_response?.in_wishlist_id_dm,
          in_wishlist_id_im: remove_product_from_named_wishlist_response?.in_wishlist_id_im,
        }))
      }
      //queryClient.invalidateQueries({ queryKey: ["login"] });
      if(window.innerWidth < 576){
        dispatch(set_trigger_shortlist_btn(true));
      }
    }
  
  }

//   if(!openNmaedWishlistpopupModal && window.scrollY === 0) {
      
//     ScrollToTopCustom("instant")
//     ScrollToTopCustom("instant", 200)
  
 
// }

 
  return (
    <div className={`${order}`}>
      {/* NAMED LIST POPUP / MODAL */}
      {show_named_wishlist && openNmaedWishlistpopupModal ? (
        <NamedWishlistPopUp
          openNmaedWishlistpopupModal={openNmaedWishlistpopupModal}
          handleCloseWishlistpopupModal={handleCloseWishlistpopupModal}
          data={named_wishlist_query?.data}
          product_id={item[Get_Product_ID_Property_Name()]}
          
        />
      ) : null}

      {openCardMenu ? <NamedWishlistDropMenu
        openCardMenu={openCardMenu}
        anchorEl={anchorEl}
        handleCloseCardMenu={handleCloseCardMenu}
        product_id={item[Get_Product_ID_Property_Name()]}
        wishlist_id={wishlist_id}
        master={master}
        matrix_data_count={matrix_data_count}
        handleOpenseWishlistpopupModal={handleOpenseWishlistpopupModal}
      /> : null}

      {/* {(!false && inCartWishList?.in_wishlist_id_dm?.length > 0 && */}
      {is_named_wishlist_mode ? (
        <div
          className="  whishlist-selected whishlist-circle bg-transparent"
          id="basic-button"
          aria-controls={openCardMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openCardMenu ? "true" : undefined}
          onClick={handleOpenCardMenu}
        >
          <i
            className="fa-solid fa-ellipsis-vertical"
            style={{ fontSize: "1.5rem", color: "inherit" }}
          ></i>
        </div>
      ) : inCartWishList?.in_wishlist_id_dm?.length > 0 &&
        inCartWishList?.in_wishlist_id_dm.includes(item.design_master_id) ? (
        <div
          className=" whishlist-selected whishlist-circle bg-transparent"
          onClick={async (e) => {
            const productID = Get_Product_ID_Property_Name();
            if (show_named_wishlist) {
              handleNamedWishlist("remove")
            } else {
              handle_cart_and_wishlist("wishlist", item[productID], "remove");
            }
          }}
        >
          <i className="fa-solid fa-heart fa-lg px-2 py-3"></i>
        </div>
      ) : (
        <div
          className="  whishlist-circle bg-transparent"
          onClick={(e) => {
            const productID = Get_Product_ID_Property_Name();
            if (show_named_wishlist) {
              handleNamedWishlist("add")
              
            } else {
              handle_cart_and_wishlist("wishlist", item[productID], "add");
            }
          }}
        >
          <i className="fa-solid fa-heart fa-lg px-2 py-3 "></i>
        </div>
      )}
    </div>
  );
}

export default ShortListIcon;
