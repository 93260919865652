import { useFormContext } from "react-hook-form"

function TextInput({field}) {
    const {field_name, short_code, required_field} = field || {}
    const { register, formState: {errors} } = useFormContext()
    
  return (
    <div className="form-input-group">
        <label htmlFor={short_code}>
          <span>{field_name}</span>
          {required_field === "1" && <span className="required">*</span>}
        </label>
        <input id={short_code} {...register(short_code, {
          required: {
            value: required_field === "1",
            message: `${field_name} is required.`
          }
        
        })}
        type="text"
        />
        {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
      </div>
  )
}

export default TextInput
