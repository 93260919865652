import React, { useRef } from 'react'
import WhatsAppModal from '../../../WhatsAppModal/WhatsAppModal';

function SocialLink({social_link}) {
    const { short_code, url } = social_link || {};
    const open_whatsapp_modal_ref = useRef(null)
  return (
    <li className="list-inline-item">
      {short_code === "social_whatsapp" && <WhatsAppModal ref={open_whatsapp_modal_ref}/>}
      <a href={url} target="_blank" rel="noopener noreferrer"
        onClick={(e) => {
          if(short_code === "social_whatsapp"){
            e.preventDefault()
            if(open_whatsapp_modal_ref.current){
              open_whatsapp_modal_ref.current?.open_modal()
            }
          }
        }}
      >
        {short_code === "social_instagram" ? (
          <i className="ecicon eci-instagram"></i>
        ) : short_code === "social_facebook" ? (
          <i className="ecicon eci-facebook"></i>
        ) :  short_code === "social_linkedin" ? (
          <i className="ecicon eci-linkedin"></i>
        ) : short_code === "social_twitter" ? (
          <i className="ecicon eci-twitter"></i>
        ) : short_code === "social_youtube" ? (
          <i className="ecicon eci-youtube"></i>
        ): short_code === "social_pinterest" ? (
          <i className="ecicon eci-pinterest"></i>
        ): short_code === "social_locate_us" ? (
          <i className="ecicon eci-map"></i>
        ): short_code === "social_whatsapp" ? (
          <i className="ecicon eci-whatsapp"></i>
        ): null}
         {/* <i className="ecicon eci-twitter"></i> */}
    </a>
    </li>
  )
}

export default SocialLink
