import axios from "axios";
import { encryptData, decryptData, PROXY_BASE_URL } from "../../../../Api/APICalls";
import { get_informationFromSessionID } from "../../../../Component/Helper";




export async function get_enqiure_response(webServiceName, api_endpoint, params = {}, is_verify = false) {
  //"https://prodone.jewelflow.pro/kalasha/webservices/Thirdparty_otp?"
  let BASE_URL = null
  if(is_verify){
     BASE_URL = "https://backend.jewelflow.pro/backend/"
  }else{
    
     BASE_URL = `https://prodone.jewelflow.pro/${webServiceName}/webservices/`;
  }
  

  const axiosInstance = axios.create({
    baseURL: BASE_URL, // Set your base URL
  });

  const api_url = BASE_URL + api_endpoint;

  const proxy_url = `${PROXY_BASE_URL}/api-call/?${api_endpoint}`;
  const encrypted_parameters = {
    params: encryptData(params),
    api_url: encryptData(api_url),
  };

  //For Testing Purpose
  // eslint-disable-next-line no-unused-vars
  const URL = `${api_url}/?${new URLSearchParams(params).toString()}`;
 

  const authorization = is_verify ? {authorization: "Y1G34SCAqmkvVnyuFD"} : {}

  try{

    const response = await axiosInstance.post(proxy_url, encrypted_parameters, {
      headers: authorization
    });
   
    if (response.status === 200) {
      
      const decryptedData = decryptData(response.data);
   
      return decryptedData;
    }else{
      
      return response.data
    }
  }catch(err){
    console.log(err)
    return err
  }
  
  
}

export const get_webServiceName = () => {
    const companyCode = get_informationFromSessionID("companyCode")
    const decryptCompanyName = atob(companyCode);

    switch(decryptCompanyName){
        case "KALASHAONLINE" : return "kalasha"
        default: return null
    }
}