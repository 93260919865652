import dayjs from 'dayjs';
import { useEffect, useState } from 'react'

function useRealTime() {
  
    const [time, setTime] = useState(dayjs().format('hh:mm:ss A'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(dayjs().format('hh:mm:ss A'));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return time
  
}

export default useRealTime