import "./Management.css"
import ProfileCard from "./ProfileCard"
import { ManagementData } from "./ManagementData"
import { useEffect } from "react"
import { ScrollToTop } from "../../_CommonPage/ScrollToTop"



function Management() {
  const BannerImg = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/management/Banner.jpg"
  
  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className='management-page'>
        
        <div className='banner-section'>
            <img src={BannerImg} alt="Banner" />
        </div>
        <header>
            <h2>The Managment</h2>
        </header>
        <section className="profile-cards-section">
          {
            ManagementData.map(data => <ProfileCard key = {data.details.name} {...data}/>)
          }
          
        </section>
    </section>
  )
}

export default Management