import React from "react";
import { get_short_code_name } from "../../../../Helper"
import ButtonFlow from "./components/ButtonFlow";
function ButtonsControl({
  selectedProduct,
  item,
  register,
  selectType,
  getValues,
  setValue
}) {
  let short_code = get_short_code_name(item.short_code);

  

  return (
   
      
        <ButtonFlow
          short_code={short_code}
          selectedProduct={selectedProduct}
          item={item}
          register={register}
          getValues={getValues}
          setValue={setValue}
          selectType={selectType}
        />
      
    
  );
}

export default ButtonsControl;
