import React from 'react'
import { get_input_control_value } from '../../../../utils'
import { useFormContext } from 'react-hook-form'

function TextInput({field}) {
  const {field_name, short_code, required_field, control_value, default_select, is_enable} = field || {}
  const { formState: {errors}, register } = useFormContext() 
  
  return (
    <div className={`form-input-group ${is_enable === "0" ? "disabled" : ""}`}>
        <label>
            <span>{field_name}</span>
            {required_field === "1" && <span className='required'>*</span>}
            </label>
            <input type="text" defaultValue={get_input_control_value(control_value)} id={short_code} {...register(short_code, {
              required: {
                value: required_field === "1",
                message: `${field_name} is required`
              },
              value: default_select
            })}
            disabled={is_enable === "0"}
            />
            {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
        </div>
  )
}

export default TextInput