import { useLocation } from "react-router-dom";
import "./Campaign.css";
import CampaignCard from "./CampaignCard";
import { CampaignData, CampaignInnerData } from "./CampaignData";
import { useEffect, useMemo } from "react";
import CampaignDetailsCard from "./CampaignDetailsCard";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";

function Campaign() {
  const location = useLocation()
  
  const url = location.pathname;
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const CampaignInner = useMemo(() => searchParams?.collection ? CampaignInnerData[searchParams?.collection] : null, [searchParams?.collection])
  
  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className="campaign-page">
        <header>
            <h2>Campaign</h2>
        </header>

        {!searchParams?.collection ? <section className="campaign-section">
            {
                CampaignData.map((data, index) => <CampaignCard key={data.id} {...data} index={index} url={url} searchParams={searchParams}/>)
            }
        </section>
        :
        <section className="campaign-details-section">
            {
                CampaignInner.map((data, index) => <CampaignDetailsCard key={data.id} {...data} index={index}/>)
            }
        </section>}
    </section>
  )
}

export default Campaign