import { createSearchURLParams, get_informationFromSessionID } from "../../../Component/Helper";

export function total_cart_products_cost (cartData) {
    const product_details = cartData.map(cart => cart?.product_details).flat()
    
    return product_details.reduce((total, product) => {
        
        total+=  Number(product?.product_price) || 0;
        return total
    }, 0)
}

export function total_quantity (cartData) {
    const product_details = cartData.map(cart => cart?.product_details).flat()
    
    return product_details.reduce((total, product) => {
        
        total+=  Number(product?.quantity) || 0;
        return total
    }, 0)
}

export function total_piece (cartData) {
    const product_details = cartData.map(cart => cart?.product_details).flat()
    
    return product_details.reduce((total, product) => {
        
        total+=  Number(product?.piece) || 0;
        return total
    }, 0)
}


export function get_custom_repostioned_params(params, repositioned_params_arr){
    return repositioned_params_arr.map(label => {
        const param = params.find(p => p.label === label)
        if(param) {
            return param
        }else{
            return label
        }
    })
}

export function redirection_to_FSV_URL(product_detail) {
    const userID = get_informationFromSessionID("userID")
    const params = {
        mode: "homescreen",
        data: "1",
        table: product_detail?.which_master,
        sort: "0",
        cat_id: "0",
        user_id: userID,
        product_id: product_detail[`${product_detail?.which_master}_id`]
    }

    return createSearchURLParams("product-page/0", params)
}