const spljewel1 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/specialjewllery/spl-jewel1.jpg"
const spljewel2 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/specialjewllery/spl-jewel2.jpg"
const spljewel3 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/specialjewllery/spl-jewel3.jpg"
const spljewel4 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/specialjewllery/spl-jewel4.jpg"

export const SpecialJewlleryData = [
    {
        id: 1,
        title: "SIGNATURE-BRIDAY JEWELLERY LOUNGE BY KALASHA",
        images: [spljewel1, spljewel2],
        info: `Kalasha’s Exclusive Bridal Lounge “Signature”, a first-of-its-kind exclusive bridal lounge. Your most memorable moments demand the most classic pieces of jewellery, so get ready for your happily ever after with intricate, lavish and absolutely exceptional bridal jewellery collection that you can pass on to your next generation. You can choose the best of jewellery completely curetted just for you! For more details click the link below.`
    },

    {
        id: 2,
        title: "Heritage Lounge",
        images: [spljewel3, spljewel4],
        info: `Kalasha’s Heritage Jewellery Lounge highlights the beauty of an era gone by. Jewellery showcased in the lounge is precisely chosen for our exclusive handcrafted collections. The lavish interiors and luxurious jewellery will surely leave you astonished. A Private space in the store where you can choose your jewellery at most comfort. This facility is only provided on appointed basics. So, why think? Book your appoint now!`
    }
]