import React, { useRef } from "react";
import { get_property_settings } from "../../../Component/Helper";
import DesignNoInputModal from "./DesignNoInputModal/DesignNoInputModal";

function AddItemsButtons({ setShowStepModal, set_add_items_by }) {
  const open_design_no_modal_ref = useRef(null)
  let oms_add_multiple_item_app_split_buttons = get_property_settings(
    "oms_add_multiple_item_app_split_by_designno_and_image"
  );
  oms_add_multiple_item_app_split_buttons =
    oms_add_multiple_item_app_split_buttons === "Yes";

  return oms_add_multiple_item_app_split_buttons ? (
    <div className="add-items-buttons form-input-group">
    <DesignNoInputModal ref={open_design_no_modal_ref} setShowStepModal={setShowStepModal} set_add_items_by={set_add_items_by}/>
    <label><span>Add Item</span></label>
      <div className="add-items-btn-group">
        <button
          className="add-item-btn ui button-ui"
          onClick={() => {
            if(open_design_no_modal_ref.current){
        
              open_design_no_modal_ref.current?.open_modal()
            }
          }}
          type="button"
        >
          By Design No
        </button>
        <button
          className="add-item-btn ui button-ui"
          onClick={() => {
            set_add_items_by("by image")
            setShowStepModal(true)
          }}
          type="button"
        >
          By Image
        </button>
      </div>
    </div>
  ) : (
    <button
      className="add-item-btn single-btn"
      onClick={() => setShowStepModal(true)}
      type="button"
    >
      ADD ITEM
    </button>
  );
}

export default AddItemsButtons;
