import { fetch_data_from_api } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import { ToastError, ToastSuccess } from "../../Component/Helper";

export async function handle_remove_product_from_named_wishlist(product_id, which_master, session_id = "") {
    try{
        const remove_product_from_named_wishlist_response = await fetch_data_from_api(ApiList.remove_from_named_wishlist, ApiList.remove_from_named_wishlist, {
            product_id,
            which_master,
            session_id
        })

        if(remove_product_from_named_wishlist_response?.ack === "1" || remove_product_from_named_wishlist_response?.ack === 1){
            ToastSuccess(remove_product_from_named_wishlist_response?.msg)
        }else if(remove_product_from_named_wishlist_response?.ack === "0" || remove_product_from_named_wishlist_response?.ack === 0){
            ToastError(remove_product_from_named_wishlist_response?.error)
        }
        return remove_product_from_named_wishlist_response
    }catch(e){
        console.log(e)
        ToastError("Something went wrong")
    }


    }


    export async function handle_singl_named_product_delete(product_id, wishlist_id, master, session_id = "") {
        
    
        try{
          const named_wishlist_response = await fetch_data_from_api(ApiList.named_wishlist_manage_product, ApiList.named_wishlist_manage_product, {
            selected_id: "",
            unselected_id: wishlist_id,
            product_id,
            which_master: master,
            session_id
          })
          
          if(named_wishlist_response?.ack === "1" || named_wishlist_response?.ack === 1){
            ToastSuccess(named_wishlist_response?.msg)
            
          }else if(named_wishlist_response?.ack === "0" || named_wishlist_response?.ack === 0){
            ToastError(named_wishlist_response?.error)
          }
          return named_wishlist_response
        }catch(e){
          ToastError("Something went wrong")
        }
        
      }
    