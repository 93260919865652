import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
  } from "react-bootstrap";
import { useSelector } from "react-redux";

function DeleteCartProductModal({showCartProductModal, setShowCartProductModal, productCode, deleteItem, type_of_cart, idText}) {
    const cartcounts = useSelector((state) => state?.reducer?.constants?.count);
  return (
    <Modal
            show={showCartProductModal}
            centered
            // onExit={() => setShow(false)}
            close
            onHide={() => setShowCartProductModal(false)}
          >
            <ModalHeader closeButton>
              <ModalTitle>Alert</ModalTitle>
            </ModalHeader>
            <ModalBody>{`Are you sure you want to remove ${productCode} From ${
              type_of_cart === "temp_wishlist" ? "Shortlist" : "Cart"
            }?`}</ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={() => {
                  deleteItem();
                  if (
                    cartcounts?.count === "1" ||
                    cartcounts?.shortlist_count === "1"
                  ) {
                    window.scrollTo(0, 0);
                  } else {
                    const prevProd =
                      document.getElementById(idText)?.previousElementSibling;
                    const nextProd =
                      document.getElementById(idText)?.nextElementSibling;
                    if (prevProd && window.innerWidth < 576) {
                      prevProd.scrollIntoView();
                    } else if (nextProd && window.innerWidth < 576) {
                      nextProd.scrollIntoView();
                    } else {
                      // window.scrollTo(0,0)
                    }
                  }
                }}
              >
                {" "}
                Yes
              </button>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={() => {
                  setShowCartProductModal(false);
                }}
              >
                No
              </button>
            </ModalFooter>
          </Modal>
  )
}

export default DeleteCartProductModal