import React, { useState } from "react";
import {
  get_property_settings,
  ToastError,
} from "../../../../../Component/Helper";

function CartPcsStepper({ handleChange, product_detail, clsName = "" }) {
  const minQty = product_detail?.min_order_quantity;
  const maxQty = product_detail?.max_order_quantity;
  const piece = product_detail?.piece;

  let allow_lessthan_moq = get_property_settings("allow_lessthan_moq");
  allow_lessthan_moq = allow_lessthan_moq === "Yes";
  const unit_for_pcs = product_detail?.unit_for_pcs

  const [count, setCount] = useState(piece ? piece : minQty);
  

  const checkQty = (event) => {
    let updatedCount = parseInt(event.target.value);
    //Check minQty
    if (updatedCount === 1 && allow_lessthan_moq) return;
    if (updatedCount < parseInt(minQty)) return;

    //Check maxQty
    if (parseInt(maxQty) !== 0 && updatedCount > parseInt(maxQty))
      return ToastError(`Max ${maxQty} Qty Reached.`);

    if(!updatedCount) updatedCount = 1;
    setCount(updatedCount)
    
    handleChange("piece_param", updatedCount)
  };

  
  const handleQty = async (event) => {
    const { name, value } = event.target;
    let updatedCount = parseInt(count);
    if (name === "qty-input") {
      updatedCount = parseInt(value);

      //Check minQty
      if (updatedCount === 1 && allow_lessthan_moq) return;
      if (updatedCount < parseInt(minQty)) return;

      //Check maxQty
      if (parseInt(maxQty) !== 0 && updatedCount > parseInt(maxQty))
        return ToastError(`Max ${maxQty} Qty Reached.`);

      
        setCount(updatedCount);
      
    }
    //Decrement
    else if (name === "decrement") {
      updatedCount -= 1;
      if (updatedCount === 1 && allow_lessthan_moq) return;
      if (updatedCount < parseInt(minQty)) return;

      setCount(updatedCount);
    }
    //Increment
    else {
      updatedCount += 1;
      if (parseInt(maxQty) === 0) {
        setCount(updatedCount);
      } else if (updatedCount > parseInt(maxQty)) {
        return ToastError(`Max ${maxQty} Qty Reached.`);
      } else {
        setCount(updatedCount);
      }
    }
    if(name !== "qty-input"){
      handleChange("piece_param", updatedCount)
    }
  };


  return (
    <div className={`row qty ${clsName}`}>
      <div className="col-3 qty label">{unit_for_pcs}:</div>
      <div
        className={
          // "col-8 data d-flex align-items-center justify-content-sm-start justify-content-end"
          `col-9 data d-flex align-items-center justify-content-md-start justify-content-end`
        }
      >
        <div className="qty-plus-minus d-flex border">
          
          <input
            type="number"
            placeholder="qty"
            className="qty-input"
            name="qty-input"
            value={count}
            onChange={handleQty}
            onBlur={checkQty}
          />
          <button
            className="dec ec_qtybtn  w-auto"
            name="decrement"
            onClick={handleQty}
          >
            -
          </button>
          <button
            className="inc ec_qtybtn"
            name="increment"
            onClick={handleQty}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartPcsStepper;
