import React, { useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./CustomOrderModal.css";
import { IoArrowBackOutline } from "react-icons/io5";
import CustomOrderModalStepOne from "./Components/CustomOrderModalStepOne";
import CustomOrderModalStepTwo from "./Components/CustomOrderModalStepTwo";
import CustomOrderModalFooter from "./Components/CustomOrderModalFooter";

import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { get_custom_order_items } from "../../redux/Reducer/reducer";
import {
  get_bulk_settings,
  get_property_settings,
  get_userData_from_activeUser,
  ToastError,
} from "../../Component/Helper";
import { useQueryClient } from "@tanstack/react-query";
import { getKamImageUploadPath } from "./custom_order_utils";
import { ApiList } from "../../Api/ApiList";
import { fetch_data_from_api } from "../../Api/APICalls";
import aws_image_upload from "../../aws/aws_image_upload";

function CustomOrderModal({
  showStepModal,
  setShowStepModal,
  setShowPlacedModal,
  add_items_by = "by image",
}) {
  const methods = useForm();
  const location = useLocation();
  const { update_id } = Object.fromEntries(
    new URLSearchParams(location.search)
  );
  const items = useSelector(get_custom_order_items);
  const navigate = useNavigate();
  const syod_image_required =
    get_property_settings("syod_image_required") === "Yes";

  const queryClient = useQueryClient();
  const [cat_id, set_cat_id] = useState(null);
  const [category_name, set_category_name] = useState("");

  useLayoutEffect(() => {
    if (!update_id || add_items_by === "by design no") return;
    const cat_id = items.find(
      (_, index) => index === Number(update_id)
    )?.cat_id;

    set_cat_id(cat_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update_id]);
  
  let oms_add_multiple_item_app = get_property_settings(
    "oms_add_multiple_item_app"
  );
  let kam_module_title = get_property_settings("kam_module_title");
  oms_add_multiple_item_app = oms_add_multiple_item_app === "Yes";

  //For Single custome order: onsubmit button (whn click on place order in CustomOrderModalFooter)
  //For Multiple custome order: place_order function in CustomNewOrder.jsx file
  const onSubmit = async (data) => {
    if (oms_add_multiple_item_app) return; //if multiorder is true then don't execute this function this is only for single order

    if (
      (syod_image_required && data.image_name?.length === 0) ||
      (syod_image_required && !data.image_name)
    ) {
      return ToastError("Please add atleast one image");
    }

    //Set Parameters ==================================================>

    const modifyData_form_data = (function () {
      const obj = {};
      for (const key in data) {
        if (key === "image_name" && data[key].length > 0) {
          const imgArr = data[key];
          obj[key] = imgArr
            .map((img) => `web_${img.file.name.replace(/\s/g, "-")}`)
            .join("@@");
        } else {
          obj[key] = data[key]?.value || data[key];
        }
      }

      return obj;
    })();

    const user_data = get_userData_from_activeUser();
    const default_params = {
      client_master_id: user_data?.client_id || "",
      device_type: "web",
      client_type: user_data?.client_type || "",
    };

    const finalize_form_data = {
      ...default_params,
      ...modifyData_form_data,
      cat_id,
    };

    //Upload images to S3 bucket ==================================================>
    //s3 confing variables
    const bucket_path = `${getKamImageUploadPath()}/uploads/kam_order_images`;
    const {
      jewelkam_s3Key,
      jewelkam_s3Pass,
      append_kam_path_folder,
      kam_module_title,
    } =
      get_bulk_settings([
        "jewelkam_s3Key",
        "jewelkam_s3Pass",
        "append_kam_path_folder",
        "kam_module_title",
      ]) || {};
    const credentials = {
      accesskeyID: jewelkam_s3Pass,
      secretAccessKey: jewelkam_s3Key,
    };

    if (data.image_name.length > 0) {
      const uploadPromises = data.image_name.map((image) =>
        aws_image_upload(image.file, bucket_path, credentials)
      );
      await Promise.all(uploadPromises);

      //Update data to backend ==================================================>
      let is_multi_order = oms_add_multiple_item_app === "Yes";
      const API_END_POINT = is_multi_order
        ? ApiList.jewelkam_multi_order_place
        : `${append_kam_path_folder}/order`;

      try {
        const custom_order_response = await fetch_data_from_api(
          API_END_POINT,
          "custom-order-place",
          finalize_form_data
        );

        if (custom_order_response?.ack === "1") {
          setShowPlacedModal({ show: true, response: custom_order_response });
          methods.reset();
          // setPlacingOrder(false);
          queryClient.invalidateQueries({ queryKey: ["kam_order_list"] });
          sessionStorage.removeItem("custom_order_cat_id");
          setShowStepModal(false);
          setTimeout(() => {
            setShowPlacedModal({ show: false, response: null });
            navigate(`/${kam_module_title.replace(/\s/g, "-").toLowerCase()}`);
          }, 5000);
        } else if (
          custom_order_response?.ack === "0" ||
          custom_order_response?.error
        ) {
          ToastError(custom_order_response?.error || "something went wrong");
        } else if (typeof custom_order_response === "string") {
          ToastError("Some error accured");
        }
      } catch (e) {
        ToastError("something went wrong");
      } finally {
        // setPlacingOrder(false);
      }
    }
  };

  function handleModalStep() {
    if (update_id) {
      navigate(location.pathname);
      document.body.style.overflow = "auto";
      sessionStorage.removeItem("custom_order_cat_id");
      setShowStepModal(false);
    } else if (cat_id) {
      set_cat_id(null);
    } else {
      navigate(location.pathname);
      document.body.style.overflow = "auto";
      sessionStorage.removeItem("custom_order_cat_id");
      setShowStepModal(false);
    }
  }

  function handleKeyDownEvent(event) {
    if (event.key === "Enter") {
      event.preventDefault(); //Prevent From Submition on Enter Key
    }
  }
  
  return (
    <>
      <FormProvider {...methods}>
        <Modal show={showStepModal} className="custom-order-modal">
          <Modal.Header className="custom-order-modal-header">
            <Modal.Title className="custom-order-modal-title">
              <IoArrowBackOutline
                className="back-arrow-icon"
                onClick={handleModalStep}
              />
              <span>
                {oms_add_multiple_item_app
                  ? "ADD ITEM"
                  : `NEW ${kam_module_title.toUpperCase()}`}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-order-modal-body">
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              onKeyDown={handleKeyDownEvent}
            >
              {add_items_by === "by design no" ? null : (
                <header>
                  <h3>
                    {!cat_id
                      ? "Select Product Category"
                      : "Enter Product Specifications"}
                  </h3>
                  {add_items_by === "by design no" ? null : (
                    <div className="steps">Step {!cat_id ? "1" : "2"} of 2</div>
                  )}
                </header>
              )}

              <section>
                {!cat_id && add_items_by === "by image" ? (
                  <CustomOrderModalStepOne
                    set_cat_id={set_cat_id}
                    cat_id={cat_id}
                    set_category_name={set_category_name}
                  />
                ) : null}
                {cat_id || add_items_by === "by design no" ? (
                  <CustomOrderModalStepTwo
                    cat_id={cat_id}
                    add_items_by={add_items_by}
                  />
                ) : null}
              </section>
              {cat_id || add_items_by === "by design no" ? (
                <CustomOrderModalFooter
                  setShowStepModal={setShowStepModal}
                  add_items_by={add_items_by}
                  category_name={category_name}
                  cat_id={cat_id}
                />
              ) : null}
            </form>
          </Modal.Body>
        </Modal>
      </FormProvider>
    </>
  );
}

export default CustomOrderModal;
