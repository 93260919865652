import { useEffect, useState } from "react";
import TwoColPage from "../../_CommonPage/TwoColPage"
import "./JewelleryRedesign.css"
import EnquiryFormModal from "../../_CommonPage/EnquiryFormModal";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";

function JewelleryRedesign() {
    const BeforeImg = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/jewllery_redesign/Before.png"
    const AfterImg = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/jewllery_redesign/after.png"

    const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className='jewellery-redesign-page'>
        {open && <EnquiryFormModal open={open} handleClose={handleClose} related_enquiry="Jewellery Redesign">
            
            I authorize Kalasha Jewels and its representatives to Call, SMS,
            Email or WhatsApp me about its products and offers. This consent
            overrides any registration for DND / NDNC.
          
    </EnquiryFormModal>}
        <TwoColPage>
        <div className='image-section'>
            <div className="image-warpper">
                <h3>Before</h3>
                <img src={BeforeImg} alt="BeforeImg" />
            </div>
            <div className="image-warpper">
                <h3>After</h3>
                <img src={AfterImg} alt="AfterImg" />
            </div>
        </div>

        <div className='detail-section'>
            <header>
                <h2>JEWELLERY REDESIGN & UPGRADE</h2>
            </header>
            <div className="info">
            <p>Jewellery as unique as you, we help you to create one of a kind design which will be your personal statement. Our team of designers’ helps you to figure out the details, weigh all the options, and guide you through each step of creating your master piece. From design to execution to the final product, everything is discussed and will move forward only with your approval. Finally you will have a creation of your own crafted with at most precision at every step.</p>
            
            </div>
            <div className="btn" onClick={handleOpen}>Enquire Now</div>
        </div>
        </TwoColPage>
    </section>
  )
}

export default JewelleryRedesign