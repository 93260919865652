import "./OrderPlaceModal.css";
import Modal from 'react-bootstrap/Modal';
import verifiedImage from "../../assets/images/verified.png"

function OrderPlaceModal({header, showPlacedModal, setShowPlacedModal, autoClose}) {
   function handleClose () {
    if(autoClose) return
    setShowPlacedModal({show: false, response: null})
   }
  return (
    <Modal show={showPlacedModal.show} onHide={handleClose}>
        <Modal.Header closeButton={!autoClose} className='order-placed-header'>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='order-placed-modal'>
               <img src={verifiedImage} alt="verifiedImage" />
              {showPlacedModal?.response && <h2> {showPlacedModal.response.title} </h2>}
              {showPlacedModal?.response && <p>{showPlacedModal.response.subtitle}</p>}
        </Modal.Body>
        
      </Modal>
  )
}

export default OrderPlaceModal
