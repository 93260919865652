import { Pagination, Stack } from '@mui/material'
import React, { memo, useState } from 'react'
import { createSearchURLParams } from '../Helper'
import "./PagiantionComp.css"
import { useNavigate } from 'react-router-dom'

function PaginationGlob({totalProductCount, size="large", clsName="", handle_page, page, page_size}) {

 const SHOW_PAGINATION_BUTTONS_COUNT = window.innerWidth > 450 ? 1 : 0 
 const total_pages =  Math.ceil(Number(totalProductCount) / Number(page_size))
 const [currpage, setCurrPage] = useState(page + 1)
 const navigate = useNavigate()

 const searchParams = Object.fromEntries(
  new URLSearchParams(document.location.search)
);
 

 function handlePage(page) {
  let pathName = document.location.pathname.replace("/", "");

  setCurrPage(page)
  handle_page(page - 1)
  const url = createSearchURLParams(pathName, {...searchParams, page: page - 1})
  navigate(url)
  
 
}
if(total_pages <= 1 || !totalProductCount) return null


  return (
    <div className={`${clsName} pagination-comp`}>
    <Stack spacing={2}>
          <Pagination count={total_pages} variant="outlined" shape="rounded" size={size}
          page={currpage}
          boundaryCount={SHOW_PAGINATION_BUTTONS_COUNT}
          onChange={(event, page)=>{
            handlePage(page)
         }}
          />
        </Stack>
    </div>
    
  )
}

export default memo(PaginationGlob)
