import React from "react";

function TextArea({clsName, paramsDataIndex, paramsData, formData, handleChange, register, disabled}) {
  
  return (
    <textarea
      disabled={disabled}
      tabIndex={paramsDataIndex}
      className={clsName}
      placeholder={`Enter ${paramsData.field_name}`}
      onChange={(e) => {
        handleChange(e.target.value, e.target.name);
      }}
      {...register(paramsData?.short_code, {
        required: {
          value: paramsData?.required === "1",
          message: "This field is required"
        }
      })}
    />
  );
}

export default TextArea;
