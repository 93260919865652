import React, {
  useMemo,
  useRef,
  useState,
} from "react";
import { get_bulk_settings } from "../../../../Component/Helper";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import {
  getCatMaster,
  get_fsv_products,
  get_fsv_selected_product,
  get_fsv_selected_product_property,
} from "../../../../redux/Reducer/reducer";
import Tag from "../../../../Component/Tag";
import ZoomImageNew from "../../../../Component/ZoomImageNew";
import ThumbnailPreview from "./components/ThumbnailPreview";
import noImage from "../../../../assets/images/no_image_available.png";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";
import Drawer from "@mui/material/Drawer";
import ZoomImageDrawer from "./components/ZoomImageDrawer/ZoomImageDrawer";
import { zoom_image_tocover_container } from "../../../../utils";

function ProductPreview({ nav1, nav2, setNav1, setNav2 }) {
  const master = useSelector(getCatMaster);
  const left_thumb_container_ref = useRef(null);
  const main_thumb_container_ref = useRef(null);
  const selectedProduct = useSelector(get_fsv_selected_product);
  const products_and_subproducts = useSelector(get_fsv_products);
  const [currentSlide, setCurrentSlide] = useState(0);

   const cover_zoom_image = zoom_image_tocover_container(selectedProduct.category_name)
   
  const [open_zoom_drawer, set_open_zoom_drawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  
  const DRAWER_OPEN_DIRECTION = "bottom";
  const toggleZoomDrawer = (anchor, open) => {
    // console.log(open, anchor)
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    set_open_zoom_drawer({ ...open_zoom_drawer, [anchor]: open });
  };

  const allThumbImages = useMemo(() => {
    let products = [];
    if(!Array.isArray(products_and_subproducts)) return []
    products_and_subproducts?.forEach((prod) => {
      prod.design_files.forEach((img) => {
        products.push(img);
      });
    });

    return products;
  }, [products_and_subproducts]);

  const thumbPosition = "bottom"; //top and bottom only

  let {
    cdn,
    new_arrival_badge_bg_color,
    new_arrival_badge_fg_color,
    show_hide_design_master_new_arrival,
    is_pinned_badge_fg_color: pinned_badge_fg_color,
    is_pinned_badge_bg_color: pinned_badge_bg_color,
    is_pinned_matrix_string: pinned_matrix_string,
    show_zoom_on_Fsv_web,
    default_image
  } = get_bulk_settings([
    "cdn",
    "new_arrival_badge_bg_color",
    "new_arrival_badge_fg_color",
    "show_hide_design_master_new_arrival",
    "is_pinned_badge_fg_color",
    "is_pinned_badge_bg_color",
    "is_pinned_matrix_string",
    "show_zoom_on_Fsv_web",
    "default_image"
  ]);

  const defaultImg = default_image ? `${cdn}uploads/default_image/${default_image}` : noImage;
  
  show_hide_design_master_new_arrival =
    master && show_hide_design_master_new_arrival === "show";
    show_zoom_on_Fsv_web = show_zoom_on_Fsv_web === "Yes"
    
  //isPinned
  const isPinned = useSelector((state) =>
    get_fsv_selected_product_property(state, "is_pinned")
  );
 
  
  function adjestZoomImageHeight() {
    setTimeout(() => {
      //   setShowScrollBtn(
      //     fsvContainerRef.current?.offsetHeight > window.innerHeight &&
      //       document.documentElement.offsetHeight > window.innerHeight
      //   );

      if (left_thumb_container_ref.current) {
        document.documentElement.style.setProperty(
          "--left-thumb-container-height",
          `${left_thumb_container_ref.current.offsetHeight}px`
        );
      } else {
        document.documentElement.style.setProperty(
          "--left-thumb-container-height",
          "315px"
        );
      }
    }, 300);
  }
  
  return (
    <>
      <Drawer
        anchor={DRAWER_OPEN_DIRECTION}
        open={open_zoom_drawer[DRAWER_OPEN_DIRECTION]}
        // onClose={toggleZoomDrawer(DRAWER_OPEN_DIRECTION, false)}
      >
        <ZoomImageDrawer
          DRAWER_OPEN_DIRECTION={DRAWER_OPEN_DIRECTION}
          toggleZoomDrawer={toggleZoomDrawer}
          allThumbImages={allThumbImages}
          imageRootPath={`${cdn}${selectedProduct?.zoom_image_path}`}
          currentSlide={currentSlide}
          className={cover_zoom_image ? "zoom_cover" : ""}
        />
      </Drawer>
      {/* Left thumbnails */}
      {selectedProduct && allThumbImages.length > 1 && (
        <div
          className={`col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 p-0 d-lg-block d-none left-thumb-container`}
          ref={left_thumb_container_ref}
        >
          <div className="d-lg-block d-none" id="leftThumbDiv"></div>

          <ThumbnailPreview
            slider_clsName="single-nav-thumb left"
            vertical={true}
            nav={nav1}
            setNav={setNav2}
            slidesToShow={allThumbImages.length > 4 ? 4 : allThumbImages.length}
            focusOnSelect={true}
            arrows={true}
            swipeToSlide={true}
            variableWidth={false}
            single_slide_clsName="single-slide"
            single_slide_id={"single-slide-left"}
            video_tag_clsName={"img-left"}
            img_tag_clsName={"img-left"}
            allThumbImages={allThumbImages}
            selectedProduct={selectedProduct}
            adjestZoomImageHeight={adjestZoomImageHeight}
          />
        </div>
      )}

      {/* main thumbnail */}
      <div
        className="col-xl-6 col-lg-7 col-md-6 col-sm-9 col-11 img-container"
        ref={main_thumb_container_ref}
      >
        {isPinned === "1" ? (
          <div
            className="card-top-label"
            style={{
              backgroundColor: pinned_badge_bg_color,
              color: pinned_badge_fg_color,
            }}
          >
            {pinned_matrix_string}
          </div>
        ) : (
          show_hide_design_master_new_arrival && (
            <div
              className="card-top-label"
              style={{
                backgroundColor: new_arrival_badge_bg_color,
                color: new_arrival_badge_fg_color,
              }}
            >
              {getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.new_arrivals) ||
                "New Arrivals"}
            </div>
          )
        )}
        {selectedProduct?.status_details?.length > 0 && (
          <Tag {...selectedProduct?.status_details} />
        )}
        <div
          className={
            thumbPosition === "left"
              ? "single-pro-img single-pro-img-no-sidebar sidebar-left"
              : "single-pro-img single-pro-img-no-sidebar"
          }
        ></div>
        <div className="single-product-scroll">
          {/* Thumbnail Top */}
          {thumbPosition === "top" &&
          selectedProduct &&
          allThumbImages.length > 0 &&
          allThumbImages.length !== 1 ? (
            <ThumbnailPreview
              slider_clsName="single-nav-thumb"
              nav={nav1}
              setNav={setNav2}
              slidesToShow={allThumbImages.length}
              focusOnSelect={true}
              swipeToSlide={true}
              allThumbImages={allThumbImages}
              selectedProduct={selectedProduct}
            />
          ) : null}
          <div
            className={
              thumbPosition === "left"
                ? "single-product-cover-left"
                : "single-product-cover"
            }
          >
            {/* <div className="single-slide zoom-image-hover">
                                                                <ZoomImage zoomImageUrl={`${CDN}${data?.zoom_image_path}${data?.design_files[0]}`} largeImageUrl={`${CDN}${data?.large_image_path}${data?.design_files[0]}`} />

                                                            </div> */}

            {/* <div className="single-slide zoom-image-hover">
                                          <ZoomImageNew zoomImageUrl={`${CDN}${data?.zoom_image_path}${data?.design_files[0]}`} largeImageUrl={`${CDN}${data?.large_image_path}${data?.design_files[0]}`} />

                                      </div> */}
            {allThumbImages.length === 0 && (
              <div className="single-slide zoom-image-hover">
                <img src={defaultImg} alt="" style={{ width: "100%" }} />
              </div>
            )}
            {/* Main Silder */}
            <Slider
              infinite={false}
              className={
                thumbPosition === "left"
                  ? "single-product-cover-left"
                  : "single-product-cover"
              }
              afterChange={(currentSlide) => {
                setCurrentSlide(currentSlide);
              }}
              asNavFor={nav2}
              ref={(slider1) => {
                setNav1(slider1);
              }}
              arrows={false}
            >
              {selectedProduct &&
                allThumbImages.length > 0 &&
                allThumbImages.map((d, _) => {
                  return (
                    <div className="imgLeft" key={_}>
                      <div
                        className="single-slide zoom-image-hover"
                        id={thumbPosition === "left" ? "image-left" : ""}
                      >
                        {d.includes("mp4") ? (
                          <video
                            muted
                            width={"100%"}
                            controls={false}
                            autoPlay={true}
                            style={{
                              aspectRatio: "15/10",
                              objectFit: "contain",
                              minHeight: "600px"
                            }}
                            loop
                            src={`${cdn}${`uploads/design_video/`}${d}`}
                          ></video>
                        ) : (
                          <ZoomImageNew
                            zoomImageUrl={`${cdn}${selectedProduct?.zoom_image_path}${d}`}
                            largeImageUrl={`${cdn}${selectedProduct?.large_image_path}${d}`}
                            // noZoom={window.innerWidth < 768}
                            // noZoom={window.innerWidth < 768 && !show_zoom_on_Fsv_web}
                            noZoom={!show_zoom_on_Fsv_web}
                            DRAWER_OPEN_DIRECTION={DRAWER_OPEN_DIRECTION}
                            toggleZoomDrawer={toggleZoomDrawer}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>

          {/* Thumbnails Bottom */}

          <div className="d-lg-none d-block ">
            {selectedProduct &&
            allThumbImages.length > 0 &&
            allThumbImages.length !== 1 ? (
              <ThumbnailPreview
                slider_clsName="single-nav-thumb nav-thumb-bottom"
                nav={nav1}
                setNav={setNav2}
                slidesToShow={
                  allThumbImages.length > 3 ? 4 : allThumbImages.length
                }
                single_slide_clsName="single-slide nav-slider-thumb-bottom"
                focusOnSelect={true}
                swipeToSlide={true}
                allThumbImages={allThumbImages}
                selectedProduct={selectedProduct}
                arrows={allThumbImages.length > 3}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPreview;
