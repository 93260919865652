import { useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function ZoomImageSection({ imagepath }) {
  useEffect(() => {
    const img = new Image();
    img.src = imagepath;
    const widthRatio = img.naturalWidth / img.naturalHeight
    if(widthRatio < 1){
      const imageBody = document.querySelector(".ZoomImageDrawer .image-body")
      if(imageBody){
        imageBody.classList.remove("zoom_cover")
      }
    }
  }, [])

  return (
    
      <TransformWrapper initialScale={1} maxScale={window.innerWidth > 768 ? 1 : 2}>
        <TransformComponent>
          <img src={`${imagepath}`} alt={"zoom-img"} className="zoom-img" />
        </TransformComponent>
      </TransformWrapper>
    
  );
}

export default ZoomImageSection;
