import React from "react";
import ReactSlider from "../../../../Component/ReactSlider/ReactSlider";
import ProductNew from "../../../../Component/Product/ProductNew";
import "./FSVSimilerProductCard.css"

const responsive = [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow:  5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow:  5,
        slidesToScroll: 5,
      },
    },

    {
      breakpoint: 1260,
      settings: {
        slidesToShow:  5,
        slidesToScroll: 5,
      },
    },

    {
      breakpoint: 1024,
      settings: {
        slidesToShow:  4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow:  3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow:  2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ];

function FSVSimilerProductCard(
  {products}
) {

  if(Array.isArray(products) && products.length === 0){
    return null
  }
  
  return (
    <section className="fsv-similer-product-card-container">
      <header>
        <h2>Products you may like</h2>
      </header>

      <div className="fsv-similer-product-card-wrapper">
        <ReactSlider additionalSetting={{slidesToShow: 4, infinite: false}} responsive={responsive}>
          {products?.map((item, index) => (
            <ProductNew
              index={index}
              item={item}
              sort={0}
              saveStateToLocalStorage={()=>{}}
              key={item?.design_master_id || item?.inventory_master_id}
            />
          ))}
        </ReactSlider>
      </div>
    </section>
  );
}

export default FSVSimilerProductCard;
