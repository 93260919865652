import { get_informationFromSessionID } from "../../Helper"



// Coustom About Us page
import COPLHomeAbout from "./COPL/COPL_Home_About"
import IGJEHomeAboutUsComp from "./IGJE/IGJE_Home_About_Us_Comp"
import JEWELSMITHHomeAboutUsComp from "./JEWELSMITH/JEWELSMITH_Home_About_Us_Comp"
import KALASHGOLDHomeAbout from "./KALASHGOLD/KALASHGOLD_Home_About"

function index() {
  const company_code = get_informationFromSessionID("companyCode")
  const decrypted_company_code = atob(company_code)
  const ABOUT_HOME = (function () {
    switch (decrypted_company_code) {
      case "COPL" : return <COPLHomeAbout/>;
      case "KALASHJEWELS" : return <KALASHGOLDHomeAbout/>;
      case "IGJE" : return <IGJEHomeAboutUsComp/>
      case "JEWELSMITH" : return <JEWELSMITHHomeAboutUsComp/>
      default: return null
    }
  })()

 

  return (
    <div>
      {ABOUT_HOME}
    </div>
  )
}

export default index