import React, { useEffect, useRef } from 'react'
import { FaPlay } from 'react-icons/fa'

function VideoComponent({video}) {
    const videoRef = useRef(null);

    useEffect(() => {
      const videoElement = videoRef.current;
  
      const handleFullScreenChange = () => {
        if (!document.fullscreenElement) {
          videoElement.pause();
          videoElement.currentTime = 0;
        }
      };
  
      document.addEventListener('fullscreenchange', handleFullScreenChange);
      document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.addEventListener('mozfullscreenchange', handleFullScreenChange);
      document.addEventListener('MSFullscreenChange', handleFullScreenChange);
  
      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
        document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
        document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
      };
    }, []);
  
    const handlePlayClick = () => {
      const videoElement = videoRef.current;
  
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) { // Firefox
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) { // IE/Edge
        videoElement.msRequestFullscreen();
      }
  
      videoElement.play();
    };
  return (
    <div className="video-container">
          <FaPlay className="play-btn"/>
          <video src={video} playsInline={false} autoPlay={false} ref={videoRef}  onClick={handlePlayClick}/>
        </div>
  )
}

export default VideoComponent