import logo from "../../../../assets/images/igje/about-us/rs=w_700,cg_true.webp"
function HeaderSection() {
  return (
    <headeer className="banner">
        <div className="background-text">
        IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd IGJE Pvt Ltd 
        </div>
        <h2>INTERNATIONAL GEMS AND<br/>
        JEWELLERY EXPORTERS PVT. LTD.</h2>

        <div className='logo-image'>
            <img src={logo} alt="logo" />
        </div>
    </headeer>
  )
}

export default HeaderSection