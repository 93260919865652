import { get_informationFromSessionID } from "../../../Component/Helper";

export function apply_filter_form_Params(data) {
  if (!data) return {};

  const companyCode = get_informationFromSessionID("companyCode");
  const decryptCompanyName = atob(companyCode);

  let params = {};
  for (const key in data) {
    //e.g gross_wt: { minmax: [ 58.08, 72 ] }
    if (data[key]?.constructor === Object && data[key]?.minmax) {
      if (key.includes("price") && decryptCompanyName === "KALASHAONLINE") {
        params[`min_${key}`] = data[key]?.minmax[0]?.toString();
        params[`max_${key}`] = data[key]?.minmax[1]?.toString();
      } else {
        params[`min_${key}`] = data[key]?.minmax[0]?.toString();
        params[`max_${key}`] = data[key]?.minmax[1]?.toString();
      }
    } else if (Array.isArray(data[key])) {
      if (data[key].length === 0) {
        params[key] = "";
      } else {
        params[key] = data[key].join(",");
      }
    } else if (typeof data[key] === "string") {
      params[key] = data[key];
    } else {
      params[key] = "";
    }
  }

  return params;
}

function create_form_inputs_from_url(filteredSearchParams) {
  /*
   //filteredSearchParams
   {
    min_gross_wt: '4.11',
    max_gross_wt: '5',
    min_net_wt: '0',
    max_net_wt: '4',
    design_status: '1',
    size: '64,65'
  }

  //Input Value
{ gross_wt: { minmax: [ 3.36, 5 ] }, net_wt: { minmax: [ 0, 4 ] }, design_status: '1', size: false }
   */

  const InputValues = {};

  for (const key in filteredSearchParams) {
    const editedKey = key.replace(/(min|max)_/g, "");
    if (key.includes("min_") && !InputValues[editedKey]) {
      InputValues[editedKey] = { minmax: [filteredSearchParams[key]] };
    } else if (key.includes("max_") && InputValues[editedKey]) {
      InputValues[editedKey]["minmax"].push(filteredSearchParams[key]);
    } else if (filteredSearchParams[key].includes(",")) {
      InputValues[key] = filteredSearchParams[key].split(",");
    } else {
      InputValues[key] = filteredSearchParams[key];
    }
  }

  return InputValues;
}

const required_filter_form_keys = [
  "gross_wt",
  "net_wt",
  "price",
  "design_status",
  "size",
  "keyword",
  "is_pinned",
  "cc_Rhodium",
  "cc_Enamel_Dm",
  "cc_Vibrance_Dm",
  "cc_Nakshi_dm",
  "piece",
  "tone",
  "cc_CAT"
];

export function is_url_has_form_input_keys(searchParams) {
  const searchParamsKeys = Object.keys(searchParams);
  return searchParamsKeys.some((key) =>
    required_filter_form_keys.includes(key.replace(/(min|max)_/g, ""))
  );
}

export function applied_filtered_params(searchParams) {
  const searchParamsKeys = Object.keys(searchParams);

  const filteredSearchParams = searchParamsKeys.reduce((obj, key) => {
    if (required_filter_form_keys.includes(key.replace(/(min|max)_/g, "")) || key.includes("price")) {
      obj[key] = searchParams[key];
    }
    return obj;
  }, {});
  if (Object.keys(filteredSearchParams).length === 0) return null;
 
  return create_form_inputs_from_url(filteredSearchParams);
}
