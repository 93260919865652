

function Jewelsmith_About_Us_Card() {
  return (
    <>
      {/******** About Us *******************************************/}
      <div className="card-section">
        <div className="image-section">
          <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/jewelsmith/static_pages/aboutus/LIGHTWEIGHT-SET-2.jpg" alt="pic-1" />
        </div>

        <div className="detail-section">
          <header>
            <h2>About Us</h2>
          </header>

          <div className="info">
            <h3>Jewelsmith: Crafting Elegance Since 2002 </h3>

            <p>
              Founded in 2002 by late Mr. Nanji Sangoi with his sons Anand
              Sangoi and Chirag Sangoi, Jewelsmith's legacy extends over three
              decades. Anand began his career in the bustling city of Hong Kong,
              where he immersed himself in the art of jewellery manufacturing.
              Here, he honed his skills in various manufacturing and design
              styles, working tirelessly to adapt and perfect these techniques
              for the Indian market. Their dedication and expertise paved the
              way for Jewelsmith to become a distinguished name in the jewellery
              industry.{" "}
            </p>

            <p>
              In the early days, Anand and Chirag set up their first factory in
              the iconic Zaveri Bazar at Dhanji Street. It was here that they
              revolutionized the styling of plain 22kt gold jewellery, bringing
              their Hong Kong learnings to the Indian market. Their mastery of
              the manufacturing process quickly made Jewelsmith one of India’s
              most trustworthy manufacturer, capturing the hearts of customers
              across the nation and Middle East Asia.{" "}
            </p>

            <p>
              The family’s commitment to quality and innovation has been
              showcased at the renowned IIJS trade shows since 1997, where they
              have consistently participated and impressed customers. By 2002,
              the company had outgrown its original premises and moved to the
              heart of Mumbai, establishing a state-of-the-art factory in Lower
              Parel with a new name “Jewelsmith”.{" "}
            </p>

            <p>
              Our history is marked by a legacy of extremely happy, loyal, and
              satisfied customers, a testament to our unique craftsmanship and
              high-quality finishing. We pride ourselves on our ability to stay
              flexible, continually improving our designs to stay ahead of
              market trends. Our production stage features highly skilled manual
              sketchers, the true heart of our company, and we manufacture
              purely handmade jewellery while also specializing in CAD CAM
              technology. Our modern factory and processes ensure that every
              piece we create is a masterpiece.{" "}
            </p>

            <p>
              At Jewelsmith, our deep understanding of the ethos of the current
              Indian jewellery market and our constant evolution alongside its
              changing demands and preferences have earned us the distinct honor
              of being coveted by leading retailers. Our adherence to stringent
              quality standards and uniform guidelines across our factory
              further establishes Jewelsmith as one of the most trusted
              jewellery manufacturers in the country.{" "}
            </p>

            <p>
              Join us on our journey as we continue to craft elegance, one
              exquisite piece of jewellery at a time.{" "}
            </p>
          </div>
        </div>
      </div>

      {/******** Why Us  *******************************************/}
      <div className="card-section">
        <div className="image-section">
        <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/jewelsmith/static_pages/aboutus/Diya-7.jpg" alt="pic-2" />
        </div>

        <div className="detail-section">
          <header>
            <h2>Why Us </h2>
          </header>

          <div className="info">
            <h3>1. Unmatched Quality </h3>

            <p>
              At Jewelsmith, quality is at the forefront of everything we do.
              Our skilled craftsmen and karigars, who have been in the industry
              for the longest time, bring unparalleled expertise to each piece
              of jewellery. We pride ourselves on our high-quality finishing and
              the use of the latest technology in our manufacturing processes,
              including advanced casting techniques, CAD CAM, and ensuring the
              purity of gold. This commitment to excellence guarantees that
              every piece of jewellery from Jewelsmith meets the highest
              standards of quality and craftsmanship.{" "}
            </p>

            <h3>2. Customer Satisfaction </h3>

            <p>
              Customer satisfaction is our top priority at Jewelsmith. We strive
              to exceed our customers' expectations by delivering exceptional
              products and services. Our dedication to providing personalized
              service ensures that each customer feels valued and heard. We take
              pride in our history of extremely happy, loyal, and satisfied
              customers, who appreciate our unique craftsmanship and
              high-quality finishing. Whatever the need may be—creativity,
              quality, or value for money—there is one name that caters to all
              your needs: Jewelsmith.
            </p>

            <h3>3. Strong Research and Development Team </h3>

            <p>
              Innovation is at the heart of Jewelsmith. Our strong research and
              development team continuously explores new techniques and trends
              to stay ahead of the curve. This commitment to innovation allows
              us to produce cutting-edge designs that resonate with our
              customers' evolving tastes. Our ability to adapt and incorporate
              the latest advancements in jewellery manufacturing ensures that we
              consistently offer fresh, contemporary pieces that stand out in
              the market.
            </p>

            <h3>4. Seasonal Collections and Storytelling </h3>

            <p>
              Jewelsmith is renowned for its ability to create captivating
              collections for every season and occasion. Our team curates unique
              collections with the right stories to tell our curious customers,
              making each piece more than just jewellery but a part of a larger
              narrative. This approach not only keeps our offerings fresh and
              exciting but also deepens the connection with our customers, who
              appreciate the creativity and thoughtfulness behind each
              collection.
            </p>
          </div>
        </div>
      </div>

      {/******** What We Do  *******************************************/}
      <div className="card-section">
        <div className="image-section">
        <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/jewelsmith/static_pages/aboutus/PEACOCK-SET-1.jpg" alt="pic-3" />
        </div>

        <div className="detail-section">
          <header>
            <h2>What We Do</h2>
          </header>

          <div className="info">
            <p>
              Jewelsmith is a gold jewellery designing and manufacturing house
              with over three decades of experience. We specialize in
              high-quality casted and handcrafted 22k gold jewellery. Our
              diverse product line includes full wedding sets comprising long
              harams, necklace sets, bajubands, odiyanams, bangles, and a wide
              range of intricately designed pendant sets, jumkhies, ear studs,
              rings, and bracelets. Additionally, we have a separate section
              dedicated to lightweight jewellery and also offer a stunning
              collection of antique jewellery.{" "}
            </p>

            <p>
              Our manufacturing is carried out with stringent quality control in
              a fully equipped and modern factory in the heart of Mumbai. The
              process includes castings, 3D printing, and intricate handwork,
              ensuring each piece is crafted to perfection.{" "}
            </p>

            <p>
              We proudly serve prestigious clients, including reputed jewellery
              stores across India, and export our products to clients in the
              Middle East, Southeast Asia, and the USA.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jewelsmith_About_Us_Card;
