import { useQuery } from "@tanstack/react-query";
import { ApiList } from "../../../../Api/ApiList";
import { useSelector } from "react-redux";
import { getCatMaster } from "../../../../redux/Reducer/reducer";
import { useLocation } from "react-router-dom";
import { fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { useMemo } from "react";
import "./ProductNameHeader.css";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";

function ProductNameHeader({count}) {
    const master = useSelector(getCatMaster);
    const location = useLocation()
    let searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const hide_product_count = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.hide_product_count)
    

    const category_query = useQuery({
        queryKey: ["category", master, searchParams?.cat_id],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
            which_master: master,
          }),
        initialData: [],
        
        enabled: searchParams.mode !== "named_wishlist"
      });

      const named_wishlist_query = useQuery({
        queryKey: ["named_wishlist"],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.get_named_wishlist_list, "named_wishlist", {
            product_id: "0"
          }),
          enabled: searchParams.mode === "named_wishlist"
      });

      
      const get_wishlist_name = useMemo(() => {
        if(!named_wishlist_query.data || !searchParams?.wishlist_id) return null
        const wishlist = named_wishlist_query.data?.find(data => data.id === searchParams?.wishlist_id)
        return wishlist.wishlist_name

      }, [named_wishlist_query.data, searchParams?.wishlist_id])

      const findID = (categoryData, cat_id, nestingKey) => {
        if(!Array.isArray(categoryData)) return null;
        // eslint-disable-next-line array-callback-return
        return categoryData?.reduce((result, currentItem) => {
          if (result) return result;
          if (currentItem.id === cat_id) return currentItem;
          if (currentItem[nestingKey])
            return findID(currentItem[nestingKey], cat_id, nestingKey);
        }, null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const subCategories = useMemo(() => findID(category_query?.data, searchParams?.cat_id, "subcategory"), [searchParams?.cat_id, category_query?.data])
      if(category_query.isLoading){
          return null
        }
       
    
   
  return (
    <>
    {subCategories || (searchParams?.mode === "all" && searchParams?.cat_id === "0") ? <div className="product_title">
      <h2>{(searchParams?.mode === "all" && searchParams?.cat_id === "0") ? "All Products" : subCategories?.cat_name} {`${hide_product_count ? "" : `(${count || 0})`}`}</h2>
    </div> : searchParams.mode === "named_wishlist" ? <div className="product_title">
      <h2>{get_wishlist_name} {`${hide_product_count ? "" : `(${count || 0})`}`}</h2>
    </div> : null}
    </>
  );
}

export default ProductNameHeader;
