import React, { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import {
  ToastError,
  get_property_settings,
} from "../../../../../../../Component/Helper";
import { useSelector, useDispatch } from "react-redux";
import {
  get_fsv_products_calc,
  get_fsv_quantity,
 get_fsv_sets_quantity,
 set_FSV_Handler,
} from "../../../../../../../redux/Reducer/reducer";
import { calculate_prod, FSV_ACTIONS } from "../../../../../Helper";

function SetsStepper({selectedProduct, cartCount}) {
  const allow_lessthan_moq =
    get_property_settings("allow_lessthan_moq") === "Yes";

 
  const products_calc = useSelector(get_fsv_products_calc);
  const dispatch = useDispatch();

  const maxQty = parseInt(selectedProduct?.max_order_quantity);
  const minQty = 1
  const count = useSelector(get_fsv_quantity);
  const sets_counts = useSelector(get_fsv_sets_quantity);

  const Increment = () => {
    const add = parseInt(sets_counts) + 1;
  
    if (parseInt(maxQty) === 0) {
      dispatch(
        set_FSV_Handler({ type: FSV_ACTIONS.SET_SETS_STEPPER_QUANTITY, payload: add })
      );
      calculate_prod(selectedProduct, products_calc, null, null, count, dispatch, add)
    } else {
      if (add > parseInt(maxQty)) {
        return ToastError(`Max ${maxQty} Qty Reached.`);
      } else {
        dispatch(
          set_FSV_Handler({ type: FSV_ACTIONS.SET_SETS_STEPPER_QUANTITY, payload: add })
        );
        calculate_prod(selectedProduct, products_calc, null, null, count, dispatch, add)
      }
    }
  };

  const Decrement = () => {
    const minus = parseInt(sets_counts) - 1;

    if (minus > 1 && allow_lessthan_moq) {
      dispatch(
        set_FSV_Handler({ type: FSV_ACTIONS.SET_SETS_STEPPER_QUANTITY, payload: minus })
      );
      calculate_prod(selectedProduct, products_calc, null, null, count, dispatch, minus)
    } else if (parseInt(minus) >= Number(minQty)) {
      dispatch(
        set_FSV_Handler({ type: FSV_ACTIONS.SET_SETS_STEPPER_QUANTITY, payload: minus })
      );
      calculate_prod(selectedProduct, products_calc, null, null, count, dispatch, minus)
    }
  };

  useEffect(() => {
    dispatch(
      set_FSV_Handler({ type: FSV_ACTIONS.SET_SETS_STEPPER_QUANTITY, payload: cartCount || minQty })
    );
  }, []);

  return (
    <div className="container fsv-qty-stepper">
      <div className="row product-details-inner-group-sm">
        <div className="col-sm-5 align-self-center label d-flex product-details-label">
          Sets:
        </div>
        <div className=" col-sm-7">
          <div className="qty-plus-minus">
            <NumericFormat
              className="qty-input"
              placeholder="qty"
              value={sets_counts}
              isAllowed={(values) => {
                
              }}
            />

            <div className="dec ec_qtybtn" onClick={Decrement}>
              -
            </div>
            <div className="inc ec_qtybtn" onClick={Increment}>
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetsStepper;
