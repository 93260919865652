import { MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { RiLockPasswordFill } from 'react-icons/ri'
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';

function ChangePassword({handleClose}) {
      /* PASSWORD MODAL OPEM / CLOSE SETTING */
  const [openChangePasswordModal, setOpenChangePasswordModal] =
useState(false);
const handleCloseChangePasswordModal = () =>
  setOpenChangePasswordModal(false);
const handleOpenChangePasswordModal = () => setOpenChangePasswordModal(true);
  return (
    <>
    {openChangePasswordModal && (
        <ChangePasswordModal
          openChangePasswordModal={openChangePasswordModal}
          handleCloseChangePasswordModal={handleCloseChangePasswordModal}
          handleUserProfileMenuClose={handleClose}
        />
      )}
    <MenuItem className="menu-list" 
    
    onClick={() => {
        handleOpenChangePasswordModal()
        
        
      }}>
        <RiLockPasswordFill className="icon" />
        <span>Change Password</span>
      </MenuItem>
    </>
  )
}

export default ChangePassword