/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { get_property_settings } from "../../../../../Component/Helper";
import "./HomePagePopUpModal.css";
import { useEffect, useRef, useState } from "react";
import { dont_show_homescreen_popup } from "../HomePagePopUpHelper";
import { IoVolumeMute, IoVolumeHigh } from "react-icons/io5";
import { BeatLoader } from "react-spinners";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 576 ? "min(280px, 90%)" : "min(320px, 90%)",
  minHeight: "480px",
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  border: "none",
  p: 4,
};

function HomePagePopUpModal({
  openHomeScreenPopup,
  handleHomeScreenPopupClose,
  homescreen_popup,
}) {
  
  const popup_video_ref = useRef(null);
  const cdn = get_property_settings("cdn");
  const [checkInput, setCheckInput] = useState(false);
  const [mutedVideo, setMutedVideo] = useState(true);
  const [loading, setLoading] = useState(true);
  const primaryColor = get_property_settings("btn_primary_color");
  const videoURL = homescreen_popup[0]?.popup_video
    ? `${cdn}uploads/home_screen_popups_uploads/${homescreen_popup[0]?.popup_video}`
    : null;
  const imageURL = homescreen_popup[0]?.popup_image
    ? `${cdn}uploads/home_screen_popups_uploads/${homescreen_popup[0]?.popup_image}`
    : null;
 
  function handleOKBtn() {
    if (!checkInput) {
      handleHomeScreenPopupClose();
    } else {
      dont_show_homescreen_popup(homescreen_popup[0]?.popup_code);
      handleHomeScreenPopupClose();
    }
  }

  useEffect(() => {
    if (!videoURL) return;
    document.querySelector(".muted-btn")?.focus();

    if (popup_video_ref.current) {
      popup_video_ref.current.play();
      popup_video_ref.current.muted = mutedVideo;
    }
  }, [mutedVideo]);

  useEffect(() => {
    if (imageURL) {
      setLoading(false);
    }
    sessionStorage.setItem(
      "homescreen_popup_already_shown",
      JSON.stringify(true)
    );
    if (!videoURL) return;
    const video = document.getElementById("homescreen-popup-video");
    document.addEventListener("DOMContentLoaded", () => {
      if (video) {
        video.play();
      }
    });
    if (video) {
      video.play();
    }
  }, []);

  return (
    <Modal
      open={openHomeScreenPopup}
      onClose={handleHomeScreenPopupClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="homepage-popup-modal"
    >
      <Box
        sx={{
          ...style,
          width: imageURL && window.innerWidth > 576 ? "700px" : imageURL && window.innerWidth < 576 ? "90%" : style.width,
          minHeight: imageURL ? "auto" : style.minHeight,
          
        }}
      >
        <section className="homepage-popup-container position-relative">
          {loading && (
            <div className="loader">
              <BeatLoader color={primaryColor} />
            </div>
          )}
          <div
            className="video-wrapper position-relative"
            
          >
            {!loading && videoURL && (
              <button
                style={{ display: "block" }}
                className="muted-btn"
                onClick={() => {
                  setMutedVideo((pre) => !pre);
                }}
              >
                {mutedVideo ? <IoVolumeMute /> : <IoVolumeHigh />}
              </button>
            )}

            {videoURL ? (
              <video
                id="homescreen-popup-video"
                src={videoURL}
                playsInline
                className={"video"}
                width={"100%"}
                controls={false}
                autoPlay={true}
                loop
                ref={popup_video_ref}
                muted
                preload="metadata"
                onLoadedData={(e) => {
                  setLoading(false);
                  e.target.play();
                }}
                onLoad={(e) => {
                  setTimeout(() => {
                    e.target.play();
                    
                  }, 1000);
                }}
                onClick={(e) => e.target.play()}
                style={{ aspectRatio: homescreen_popup[0]?.image_ratio }}
              ></video>
            ) : (
              <img src={imageURL} alt="" style={{ aspectRatio: homescreen_popup[0]?.image_ratio }}/>
            )}
          </div>
          {!loading && (
            <footer>
              <div className="input-group">
                <input
                  type="checkbox"
                  id="dont-show"
                  checked={checkInput}
                  onChange={() => setCheckInput((pre) => !pre)}
                />
                <label htmlFor="dont-show">Don't show again</label>
              </div>

              <button onClick={handleOKBtn} className="ok-btn">
                OK
              </button>
            </footer>
          )}
        </section>
      </Box>
    </Modal>
  );
}

export default HomePagePopUpModal;
