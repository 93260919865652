import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import MUIModal from "../../../../../Component/MUIModal/MUIModal";
import "./CancelPastOrderModal.css";
import { IoMdClose } from "react-icons/io";
import {ToastError, ToastSuccess} from "../../../../../Component/Helper"
import { fetch_data_from_api } from "../../../../../Api/APICalls";
import { ApiList } from "../../../../../Api/ApiList";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

function CancelPastOrderModal(_, ref) {
  const [open_MUI_modal, set_open_MUI_modal] = useState(false);
  const [reason, set_reason] = useState("")
  const handle_close_MUI_modal = useCallback(
    () => set_open_MUI_modal(false),
    []
  );
  const inputRef = useRef(null)
  const handle_open_MUI_modal = () => set_open_MUI_modal(true);
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  useImperativeHandle(ref, () => ({
    open_modal: () => handle_open_MUI_modal(),
  }));

  const {order_id} = useParams()
  
  async function handelCancelReason () {
    if(!reason) return ToastError("Enter Reason for cancellation")

    const cancel_order_response = await fetch_data_from_api(ApiList.cancel_past_order, "cancel_past_order", {
      reason,
      device_type: "web",
      order_id
    })
   
    if(cancel_order_response?.ack === "1"){
      ToastSuccess(cancel_order_response?.msg)
      await queryClient.invalidateQueries({ queryKey: ['all-order-list'] })
      navigate("/all-order")
      return handle_close_MUI_modal()
    }else if(cancel_order_response?.ack === "0"){
      ToastError(cancel_order_response?.error || "Somthing went wrong !!!")
    }else{
      ToastError("Somthing went wrong !!!")
    }
    

    handle_close_MUI_modal()
  }

  useEffect(() =>{
    // if(!inputRef.current) return
    setTimeout(() => {
       inputRef.current?.focus()

    }, 200)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current])

  return (
    <MUIModal
    className="cancel-past-order-modal"
    open_MUI_modal={open_MUI_modal}
    handle_close_MUI_modal={handle_close_MUI_modal}
    style={{ p: 0, width: "min(320px, 90%)" }}
  >
    <section className="cancel-past-order-modal-content">
      <header>
        <h3>
          <span>Reason for Cancellation</span>
          <IoMdClose className="close-btn" onClick={handle_close_MUI_modal}/>
        </h3>
      </header>
      <section className="cancel-past-order-modal-body">
          <textarea rows={5}
          value={reason}
          onChange={(e) => set_reason(e.target.value)}
          ref={inputRef}
          ></textarea>
          <div className="done-btn">
            <button onClick={handle_close_MUI_modal}>Cancel</button>
            <button onClick={handelCancelReason}>Done</button>
          </div>
      </section>
    </section>
    </MUIModal>
  )
}

export default forwardRef(CancelPastOrderModal)
