import { get_informationFromSessionID } from "../../Component/Helper"

// Coustom About Us page
import COPLAboutUs from "./COPL/COPL_About_Us"
import AboutUs from "./AboutUs"
import WhatsappIconNew from "../../Component/WhatsappIcon/WhatsappIconNew"
import KALASHGOLDAboutUs from "./KALASHGOLD/KALASHGOLD_About_Us"
import IGJEAboutUs from "./IGJE/IGJE_About_Us"
import JEWELSMITHAboutUs from "./JEWELSMITH/JEWELSMITH_About_Us"
import CHAINHOUSE24AboutUs from "./CHAINHOUSE24/CHAINHOUSE24AboutUs"



function index() {

  const company_code = get_informationFromSessionID("companyCode")
  const decrypted_company_code = atob(company_code)
  const ABOUT_PAGE = (function () {
    switch (decrypted_company_code) {
      case "COPL" : return <COPLAboutUs/>;
      case "KALASHJEWELS" : return <KALASHGOLDAboutUs/>;
      case "IGJE" : return <IGJEAboutUs/>;
      case "JEWELSMITH" : return <JEWELSMITHAboutUs/>
      case "CHAINHOUSE24" : return <CHAINHOUSE24AboutUs/>
      default: return <AboutUs/>
    }
  })()

 

  return (
    <div>
      <WhatsappIconNew />
      {ABOUT_PAGE}
    </div>
  )
}

export default index
