import "./EditProfile.css"
import { ApiList } from '../../Api/ApiList';
import { fetch_data_from_api, fetch_data_from_api_from_query } from '../../Api/APICalls';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../Component/Loader';
import TextField from '../../Component/InputFields/TextField/TextField';
import { useForm, FormProvider } from "react-hook-form"
import { ToastError, ToastSuccess } from "../../Component/Helper";
import { dropdown_control_types, get_params_values } from "../../Component/InputFields/InputField_Helper";
import CountryStateCity from "../../Component/InputFields/CountryStateCity/CountryStateCity";
import Currency from "../../Component/InputFields/Currency/Currency";
import DatePicker from "../../Component/InputFields/DatePicker/DatePicker";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";



function EditProfile() {
    const navigate = useNavigate()
    const [edit_profile_form, set_edit_profile_form] = useState([])
    // eslint-disable-next-line no-unused-vars
    const edit_profile_query = useQuery({
        queryKey: ["edit_profile"],
        queryFn: () =>
          fetch_data_from_api_from_query(`${ApiList.UserRegistration}/${ApiList.get_edit_profile_form_params}`, "edit_profile", {
            device_type: "web",
          }),
        
      });


      async function fetch_edit_profile () {
        const edit_profile_response = await fetch_data_from_api(`${ApiList.UserRegistration}/${ApiList.get_edit_profile_form_params}`, "edit_profile", {
            device_type: "web",
          })
          
          if(edit_profile_response){
            set_edit_profile_form(edit_profile_response)
          }
      }


      useEffect(() => {
        fetch_edit_profile()
      }, [])

   

      const methods = useForm()
  const onSubmit = async (data) => {
    const edited_data = get_params_values(data)
    
    const edit_profile_response = await fetch_data_from_api(`${ApiList.UserRegistration}/${ApiList.update_profile}`, ApiList.update_profile, edited_data)
    
    if(edit_profile_response?.ack === "1"){
        
        ToastSuccess(edit_profile_response?.msg)
        navigate("/home")
    }else if(edit_profile_response?.error){
      ToastError(edit_profile_response?.error)
    }
  }

      if(edit_profile_form.length === 0){
            return <Loader isLoading={true}/>
      }

    
  return (
    
    <section className='edit_profile_section'>
        
        <header className="title">
            <h2>Edit Profile</h2>
        </header>
        <FormProvider {...methods}>

        <form className='edit_profile_form' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            
            {
                // eslint-disable-next-line array-callback-return
                edit_profile_form?.map(fields => {
                    if(fields?.control_type === "Textfield"){
                        return <TextField key={fields?.short_code} fields={fields}/>
                    }else if(dropdown_control_types.includes(fields?.control_type) && (fields?.control_type === "Country" || fields?.control_type === "State" || fields?.control_type === "City")){
                        if(fields?.control_type === "State" || fields?.control_type === "City") return null
                        const state_field = edit_profile_form?.find(data => data?.control_type === "State" )
                        const city_field = edit_profile_form?.find(data => data?.control_type === "City" )
                        return <CountryStateCity  key={fields?.short_code} fields={fields} state_field={state_field} city_field={city_field}/>
                    }else if(dropdown_control_types.includes(fields?.control_type) && fields?.control_type === "Currency"){
                        return <Currency fields={fields} key={fields?.short_code}/>
                    }else if(fields?.control_type === "Datepicker"){
                        return <DatePicker fields={fields} key={fields?.short_code}/>
                    }
                })
            }

            <button className="update-profile-btn">Update Profile</button>
        </form>
        </FormProvider>
    </section>
  )
}

export default EditProfile