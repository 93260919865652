import StoreCard from "./components/StoreCard";
import { useQuery } from "@tanstack/react-query";
import "./StoreSection.css";
import { ApiList } from "../../../Api/ApiList";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { get_bulk_settings } from "../../Helper";
import NoImg from "../../../assets/images/no_image_available.png";
import ReactSlider from "../../ReactSlider/ReactSlider";
import { useEffect } from "react";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import { set_equal_slick_slider_card_height } from "../../../utils";

const responsive = [
  {
    breakpoint: 2000,
    settings: {
      slidesToShow: 3 ,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1440,
    settings: {
      slidesToShow:  3,
      slidesToScroll: 1,
    },
  },

  {
    breakpoint: 1260,
    settings: {
      slidesToShow:  3,
      slidesToScroll: 1,
    },
  },

  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow:  2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
];

function StoreSection() {
  const { cdn, location_banner_BG } =
    get_bulk_settings(["cdn", "location_banner_BG"]) || {};
  const location_banner = location_banner_BG
    ? `${cdn}uploads/location_banner_image/${location_banner_BG}`
    : NoImg;

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["location"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.Location, "location", {
        device: "web",
      }),
    staleTime: Infinity,
  });

  const store_location_bg_banner_image_position = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.store_location_bg_banner_image_position) || "top center"

  useEffect(() => {
    
    set_equal_slick_slider_card_height(".store-card", 200)
    
    
  }, [data, isSuccess])

  return (
    <>
      {!isLoading && data?.location?.length ? (
        <div className="store-section-container">
          <img
            src={location_banner}
            alt="store-bg"
            className="store-bg-image"
            style={{objectPosition: store_location_bg_banner_image_position}}
          />
          <div className="store-card-container">
            <ReactSlider
              additionalSetting={{
                infinite: false,
                slidesToShow: 3,
                arrow: false,
              }}
              responsive={responsive}
              className={""}
            >
              {data?.location?.map((locationData) => (
                <StoreCard key={locationData?.title} {...locationData} />
              ))}
            </ReactSlider>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default StoreSection;
