import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import "./MasterListModal.css";
import { createSearchURLParams, get_designStatus } from "../../../../Component/Helper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(400px, 90%)",
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function MasterListModal({
  openMasterListModal,
  handleCloseMasterListModal,
  listData,
  default_params
}) {
 
  const redirection_to_matrix = (master) => {
    let other_params = {}
    if (listData?.design_data?.which_master === master) {
      other_params = {
        table: listData?.design_data?.which_master,
        design_status: get_designStatus(listData?.design_data?.which_master),
      };
    } else {
      other_params = {
        table: listData?.inventory_data?.which_master,
        inventory_status: get_designStatus(listData?.inventory_data?.which_master),
      };
    }

    return createSearchURLParams("matrix-page", {...default_params, ...other_params})
    
  }


  return (
    <Modal
      open={openMasterListModal}
      onClose={() => {
        handleCloseMasterListModal();
        
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="master-list-orders-modal"
    >
      <Box sx={style}>
        <header>
          <h3>{listData?.wishlist_name}</h3>
          <IoMdClose className="close-btn" onClick={() => {
            handleCloseMasterListModal();
            
          }}/>
        </header>
        <ul>
          <li li onClick={() => {
            handleCloseMasterListModal();
            
          }}>
            <Link to={redirection_to_matrix(listData?.design_data?.which_master)}>{listData?.design_data?.label}</Link>
          </li>
          <li onClick={() => {
            handleCloseMasterListModal();
            
          }}>
            <Link to={redirection_to_matrix(listData?.inventory_data?.which_master)}>  
            {listData?.inventory_data?.label}
            </Link>
          </li>
        </ul>
      </Box>
    </Modal>
  );
}

export default MasterListModal;
