import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IoMdClose } from "react-icons/io";
import "./MUISideBar.css";

//'left', 'right', 'top', 'bottom'
function MUISideBar({ children, width = 'auto', className = "", title}, ref) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [open, setOpen] = React.useState("left");

  const toggleDrawer = (direction, open) => {
    
    
    setOpen(direction);
    setState({ ...state, [direction]: open });
  };

  React.useImperativeHandle(ref, () => ({
    open_side_modal: (direction) => {
        toggleDrawer(direction, true);
    },
    close_side_modal: () => {
      toggleDrawer(open, false);
    }
  }));
 
  const list = (direction) => (
    <Box
      
      sx={{
        width: direction === "top" || direction === "bottom" ? "auto" : width,
        
      }}
      role="presentation"
    //   onClick={() => toggleDrawer(direction, false)}
    
    >
       <header className="mui-sidebar-header">
        <h2>{title}</h2>
        <IoMdClose className="close-icon" 
        onClick={() => toggleDrawer(open, false)}
        />
      </header>
      {children}
    </Box>
  );
 
  return (
    <Drawer
      anchor={open}
      open={state[open]}
      onClose={() => toggleDrawer(open, false)}
      className={`mui-side-slider-option ${className}`}
      style={{'--sidebar-container-width': width}}
    >
      {list(open)}
    </Drawer>
  );
}

export default React.forwardRef(MUISideBar);
