import "./TwoColPage.css";

function TwoColPage({children}) {
  return (
    <section className='two-col-layout'>
        {children}
    </section>
  )
}

export default TwoColPage