import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useCallback } from 'react';
import { useState } from 'react'
import MUIModal from '../../../../Component/MUIModal/MUIModal';
import "./DesignNoInputModal.css"
import { IoMdClose } from 'react-icons/io';
import { fetch_data_from_api } from '../../../../Api/APICalls';
import { ApiList } from '../../../../Api/ApiList';
import { createSearchURLParams, ToastError } from '../../../../Component/Helper';
import { useNavigate } from 'react-router-dom';

function DesignNoInputModal({setShowStepModal, set_add_items_by}, ref) {
    const [open_MUI_modal, set_open_MUI_modal] = useState(false);
    const handle_close_MUI_modal = useCallback(
      () => set_open_MUI_modal(false),
      []
    );
    const input_ref = useRef(null)
    const [error, setError] = useState("")
   

    const navigate = useNavigate()
   

    
    
    
     
     
     async function handleSubmit() {
      if(!input_ref?.current) return
      const {value} = input_ref?.current
      
      if(value){

        setError("")
        set_add_items_by("by design no")
        fetch_data_from_api(
          ApiList.Jewelkam_check_design_exist,
          "Jewelkam_check_design_exist",
          {
            session_id: 0,
            oms_app_add_by_design_no: input_ref?.current?.value || ""
            
          }
        ).then(res => {
          if(res?.ack === "0" || res?.ack === 0){
            ToastError(res?.error || res?.msg || "Design no. not exist")
          }else if(typeof res === "object"){
            handle_close_MUI_modal()
            const pathname = document.location.pathname.replace("/", "");
            navigate(createSearchURLParams(pathname, {oms_app_add_by_design_no: input_ref?.current?.value}))
            setShowStepModal(true)
          } 

          return res
        })
        
      }else{
        setError("Please enter design no.")
        
      }
     }
   
   

    const handle_open_MUI_modal = () => set_open_MUI_modal(true);
  
    useImperativeHandle(ref, () => ({
      open_modal: () => handle_open_MUI_modal(),
      
    }));
  return (
    <MUIModal
      className="by-design-custom-order-modal"
      open_MUI_modal={open_MUI_modal}
      handle_close_MUI_modal={handle_close_MUI_modal}
      style={{ p: 0,  width: "min(320px, 90%)" }}
    >
          <section className="by-design-custom-section">
        <header>
          <h3>By Design No</h3>
          <IoMdClose className="close-icon" onClick={handle_close_MUI_modal}/>
        </header>

        <section className='by-design-custom-body'>
            <div className="input-group">
              <input type="text" placeholder='Enter Design No.' ref={input_ref}/>
             {error && <p className='error-text'>{error}</p>}
            </div>
            <div className='button-group'>
              <button type="button" onClick={handleSubmit}>Submit</button>
              {/* <button type="button" onClick={handle_close_MUI_modal}>Cancel</button> */}
            </div>
        </section>
        
      </section>
    </MUIModal>
  )
}

export default forwardRef(DesignNoInputModal)