import React from 'react'
import ChangeCurrencyModal from '../ChangeCurrencyModal/ChangeCurrencyModal'
import { MenuItem } from '@mui/material';
import { GrCurrency } from "react-icons/gr";

function ChangeCurrency({handleClose}) {
  
  /* CURRENCY MODAL OPEM / CLOSE SETTING */
  const [openCurrencydModal, setOpenCurrencydModal] = React.useState(false);
  const handleCloseCurrencyModal = () => setOpenCurrencydModal(false);
  const handleOpenCurrencyModal = () => setOpenCurrencydModal(true);

  
  

  return (
    <>
    {openCurrencydModal && (
        <ChangeCurrencyModal
          openCurrencydModal={openCurrencydModal}
          handleCloseCurrencyModal={handleCloseCurrencyModal}
          handleUserProfileMenuClose={handleClose}
        />
      )}

    <MenuItem  className="menu-list" onClick={() => {
        
        handleOpenCurrencyModal()
      }}>
        <GrCurrency className="icon" />
        <span>Change Currency</span>
      </MenuItem>
    </>
  )
}

export default ChangeCurrency