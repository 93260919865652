import React from 'react'
import { getCustomValue } from '../../../Custom/CompanyList';
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../../Custom/CompanyCustomization';
import { convertToGrams } from '../../../Component/Helper';

function AllOrderTotalFooter({total}) {
    
    const hide_elements_from_cart_footer = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.hide_elements_from_cart_footer);
    const hide_net_wt = hide_elements_from_cart_footer && hide_elements_from_cart_footer?.includes("net_wt")
    const show_pcs_sets_in_cart_footer = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_pcs_sets_in_cart_footer)
  return (
    <section className='footer-data-section'>
      <div className="footer-data">
        <span>Gross Wt:</span>
        <span className="value">{convertToGrams(total?.total_gross_wt)}</span>
      </div>
      {hide_net_wt ? null : <div className="footer-data">
        <span>Net Wt:</span>
        <span className="value">{convertToGrams(total?.total_net_wt)}</span>
      </div>}
      {
        show_pcs_sets_in_cart_footer ? (
          <>
          <div className="footer-data">
          <span>Sets:</span>
          <span className="value">{total?.total_quantity}</span>
        </div>
        <div className="footer-data">
          <span>Pcs:</span>
          <span className="value">{total?.total_piece}</span>
        </div>
          </>
        ) : (
        <div className="footer-data">
          <span>Pcs:</span>
          <span className="value">{total?.total_quantity}</span>
        </div>

        )
      }
      
    </section>
  )
}

export default AllOrderTotalFooter
