import { MenuItem } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaUserEdit } from "react-icons/fa";

function EditProfile({handleClose}) {
    const navigate = useNavigate()
  return (
    <MenuItem  className="menu-list" onClick={() => {
        handleClose()
        navigate("/edit-profile")
      }}>
        <FaUserEdit className="icon" />
        <span>Edit Profile</span>
      </MenuItem>
  )
}

export default EditProfile