/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react'
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import Select from "react-select";
import { fetch_data_from_api_from_query } from '../../../Api/APICalls';
import { ApiList } from '../../../Api/ApiList';
import { createSelectArrayForReactSelect, get_property_settings } from '../../Helper';
import { get_default_county_state_city_value } from '../InputField_Helper';

function CountryStateCity({fields, state_field, city_field}) {
    const {field_name, short_code, required, country_id} = fields || {};
    const { formState: {errors}, control, setValue} = useFormContext()
    const primary_BG_color = get_property_settings("btn_primary_color");

    const selected_country = useWatch({
        control,
        name: short_code,
      })

      const selected_state = useWatch({
        control,
        name: state_field?.short_code,
      })

      const selected_city = useWatch({
        control,
        name: city_field?.short_code,
      })

    const country_query = useQuery({
        queryKey: ["country"],
        queryFn: () => fetch_data_from_api_from_query(`${ApiList.UserRegistration}/${ApiList.get_loc_data}`, "country", {
          id: "",
          table: "countries",
        }).then(res => {
          return createSelectArrayForReactSelect(res, "name", "id")
        }),
     });

     const state_query = useQuery({
        queryKey: ["state", selected_country, country_id],
        queryFn: () => fetch_data_from_api_from_query(`${ApiList.UserRegistration}/${ApiList.get_loc_data}`, "state", {
          id: selected_country?.value || country_id,
          table: "states",
        }).then(res => {
            
          return createSelectArrayForReactSelect(res, "name", "id")
        }),
      
        enabled: Boolean(selected_country?.value) || !!country_id,
      });

      const city_query = useQuery({
        queryKey: ["city", selected_state, state_field?.state_id],
        queryFn: () => fetch_data_from_api_from_query(`${ApiList.UserRegistration}/${ApiList.get_loc_data}`, "city", {
          id: selected_state?.value || state_field?.state_id,
          table: "cities",
        }).then(res => {
          return createSelectArrayForReactSelect(res, "name", "id")
        }),
      
        enabled: Boolean(selected_state?.value) || !!state_field?.state_id,
      });

      useEffect(() => {
        if(selected_country) return
        if(country_id){
            const country_default_value = get_default_county_state_city_value(country_query?.data, country_id);
            setValue(short_code, country_default_value)
        }
    }, [country_query?.data])

    useEffect(() => {
        if(selected_state) return
        if(state_field?.state_id){
            const state_default_value = get_default_county_state_city_value(state_query?.data, state_field?.state_id);
            setValue(state_field?.short_code, state_default_value)
        }
    }, [state_query?.data])

    useEffect(() => {
        if(selected_city) return
        if(city_field?.city_id){
            const city_default_value = get_default_county_state_city_value(city_query?.data, city_field?.city_id);
            setValue(city_field?.short_code, city_default_value)
        }
    }, [city_query?.data])
      
  return (

    <>
        {/* --------- COUNTRY DROPDOWN -------------- */}
        <div className='input-group dropdown'>
            <label htmlFor={short_code}>
            <span>{field_name}</span>
            {required === "1" && <span className="required">*</span>}
            </label>
            <Controller
            control={control}
            name={short_code}
            rules={{
            required:{
                value: required === "1",
                message: `${field_name} is required`
            },
            
            }}
            render={({ field }) => (
            <Select
                className="react-select-container r-container"
                classNamePrefix="react-select r-select"
                id={short_code}
                options={country_query?.data || []}
                {...field}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25:
                      (primary_BG_color &&
                        primary_BG_color
                          .replace("rgb", "rgba")
                          .replace(")", ",0.2)")) ||
                      "inherit",
                    primary: primary_BG_color,
                  },
                })}
            />
            )}
            />
            {errors[short_code] && <p className="error-text">{errors[short_code]?.message}</p>}
        </div>

        {/* --------- STATE DROPDOWN -------------- */}
        <div className='input-group dropdown'>
            <label htmlFor={state_field?.short_code}>
            <span>{state_field?.field_name}</span>
            {state_field?.required === "1" && <span className="required">*</span>}
            </label>
            <Controller
            control={control}
            name={state_field?.short_code}
            rules={{
            required:{
                value: required === "1",
                message: `${state_field?.field_name} is required`
            }
            }}
            render={({ field }) => (
            <Select
                className="react-select-container r-container"
                classNamePrefix="react-select r-select"
                id={state_field?.short_code}
                options={state_query?.data || []}
                {...field}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25:
                      (primary_BG_color &&
                        primary_BG_color
                          .replace("rgb", "rgba")
                          .replace(")", ",0.2)")) ||
                      "inherit",
                    primary: primary_BG_color,
                  },
                })}
            />
            )}
            />
            {errors[state_field?.short_code] && <p className="error-text">{errors[state_field?.short_code]?.message}</p>}
        </div>

        {/* --------- CITY DROPDOWN -------------- */}
        <div className='input-group dropdown'>
            <label htmlFor={city_field?.short_code}>
            <span>{city_field?.field_name}</span>
            {city_field?.required === "1" && <span className="required">*</span>}
            </label>
            <Controller
            control={control}
            name={city_field?.short_code}
            rules={{
            required:{
                value: required === "1",
                message: `${city_field?.field_name} is required`
            },
            
            }}
            render={({ field }) => (
            <Select
                className="react-select-container r-container"
                classNamePrefix="react-select r-select"
                id={city_field?.short_code}
                options={city_query?.data || []}
                {...field}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25:
                      (primary_BG_color &&
                        primary_BG_color
                          .replace("rgb", "rgba")
                          .replace(")", ",0.2)")) ||
                      "inherit",
                    primary: primary_BG_color,
                  },
                })}
            />
            )}
            />
            {errors[city_field?.short_code] && <p className="error-text">{errors[city_field?.short_code]?.message}</p>}
        </div>
    </>
  )
}

export default CountryStateCity