import { FaCircleUser } from "react-icons/fa6";

function IconMenu() {
  return (
    <div className="icons-menu">
      <FaCircleUser />
     </div>
  )
}

export default IconMenu
