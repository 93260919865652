import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {  get_inline_applied_filter, get_is_myc_web, getCatMaster, setInlineAppliedFilter } from "../../../../redux/Reducer/reducer";
import { convert_filterd_to_capsul_string } from "./inline_filter_utils";
import { ToastError, createSearchURLParams, delete_informationFromSessionID, get_designStatus, get_informationFromSessionID, set_informationToSessionID } from "../../../../Component/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { applied_filtered_params } from "../../FilterSideBarOptions/FilterHelper";



function LabelCapsul ({data, searchParams, optionData}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {getValues, setValue} = useFormContext()

  function removeLable(key, id = null)  {
      const edited_key = key.includes("price") ? "price" : key;
      const edited_search_params = {...searchParams}
      const is_range = optionData[edited_key].every(option => option.type === "range")
      if(edited_key === "design_status"){
       const {design_status} = getValues()
      
       if(design_status.length === 1){
        
        return ToastError("This field is mandatory")
       }else if(Array.isArray(design_status) && design_status.length > 1){
          const newVal = design_status.filter(value => value !== id)
          setValue("design_status", newVal)
       }

       if(Array.isArray(design_status)){

         edited_search_params["design_status"] = design_status.join(",")
       }else if(design_status){
        edited_search_params["design_status"] = design_status
       }
       
      }
      
      if(edited_key && id){
        const filterItem = edited_search_params[edited_key].split(",").filter(value => value !== id).join(",")
        
        if(!filterItem){
          setValue(edited_key, "")
        }else if(filterItem && edited_search_params[edited_key].includes(",")){
          console.log(edited_key, filterItem.split(","))
          setValue(edited_key, filterItem.split(","))
        }else{
          setValue(edited_key, filterItem)
        }
        edited_search_params[edited_key] = filterItem
      }else if(is_range){
          
        const companyCode = get_informationFromSessionID("companyCode");
       
        const decryptCompanyName = atob(companyCode);
          
          optionData[edited_key].forEach(data => {
              if(data?.type === "range"){
                const default_min_value = Number(data[`min_${edited_key}`])
                const default_max_value = Number(data[`max_${edited_key}`])
                if(edited_key.includes("price") && decryptCompanyName === "KALASHAONLINE"){
                  
                 setValue(`kalasha_${edited_key}`, {minmax: [default_min_value, default_max_value]})
                 
  
                }else{

                  setValue(edited_key, {minmax: [default_min_value, default_max_value]})
                  
                }
              }
              
            })
        let prefix = ""
        if(edited_key.includes("price") && decryptCompanyName === "KALASHAONLINE"){
          prefix = "kalasha_"
        }
        
        delete edited_search_params[`min_${prefix}${edited_key}`];
        delete edited_search_params[`max_${prefix}${edited_key}`];
        
        const filterParams = get_informationFromSessionID("filterParams")
        delete filterParams[`min_${prefix}${edited_key}`];
        delete filterParams[`max_${prefix}${edited_key}`];
        set_informationToSessionID({
          filterParams: { ...filterParams},
        });

        
      }else{
        if(edited_key !== "design_status"){
          edited_search_params[edited_key] = "";
          //delete edited_search_params[edited_key]
          
          setValue(edited_key, "")
        }
      }
      //If there is only one deisge status then don't show the capsul of it
      if(optionData?.design_status && optionData?.design_status?.length === 1){
          delete edited_search_params["design_status"]
      }
      const applied_filter = applied_filtered_params(edited_search_params);
      
      
      if("is_pinned" in applied_filter && typeof applied_filter["is_pinned"] === "string"){
        // eslint-disable-next-line no-self-assign
        applied_filter["is_pinned"] = applied_filter["is_pinned"]
      }

      if("design_status" in applied_filter && typeof applied_filter["design_status"] === "string"){
        applied_filter["design_status"] = applied_filter["design_status"].split(",")
      }
      
      console.log(applied_filter)
      dispatch(setInlineAppliedFilter({type: "remove", payload: applied_filter}))
      const filterParams = get_informationFromSessionID("filterParams")
      set_informationToSessionID({
        filterParams: { ...filterParams, ...edited_search_params },
      });
      //console.log({...filterParams, ...edited_search_params})
     navigate(createSearchURLParams("matrix-page", edited_search_params))
    }
   
    
    return <div className='label-capsul'>
        <span className='label-name'>{data?.str}</span>
        <span className='remove-btn' onClick={() => {
          removeLable(data.key, data?.id)
        }}><IoMdClose/></span>
    </div>
}

function FilteredLabel({optionData, sortData}) {
  
    const {setValue, getValues} = useFormContext()
  const inline_applied_filter = useSelector(get_inline_applied_filter)
  
  const master = useSelector(getCatMaster)
  const is_myc_web = useSelector(get_is_myc_web)
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch()
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const inline_applied_filter_arr = inline_applied_filter.map(filterData => {
    //const value = Object.values(filterData)
    //if(!value || Array.isArray(value) && value.length === 0) return null
    return convert_filterd_to_capsul_string(filterData, optionData, sortData)
  }).flat().filter(data => !!data)

  
  
   function removeAllFilter(){
        const {design_status} = getValues()
        
        
        let default_design_status = null;
        sortData.forEach(sort => {
            optionData[sort?.field].forEach(data => {
                if(data?.type === "range"){
                  const default_min_value = Number(data[`min_${sort?.field}`])
                  const default_max_value = Number(data[`max_${sort?.field}`])
                  const companyCode = get_informationFromSessionID("companyCode");
                  const decryptCompanyName = atob(companyCode);
                    if(sort?.field.includes("price") && decryptCompanyName === "KALASHAONLINE"){

                      setValue(`kalasha_${sort?.field}`, {minmax: [default_min_value, default_max_value]})
                      
                    }else{

                      setValue(sort?.field, {minmax: [default_min_value, default_max_value]})
                      
                    }
                }else if(sort?.field === "design_status"){
                    if(Array.isArray(design_status) && design_status.length > 1){
                      setValue(sort?.field, optionData[sort?.field][0]?.id)
                      default_design_status = optionData[sort?.field][0]?.id
                    }
                }else{
                  setValue(sort?.field, data?.selected === "1" ? data?.id : "")

                }
                
              })
        })

    
        dispatch(setInlineAppliedFilter({type: "remove_all", payload:  !design_status && !default_design_status ? null : {design_status : default_design_status || design_status}}))
        //set_informationToSessionID({ table: master });
    delete_informationFromSessionID("filterParams");

    const named_wishlist_params = (function () {
      if(searchParams.mode === "named_wishlist" ) {
        const status_key = searchParams?.table ? searchParams?.table.replace("master", "status") : master.replace("master", "status")
        return {
          table: searchParams?.table || master,
          [status_key]: get_designStatus(searchParams?.design_status || master),
        }
      }else{
         return {}
      }
    })()
    
    const clearSearchParams = {
      mode: is_myc_web
        ? "catalogue_filter"
        : searchParams?.mode === "featuredBased"
        ? "featuredBased_filter"
        : searchParams?.mode || "filter",
      cat_id: searchParams?.cat_id || "0",
      featured_id: searchParams?.featured_id || "",
      img_type: searchParams?.img_type || "",
      wishlist_id: searchParams?.wishlist_id || "",
      ...named_wishlist_params
    };

    if(!default_design_status && !design_status){
      navigate(
        createSearchURLParams("matrix-page", {
          ...clearSearchParams,
          sort: searchParams?.sort || "0",
          page: searchParams?.page || "0",
          
        })
      );

    }else{

      navigate(
        createSearchURLParams("matrix-page", {
          ...clearSearchParams,
          sort: searchParams?.sort || "0",
          page: searchParams?.page || "0",
          design_status: default_design_status || design_status
        })
      );
    }
        
      }

  if(!inline_applied_filter || inline_applied_filter?.length === 0 || inline_applied_filter_arr?.length === 0){
    return null
  }

 
  return (
    <div className='filtered-label-section'>
        <div className="filtered-capsul-section">
            {   
                
                inline_applied_filter_arr.map((filter, index) =>  <LabelCapsul key={index} data={filter} searchParams={searchParams} optionData={optionData} sortData={sortData}/>)
            }
           
            
        <div className="filtered-remove-all" onClick={removeAllFilter}> 
            Remove All
        </div>
        </div>
    </div>
  )
}

export default FilteredLabel