import { useState } from "react";
import SelectClientFormModal from "./SelectClientFormModal/SelectClientFormModal";

function AddSelectClientBtn() {
  const [show_select_client_modal, set_show_select_client_modal] =
    useState(false);

  return (
    <>
      {show_select_client_modal && (
        <SelectClientFormModal
          show_select_client_modal={show_select_client_modal}
          set_show_select_client_modal={set_show_select_client_modal}
        />
      )}
      <div className="add-btn" onClick={() => set_show_select_client_modal(true)}>Add</div>
    </>
  );
}

export default AddSelectClientBtn;
