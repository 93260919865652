
import { useFormContext } from "react-hook-form";

function TextareaInput({field}) {
  const {field_name, short_code, required_field, default_select} = field || {}
  const { formState: {errors}, register } = useFormContext() 
  return (
    <div className="form-input-group">
      <label>
        <span>{field_name}</span>
        {required_field === "1" && <span className="required">*</span>}
      </label>
      <textarea rows={3} className="textarea" id={short_code} {...register(short_code, {
              required: {
                value: required_field === "1",
                message: `${field_name} is required`
              },
              value: default_select || ""
            })}/>
       {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default TextareaInput;
