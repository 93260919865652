import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { memo } from "react";

function MUIModal({
  children,
  open_MUI_modal,
  handle_close_MUI_modal,
  className = "",
  style = {},
}) {
  const default_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(350px, 90%)",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    ...style,
  };
  
  return (
    <Modal
      open={open_MUI_modal}
      onClose={handle_close_MUI_modal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={className}
    >
      <Box sx={default_style}>
        {children}
      </Box>
    </Modal>
  );
}

export default memo(MUIModal);
