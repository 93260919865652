import React from 'react'
import {  useNavigate } from 'react-router-dom'
import { createSearchURLParams, get_property_settings } from '../../../Component/Helper'

function CustomOrderStatus({status_group, set_active_status, active_status}) {
  const navigate = useNavigate() 
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );

 

  function Button ({order_status, is_selected, id, order_count}) {
    
    return (
        <li className={`${active_status === id || (!active_status && is_selected === id) ? 'group-by-buttons-selected' : 'group-by-buttons-unselected'} text-nowrap ui button-ui`}
        onClick={() => {
          const kam_module_title = get_property_settings("kam_module_title")
          set_active_status(id);
          navigate(createSearchURLParams(kam_module_title.replace(/\s/g, "-").toLowerCase(), {...searchParams, status: id, page: "0", sort: searchParams?.sort || "0"}))
        }}
        ><span>{order_status}</span>
        {order_count !== "0" ?  <span className='order-count group-by-buttons-selected'>{order_count}</span> : null} 
        </li>
        
        
    )
  }

  return (
    <section className='custom-order-status'>
      <div className='status text-nowrap'>STATUS :</div>
      <ul className='status-button-group overflow-scroll example'>
        {
          status_group?.map(status => <Button key={status?.id} {...status}/>)
        }
        
      </ul>
    </section>
  )
}

export default CustomOrderStatus
