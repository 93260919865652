import { get_property_settings } from "./Component/Helper";

//Utils Function
function roundDecimalPoint(value) {
    if(!value) return 0
    let cost = value.toFixed(2).toString();

    const extractDecimal = cost.split('.')[1] ? Number(cost.split('.')[1]) : null;
    
    if (extractDecimal) {
        if (extractDecimal <= 50) {
            cost = Math.floor(cost)
        } else if (extractDecimal > 50) {
            cost = Math.ceil(cost)
        }
    }
    
    return cost
}


export function material_all_total(material, propertyName, is_mat_affects_wt) {
    let Mat_Total = 0;

    if(is_mat_affects_wt){
        material.forEach(mat => {
            if (Object.keys(mat).length > 0) {
                for (const property in mat) {
                    if(Array.isArray(mat[property])){
                        // eslint-disable-next-line no-loop-func
                        mat[property].forEach(material => {
                            if(material.mat_affects_wt === "Yes"){
                                Mat_Total += parseFloat(material[propertyName])
                            }
                        })
                    }
                    
                }
            }
        })
    }else{
        material.forEach(mat => {
            if (Object.keys(mat).length > 0) {
                for (const property in mat) {
                    if(Array.isArray(mat[property])){
                        // eslint-disable-next-line no-loop-func
                        mat[property].forEach(material => {
                            if(material.mat_affects_wt === "Yes"){
                                Mat_Total += parseFloat(material[propertyName])
                            }
                        })
                    }
                    
                }
            }
        })  
    }
    
    if(propertyName === "total"){
        Mat_Total = parseFloat(roundDecimalPoint(Number(Mat_Total)))
    }
    
    return Mat_Total;
}

//value: gross weight OR net weight, unitType 'gwt' OR 'nwt'
//This function must be run last 
export function weightGWNW_decimal_count(value, unitType) {
    const gwt_decimal_count = Number(get_property_settings("gwt_decimal_count") || 3);
    const nwt_decimal_count = Number(get_property_settings("nwt_decimal_count") || 3);

    
    if (gwt_decimal_count >= 3 && unitType === 'gwt') {
        return value.toFixed(3)
    } else if (nwt_decimal_count >= 3 && unitType === 'nwt') {
        return value.toFixed(3)
    } else {
        const str = value.toString()

        //if last value of 5 then return slice string 1.625 => 1.62
        if(str.includes('.')){
            const strArr = str.split('.')
            if(strArr[1].length === 3 && strArr[1][strArr[1].length - 1] === '5'){
                return str.substring(0, 4) //1.62
            }
        }


        return value.toFixed(2) //1.63
    }
}



/* ========== Sequence Call functions ====================================================================> */
//1. Call function
export function gross_weight_converstion(selectedGrossWeight, FSV_API_OBJ) {
    const defaults = FSV_API_OBJ["defaults"]
    const material = FSV_API_OBJ["material"]
    

    //Grab gross weight from selected else grab from defaults
    let grossWeight = Number(selectedGrossWeight ? selectedGrossWeight : defaults["gross_wt"])
    let Mat_Total = material_all_total(material, "weight", true)

    return { grossWeight, netWeight: grossWeight - Mat_Total }
}

//2. Call function
export function karat_to_weight_converstion(selectedKarat, selectedGrossWeight, selectedNetWeight, FSV_API_OBJ) {
    //Grab required property from FSV OBJ
    const karat_rate_change = FSV_API_OBJ["karat_rate_change"]
    const defaultKarat = FSV_API_OBJ["defaults"]["karat"]
    const material = FSV_API_OBJ["material"]
    const grossNetDiff = FSV_API_OBJ["grossNetDiff"]
    const defaults = FSV_API_OBJ["defaults"]


    //Grab rate from selected krarat
    const karat_rate_change_array = karat_rate_change[0][defaultKarat.toString()];
    let selected_karat_rate = 0;
    if(Array.isArray(karat_rate_change_array) && karat_rate_change_array.length > 0){
        
        selected_karat_rate = Number(karat_rate_change_array.find(arr => arr.karat_name === selectedKarat.toString())?.rate)
        
    }
    
    

    //Grab gross / net weight from selected else grab from defaults
    let grossWeight = Number(selectedGrossWeight ? selectedGrossWeight : defaults["gross_wt"])
    let netWeight = Number(selectedNetWeight ? selectedNetWeight : defaults["net_wt"])


    //Grab new net weight
    if (Number(selected_karat_rate) > 0) {
        if (Number(defaultKarat) < Number(selectedKarat)) {
            netWeight = netWeight + (netWeight * (selected_karat_rate / 100));
        }
        else {
            netWeight = netWeight - (netWeight * (selected_karat_rate / 100));
        }
    }


    //Grab new gross weight
    if (Array.isArray(material) && material.length > 0) {
        //Base on material calc pending
    } else {
        if (grossNetDiff && Number(grossNetDiff) > 0) {
            grossWeight = netWeight + Number(grossNetDiff);
        } else {
            grossWeight = netWeight
        }
    }
    
    return { karat: selectedKarat, grossWeight, netWeight }
}



//3. Call Function
export function size_to_weight_converstion(selectedSize, selectedGrossWeight, selectedNetWeight, size_change_wt_on, size_diameter_based_wt_change, FSV_API_OBJ) {
    // const {size_change_wt_on, size_diameter_based_wt_change} = requiredSettingsForCalculation;

    const size_calculation_type = FSV_API_OBJ["size_calculation_type"]["calculation_type"]
    const diameter_for_size = FSV_API_OBJ["diameter_for_size"]
    const defaults = FSV_API_OBJ["defaults"]
    const wt_value_based_on_size = FSV_API_OBJ["wt_value_based_on_size"]
    const material = FSV_API_OBJ["material"]
    const grossNetDiff = FSV_API_OBJ["grossNetDiff"]

    //Grab gross / net weight from selected else grab from defaults
    let grossWeight = Number(selectedGrossWeight ? selectedGrossWeight : defaults["gross_wt"])
    let netWeight = Number(selectedNetWeight ? selectedNetWeight : defaults["net_wt"])
    

    let newSizeMMValue = "";
    let defaultSizeMMValue = "";
    let defaultSize = defaults["size"];
    
    const size_change_wt_on_2 = get_property_settings("size_change_wt_on")
    if ((size_change_wt_on === "calculation" || size_change_wt_on_2 === "calculation") && size_calculation_type === "proportionate") {

        //Calculate Net Weight
        if (size_diameter_based_wt_change === "Yes") {
            if (Array.isArray(diameter_for_size) && diameter_for_size.length > 0) {
                diameter_for_size.forEach(dia_size => {
                    
                    if (parseFloat(dia_size.size_value.toString()) === parseFloat(selectedSize.toString())) {
                       
                        newSizeMMValue = dia_size.dia_val_mm
                    }
                    if (parseFloat(dia_size.size_value.toString()) === parseFloat(defaultSize.toString())) {
                        defaultSizeMMValue = dia_size.dia_val_mm
                    }


                })
                
                //if there is no dia_val_mm in diameter_for_size then newSizeMMValue is the same which is selected
                if(!newSizeMMValue){
                    newSizeMMValue = selectedSize
                }
            }
            
            netWeight = (Number(newSizeMMValue) * netWeight) / Number(defaultSizeMMValue);

        } else {
            
            netWeight = (Number(selectedSize) * netWeight) / Number(defaultSize);
        }

       
        
    } else if (size_change_wt_on === "value") {
        if (Array.isArray(wt_value_based_on_size) && wt_value_based_on_size.length > 0) {
            wt_value_based_on_size.forEach(size => {
                if (size.size_name.toString() === selectedSize.toString()) {
                    grossWeight = size.weight;
                    netWeight = grossWeight
                }
            })
        }

        
    }


    //Calculate Gross Weight
    if (Array.isArray(material) && material.length > 0) {

        let Mat_Total = material_all_total(material, "weight", true)

        grossWeight = parseFloat(netWeight + Mat_Total)
        
    } else {
        if (grossNetDiff && Number(grossNetDiff) > 0) {
            grossWeight = netWeight + Number(grossNetDiff)
        } else {
            grossWeight = netWeight
        }
        
    }
    
    grossWeight = weightGWNW_decimal_count(grossWeight, 'gwt')
    netWeight = weightGWNW_decimal_count(netWeight, 'nwt')
    
    

    return { size: selectedSize, grossWeight, netWeight }
}


//4. Call Function
export function karat_change_wt_on_is_value() {

}

//5. Call Function
export function karat_amount_calc(selectedKarat, grossWeight, netWeight, FSV_API_OBJ) {
   
    const calc_karat_amt_on = get_property_settings("calc_karat_amt_on")
    const karat_rates_array = FSV_API_OBJ["karat_rates"];
    // eslint-disable-next-line no-unused-vars
    const karat_fine_perc = FSV_API_OBJ["karat_fine_perc"];

    //grab karat rates from array
    
    const karat_rates = Number(karat_rates_array?.find(karat => {

        return karat.karat_value.toString() === selectedKarat?.toString()

    })?.rate);

    let calculationBasedOnGNWT = 0;

    if (calc_karat_amt_on === "1") {
        calculationBasedOnGNWT = Number(grossWeight)
    } else {
        calculationBasedOnGNWT = Number(netWeight)
    }
    let cost = (karat_rates * calculationBasedOnGNWT)

    cost = roundDecimalPoint(cost)

    return { karat_amount: cost || 0 }

}

//6. Call Function
export function labour_calc(seletedSize, selectedKarat, grossWeight, netWeight, FSV_API_OBJ) {
    const change_labour_based_on_size = get_property_settings("change_labour_based_on_size");
    const calc_labour_per_gram_amt_on = get_property_settings("calc_labour_per_gram_amt_on");
    const labour_amount_column_type = get_property_settings("labour_amount_column_type");
    const karat_fine_perc = FSV_API_OBJ["karat_fine_perc"];
    const labour_value_based_on_size = FSV_API_OBJ["labour_value_based_on_size"]
    const labour_details = FSV_API_OBJ["labour_details"]

    let labourRateBaseonSize = null;
    if (change_labour_based_on_size === "Yes" && Array.isArray(labour_value_based_on_size) && labour_value_based_on_size.length > 0) {
        labour_value_based_on_size.forEach(value => {
           
            let sizeName = value["size_name"];
            let labour = value["labour"];

            if (sizeName === seletedSize) {
                labourRateBaseonSize = labour;
            }
        })
    }

   
    

    //Labour based calculation
    let calculationLabourBasedOnGNWT = 0;
    if (calc_labour_per_gram_amt_on === "1") {
        calculationLabourBasedOnGNWT = Number(grossWeight)
    } else {
        calculationLabourBasedOnGNWT = Number(netWeight)
    }

    

    //Labour amount calculation
    let labourAmount = 0;
    let thisLabourVal = null;
    let wasteage = null;
    if (labour_details.length > 0) {

        //Markup code pending for Zorba
        

        labour_details.forEach((details) => {
            // if (change_labour_based_on_size === "Yes") {
                
            // }   
                //For Compnay Condition Pending "kalasha"
                if(false){

                }else{
                    if(details.type.toString() === "1"){
                        if(labour_amount_column_type === "fine"){
                            let percentage = Number(karat_fine_perc[selectedKarat.toString()]);
                            let LabourRate = labourRateBaseonSize ? Number(labourRateBaseonSize) : Number(details.rate)
                            thisLabourVal = ((percentage + LabourRate) * calculationLabourBasedOnGNWT) / 100
                        }else if(labour_amount_column_type === "amount"){
                            
                            // let percentage = Number(karat_fine_perc[selectedKarat.toString()]);
                            let LabourRate = labourRateBaseonSize ? Number(labourRateBaseonSize) : Number(details.rate)
                            thisLabourVal = (LabourRate * calculationLabourBasedOnGNWT) / 100;
                            thisLabourVal = thisLabourVal * Number(details.karat_type_rate);
                            wasteage = thisLabourVal
                            
                            
                        }else{
                            let LabourRate = labourRateBaseonSize ? Number(labourRateBaseonSize) : Number(details.rate)
                            thisLabourVal = ((LabourRate / 100) * calculationLabourBasedOnGNWT) * Number(details.karat_type_rate)
                        }
                    }else if(details.type.toString() === "2"){
                        let LabourRate = labourRateBaseonSize ? Number(labourRateBaseonSize) : Number(details.rate)
                        thisLabourVal = LabourRate * calculationLabourBasedOnGNWT
                    }else{
                        let LabourRate = labourRateBaseonSize ? Number(labourRateBaseonSize) : Number(details.rate)
                        thisLabourVal = LabourRate 
                    }
                }

                labourAmount += thisLabourVal;
            
        })



    }
    labourAmount = roundDecimalPoint(labourAmount)
    thisLabourVal = roundDecimalPoint(thisLabourVal)
    wasteage = roundDecimalPoint(wasteage)
    return {labourAmount, thisLabourVal, wasteage}
}

//7. Pcs Call Function
export function Pcs_stepper_calc(grossWeight, netWeight, stepperValue, FSV_API_OBJ, fsv_sets_count){
    const change_wt_based_on_qty = get_property_settings("change_wt_based_on_qty") === "Yes";
    

    // eslint-disable-next-line no-unused-vars
    const subProdsArray = FSV_API_OBJ["subproducts"]
    const pcs = Number(FSV_API_OBJ["no_pcs"])
    let enable_set_and_pcs_logic = FSV_API_OBJ["enable_set_and_pcs_logic"]
    enable_set_and_pcs_logic = enable_set_and_pcs_logic === "1";

    let tempGW = grossWeight;
    let tempNW = netWeight;

    
    
    if(change_wt_based_on_qty){
        let newGW = null;
        let newNW = null;
        const GW_weightForOnePcs = Number(tempGW) / pcs;  
        const NW_weightForOnePcs = Number(tempNW) / pcs;  
        
        //if design master
        // if(true){
        //     newGW = GW_weightForOnePcs * Number(stepperValue);
        //     newNW = NW_weightForOnePcs * Number(stepperValue);
        // }

        // if(subProdsArray.length > 0){
        //     newGW = tempGW * Number(stepperValue);
        //     newNW = tempNW * Number(stepperValue);
        // }
        
        newGW = GW_weightForOnePcs * Number(stepperValue);
        newNW = NW_weightForOnePcs * Number(stepperValue);
        
        
        if(enable_set_and_pcs_logic && Number(fsv_sets_count) > 0){
            newGW = newGW * Number(fsv_sets_count)
            newNW = newNW * Number(fsv_sets_count)
        }

        
        newGW = weightGWNW_decimal_count(newGW, "gwt")
        newNW = weightGWNW_decimal_count(newNW, "nwt")

        return {newGW, newNW}
    }else{
        return {newGW:grossWeight, newNW:netWeight}
    }
    
    
    
}


//8. Call Total Material (use utility function for material_all_total)


//9. Calc GST and Total Cast
export function gst_and_all_total_calc (metal_cost, material_cost, labour_cost, other_charges, FSV_API_OBJ) {
        const GST_tax_perc = FSV_API_OBJ?.taxes.find(tax => tax.tax_name.toUpperCase() === "GST")?.tax_percentage || 0
        
        const total_before_tax =  Number(metal_cost) + Number(material_cost) + Number(labour_cost) + Number(other_charges);
        
        let GST = (total_before_tax * Number(GST_tax_perc)) / 100
        let total_after_tax = total_before_tax + GST;

        GST = roundDecimalPoint(GST);
        total_after_tax = roundDecimalPoint(total_after_tax)

        return {GST, total_after_tax}
    
    
}