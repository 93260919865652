export const CampaignData = [
  {
    title: "Valentine's Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/671709213564.jpg",
    year: "2024",
    collectionID: "Collection 38",
    id: 0,
  },
  {
    title: "Sankranthi Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/661709213483.jpg",
    year: "2024",
    collectionID: "Collection 38",
    id: 1,
  },
  {
    title: "Sankranthi Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/651709213479.jpg",
    year: "2024",
    collectionID: "Collection 38",
    id: 2,
  },
  {
    title: "Diwali Radiance",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/641709213388.jpg",
    year: "2023",
    collectionID: "Collection 37",
    id: 3,
  },
  {
    title: "Navratri Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/631709213344.jpg",
    year: "2023",
    collectionID: "Collection 36",
    id: 4,
  },
  {
    title: "Aarambh ",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/621709213251.jpg",
    year: "2023",
    collectionID: "Collection 35",
    id: 5,
  },
  {
    title: "Sravanam Elegance",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/611709213198.jpg",
    year: "2023",
    collectionID: "Collection 34",
    id: 6,
  },
  {
    title: "Heritage Grandeur",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/601709213149.jpg",
    year: "2023",
    collectionID: "Collection 33",
    id: 7,
  },
  {
    title: "Minimal Elegance",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/591709213100.jpg",
    year: "2023",
    collectionID: "Collection 32",
    id: 8,
  },
  {
    title: "Manga Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/581709212337.jpg",
    year: "2023",
    collectionID: "Collection 31",
    id: 9,
  },
  {
    title: "Handcrafted Golden Creations",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/571709212231.jpg",
    year: "2023",
    collectionID: "Collection 30",
    id: 10,
  },
  {
    title: "6th Anniversary Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/561709212166.jpg",
    year: "2023",
    collectionID: "Collection 29",
    id: 11,
  },
  {
    title: "Ugadhi Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/551709212089.jpg",
    year: "2023",
    collectionID: "Collection 28",
    id: 12,
  },
  {
    title: "Tales of Sankranthi",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/541709212026.jpg",
    year: "2023",
    collectionID: "Collection 27",
    id: 13,
  },
  {
    title: "Majestic Creations",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/531709211948.jpg",
    year: "2022",
    collectionID: "Collection 26",
    id: 14,
  },
  {
    title: "Royal Wedding Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/521709211699.jpg",
    year: "2022",
    collectionID: "Collection 25",
    id: 15,
  },
  {
    title: "Modern Elegance",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/511709211640.jpg",
    year: "2022",
    collectionID: "Collection 24",
    id: 16,
  },
  {
    title: "Mother's Day Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/501709211540.jpg",
    year: "2022",
    collectionID: "Collection 23",
    id: 17,
  },
  {
    title: "Diamond Dazzle",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/491709211454.jpg",
    year: "2022",
    collectionID: "Collection 22",
    id: 18,
  },
  {
    title: "Sravanam",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/481709207934.jpg",
    year: "2022",
    collectionID: "Collection 21",
    id: 19,
  },
  {
    title: "Alluring Sparkle",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/471709207520.jpg",
    year: "2022",
    collectionID: "Collection 20",
    id: 20,
  },
  {
    title: "Timeless Wedding Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/451709199372.jpg",
    year: "2022",
    collectionID: "Collection 18",
    id: 21,
  },
  {
    title: "The Sparkling Glimpse",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/431686312485.jpg",
    year: "2022",
    collectionID: "Collection 17",
    id: 22,
  },
  {
    title: "5th Anniversary Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/421651819579.jpg",
    year: "2022",
    collectionID: "Collection 16",
    id: 23,
  },
  {
    title: "Valentine's Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/411651819538.jpg",
    year: "2022",
    collectionID: "Collection 16",
    id: 24,
  },
  {
    title: "The Sparkling Bridal Jewellery",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/401651819486.jpg",
    year: "2022",
    collectionID: "Collection 15",
    id: 25,
  },
  {
    title: "The Wedding Edit",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/391651819374.jpg",
    year: "2021",
    collectionID: "Collection 14",
    id: 26,
  },
  {
    title: "The Vintage Elegance",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/381651818315.jpg",
    year: "2021",
    collectionID: "Collection 13",
    id: 27,
  },
  {
    title: "The Work-Wear Essentials",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/371651818134.jpg",
    year: "2021",
    collectionID: "Collection 12",
    id: 28,
  },
  {
    title: "The Opulent Accessories",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/361651818056.jpg",
    year: "2021",
    collectionID: "Collection 11",
    id: 29,
  },
  {
    title: "The Diamond Allure",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/351651817621.jpg",
    year: "2021",
    collectionID: "Collection 10",
    id: 30,
  },
  {
    title: "4th Anniversary Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/341620649287.png",
    year: "2021",
    collectionID: "Collection 9",
    id: 31,
  },
  {
    title: "Exclusive Bridal Range",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/331620649234.png",
    year: "2020",
    collectionID: "Collection 8",
    id: 32,
  },
  {
    title: "Fusion Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/321620647041.png",
    year: "2020",
    collectionID: "Collection 7",
    id: 33,
  },
  {
    title: "Temple Jewellery",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/311620453840.png",
    year: "2020",
    collectionID: "Collection 6",
    id: 34,
  },
  {
    title: "Redefining classics",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/301584440435.jpg",
    year: "2019-2020",
    collectionID: "collection1",
    id: 35,
  },
  {
    title: "Eminent Fusion collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/291584440375.jpg",
    year: "2019-2020",
    collectionID: "collection2",
    id: 36,
  },
  {
    title: "Exquisite signature collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/281584440238.jpg",
    year: "2019-2020",
    collectionID: "collection3",
    id: 37,
  },
  {
    title: "Royal Heritage Collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/271584365766.jpg",
    year: "2018-2019",
    collectionID: "collection4",
    id: 38,
  },
  {
    title: "Royal opulent bridal jewellery collection",
    image: "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_baner/261584365706.jpg",
    year: "2017-2018",
    collectionID: "Collection 1",
    id: 39,
  },
];

export  const CampaignDataAllImages = CampaignData.map(campaign => campaign.image)


export const CampaignInnerData = {
  "Collection 38": [
    {
      year: "2024",
      title: "Sankranthi Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/65Sankranti%20Page-10.jpg",
      ],
    },

    {
      year: "2024",
      title: "Sankranthi Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/66Sankranti%20Page-10.jpg",
      ],
    },

    {
      year: "2024",
      title: "Valentine's Collection",
      images: [
        
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-01.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-02.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-03.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-04.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-05.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-06.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-07.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-08.jpg",
          "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/67Valentine_s%20Page-09.jpg",
        
      ],
    },
  ],

  "Collection 37": [
    {
      year: "2023",
      title: "Diwali Radiance",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/64Diwali%20Page-12.jpg",
      ],
    },
  ],

  "Collection 36": [
    {
      year: "2023",
      title: "Navratri Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/63Navaratri%20Page-09.jpg",
      ],
    },
  ],

  "Collection 35": [
    {
      year: "2023",
      title: "Aarambh",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/62Aarambh%20Page-08.jpg",
      ],
    },
  ],

  "Collection 34": [
    {
      year: "2023",
      title: "Sravanam Elegance",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/61Sravanam%20Page-12.jpg",
      ],
    },
  ],

  "Collection 33": [
    {
      year: "2023",
      title: "Heritage Grandeur",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/60Heritage%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/60Heritage%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/60Heritage%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/60Heritage%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/60Heritage%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/60Heritage%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/60Heritage%20Page-07.jpg",
      ],
    },
  ],

  "Collection 32": [
    {
      year: "2023",
      title: "Minimal Elegance",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/59Minimal%20%20Page-10.jpg",
      ],
    },
  ],

  "Collection 31": [
    {
      year: "2023",
      title: "Manga Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/58Manga%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/58Manga%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/58Manga%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/58Manga%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/58Manga%20Page-05.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/58Manga%20Page-06.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/58Manga%20Page-07.webp",
      ],
    },
  ],

  "Collection 30": [
    {
      year: "2023",
      title: "Handcrafted Golden Creations",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-05.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-06.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-07.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-08.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-09.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-10.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-11.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-12.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-13.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-14.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-15.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-16.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/57Gold%20Page-17.webp",
      ],
    },
  ],

  "Collection 29": [
    {
      year: "2023",
      title: "6th Anniversary Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-05.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-06.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-07.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-08.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-09.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-10.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-11.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-12.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-13.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-14.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-15.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-16.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-17.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-18.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/566th%20Page-19.webp",
      ],
    },
  ],

  "Collection 28": [
    {
      year: "2023",
      title: "Ugadhi Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-05.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-06.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-07.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-08.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-09.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-10.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-11.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/55Ugadhi%20Page-12.webp",
      ],
    },
  ],
  "Collection 27": [
    {
      year: "2023",
      title: "Tales of Sankranthi",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/54Sankranti%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/54Sankranti%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/54Sankranti%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/54Sankranti%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/54Sankranti%20Page-05.webp",
      ],
    },
  ],

  "Collection 26": [
    {
      year: "2022",
      title: "Majestic Creations",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-05.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-06.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-07.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-08.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-09.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-10.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-11.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-12.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/53NRI%20Page-13.webp",
      ],
    },
  ],

  "Collection 25": [
    {
      year: "2022",
      title: "Royal Wedding Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/52Wedding%20Edit%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/52Wedding%20Edit%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/52Wedding%20Edit%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/52Wedding%20Edit%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/52Wedding%20Edit%20Page-05.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/52Wedding%20Edit%20Page-06.webp",
      ],
    },
  ],

  "Collection 24": [
    {
      year: "2022",
      title: "Modern Elegance",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-01.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-02.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-03.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-04.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-05.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-06.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-07.webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/51Aravinda%20Page-08.webp",
      ],
    },
  ],

  "Collection 23": [
    {
      year: "2022",
      title: "Mother's Day Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(1).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(2).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(3).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(4).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(5).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(6).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(7).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(8).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(9).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(10).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(11).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/50Diamond%20Collection%20Page%20(12).webp",
      ],
    },
  ],

  "Collection 22": [
    {
      year: "2022",
      title: "Diamond Dazzle",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(1).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(2).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(3).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(4).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(5).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(6).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(7).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(8).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(9).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(10).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(11).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/49Diamond%20Collection%20Page%20(12).webp",
      ],
    },
  ],

  "Collection 21": [
    {
      year: "2022",
      title: "Sravanam",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(1).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(2).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(3).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(4).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(5).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(6).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(7).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(8).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(9).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(10).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(11).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(12).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(13).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(14).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(15).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(16).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(17).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/48Sravanam%20Elegance%20Page%20(18).webp",
      ],
    },
  ],

  "Collection 20": [
    {
      year: "2022",
      title: "Alluring Sparkle",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(2).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(6).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(15).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(1).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(3).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(4).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(5).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(7).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(8).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(9).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(10).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(11).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(12).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(13).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(14).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(16).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(17).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/47Diamond%20Campaign%20Page%20(18).webp",
      ],
    },
  ],

  "Collection 18": [
    {
      year: "2022",
      title: "Timeless Wedding Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(18).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(17).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(16).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(13).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(14).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(15).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(12).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(11).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(10).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(8)%20(1).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(8).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(7).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(9).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(6).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(5).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(4).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(1).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(2).webp",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/45Wedding%20Collection%20Page%20(3).webp",
      ],
    },
  ],

  "Collection 17": [
    {
      year: "2022",
      title: "The Sparkling Glimpse",
      images: [],
    },
  ],

  "Collection 16": [
    {
      year: "2022",
      title: "Valentine's Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-12.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-13.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-14.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-15.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-16.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-17.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/41Web%20Campaign-V-18.jpg",
      ],
    },
  ],

  "Collection 15": [
    {
      year: "2022",
      title: "The Sparkling Bridal Jewellery",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/40Web%20Campaign-11.jpg",
      ],
    },
  ],

  "Collection 14": [
    {
      year: "2021",
      title: "The Wedding Edit",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-08.jpg",
      ],
    },
  ],

  "Collection 13": [
    {
      year: "2021",
      title: "The Vintage Elegance",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-12.jpg",
      ],
    },
  ],

  "Collection 12": [
    {
      year: "2021",
      title: "The Work-Wear Essentials",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/37Web%20Campaign-09.jpg",
      ],
    },
  ],

  "Collection 11": [
    {
      year: "2021",
      title: "The Opulent Accessories",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/36Web%20Campaign-11.jpg",
      ],
    },
  ],

  "Collection 10": [
    {
      year: "2021",
      title: "The Wedding Edit",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/39Web%20Campaign-08.jpg",
      ],
    },
  ],

  "Collection 9": [
    {
      year: "2021",
      title: "The Vintage Elegance",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/38Web%20Campaign-12.jpg",
      ],
    },
  ],

  "Collection 8": [
    {
      year: "2020",
      title: "Exclusive Bridal Range",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/331.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/332.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/333.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/334.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/335.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/336.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/337.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/338.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/339.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3310.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3311.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3312.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3313.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3314.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3315.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3316.jpg",
      ],
    },
  ],

  "Collection 7": [
    {
      year: "2020",
      title: "Fusion Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-01.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-02.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-03.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-04.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-05.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-06.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-07.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-08.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-09.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/32Trisha%20Shoot-12.jpg",
      ],
    },
  ],

  "Collection 6": [
    {
      year: "2020",
      title: "Temple Jewellery",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/311.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/312.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/313.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/314.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/315.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/316.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/317.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/318.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/319.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3110.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3111.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3112.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3113.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3114.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3115.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3116.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3117.jpg",
      ],
    },
  ],

  collection1: [
    {
      year: "2019-2020",
      title: "Redefining classics",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/301.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/302.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/303.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/304.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/305.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/306.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/307.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/308.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/309.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3010.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3011.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3012.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/3013.jpg",
      ],
    },
  ],

  collection2: [
    {
      year: "2019-2020",
      title: "Eminent Fusion collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-1.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-2.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-3.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-4.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-5.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-6.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-7.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-8.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-9.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/29campaign-2-11.jpg",
      ],
    },
  ],

  collection3: [
    {
      year: "2019-2020",
      title: "Exquisite signature collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-1.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-2.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-3.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-4.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-5.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-6.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-7.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-8.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-9.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/28collection-12.jpg",
      ],
    },
  ],

  collection4: [
    {
      year: "2018-2019",
      title: "Royal Heritage Collection",
      images: [
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-1.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-2.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-3.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-4.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-5.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-6.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-7.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-8.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-9.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-10.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-11.jpg",
        "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/campaign/campaign_collection/27campaign-4-12.jpg",
      ],
    },
  ],

  "Collection 1": [
    {
      year: "2017-2018",
      title: "Royal opulent bridal jewellery collection",
      images: [],
    },
  ],
};
