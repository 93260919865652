import AWS from 'aws-sdk'; // Import entire SDK (optional)
// import AWS from 'aws-sdk/global'; // Import global AWS namespace (recommended)
import S3 from 'aws-sdk/clients/s3'; // Import only the S3 client



async function aws_image_upload(file, bucket_path, credentials) {
  const default_credentials = {
    region: "ap-southeast-1"
  }
  // console.log(file)
  const { accesskeyID, secretAccessKey} = credentials || {}
 
  const S3_BUCKET = bucket_path; // Replace with your bucket name
    const REGION = default_credentials.region; // Replace with your region

    AWS.config.update({
      accessKeyId: accesskeyID,
      secretAccessKey: secretAccessKey,
    });

    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: `web_${file?.name.replace(/\s/g, "-")}`,
      Body: file,
    };

   
    
    try {
        const upload = await s3.putObject(params).promise();
        
        //alert("File uploaded successfully.");
        return upload
  
      } catch (error) {
        console.error(error);
        
        //alert("Error uploading file: " + error.message); // Inform user about the error
      }
        
  
     

}

export default aws_image_upload
