

const style = {
  width: "100%",
};

function Banner({ img, video }) {
  if (video) {
    if (Array.isArray(video)) {
      return (
        <div className="video-banner">
          {window.innerWidth > 576 ? (
            <video
              playsInline
              muted
              className={"video"}
              width={"100%"}
              controls={false}
              autoPlay={true}
              loop
              src={video[0]}
              onLoad={(e) => {
                setTimeout(() => {
                  e.target.play()
                }, 1000)
              }}
              onLoadedData ={(e) => e.target.play()}
              onClick={(e) => e.target.play()}
            />
          ) : (
            <video
              playsInline
              muted
              className={"video"}
              width={"100%"}
              controls={false}
              autoPlay={true}
              loop
              src={video[1]}
              onLoad={(e) => {
                setTimeout(() => {
                  e.target.play()
                }, 1000)
              }}
              onLoadedData ={(e) => e.target.play()}
              onClick={(e) => e.target.play()}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="video-banner">
          <video
            playsInline
            muted
            className={"video"}
            width={"100%"}
            controls={false}
            autoPlay={true}
            loop
            src={video}
            onLoad={(e) => {
              setTimeout(() => {
                e.target.play()
              }, 1000)
            }}
              onLoadedData ={(e) => e.target.play()}
              onClick={(e) => e.target.play()}
          />
        </div>
      );
    }
  } else if (Array.isArray(img)) {
    return (
      <div>
        {
          <picture>
            <source media="(min-width:576px)" srcSet={img[0]} />
            <img src={img[1]} alt="" style={style} />
          </picture>
        }
      </div>
    );
  } else {
    return (
      <div>
        <img src={img} alt="" style={style} />
      </div>
    );
  }
}

export default Banner;
