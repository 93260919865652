import { useEffect } from "react";
import TwoColPage from "../../_CommonPage/TwoColPage";
import "./JewelleryCare.css";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";

function JewelleryCare() {
    const JewelleryCareImg = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/jewellery_care/Jewellery-Care+2.jpg"

    useEffect(() => {
        ScrollToTop()
    }, [])

  return (
    <section className="jewellery-care-page">
            <header>
                <h2>JEWELLERY CARE</h2>
            </header>
        <TwoColPage>
            <div className="image-section">
                <img src={JewelleryCareImg} alt="JewelleryCare" />
            </div>

            <div className="detail-section">
                <ol>
                    <li>Kalasha Fine Jewels are special and completely hand-crafted. Our contemporary designs showcase rich Indian heritage.</li>
                    <li>Your jewellery should be stored in a dry place away from humidity, moisture and extreme temperatures.</li>
                    <li>Rings and bracelets should be worn sparingly to avoid damage through impact.</li>
                    <li>Don´t forget to remove your rings when washing up or showering, as soap particles may become lodged in stone settings and crevices. Soaps can also leave a film over metal and stones, creating a dull appearance.</li>
                    <li>
                    Store your jewellery in its own box or pouch to prevent scratching, chipping and entanglement.
                    </li>
                    <li>Do not use liquid cleaners for carved silver jewellery as the liquid dries into the engraving.</li>
                    <li>Make sure your jewellery is not in regular contact with hard surfaces, which will scratch or wear down the plating or enamelling.</li>
                    <li>Never use detergent, soap, toothpaste etc to clean your gold jewellery.</li>
                    <li>When dressing, jewellery should always be put on last as make-up, cosmetics and perfumes can cause damage to delicate items.</li>
                    <li>All stone jewellery requires extra care and consideration during wearing to protect the setting as well as to safeguard the diamond or gemstone it holds.</li>
                </ol>
            </div>
        </TwoColPage>
    </section>
  )
}

export default JewelleryCare