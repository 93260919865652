import React, { useEffect, useState } from 'react'


import "./BridalAppointment.css"
import EnquiryFormModal from '../../_CommonPage/EnquiryFormModal';
import TwoColPage from '../../_CommonPage/TwoColPage';
import { ScrollToTop } from '../../_CommonPage/ScrollToTop';


function BridalAppointment() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const BridalsImg = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/bridalappointment/Bridal+Appointment+Pic.png"
  
  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className='bridal-appointment-page'>
      {open && <EnquiryFormModal open={open} handleClose={handleClose} related_enquiry="Bridal Appointment">
            
              I authorize Kalasha Jewels and its representatives to Call, SMS,
              Email or WhatsApp me about its products and offers. This consent
              overrides any registration for DND / NDNC.
            
      </EnquiryFormModal>}
      <TwoColPage>
      <div className='image-section'>
            <img src={BridalsImg} alt="pic" />
        </div>

        <div className='detail-section'>
            <header>
                <h2>BRIDAL APPOINTMENT</h2>
            </header>
            <div className="info">
            <p>Make your wedding day one of the most memorable day of life by choosing the best trousseau. Jewellery plays an important role in any bridal look. Don’t ruin it by choosing wrong jewellery.</p>
            <p>Book an appointment and let experts at Kalasha Fine Jewels assist you in choosing the best jewellery for your big day.</p>
            </div>
            <div className="btn" type="reset" onClick={handleOpen}>Book Now</div>
        </div>
        
      </TwoColPage>
    </section>
  )
}

export default BridalAppointment