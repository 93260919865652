import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import "./Pagination.css";
import { createSearchURLParams, get_informationFromSessionID, get_property_settings } from '../Helper';
import React, {  useEffect,  useMemo,   useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { get_is_myc_web, get_myc_is_secured } from '../../redux/Reducer/reducer';


  function PaginationComp({clsName="", size="large", page, totalProductCount: matrix_data_count}) {
    const navigate = useNavigate()
    const location = useLocation()
    const is_secured_myc = useSelector(get_myc_is_secured)
    const is_myc_web = useSelector(get_is_myc_web)
    const userID = get_informationFromSessionID("userID") 
    const [currpage, setCurrPage] = useState(page + 1)
    const searchParams = Object.fromEntries(
      new URLSearchParams(location.search)
      )
    const SHOW_PAGINATION_BUTTONS_COUNT = window.innerWidth > 450 ? 1 : 0 
      const totalProductCount = matrix_data_count || get_informationFromSessionID("totalProductCount")
      const default_matrix_page_load = get_property_settings("default_matrix_page_load")
      
  
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const total_pages = useMemo(() => Math.ceil(Number(totalProductCount) / Number(default_matrix_page_load)), [searchParams?.cat_id, matrix_data_count])
      useEffect(()=>{
        setCurrPage(page + 1)
        
      }, [page, totalProductCount])

     
    
    // const page = Number(searchParams.page) || 0;
   
    
    

    function handlePage(page) {
            if(is_secured_myc && !userID) return navigate("/login")
            setCurrPage(page)
            const url = createSearchURLParams("matrix-page", {...searchParams, page: page - 1})
            navigate(url)
            window.scrollTo(0,0)
                      
    }
    
   const filterSortingHeight = sessionStorage.getItem("filterSortingHeight")

   if(total_pages <= 1 || !totalProductCount) return null

  return (
        <section 
        className={`pagination-container ${clsName}`}
        style={{top: clsName.includes('top-header-mobile-view-pagination') && is_myc_web ? ((filterSortingHeight - 2) || 0)+'px' : clsName.includes("bottom-pagination") ? "initial" : '0px'}}
        
        // style={{top: clsName.includes('top-header-mobile-view-pagination') ? '0px' : '0px'}}
        
        >
      
        <Stack spacing={2}>
          <Pagination count={total_pages} variant="outlined" shape="rounded" size={size}
          page={currpage}
          boundaryCount={SHOW_PAGINATION_BUTTONS_COUNT}
          onChange={(event, page)=>{
            handlePage(page)
            }}
          />
        </Stack>
        
        </section>
      );
}

export default PaginationComp