import "../CustomOrderCard.css";
import { IoImages } from "react-icons/io5";
import {
  get_property_settings,
  get_userData_from_activeUser,
} from "../../../Component/Helper";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { get_custom_order_design_by_image_arr, set_custom_order } from "../../../redux/Reducer/reducer";
import FullScreenImageSlider from "../../../Component/FullScreenImageSlider/FullScreenImageSlider";
import { sentenceCase } from "../../../utils";

function CustomOrderCard({ order, index, isItemList, setFile, set_add_items_by }) {
  const {
    order_no,
    reference_no,
    narration,
    order_date,
    client_due_date,
    place_by_whom,
    distributor_company_name,
    cat_name,
    company_name,
    from_wt,
    to_wt,
    quantity,
    unit_of_measurement,
    image_path,
    images,
    selected_value,
    retailer_company_name,
    bng_purity,
    bng_mm,
    bng_mm_custom_input,
    image_name,
    Size,
    design_no,
    client_due_date_days_left,
    cof_Enamel,
    cof_vibrance,
    cof_HallMark,
    cof_Nakshi,
    cof_Rhodium,
    cof_mmsize,
    cof_mmsize_custom_input,
    cof_Vibrance,
    cof_enamle,
    bgcof_Enamel,
    bgcof_Vibrance,
    
    bng_HallMark,
    bng_Nakshi,
    bng_Rhodium,
    single_wt,
    category_name
  } = order || {};
  
  const cdn = get_property_settings("cdn");
  const user_role_id_for_karigar = get_property_settings(
    "user_role_id_for_karigar"
  );

  let custom_order_design_by_image_arr = useSelector(get_custom_order_design_by_image_arr)
  custom_order_design_by_image_arr = isItemList && design_no ? custom_order_design_by_image_arr.filter(image_fields => {
    return image_fields?.design_no === design_no
  }) : []
  const hasImages = {
    status: (isItemList && custom_order_design_by_image_arr.length > 0) ? custom_order_design_by_image_arr.length > 0 :
      isItemList && image_name?.length > 0 && image_name[index]
        ? image_name[index].length > 0
        : images?.length > 0,
    noOfImg: isItemList && custom_order_design_by_image_arr.length > 0 ? (image_name[index]?.length || 0) + custom_order_design_by_image_arr.length : isItemList ? image_name[index]?.length : images?.length,
  };
  
  
  let image = isItemList && custom_order_design_by_image_arr.length > 0 ? custom_order_design_by_image_arr[0]?.image_path :
    isItemList && image_name.length > 0  && image_name[index]
      ? image_name[index][0]?.src
      : `${images?.length > 0 ? `${cdn}${image_path}${images[0]}` : ""}`;

  const user_data = get_userData_from_activeUser();

  const isKariger =
    user_role_id_for_karigar === user_data?.user_type ? true : false;

  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const update_bottom_value = () => {
    let preValue = "";
    if(category_name){
      
      preValue += `Category: ${category_name} | `;
    }

    if (quantity) {
      preValue += `${sentenceCase(unit_of_measurement)}: ${quantity} ${unit_of_measurement} | `;
    }

    if(single_wt){
      preValue += `Weight: ${single_wt} | `;
    }

    if (from_wt && (from_wt === to_wt)) {
      preValue += `Weight: ${from_wt || ""} | `;
    }else if(from_wt){
      preValue += `Weight: ${from_wt || ""} to ${to_wt || ""} Gms | `;

    }
    
    
    if (cat_name) {
      preValue += `Category: ${cat_name} | `;
    }
    

    if (Array.isArray(selected_value) && selected_value.length > 0) {
      selected_value.forEach((value) => {
        preValue += `${
          value?.field_name && value?.field_value
            ? `${value?.field_name} : ${value?.field_value} | `
            : ""
        }`;
      });
    }
    

    if(Size){
      preValue += `Size: ${Size} | `;
    }
    

    if (bng_purity) {
      preValue += `Purity: ${bng_purity} | `;
    }

    if (bgcof_Enamel || cof_Enamel || cof_enamle) {
      preValue += `Enamel: ${bgcof_Enamel || cof_Enamel || cof_enamle} | `;
    }

    if (bgcof_Vibrance || cof_vibrance || cof_Vibrance) {
      preValue += `Vibrance: ${bgcof_Vibrance || cof_vibrance || cof_Vibrance} | `;
    }

    if (bng_Nakshi || cof_Nakshi) {
      preValue += `Nakshi: ${bng_Nakshi || cof_Nakshi} | `;
    }

    

    if (bng_HallMark || cof_HallMark) {
      preValue += `HallMark: ${bng_HallMark || cof_HallMark} | `;
    }
    
    if (bng_Rhodium || cof_Rhodium) {
      preValue += `Rhodium: ${bng_Rhodium || cof_Rhodium} | `;
    }

    if (bng_mm || bng_mm_custom_input || cof_mmsize || cof_mmsize_custom_input) {
      preValue += `MM Size: ${bng_mm_custom_input || bng_mm || cof_mmsize_custom_input || cof_mmsize} | `;
    }

    

    if(isItemList && client_due_date){
      preValue += `Delivery Due Date: ${dayjs(client_due_date).format("DD-MM-YYYY")} | `
    }

    if (narration) {
      
      preValue += `Narrration: ${narration}`;
    }
    

    return preValue;
  };

  const [bottom_values, set_bottom_values] = useState(update_bottom_value);

  useEffect(() => {
    if (!isItemList) return;
    set_bottom_values(update_bottom_value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemList, order]);
  
  // const showThumbnailPreview = hasImages.status && isItemList ? image_name[index]?.length > 1 : hasImages.status ? images?.length > 1 : true;
  
  const file_image = isItemList && Array.isArray(image_name[index]) && image_name[index].length > 0 ? image_name[index] : []; 
  const design_by_image_arr = isItemList && custom_order_design_by_image_arr.length > 0 ? custom_order_design_by_image_arr.map(image_obj => image_obj?.image_path) : []

  const imagesArr = isItemList ? [...design_by_image_arr, ...file_image] : images

  return (
    <div
      className={`custom-order-card ${isItemList ? "itemList" : ""}`}
     
    >
      {hasImages.status && (
        
        <section className="image-section">
          <FullScreenImageSlider imagesArr={imagesArr} imagePath={isItemList ? null : image_path} showThumbnailPreview={imagesArr.length > 1}>
          {hasImages.noOfImg > 1 && (
            <div className="image-count">
              <IoImages />
              <span>{hasImages.noOfImg}</span>
            </div>
          )}
          <img src={image} alt="product" />
        </FullScreenImageSlider>
        </section>
      )}

      <section className="details-section"
       onClick={() => {
        if (isItemList) {
          if(design_no){
            set_add_items_by("by design no")
          }else{
            set_add_items_by("by image")
          }
          setTimeout(() => {
            const search_params_string = design_no ? `?update_id=${index}&oms_app_add_by_design_no=${design_no}` : `?update_id=${index}`
            navigate(`${location.pathname}${search_params_string}`);
          },100)
        } else {
          navigate(`${location.pathname}/${order_no}${location.search}`);
        }
      }}
      >
        {!isItemList && <section className="top-section">
          <div className="order-detail">
            {order_no && <h2>ORDER NO: {order_no}</h2>}
            {reference_no && <h3>REF. NO: {reference_no}</h3>}
          </div>
          <div className="date-section">
            {order_date && <span>Date: {order_date}</span>}
            
            
              {!isItemList && <span>Delivery: {client_due_date}</span>}
              {!isItemList && <span><em>Due in {client_due_date_days_left} {`${parseInt(client_due_date_days_left) > 1 ? 'days' : 'day'}`}</em></span>}
          </div>

          
        </section>}

        {!isItemList && (isKariger && order?.karigar_status_name) && <p
            className="tag"
            style={{
              color: order?.order_status_badge_fg_color,
              backgroundColor: order?.order_status_badge_bg_color,
            }}
          >
            {order?.karigar_status_name}
          </p>}

          {!isItemList && (!isKariger && order?.order_status_name) && <p
            className="tag"
            style={{
              color: order?.order_status_badge_fg_color,
              backgroundColor: order?.order_status_badge_bg_color,
            }}
          >
            {order?.order_status_name}
          </p>}

        {!isItemList && <div className="middle-section">
          {place_by_whom && <p>Placed By: {place_by_whom}</p>}
          {company_name && <p>Client: {company_name}</p>}
          {distributor_company_name && (
              <p>Distributor: {distributor_company_name}</p>
            )}
            {retailer_company_name && (
              <p>Retailer: {retailer_company_name}</p>
            )}
        </div>}

        <section className="bottom-section">
          {bottom_values?.replace(/(\s\|\s)$/g, "")}
        </section>
        {isItemList && (
            <div
              className="del-icon-wrapper"
              onClick={(e) => {
                e.stopPropagation();
                if (isItemList) {
                  setFile((pre) => pre.filter((_, i) => index !== i));
                }
                dispatch(
                  set_custom_order({
                    type: "delete_item",
                    payload: { index: index },
                  })
                );
              }}
            >
              <MdDelete className="del-icon" />
            </div>
          )}
      </section>
    </div>
  );
}

export default CustomOrderCard;
