import { useFormContext, Controller } from 'react-hook-form';
import Select from "react-select";
import { get_property_settings } from '../../Helper';

function Dropdown({fields, options, isMutiSelect=false}) {
    const {field_name, short_code, required} = fields || {};
    const primary_BG_color = get_property_settings("btn_primary_color");
    const { formState: {errors}, control} = useFormContext()
  return (
    <div className='input-group dropdown'>
        <label htmlFor={short_code}>
          <span>{field_name}</span>
          {required === "1" && <span className="required">*</span>}
        </label>
        <Controller
        control={control}
        name={short_code}
        rules={{
          required:{
            value: required === "1",
            message: `${field_name} is required`
          }
        }}
        render={({ field }) => (
          <Select
            className="react-select-container r-container"
            classNamePrefix="react-select r-select"
            id={short_code}
            options={options}
            isMulti={isMutiSelect}
            {...field}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25:
                  (primary_BG_color &&
                    primary_BG_color
                      .replace("rgb", "rgba")
                      .replace(")", ",0.2)")) ||
                  "inherit",
                primary: primary_BG_color,
              },
            })}

          />
        )}
        />
        {errors[short_code] && <p className="error-text">{errors[short_code]?.message}</p>}
    </div>
  )
}

export default Dropdown