import { MenuItem } from "@mui/material";
import React from "react";
import { IoIosListBox } from "react-icons/io";
import { FaHeart } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { get_property_settings } from "../../../../../../Helper";

function Shortlist({ handleClose }) {
  const navigate = useNavigate();
  const shortlist_label = get_property_settings("shortlist_label");
  return (
    <MenuItem
      className="menu-list"
      onClick={() => {
        navigate("/named-wishlist");
        handleClose();
      }}
    >
      {shortlist_label === "Favourites" ? (
        <FaHeart className="icon" />
      ) : (
        <IoIosListBox className="icon" />
      )}

      <span>{shortlist_label}</span>
    </MenuItem>
  );
}

export default Shortlist;
