import React from "react";
import { get_bulk_settings} from "../../../../../Component/Helper";
import { useSelector } from "react-redux";
import { get_selected_currency } from "../../../../../redux/Reducer/reducer";
import { formatPrice } from "../../../../../Component/HomeComponents/HomeHeader/components/UserProfileDropDownMenu/Components/ChangeCurrencyModal/change_currency_helper";

function MaterialDetails({data}) {

  const {fsv_material_details_title, labour_amount_column_show_rupee_symbol} = get_bulk_settings(["fsv_material_details_title","labour_amount_column_show_rupee_symbol"]) || {};
  const selected_currency = useSelector(get_selected_currency)
  return (
    <div className="details-block material-details">
      {data && data?.total?.length > 0 && (
        <>
          <div className="heading">
            <h5 className="ec-single-title">
              {fsv_material_details_title}
            </h5>
          </div>
          <table className="w-100">
            <thead>
              <tr className="row product-other-details-bottom-group">
                {data &&
                  data.material_position.length > 0 &&
                  data.material_position.map((title, i) => {
                    
                    return (
                      data.total_material_visible_setting[title.short_code] ===
                        "1" ? (
                        <td
                          key={title.short_code}
                          className={`col label product-details-label ${
                            i === 0 ? "text-left" : "product-details-label"
                          }  ${
                            i === data.material_position.length - 1
                              ? "text-right"
                              : ""
                          }`}
                        >
                          {title.custom_label.toUpperCase()} 
                        </td>
                      ): title.short_code === "material" ? (
                        <td
                          key={title.short_code}
                          className={`col label product-details-label ${
                            i === 0 ? "text-left" : "product-details-label"
                          }  ${
                            i === data.material_position.length - 1
                              ? "text-right"
                              : ""
                          }`}
                        >
                          TYPE
                        </td>
                      ) : null
                    ) 
                  })}
              </tr>
            </thead>
            <tbody>
              {data.total.map((total_item, i) => {
                return (
                  <tr
                    className="row product-details-value product-other-details-bottom-group"
                    key={i}
                  >
                    {data.material_position.map((item, ci) => {
                      return (
                        <React.Fragment key={ci}>
                          {item.short_code === "material" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" ? (
                              <td
                                className={`col product-details-value ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {total_item?.name ? total_item?.name : "-"}{" "}
                                {total_item?.name === "Diamond" ? <>
                                {
                                  
                                  data?.material[0]?.Diamond[0]?.shape_name ? <p className="diamond-details">Shape: {data?.material[0]?.Diamond[0]?.shape_name}</p> : null
                                  
                                }
                                {
                                  data?.material[0]?.Diamond[0]?.color_name ? <p className="diamond-details">Color: {data?.material[0]?.Diamond[0]?.color_name}</p> : null
                                }
                                {
                                  data?.material[0]?.Diamond[0]?.clarity_name ? <p className="diamond-details">Clarity: {data?.material[0]?.Diamond[0]?.clarity_name}</p> : null
                                }
                                {
                                  data?.material[0]?.Diamond[0]?.cut_name ? <p className="diamond-details">Clarity: {data?.material[0]?.Diamond[0]?.cut_name}</p> : null
                                }
                                
                                </> : null
                                }
                              </td>
                            ) : item.short_code === "material" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "0" ? (
                              <td
                              className={`col product-details-value ${
                                ci === 0 ? "text-left" : ""
                              }  ${
                                ci === data.material_position.length - 1
                                  ? "text-right"
                                  : ""
                              }`}
                            >
                              {total_item?.name ? total_item?.name : "-"}
                              </td>
                            ) : null
                            
                            
                            
                            
                            
                            
                            }
                          {item.short_code === "cts" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <td
                                className={`col ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {total_item?.cts ? total_item?.cts : "-"}{" "}
                              </td>
                            )}
                          {item.short_code === "weight" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <td
                                className={`col ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {total_item?.weight ? parseFloat(total_item?.weight) === 0 ? "" : total_item?.weight : "-"}{" "}
                              </td>
                            )}
                          {item.short_code === "pieces" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <td
                                className={`col ${
                                  ci === 0 ? "text-left" : ""
                                }  ${
                                  ci === data.material_position.length - 1
                                    ? "text-right"
                                    : ""
                                }`}
                              >
                                {total_item?.pieces ? total_item?.pieces : "-"}{" "}
                              </td>
                            )}
                          {item.short_code === "other_charges" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <>
                                {" "}
                                {labour_amount_column_show_rupee_symbol ===
                                "Yes" ? (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {formatPrice(total_item?.other_charges, true, selected_currency)}
                                  </td>
                                ) : (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {total_item?.other_charges}
                                  </td>
                                )}
                              </>
                            )}
                          {item.short_code === "total" &&
                            data.total_material_visible_setting[
                              item.short_code
                            ] === "1" && (
                              <>
                                {" "}
                                {labour_amount_column_show_rupee_symbol ===
                                "Yes" ? (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {formatPrice(total_item?.total, true, selected_currency)}
                                  </td>
                                ) : (
                                  <td
                                    className={`col ${
                                      ci === 0 ? "text-left" : ""
                                    }  ${
                                      ci === data.material_position.length - 1
                                        ? "text-right"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    {total_item?.total}
                                  </td>
                                )}
                              </>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </tr>
                );
              })}

              {data &&
                data.material_position.length > 0 &&
                data.material_position.map((title, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {/* <ul>
                          {data[title.short_code] &&
                            data[title.short_code].length > 0 &&
                            data[title.short_code].map((item) => {
                              return (
                                <li key={item.id}>
                                  {item.mat_name} - Cts: {item.cts}, Weight:{" "}
                                  {item.weight}
                                </li>
                              );
                            })}
                        </ul> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default React.memo(MaterialDetails);
