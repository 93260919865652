import dayjs from 'dayjs'



function LastUpdate({time_stamp}) {
    
  const date_time = dayjs(time_stamp).format('DD/MM/YYYY hh:mm:ss a')
  return (
    <p className="last-update-bar">
            <span>Last Updated: {date_time}</span>
            
          </p>
  )
}

export default LastUpdate