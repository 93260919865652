import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import MUIModal from "../MUIModal/MUIModal";
import OTPInput from "react-otp-input";
import "./OTPModalComp.css";
import { ToastError } from "../Helper";

function OTPModalComp(
  { title = "Verify Mobile Number",
    children = null, 
    mobile_no,
    otpRes, 
    callbackFn,
    resendOTPFn
  },
  ref
) {
  const [open_MUI_modal, set_open_MUI_modal] = useState(false);
  const [inputOtp, setInputOtp] = useState("");
  const [counter, setCounter] = useState(60);
  const [startCounter, setStartCounter] = useState(false);
  const handle_close_MUI_modal = useCallback(
    () => set_open_MUI_modal(false),
    []
  );

  const handle_open_MUI_modal = () => set_open_MUI_modal(true);

  useImperativeHandle(ref, () => ({
    open_modal: () => handle_open_MUI_modal(),
    close_modal: () => handle_close_MUI_modal(),
  }));

  const handleResedOTP = () => {
    setStartCounter(true);
    resendOTPFn()
  };

  const handleVerifyCode = () => {
    if(inputOtp < 6) return 
    if(inputOtp !== otpRes) return ToastError("Invalid OTP")
      callbackFn()
  }

  function formatTime(counter) {
    if (counter < 10) return `00:0${counter}`;
    return `00:${counter}`;
  }

  useEffect(() => {
    if (!startCounter) return;
    const interval = setInterval(() => {
      if (counter <= 0) {
        setStartCounter(false);
        setCounter(60);
        clearInterval(interval);
      } else {
        setCounter((preCount) => (preCount -= 1));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startCounter, counter]);

  return (
    <MUIModal
      className="opt-input-modal"
      open_MUI_modal={open_MUI_modal}
      handle_close_MUI_modal={handle_close_MUI_modal}
      style={{ p: 0, width: "min(400px, 90%)" }}
    >
      <section className="otp-modal-wrapper">
        <header>
          <h2>{title}</h2>
        </header>
        <div className="details">
          {!!children ? (
            children
          ) : (
            <>
              <p>Please Enter 6 digit code sent on</p>
              <p>{mobile_no}</p>
            </>
          )}
        </div>
        <OTPInput
          id="input"
          inputType="number"
          value={inputOtp}
          onChange={(e) => {
            setInputOtp(e);
          }}
          isInputNum={true}
          numInputs={6}
          shouldAutoFocus // Optional: Automatically focus the first input box on mount
          renderInput={(props) => (
            <input type="number" {...props} className="" />
          )}
        />
        <button type="button" className="verify-code-btn" onClick={handleVerifyCode}>
          Verify Code
        </button>
        <button
          className="resend-otp-btn"
          type="button"
          onClick={handleResedOTP}
          disabled={startCounter}
        >
          {startCounter ? (
            <span>Resend OTP in {formatTime(counter)}</span>
          ) : (
            <span>Resend OTP</span>
          )}
        </button>
      </section>
    </MUIModal>
  );
}

export default forwardRef(OTPModalComp);
