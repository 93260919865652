import React from 'react'

function DownloadBrochure() {
  return (
    <a href="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/pdf/brochure.pdf" download
    className='download-brochure-btn'
    target='_blank' rel="noreferrer"
    >Download Brochure</a>
  )
}

export default DownloadBrochure   