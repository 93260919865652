export const dropdown_control_types = ["Country", "State", "City", "Currency" ];
export const get_default_county_state_city_value = (data_array, default_id) => {
    if(!default_id || !data_array) return null
    const obj = data_array.find(data => data.value === default_id)
    if(obj){
        return obj
    }else{
        return null
    }
};

export const get_params_values = (data) => {
    const obj = {};
    for(const key in data){
        if(typeof data[key] === "string"){
            obj[key] = data[key]
        }else if(!Array.isArray(data[key]) && typeof data[key] === "object" && "label" in data[key]){
            obj[key] = data[key]?.value
        }else{
            obj[key] = data[key]
        }
    }

    return obj
}