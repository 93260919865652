import React from 'react'

function Imagefield({ field, register, errors }) {
    const { field_name, required, short_code } = field;

    return (
      <div>
        <label htmlFor="name">
          {field_name}
          {required === "1" && <span className="required-field">*</span>}
        </label>
        <div className="imagefile-container">
          <input type="file" {...register(short_code, {
              required: {
                  value: required === "1",
                  message: `${field_name} is required`
              }
          
          })}
          className='image-file'
          accept='.pdf, .png, .jpg, .jpeg'
          />
        </div>
  
        {errors[short_code]?.message && (
          <p className="error-msg">{errors[short_code]?.message}</p>
        )}
      </div>
    );
}

export default Imagefield
