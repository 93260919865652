import { useFormContext } from "react-hook-form";
import "../InputField.css";
import { EMAIL_REGEX } from "../../../utils";

function TextField({fields}) {
    const {register, formState: {errors}} = useFormContext()
    const {field_name, short_code, required, value, validation_options} = fields || {};
  return (
    <div className='input-group textfield'>
        <label>{field_name}{required === "1" || fields?.required_field === "1" ? <span className="required">*</span> : null}</label>
        <input type={validation_options === "number" ? "number" : "text"} {...register(short_code, {
          required: {
            value: required === "1" || fields?.required_field === "1",
            message: `${field_name} is required`
          },
          pattern: {
            value: short_code === "email_id" && EMAIL_REGEX,
            message: "Invaild email"
          },
          value: value || "",
          
        })}/>
        {errors[short_code] ? <p className="error-text">{errors[short_code]?.message}</p> : null}
    </div>
  )
}

export default TextField