import { useQuery, useQueryClient } from "@tanstack/react-query";
import "./CustomOrderFilterForm.css";
import { fetch_data_from_api_from_query } from "../../../../../../Api/APICalls";
import { ApiList } from "../../../../../../Api/ApiList";
import Loader from "../../../../../../Component/Loader";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "../../../../../../Component/InputFields/TextField/TextField";
import Dropdown from "../../../../../../Component/InputFields/Dropdown/Dropdown";
import {
  convertOBJ_to_html_react_options,
  createSearchURLParams,
  createSelectArrayForReactSelect,
 
  get_userData_from_activeUser,
} from "../../../../../../Component/Helper";
import RetailerHTMLDropdown from "../../../../../../Component/InputFields/Dropdown/RetailerHTMLDropdown";
import {
  get_plain_url_search_params,
  get_search_params,
} from "../../../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";


function CustomOrderFilterForm({ close_filter_modal }) {
  const userData = get_userData_from_activeUser() || {};
  const client_type = userData?.client_type;
  const methods = useForm();
  const watch_distributor = methods.watch("filter_distributor_id");
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const searchParams = get_search_params();

  const order_filter_param_query = useQuery({
    queryKey: ["fetch_order_filter_param"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.fetch_order_filter_param,
        "fetch_order_filter_param"
      ).then( order_filter_param_res => {
        const isSearchParams = Object.values(searchParams)
        if(isSearchParams.length === 0) return  order_filter_param_res;
        if(Array.isArray(order_filter_param_res)){
            order_filter_param_res.forEach(params => {
                const {short_code, control_type} = params || {}
                if(control_type.toLowerCase() === "textfield" && searchParams[short_code]){
                    methods.setValue(short_code, searchParams[short_code])
                }
            })
        }
        return order_filter_param_res
      }),
  });

  

  const distributor_query = useQuery({
    queryKey: ["get_distributor"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${ApiList.Finalize}/${ApiList.get_distributor}`,
        "get_distributor",
        {
          client_type,
        }
      ).then((res) => {
        if (Array.isArray(res?.final_result) && res?.final_result?.length) {
            const options = createSelectArrayForReactSelect(
                res?.final_result,
                "name",
                "id"
              );
        const isSearchParams = Object.values(searchParams)
        if(isSearchParams.length === 0) return  options;
        if(searchParams[`filter_distributor_id`]){

            const selectedOption = options.find(option => option.value === searchParams[`filter_distributor_id`])
            methods.setValue("filter_distributor_id", selectedOption || null)
        }

        return options
           
        } else {
          return [];
        }
      }),
    initialData: [],
  });

  const retailer_query = useQuery({
    queryKey: ["get_retailer", watch_distributor?.value],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${ApiList.Finalize}/${ApiList.get_retailer}`,
        "get_retailer",
        {
          client_type,
          distributor_client_master_id: watch_distributor?.value,
        }
      ).then((res) => {
        if (Array.isArray(res?.final_result) && res?.final_result?.length) {
          const htmlOption = convertOBJ_to_html_react_options(
            res?.final_result || []
          );

          const isSearchParams = Object.values(searchParams)
        if(isSearchParams.length === 0) return  htmlOption;
        if(searchParams[`filter_retailer_id`]){

            const selectedOption = htmlOption.find(option => option.value === searchParams[`filter_retailer_id`])
            methods.setValue("filter_retailer_id", selectedOption || null)
        }

        
          return htmlOption;
        } else {
          return [];
        }
      }),
    initialData: [],
    enabled: !!watch_distributor,
  });

  const karigar_query = useQuery({
    queryKey: ["get_karigar_details"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${ApiList.get_karigar_details}`,
        "get_karigar_details"
      ).then((res) => {
        if (
          Array.isArray(res?.karigar_details) &&
          res?.karigar_details?.length
        ) {
          const options = createSelectArrayForReactSelect(
            res?.karigar_details,
            "vendor_company_name",
            "id"
          );

          const isSearchParams = Object.values(searchParams)
        if(isSearchParams.length === 0) return  options;
        if(searchParams[`vendor_id`]){
            const selectedValues = searchParams[`vendor_id`].split(",")
            const selectedOption = options.filter(option => selectedValues.includes(option?.value))
            methods.setValue("vendor_id", selectedOption || null)
        }

        return options

          
        } else {
          return [];
        }
      }),
    initialData: [],
  });

  function resetAll() {
    order_filter_param_query.data?.forEach((field) => {
      methods.setValue(field?.short_code, null);
    });
  }

  const onSubmit = async (data) => {
    const params = get_plain_url_search_params(data);
    const isEmptyValue = Object.values(params).every((value) => !value);
    const isSearchPramasValueEmpty = Object.values(searchParams).every((value) => !value);
    if (isEmptyValue && !isSearchPramasValueEmpty) {
      const path = location.pathname;
      navigate(path, { state: { filter_applied: true } });
      queryClient.invalidateQueries(["kam_order_list"]);
      
      close_filter_modal();
    } else {
      const path = location.pathname.replace("/", "");
      navigate(createSearchURLParams(path, params), {
        state: { filter_applied: true },
      });
      queryClient.invalidateQueries(["kam_order_list"]);
      close_filter_modal();
    }
  };

  

  return order_filter_param_query.isLoading ? (
    <Loader isLoading={true} />
  ) : (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="custom-order-filter-form ui form-ui"
      >
        {order_filter_param_query?.data?.map((field) => {
          if (field?.control_type.toLowerCase() === "textfield") {
            return <TextField fields={field} key={field?.short_code} />;
          } else if (field?.control_type.toLowerCase() === "dropdown") {
            if (field?.field_name?.toLowerCase() === "distributor") {
              return (
                <Dropdown
                  fields={field}
                  options={distributor_query.data}
                  key={field?.short_code}
                />
              );
            } else if (field?.field_name?.toLowerCase() === "retailer") {
              return (
                <RetailerHTMLDropdown
                  fields={field}
                  options={retailer_query.data}
                  key={field?.short_code}
                />
              );
            } else if (field?.field_name?.toLowerCase() === "karigar") {
              return (
                <Dropdown
                  fields={field}
                  options={karigar_query.data}
                  isMutiSelect
                  key={field?.short_code}
                />
              );
            } else {
              return <Dropdown fields={field} options={[]} />;
            }
          } else {
            return null;
          }
        })}

        <footer>
          <button
            type="reset"
            className="ui button-ui clear-btn"
            onClick={resetAll}
          >
            Clear All
          </button>
          <button type="submit" className="ui button-ui apply-btn">
            Apply
          </button>
        </footer>
      </form>
    </FormProvider>
  );
}

export default CustomOrderFilterForm;
