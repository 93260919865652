import React from "react"
import { getCatMaster, get_is_myc_web } from "../../../redux/Reducer/reducer"
import SingleCategoryCard from "./SingleCategoryCard"
import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query";
import { ApiList } from "../../../Api/ApiList";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { get_informationFromSessionID } from "../../Helper";


function FooterCategories() {
    const master = useSelector(getCatMaster);
    const userID = get_informationFromSessionID("userID")
    const is_myc_web = useSelector(get_is_myc_web);

    const category_query = useQuery({
        queryKey: ["category", master, userID],
        queryFn: () => fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
          which_master: master,
        }),
        initialData: [],
        enabled: !is_myc_web
    });

if(category_query?.isLoading || category_query?.data?.length === 0){
    return null
}

   
return (
    <div className="footer-cat">
                <div className="container-fluid">
                <div className="footer-cat-block footer-catalogue-section">
                            <div className="footer-cat-title">Products Directory</div>
                            {Array.isArray(category_query?.data) && <section>
                                {
                                 category_query?.data?.map(category => <SingleCategoryCard cat_name={category.cat_name} subcategory={category.subcategory} key={category.id} cat_id={category.id}/>)   
                                }
                            </section>}
                        </div>
                </div>
            </div>
  )
}

export default FooterCategories
