import React, { useId } from "react";
import { useSelector } from "react-redux";

import { get_bulk_settings } from "../../../Component/Helper";
import { getCatMaster } from "../../../redux/Reducer/reducer";

function ButtonsSection({
  data,
  dataField,
  register,
  is_multiselect,
  setValue,
  getValues,
  lengthOfField
}) {
  const { onChange } = register(dataField);
  const filter_size = get_bulk_settings([
    "filter_size_multiselect",
    "filter_size_multiselect_im",
  ]);
  const master = useSelector(getCatMaster);

  // eslint-disable-next-line no-unused-vars
  const filter_size_multiselect =
    master === "inventory_master"
      ? filter_size?.filter_size_multiselect_im === "Yes"
      : filter_size?.filter_size_multiselect === "Yes";

  // ? filter_size?.filter_size_multiselect_im === "Yes" && dataField === "size"
  // : filter_size?.filter_size_multiselect === "Yes" && dataField === "size";

  const id = useId();

  function handleNonMultiClick(e) {
    if (is_multiselect) return;
    const { value } = e.target;
    const selectedValue = getValues();
    const prevSelectedValue = selectedValue[dataField];

    if (
      prevSelectedValue === value ||
      (Array.isArray(prevSelectedValue) && prevSelectedValue.includes(value))
    ) {
      setValue(dataField, null);
    } else {
      setValue(dataField, value);
    }
  }



  return (
    <div className="btn-container">
      <input
        type={"checkbox"}
        id={data[`${dataField}_value`] + id}
        defaultValue={data?.id}
        value={data?.id}
        onClick={handleNonMultiClick}
        defaultChecked={data?.selected === "1" || (dataField === "design_status" && lengthOfField === 1) }
        {...register(dataField)}
        onChange={(e) => {
          const { design_status } = getValues() || {};

          if (dataField === "design_status" && design_status && design_status.length === 1) {
            e.target.checked = true;
          } else {
            onChange(e);
          }
        }}
        className="filter-btn-input"
        style={{ display: "block" }}
      />
      <label
        htmlFor={data[`${dataField}_value`] + id}
        className="filter-btn-label"
      >
        {data[`${dataField}_value`]}
      </label>
    </div>
  );
}

export default React.memo(ButtonsSection);
