const Award1 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/01-Store-Of-The-Year-2017.png"
const Award2 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/02-The-Best-Heritage-Bridal-Jewellery-2018.png"
const Award3 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/03-Temple-Jewellery-Of-The-Year-2018.png"
const Award4 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/04-Best-Ring-Design-2020.png"
const Award5 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/05-Best-Sterling-Silver-Artefact-Award-2021.jpg"
const Award6 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/06-Best-Statement-Jewellery-of-the-Year-2021.jpg"
const Award7 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/07-Leading-Legacy-Jewellery-Store-2023.png"
const Award8 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/08-Bridal-Polki-Jewellery-2023.png"

const InnerImg1 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/01-AwardCreatives1.jpg"
const InnerImg2 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/02-AwardCreatives2.jpg"
const InnerImg3 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/03-AwardCreatives3.jpg"
const InnerImg4 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/04-41620399177.jpg"
const InnerImg5 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/05-51639572441.jpg"
const InnerImg6 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/06-61642063673.jpg"
const InnerImg7 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/07-71704528462.jpg"
const InnerImg8 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/awards/inner-images/08-81704528584.jpg"

export const AwardsData = [
    {
        id: 1,
        title: "Store Of The Year 2017 By UBM",
        image: Award1,
        innerImage: InnerImg1
    },
    {
        id: 2,
        title: "The Best Heritage Bridal Jewellery 2018 By Times Retail Icons",
        image: Award2,
        innerImage: InnerImg2
    },
    {
        id: 3,
        title: "Temple Jewellery Of The Year 2018 By IJ Jewellers Choice Design Awards",
        image: Award3,
        innerImage: InnerImg3
    },
    {
        id: 4,
        title: "Best Ring Design 2020 By IJ Jewellers Choice Design Awards",
        image: Award4,
        innerImage: InnerImg4
    },
    {
        id: 5,
        title: "Best Sterling Silver Artefact Award 2021 By NJA",
        image: Award5,
        innerImage: InnerImg5
    },
    {
        id: 6,
        title: '"Best Statement Jewellery of the Year 2021", by IJ Jewellers Choice Design Awards',
        image: Award6,
        innerImage: InnerImg6
    },
    {
        id: 7,
        title: "Leading Legacy Jewellery Store 2023 By Kohinoor",
        image: Award7,
        innerImage: InnerImg7
    },
    {
        id: 8,
        title: "Bridal Polki Jewellery 2023 BY RJA",
        image: Award8,
        innerImage: InnerImg8
    },
]