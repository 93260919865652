import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

function Passwordfield({formParams, clsName="", errors, onChange, value}) {
    
   const [showPassword, setShowPassword] = useState(false)
  return (
    <span className={clsName}>
      <label className="label">
        {formParams.field_name}
        {formParams?.required === "1" && (
          <span className="required-field">*</span>
        )}
      </label>
      <div className="password-input-wrapper">
        <input
          placeholder={`Enter ${formParams.field_name}`}
          
          className="RegInp fieldValue"
          type={showPassword ? "text" : "password"}
          onChange={(e) => onChange(e)}
        value={value}
        name={formParams.short_code}
         
        />
        {/* Add the eye icon and toggle the visibility onClick */}
        <span
          className="password-toggle-icon"
          onClick={() => {
            setShowPassword((prevState) => !prevState);
          }}
        >
          {showPassword ? (
            <>
              <FontAwesomeIcon icon={faEyeSlash} />
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faEye} />
            </>
          )}
        </span>
      </div>
      <div className="required-field">
      {errors[formParams?.short_code]?.message || ""}
      </div>
    </span>
  );
}

export default Passwordfield;
