
import { ClockLoader } from "react-spinners"
import { get_property_settings } from "../Helper"
import "./WaitSearch.css"

function WaitSearch({keyWord, totalCount}) {

  const primaryColor = get_property_settings('btn_primary_color')

  return (
    <div className="wait-search-container">
        <ClockLoader color={primaryColor || "rgb(238, 85, 39)"} />
        <p>Searching for <b>"{keyWord}"</b>. Please wait...</p>
        {/* <p>Searching for <b>"{keyWord}"</b> within <b>{totalCount}</b> keywords.<br/> This may take some time.</p> */}
    </div>
  )
}

export default WaitSearch
