import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AiFillHome } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { get_informationFromSessionID, get_property_settings, get_settings, logOutUser } from "./Helper";
import ModalDialog from "./ModalDialog";
import WhatsappIconNew from "./WhatsappIcon/WhatsappIconNew";
import SuccessCheckAnim from "./SuccessCheckAnim/SuccessCheckAnim";
import { get_trigger_cart_btn, get_trigger_shortlist_btn } from "../redux/Reducer/reducer";
import UserProfileDropDownMenu from "./HomeComponents/HomeHeader/components/UserProfileDropDownMenu/UserProfileDropDownMenu";
import { getCustomValue } from "../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../Custom/CompanyCustomization";
//import checkedAnim from "../assets/images/checked-anim.gif"

const FooterNavPanel = ({ isLoggedIn }) => {
  // const cartItems = useSelector((state) => state.reducer.basket);
  const [show, setShow] = useState(false);
  // const [quantity, setQuantity] = useState(0);
  const location = useLocation();
  const cartcounts = useSelector((state) => state?.reducer?.constants?.count);
  const navigate = useNavigate();

  const urlpath = location.pathname;
  const company_code = get_informationFromSessionID("companyCode")
  const homeIconHeightRef = useRef(null)
  const MobileNavBarRef = useRef(null)
  const [iconHeight, setIconHeight] = useState('38.32px')
  const shortlist_label = get_property_settings("shortlist_label")
  const show_shortlist_icon_as_named_shortlist = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_shortlist_icon_as_named_shortlist)

  useEffect(() => {
    if(!homeIconHeightRef?.current) return 
    document.documentElement.style.setProperty("--mobile-nav-bar-height", MobileNavBarRef.current.offsetHeight + "px")
    sessionStorage.setItem("MobileNavBarHeight", MobileNavBarRef.current.offsetHeight)
    setIconHeight(homeIconHeightRef?.current?.offsetHeight)
  }, [])

  
  const is_cart_btn_trigger = useSelector(get_trigger_cart_btn)
  const is_shortlist_btn_trigger = useSelector(get_trigger_shortlist_btn)

  if(!isLoggedIn) return null;



   
        
  const settings = get_settings();

  const show_named_wishlist = settings?.enable_shortlist === "Yes" && settings?.show_named_wishlist_app === "Yes";

  return (
    isLoggedIn === true && (
      <div>
        <div className="ec-nav-toolbar mobile-footer-navigation-bar" ref={MobileNavBarRef}>
          <div className="container">
            <div className="ec-nav-panel">
              {/* HOME ICON */}
              <div className={`ec-nav-panel-icons ${urlpath.includes('home') || urlpath === '/' ? 'active' : ''}`}
              onClick={() => {
                navigate(`/home?company_code=${company_code}`);
              }}
              ref={homeIconHeightRef}
              >
               <AiFillHome className="footer-icons"/>
                <span className="footer-icons-text">Home</span>
                
              </div>
              {/* CART ICON */}
              <div className={`ec-nav-panel-icons ${urlpath.includes('cart') ? 'active' : ''}`} style={{minHeight: iconHeight+'px'}}>
                
                <Link
                  className="toggle-cart ec-header-btn ec-side-toggle header-buttons"
                  to={`/cart?company_code=${company_code}`}
                  state={location}
                >
                  {/* <SuccessCheckAnim type="cart" clsName='cart-anim'/>  */}
                 {/* {is_cart_btn_trigger ? <img src={checkedAnim} alt=""/> : */}
                 {is_cart_btn_trigger ? <SuccessCheckAnim type="cart" clsName='cart-anim'/> :
                  <>
                  <FaShoppingCart className="footer-icons"/>
                  
                  <span className="ec-cart-noti ec-header-count cart-count-lable ">
                    {cartcounts && (cartcounts?.count || 0)}
                  </span>
                  </>}
                </Link>
                <span className="footer-icons-text">Cart</span>
              </div>

              {/* SHORTLIST ICON */}
              {(!show_named_wishlist || (show_named_wishlist && show_shortlist_icon_as_named_shortlist)) && settings?.enable_shortlist === "Yes" && <div className={`ec-nav-panel-icons ${(urlpath.includes('short-list') || urlpath.includes('named-wishlist')) ? 'active' : ''}`} style={{minHeight: iconHeight+'px'}}>

                {is_shortlist_btn_trigger ? <SuccessCheckAnim type="shortlist" clsName='shortlist-anim'/> : (settings && settings?.enable_shortlist === "Yes" && (
                  <Link
                    className="toggle-cart ec-header-btn ec-side-toggle header-buttons"
                    to={show_named_wishlist && show_shortlist_icon_as_named_shortlist ? '/named-wishlist' : `/short-list?company_code=${company_code}`}
                    state={location}
                  >
                    
                    <FaHeart className="footer-icons"/>
                    <span className="ec-cart-noti ec-header-count cart-count-lable ">
                      {cartcounts && (cartcounts?.shortlist_count || 0)}
                    </span>
                    
                  </Link>
                  
                ))}
                <span className="footer-icons-text">{show_named_wishlist && show_shortlist_icon_as_named_shortlist ? shortlist_label : "Shortlist"}</span>
              </div>}

              {/* WHATSAPP ICON */}
              <WhatsappIconNew showInMobile={true}/>
              {/* LOGOUT ICON */}
              {<div className={"ec-header-user dropdown"}>
                <button
                  className="dropdown-toggle ec-nav-panel-icons"
                  data-bs-toggle="dropdown"
                  // onClick={() => setShow(!show)}
                  title="logout"
                >
                  <UserProfileDropDownMenu clsName="mobile-nav-menu"/>
                  {/* <IoLogOutOutline className="footer-icons fw-bold"/> */}
                  <span className="footer-icons-text">User</span>
                </button>
                
              </div>}
            </div>
          </div>
        </div>
       {show && <ModalDialog show={show} setShow={setShow} action={logOutUser} toastMsg={"Your Successfully logout"} />   }   
      </div>
    )
  );
};

export default React.memo(FooterNavPanel);
