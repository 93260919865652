
import { useEffect } from "react";
import "./COPL_About_Us.css";

function COPL_About_Us() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div className="aboutUs">
      <header>
        <h2>About Us</h2>
        
      </header>

      <section>
        <div className="image-section">
            <img src="https://cdn.jfone.jewelflow.pro/copl/uploads/branding_image/web/4003-web-banner1.png" alt="" />
        </div>
        <div className="details-section">
            {/* <h3>Lorem ipsum dolor sit ?</h3> */}
            <p>CLASSIC ORNAMENTS PRIVATE LIMITED… formerly known as CLASSIC JEWELLERS were Est. 1996 who have carved a Niche’ for themselves in the exports business and are fore mostly known for manufacturing of casting and handmade Jewellery.</p>

           <p>
           CLASSIC ORNAMENTS PRIVATE LIMITED has been able to fulfill the everlasting demand of the Indian market covering major retail outlets and showrooms across India. It is no wonder that our integrity and reliability has become a name synonymous with classic ornaments among the inhabitants.
           </p>

           <p>
           With over a decade of experience, CLASSIC ORNAMENTS PRIVATE LIMITED have expanded tremendously in a short span of time and dream to be the most hunted organization when it comes to commemorating special occasions.
           </p>
        </div>
      </section>
    </div>
  )
}

export default COPL_About_Us
