import { useSelector } from "react-redux"
import { get_is_myc_web, get_myc_is_secured } from "./redux/Reducer/reducer"
import { Navigate } from "react-router-dom"
import { delete_informationFromSessionID, get_informationFromSessionID, get_property_settings } from "./Component/Helper"
import Loader from "./Component/Loader"





function Redirection() {
    const is_myc_web = useSelector(get_is_myc_web)
    const company_code = get_informationFromSessionID("companyCode")
    const is_myc_secure = useSelector(get_myc_is_secured);
    let login_on_startup_online = get_property_settings("login_on_startup_online");
    const userID = get_informationFromSessionID("userID")
    login_on_startup_online = !userID && login_on_startup_online === "Yes"
    const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development";
    //Remove filter params when user click on '\' root url
    delete_informationFromSessionID("filterParams")
    delete_informationFromSessionID("filter")
    
    if(is_myc_web && is_myc_secure === undefined ){
      return <Loader isLoading={true}/>
    }
    
    const Home_URL = IS_DEVELOPMENT_MODE ? `/home?company_code=${company_code}` : '/home'

  return (
    <>
      {
        is_myc_web ? <Navigate to="/matrix-page"/> : login_on_startup_online ? <Navigate to="/login"/> : <Navigate to ={Home_URL}/>
      }
    </>
  )
}

export default Redirection