import { useEffect } from "react";
import JewelsmithAboutUsCard from "./Components/Jewelsmith_About_Us_Card";
import "./JEWELSMITH_About_Us.css";
import { ScrollToTopCustom } from "../../../utils";

function JEWELSMITHAboutUs() {
  
    useEffect(() => {
        ScrollToTopCustom(null, 100)
    }, [])

  return (
    <section className="jewelsmith-about-us-page">
        <JewelsmithAboutUsCard />
    </section>
  )
}

export default JEWELSMITHAboutUs