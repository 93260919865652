import React from 'react'
import "./GoldScheme.css"
import Banner1 from './Components/Banner1'
import Banner2 from './Components/Banner2'
import Banner3 from './Components/Banner3'
import TermsAndCondition from './Components/TermsAndCondition'
import ScanToDownload from './Components/ScanToDownload'
import DownloadBrochure from './Components/DownloadBrochure'

function GoldScheme() {
  return (
    <section className='gold-scheme-page'>
        <DownloadBrochure />
        <Banner1/>
        <Banner2/>
        <Banner3/>
        <TermsAndCondition/>
        <ScanToDownload/>
    </section>
  )
}

export default GoldScheme