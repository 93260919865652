import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_martix_view_layout, set_matrixViewLayout } from '../../../../../redux/Reducer/reducer'

function ViewLayout({type, image}) {

  
    const selectedLayout = useSelector(get_martix_view_layout)
    const selectView = selectedLayout === type ? "selected" : ""
    const dispatch = useDispatch()
    
    

    // useEffect(()=>{
    //   const default_Layout_style = Layout.find(layout => layout.layout_style === default_image_type_mycat_v2_mobile)
    //   dispatch(set_matrixViewLayout(default_Layout_style?.type || "grid-layout"))
    // }, [])

  return (
    <div className={`view-layout-image-container ${selectView}`}
    
    >
        <img src={image} alt="singleLayout" onClick={() => {dispatch(set_matrixViewLayout(type))}}/>
    </div>
  )
}

export default ViewLayout
