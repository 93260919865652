import { useEffect, useState } from "react";
import NewsSubscriptionModal from "../../_CommonPage/NewsSubscriptionModal";
import "./NewsLetters.css";
import NewsLettersCard from "./NewsLettersCard";
import { NewsLetterData } from "./NewsLettersData";


function NewsLetters() {
  
    const [openSubcriptionModal, setOpenSubcriptionModal] =  useState(false);
    const handleSubscriptionOpen = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
      
      setOpenSubcriptionModal(true);
    }
    const handleSubscriptionClose = () => {
      
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
        setOpenSubcriptionModal(false)
    };
    
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        })
        const blank_link = document.getElementById("blank_link")

        if(blank_link){

          document.getElementById("blank_link").click() 
        }


        
        setTimeout(() => {
          handleSubscriptionOpen()
         
        }, 2000)

        
    }, [])

  return (
    <section className="news-letters-page" >

    <NewsSubscriptionModal openSubcriptionModal={openSubcriptionModal} handleSubscriptionClose={handleSubscriptionClose}/>
      <header id="news-letters">
        <a href="#news-letters" id="blank_link">&nbsp;</a>
        <h2>News Letters</h2>
      </header>

      <section className="banner-iframe-video" id="banner-iframe-video">
        <iframe
        //   width="1153"
          height="420"
          src="https://www.youtube.com/embed/ahEytZ-1A3E"
          title="Kalasha Newsletter - September"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          style={{width: 'min(1440px, 100%)'}}
        ></iframe>
      </section>

      <section className="news-letters-section">
            {
                NewsLetterData.map((data, index) => <NewsLettersCard key={`news-letter-${index}`} {...data}/>)
            }
      </section>
    </section>
  );
}

export default NewsLetters;
