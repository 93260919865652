import React from 'react'
import { get_property_settings } from '../../Helper'
import NoImg from "../../../assets/images/no_image_available.png"
import { useNavigate } from 'react-router-dom';
import {createSearchURLParams} from "../../Helper"

function BrandingBasedSection({title, btnName, imgName, cat_id}) {

  const cdn = get_property_settings("cdn");
  const img = imgName ? `${cdn}uploads/branding_image/featured/${imgName}` : NoImg;
  const navigate = useNavigate()
  function handleViewBtn() {
      navigate(createSearchURLParams("matrix-page", {
        cat_id: cat_id,
        mode: "filter",
        page: 0,
      }))
  }

  return (
    <div className='branding-based-section' style={{backgroundImage: `url(${img})`}}>
      <img src={img} alt={title} />
      <div className='branding-details'>
        <h2 className='branding-title'>{title}</h2>
        {/* <p className='branding-body'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Est harum, animi fuga dicta modi totam!</p> */}
        {btnName && <button className='branding-button'
          onClick={handleViewBtn}
        >
            {btnName}
        </button>}
      </div>
    </div>
  )
}

export default BrandingBasedSection
