import Accordion from "react-bootstrap/Accordion";
import OrderAccordationCard from "./OrderAccordationCard";
import { get_property_settings } from "../../../Component/Helper";

function OrderAccordation({ cart_data }) {
  let show_set_and_pcs_in_cart_grouped = get_property_settings("show_set_and_pcs_in_cart_grouped")
  show_set_and_pcs_in_cart_grouped = show_set_and_pcs_in_cart_grouped === "Yes";
  return cart_data?.map((data, index) => {
    return (
      <Accordion defaultActiveKey="0" key={data?.labour_type_name}>
        <Accordion.Item eventKey={index.toString()}>
          <Accordion.Header className="accordation-header">
            <h3>
              <span>{data?.labour_type_name}</span>
              <span className="unit">
                <span>{data?.total_gross_wt} gms </span>
                <span className="divide"></span>{" "}
                {
                  show_set_and_pcs_in_cart_grouped ? (
                    <>
                       <span>{data?.total_quantity} sets</span>
                       <span className="divide"></span>{" "}
                       <span>{data?.total_piece} pcs</span>
                    </>
                  ) : (
                    <span>{data?.total_quantity} pcs</span>
                  )
                }
                
              </span>
            </h3>
          </Accordion.Header>
          <Accordion.Body className="accordation-body">
            {data?.product_details?.map((product_detail) => (
              <OrderAccordationCard
                {...product_detail}
                key={product_detail?.id}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  });
}

export default OrderAccordation;
