import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form"



function Selectfield({field, control, options, errors}) {
    const {field_name, required, short_code} = field;

  return (
    <div>
        <label htmlFor="name">{field_name}
      {required === "1" && <span className="required-field">*</span>}
      </label>
      <Controller 
        control={control}
        name={short_code}
        rules={{
          required: {
            value: required === "1",
            message: `${field_name} is required`
          }
        }}
        render={({ field }) => (
          <Select
          // tabIndex={i + 1}
          // menuPlacement="top"
          placeholder={`Select`}
          options={options}
          className="react-select-container r-container"
          classNamePrefix="react-select r-select"
          {...field}
          
        />
        )}
      />
       
       {errors[short_code]?.message && <p className="error-msg">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default Selectfield;
