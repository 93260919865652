import React from "react";
import { get_property_settings } from "../../Helper";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";

function FooterCustomLoaction() {
  
  let show_social_link = get_property_settings("show_social_link");
  show_social_link = show_social_link === "Yes";
  

  // const locationData = useSelector(getLocation) || []

  const location_query = useQuery({
    queryKey: ["location"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.Location, "location", {
        device: "web",
      }),
    staleTime: Infinity,
  });

  if (!Array.isArray(location_query?.data?.location)) {
    return null;
  }

  return (
    <div className="ec-footer-widget">
      <h4 className="ec-footer-heading">Location</h4>
      <div className="ec-footer-links">
        <ul className="align-items-center my-3">
          {location_query?.isSuccess &&
            location_query?.data?.location?.map((location, index) => (
              <li className="ec-footer-link address-section" key={index}>
                <div>
                  {/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
                  <p>&nbsp;&nbsp;&nbsp;</p>
                  <b>{location?.title}</b>
                </div>

                <div className="address">
                  <i className="fa fa-location-arrow" aria-hidden="true"></i>
                  <p>{location?.address}</p>
                </div>

                <div className="phone">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <p>+91-22-22402580, 61862580</p>
                </div>

                <div className="whats-app-icon-footer">
                  <i className="fa-brands fa-whatsapp" style={{fontSize: "1.6rem"}}></i>
                  <a
                    className="hovered"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://api.whatsapp.com/send?phone=${location?.mobile.replace(/[+-]/g, "")}&text=`}
                  >

                  <p>{location?.mobile}</p>
                  </a>
                </div>

                <div className="email-icon-footer">
                <i className="fa-solid fa-envelope" style={{fontSize: "1.6rem"}}></i>
                  <p>kalashgold.marketing@gmail.com</p>
                </div>

                <div className="insta-icon-footer" >
                <i className="ecicon eci-instagram" style={{fontSize: "1.6rem"}}></i>
                  <a href="https://www.instagram.com/kgopl.jewellery?igsh=bWJodXlxbHZlcHdo" target="_blank" rel="noreferrer"><p>kgopl.jewellery</p></a>
                </div>

              </li>
            ))}
        </ul>
        
      </div>
    </div>
  );
}

export default React.memo(FooterCustomLoaction);
