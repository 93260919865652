import React, { useEffect } from "react";
import "./SuccessCheckAnim.css"
import { useDispatch } from "react-redux";
import { set_trigger_cart_btn, set_trigger_shortlist_btn } from "../../redux/Reducer/reducer";
import checkedAnim from "../../assets/images/checked-anim.gif"


function SuccessCheckAnim({type, clsName}) {

 const dispatch = useDispatch()
 const gifAnimImage = false;
 

 useEffect(() => {
    setTimeout(() => {
        if(type === "cart"){
            dispatch(set_trigger_cart_btn(false))
        }else{
          dispatch(set_trigger_shortlist_btn(false))
        }
    }, 1000)
    
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [type])

  
 return (
    <>
    {!gifAnimImage ? <div className={`success-checkmark ${clsName}`}>
      <div className="check-icon">
        <span className="icon-line line-tip"></span>
        <span className="icon-line line-long"></span>
        <div className="icon-circle"></div>
        <div className="icon-fix"></div>
      </div>
    </div> :
    <img src={checkedAnim} alt="" />}
    </>
    
  );
}

// $("button").click(function () {
//     $(".check-icon").hide();
//     setTimeout(function () {
//       $(".check-icon").show();
//     }, 10);
//   });

export default SuccessCheckAnim;
