import { FaSearch } from "react-icons/fa";
import debounce from 'debounce';
import { useNavigate } from "react-router-dom";
import { createSearchURLParams } from "../Helper";
import "./SearchBar.css"
import { memo } from "react";




function SearchBar({handle_search_key, placeholder = 'Search by Order No'}) {

  const navigate = useNavigate()
  
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );

  function handleSearch (e) {
    
    handle_search_key(e.target.value)
    const pathName = document.location.pathname.replace("/", "")
    navigate(createSearchURLParams(pathName, {...searchParams, order_no: e.target.value}))
  }
  
  return (
    <div className='search-bar-comp'>
      <FaSearch className="search-icon"/>
      <input type="text" placeholder={placeholder}
      onChange={debounce(handleSearch, 500)}
      
      />
    </div>
  )
}

export default memo(SearchBar)