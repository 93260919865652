import { AwardsData } from "./AwardsData"
import "./Awards.css"
// import FullScreenImageSlider from "../../../../../Component/FullScreenImageSlider/FullScreenImageSlider"
import { useEffect, useRef } from "react"
import { ScrollToTop } from "../../_CommonPage/ScrollToTop"
import FullScreenImageSliderNew from "../../../../../Component/FullScreenImageSlider/FullScreenImageSliderNew"
const innerImagesArray = AwardsData.map(data => data.innerImage)

function AwardCard({image, title, id, handleOpenFullScreenImageSlider}) {
    
    return (
        <section>
        
        {/* <FullScreenImageSlider imagePath={null} imagesArr={innerImagesArray}> */}

        <div className="award-card" onClick={() => {
            sessionStorage.setItem("active_order_details_slide", id)
            handleOpenFullScreenImageSlider()
        }}>
            <div className="image-section">
                <img src={image} alt="" />
            </div>
            <div className="detail-section">
                <p>{title}</p>
            </div>
        </div>
        {/* </FullScreenImageSlider> */}
        </section>
    )
}



function Awards() {

    const openModalRef = useRef(null);

    useEffect(() => {
        ScrollToTop()
    }, [])

  const handleOpenFullScreenImageSlider = () => {
    openModalRef.current.openModal();
  };

  return (
    <section className="awards-page">
        <FullScreenImageSliderNew imagePath={null} imagesArr={innerImagesArray} ref={openModalRef}/>
        <header>
            <h2>AWARDS & ACCOLADES</h2>

            <section className="awards-section">
                {
                    AwardsData.map(data => <AwardCard key={data.id} {...data} handleOpenFullScreenImageSlider={handleOpenFullScreenImageSlider}/>)
                }
            </section>
        </header>
    </section>
  )
}

export default Awards