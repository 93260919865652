import React from "react";
import PhoneInput from "react-phone-input-2";
import { get_property_settings, set_informationToSessionID } from "../Component/Helper";

function Telephone(props, ref) {
  const country_name_shortcode = get_property_settings("country_name_shortcode")

  const { onChange, value, tabIndex } = props;

  return (
    <PhoneInput
    
      inputClass="number-input fieldValue"
      buttonClass="contryCodeDropDown"
      placeholder=""
      value={value}
      country={country_name_shortcode ? country_name_shortcode.toLowerCase() : 'in'}
      masks={{ in: ".........." }}
      inputProps={{
        tabIndex: tabIndex + 1
      }}
      onChange={(phoneNumber, country) => {
        set_informationToSessionID({country_code: country.dialCode})
        onChange(phoneNumber)
      }}
    />
  );
}

export default React.forwardRef(Telephone);
