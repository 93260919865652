/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "rc-slider";
import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { get_informationFromSessionID } from "../../../../../../Component/Helper";
import { useLocation } from "react-router-dom";

function RangeOption({ data, field }, ref) {
  const { setValue } = useFormContext();
  const location = useLocation()
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const companyCode = get_informationFromSessionID("companyCode");

  const decryptCompanyName = atob(companyCode);

  //Change field carat (base on mat_calc_type) if there is Diamond else set DataField as is
  const datafield =
    field === "Diamond"
      ? data?.mat_calc_type.toLowerCase()
      : field.includes("price") && decryptCompanyName === "KALASHAONLINE"
      ? `kalasha_${field}`
      : field;
  
  const default_min_value = Number(data[`min_${field}`]);
  const default_max_value = Number(data[`max_${field}`]);
  const [minValue, setMinValue] = useState(default_min_value)
  const [maxValue, setMaxValue] = useState(default_max_value)

  let step = 0;
  let labelText = "";

  if (field === "gross_wt" || field === "net_wt") {
    step = 0.01;
    labelText = "gm";
  } else if (field === "Diamond" && data?.mat_calc_type === "Carat") {
    step = 0.01;
    labelText = "cts";
  } else if (field.includes("price")) {
    step = 1;
    labelText = "";
  } else {
    // eslint-disable-next-line no-unused-vars
    step = 1;
    labelText = "";
  }

  useImperativeHandle(ref, () => ({
    clearRange: () => {
      setMinValue(default_min_value)
      setMaxValue(default_max_value)
    }
  }))

  useEffect(() => {
    setValue(`${datafield}`, {minmax: [Number(minValue), Number(maxValue)]})
  }, [minValue, maxValue])

  useLayoutEffect(() => {
    const keys = Object.keys(searchParams)
    if(keys.length === 0) return 
    if(`min_${datafield}` in searchParams && `max_${datafield}` in searchParams)  {
      const minSearchValue = searchParams[`min_${datafield}`]
      const maxSearchValue = searchParams[`max_${datafield}`]
      if(minSearchValue !== minValue && maxSearchValue !== maxValue)
        setMinValue(minSearchValue)
        setMaxValue(maxSearchValue)
    }

  }, [searchParams?.[`min_${datafield}`], searchParams?.[`max_${datafield}`]])

  return (
    <div className="range-section">
      <Slider
        range
        min={default_min_value}
        max={default_max_value}
        value={[
          minValue, maxValue
        ]}
        onChange={(values) => {
          setMinValue(values[0])
          setMaxValue(values[1])
          
        }}
      />
      <div className="range-input">
        {field.includes("price") && <label htmlFor="">{labelText}</label>}
        <input
          type="number"
          defaultValue={default_min_value}
          value={minValue}
          onChange={(e) => setMinValue(e.target.value)}
        />
        {!field.includes("price") && <label htmlFor="">{labelText}</label>}
        <span className="ec-price-divider"> - </span>
        {field.includes("price") && <label htmlFor="">{labelText}</label>}
        <input
          type="number"
          defaultValue={default_max_value}
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
        />
        {!field.includes("price") && <label htmlFor="">{labelText}</label>}
      </div>
    </div>
  );
}

export default forwardRef(RangeOption);
