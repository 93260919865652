import { useQuery } from "@tanstack/react-query";
import "./NewsUpdates.css"
import NewsUpdatesCards from "./NewsUpdatesCards"
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import Loader from "../../../Component/Loader";
import useLiveRateReact from "../../../Hooks/useLiveRateReact/useLiveRateReact";

function NewsUpdates() {

  const news_update_query = useQuery({
    queryKey: ["myc"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.News_updates, "news-update"),
    
  });

  useLiveRateReact()

  return (
    <section className="news-updates-page">
        <header>
          <h2>News & Updates</h2>
        </header>

        {news_update_query.isLoading ? (<Loader isLoading={true}/>) : (
          <div className="news-updates-section">

            {
              news_update_query.data?.map(news => <NewsUpdatesCards key={news.id} {...news}/>)
            }
          
          
        </div>
      )}
    </section>
  )
}

export default NewsUpdates