
import { EMAIL_REGEX, DATE_REGEX } from "../../../utils";

function Textfield({clsName, paramsDataIndex, paramsData, formData, handleChange, typeOfField = "text", register, disabled}) {
  
  

  return (
    <input
      disabled={disabled}
      className={clsName}
      id={paramsDataIndex}
      tabIndex={paramsDataIndex + 1}
      type={typeOfField}
      placeholder={`Enter ${paramsData?.field_name}`}
      {...register(paramsData?.short_code, 
        { 
          required: {
            value: paramsData?.required === "1",
            message: `${paramsData?.field_name} is required`
          },
          pattern: {
            value: paramsData?.short_code === "email_id" ? EMAIL_REGEX : paramsData?.short_code === "delivery_date" ? DATE_REGEX : false,
            message:  paramsData?.short_code === "email_id" ? 'Invaild email Id' : paramsData?.short_code === "delivery_date" ? "Invaild date format" : ""
          } 
        }
      )}
    />
  );
}

export default Textfield;
