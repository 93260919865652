import "./ExclusiveNRIOffer.css";
import { ExclusiveNRIOfferData } from "./ExclusiveNRIOfferData";
import ExclusiveOfferCard from "./ExclusiveOfferCard";

function ExclusiveNRIOffer() {
  return (
    <section className="exclusive-nri-offer-page">
      <header>
        <h2>Exclusive Offer For NRI'<span style={{fontSize: '2rem', fontWeight: "600"}}>s</span></h2>
      </header>

      <section className="details-section">
        <p>
          <b>Hello NRIs,</b><br/> We are thrilled to invite you to experience the
          exceptional benefits of shopping at Kalasha Fine Jewels, where luxury
          meets convenience. As a valued NRI customer, you can enjoy a
          remarkable 13.75% discount on gold rates. This exclusive offer allows
          you to purchase gold at duty-free and tax-free prices, ensuring you
          get the best value for your money.
        </p>

        <section className="card-section">
            <h3>Why Choose Kalasha Fine Jewels?</h3>

            <div className="card-container">
                {
                    ExclusiveNRIOfferData.map(data => <ExclusiveOfferCard key={data.id} {...data}/>)
                }
                
            </div>
        </section>

        <section className="other-section">
            <h3>Our Exquisite Collections</h3>

            <p>
            At Kalasha Fine Jewels, we pride ourselves on offering a diverse range of jewellery that caters to every taste and occasion. From traditional designs to contemporary masterpieces, our collection is crafted with utmost precision and artistry. Whether you are looking for elegant necklaces, statement earrings, or timeless bracelets, you will find something to cherish forever.
            </p>
        </section>

        <section className="other-section">
            <h3>Join the Kalasha Fine Jewels Family</h3>

            <p>
            Indulge in the finest jewellery shopping experience with Kalasha Fine Jewels. Our commitment to quality, exceptional customer service, and exclusive benefits for NRIs make us the perfect choice for your jewellery needs.
            </p>

            <p>
            Don't miss out on this incredible opportunity. Start shopping today and embrace the luxury and elegance of Kalasha Fine Jewels.
            </p>
        </section>

      </section>
    </section>
  );
}

export default ExclusiveNRIOffer;
