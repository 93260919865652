import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useEffect, useId, useRef, useState } from "react";

import { ApiList } from "../../../../Api/ApiList";

import { useDispatch, useSelector } from "react-redux";
import {
  get_inCartWishList,
  set_inCartWishList,
  set_trigger_cart_btn,
  set_trigger_shortlist_btn,
  setConstants,
} from "../../../../redux/Reducer/reducer";
import {
  ToastError,
  ToastSuccess,
  get_informationFromSessionID,
  get_property_settings,
} from "../../../../Component/Helper";
import { fetch_data_from_api } from "../../../../Api/APICalls";
import noImage from "../../../../assets/images/no_image_available.png";
import CartRemark from "../CartRemark";
import { MdOutlineZoomIn } from "react-icons/md";
import FullScreenImageSlider from "../../../../Component/FullScreenImageSlider/FullScreenImageSlider";
import DeleteCartProductModal from "./Components/CartProductModal";
import CartQuantityStepper from "./Components/CartQuantityStepper";
import CartDropDown from "./Components/CartDropDown";
import { get_custom_repostioned_params, redirection_to_FSV_URL } from "../CartHelper";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";
import { useNavigate } from "react-router-dom";
import CartPcsStepper from "./Components/CartPcsStepper";

const CartProductNew = ({
  id,
  group_in_wishlist,
  group_in_cart,
  fetchData,
  productIndex,
  isLastProduct,
  general_settings,
  cartData,
  product_detail,
  type_of_cart,
}) => {
  // const maxQty = 5;
  // const minQty = 3;
  // const lessThanMoq = 4;
 
  const productCode =
    general_settings.cart_grouped_code_based_on === "mfg_code"
      ? product_detail?.mfg_code
      : product_detail?.search_item_code;

  const defaultImg = general_settings?.default_image
    ? `${general_settings?.cdn}uploads/default_image/${general_settings?.default_image}`
    : noImage;

  const image = product_detail?.design_files
    ? `${general_settings.cdn}${product_detail?.image_path}${product_detail?.design_files}`
    : defaultImg;
  const productname = product_detail?.product_name;
  const params = !!product_detail?.params ? Object.values(product_detail?.params[0]) : [];
  //const inch = product_detail?.size_param_catalogue

  const which_master = product_detail?.which_master;
  const custom_reposition_params =
    getCustomValue(
      CUSTOMIZIED_COMPANY_PROPERTY.custom_reposition_cart_params
    ) || [];
  const cart_to_fsv_redirection = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.cart_to_fsv_redirection
  );

  const custom_repostioned_cart_params = get_custom_repostioned_params(
    params,
    custom_reposition_params
  );
  const is_remark_in_custom_repostioned_params =
    custom_repostioned_cart_params.includes("Remark");
  const is_qty_in_custom_repostioned_params =
    custom_repostioned_cart_params.includes("Qty");

    let enable_set_and_pcs_logic = product_detail?.enable_set_and_pcs_logic;
    enable_set_and_pcs_logic = enable_set_and_pcs_logic === "1"

  const {
    show_quantity_stepper_in_cart_wishlist,
    show_qty_stepper_in_inventory,
    show_remarks_in_cart_wishlist,
    show_remarks_in_inventory,
    show_product_name_in_cart_wishlist,
    enable_shortlist,
    show_named_wishlist_app,
    set_image_bg_based_on_product_image,
  } = general_settings;

  const show_image_bg = set_image_bg_based_on_product_image === "Yes";

  const user_id = get_informationFromSessionID("userID");

  const navigate = useNavigate();

  const [showCartProductModal, setShowCartProductModal] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [isToastActive, setIsToastActive] = useState(false);

  const show_named_wishlist =
    enable_shortlist === "Yes" && show_named_wishlist_app === "Yes";

  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  let idText = useId();
  idText = `${idText}-${productIndex}`;

  let inCartWishList = useSelector(get_inCartWishList);

  const deleteItem = async () => {
    //setLoading(true)
    const deleteItem_Response = await fetch_data_from_api(
      `${ApiList.cart}/${ApiList.DeleteFromCart}`,
      "delete-from-cart",
      {
        id,
        type:
          type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
        table: type_of_cart,
      }
    );

    if (deleteItem_Response.constructor === Object) {
      dispatch(setConstants({ count: deleteItem_Response?.cart_total }));

      if (type_of_cart === "temp_cart") {
        if (product_detail.which_master === "design_master") {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              in_cart_id_dm: [
                ...inCartWishList.in_cart_id_dm.filter((i, k) => {
                  return i !== product_detail.design_master_id;
                }),
              ],
            })
          );
        }

        if (product_detail.which_master === "inventory_master") {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              in_cart_id_im: [
                ...inCartWishList.in_cart_id_im.filter((i, k) => {
                  return i !== product_detail.inventory_master_id;
                }),
              ],
            })
          );
        }
      } else {
        if (product_detail.which_master === "design_master") {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              in_wishlist_id_dm: [
                ...inCartWishList.in_wishlist_id_dm.filter((i, k) => {
                  return i !== product_detail.design_master_id;
                }),
              ],
            })
          );
        }

        if (product_detail.which_master === "inventory_master") {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              in_wishlist_id_im: [
                ...inCartWishList.in_wishlist_id_im.filter((i, k) => {
                  return i !== product_detail.inventory_master_id;
                }),
              ],
            })
          );
        }
      }
      if (window.innerWidth <= 575) {
        type_of_cart === "temp_cart" && dispatch(set_trigger_cart_btn(true));
        type_of_cart === "temp_wishlist" &&
          dispatch(set_trigger_shortlist_btn(true));
      } else {
        ToastSuccess(deleteItem_Response?.msg);
      }
    }
    setShowCartProductModal(false);
    //setLoading(false)
    fetchData("stopLoader");
  };

  const handleChange = async (short_code, value) => {
    //setLoading(true);
    setIsToastActive(true);
    const input_change_response = await fetch_data_from_api(
      `${ApiList.cart}/${ApiList.UpdateCartParams}`,
      "update-cart-params",
      {
        row_id: id,
        table: type_of_cart,
        user_id,
        update_value: value,
        type:
          type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
        update_param: short_code,
      }
    );

    if (input_change_response.constructor === Object) {
      dispatch(setConstants({ count: input_change_response?.cart_total }));

      !isToastActive && ToastSuccess(input_change_response?.msg);
      setTimeout(() => {
        setIsToastActive(false);
      }, 2000);

      //setLoading(false);
    }

    fetchData("stopLoader");
  };

  const transferProduct = async () => {
    const transferProduct_response = await fetch_data_from_api(
      `${ApiList.cart}/${ApiList.TransferProduct}`,
      "transfer-product",
      {
        id,
        table_from: type_of_cart,
        table_to:
          type_of_cart === "temp_wishlist" ? "temp_cart" : "temp_wishlist",
        type:
          type_of_cart === "temp_wishlist" ? group_in_wishlist : group_in_cart,
        user_id,
      }
    );

    if (transferProduct_response.constructor === Object) {
      if (transferProduct_response?.error)
        return ToastError(transferProduct_response?.error?.msg);
      dispatch(setConstants({ count: transferProduct_response?.cart_total }));
      ToastSuccess(transferProduct_response?.msg);
      buttonRef?.current?.blur();
    }

    if (type_of_cart === "temp_wishlist") {
      // cart +
      // shortlist -

      // design_master
      if (product_detail.which_master === "design_master") {
        if (product_detail.design_master_id) {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              // adding in to cart
              in_cart_id_dm: [
                ...inCartWishList.in_cart_id_dm,
                !inCartWishList.in_cart_id_dm.includes(
                  product_detail.design_master_id
                ) && product_detail.design_master_id,
              ],
              // remove from short list
              in_wishlist_id_dm: [
                ...inCartWishList.in_wishlist_id_dm.filter((i, k) => {
                  return i !== product_detail.design_master_id;
                }),
              ],
            })
          );
        }
      }

      // inventory_master
      if (product_detail.which_master === "inventory_master") {
        if (product_detail.inventory_master_id) {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              // adding in to cart
              in_cart_id_im: [
                ...inCartWishList.in_cart_id_im,
                !inCartWishList.in_cart_id_im.includes(
                  product_detail.inventory_master_id
                ) && product_detail.inventory_master_id,
              ],
              // remove from short list
              in_wishlist_id_im: [
                ...inCartWishList.in_wishlist_id_im.filter((i, k) => {
                  return i !== product_detail.inventory_master_id;
                }),
              ],
            })
          );
        }
      }
    } else {
      // cart -
      // shortlist +

      // design_master
      if (product_detail.which_master === "design_master") {
        if (product_detail.design_master_id) {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              // adding in to cart
              in_wishlist_id_dm: [
                ...inCartWishList.in_wishlist_id_dm,
                !inCartWishList.in_wishlist_id_dm.includes(
                  product_detail.design_master_id
                ) && product_detail.design_master_id,
              ],
              // remove from short list
              in_cart_id_dm: [
                ...inCartWishList.in_cart_id_dm.filter((i, k) => {
                  return i !== product_detail.design_master_id;
                }),
              ],
            })
          );
        }
      }

      // inventory_master
      if (product_detail.which_master === "inventory_master") {
        if (product_detail.inventory_master_id) {
          dispatch(
            set_inCartWishList({
              ...inCartWishList,
              // adding in to cart
              in_wishlist_id_im: [
                ...inCartWishList.in_wishlist_id_im,
                !inCartWishList.in_wishlist_id_im.includes(
                  product_detail.inventory_master_id
                ) && product_detail.inventory_master_id,
              ],
              // remove from short list
              in_cart_id_im: [
                ...inCartWishList.in_cart_id_im.filter((i, k) => {
                  return i !== product_detail.inventory_master_id;
                }),
              ],
            })
          );
        }
      }
    }

    //setLoading(false);
    fetchData();
  };
  const updateWindowResolution = () => {
    // Update view and thumb position based on window width
  };
  useEffect(() => {
    window.addEventListener("resize", updateWindowResolution);
    return () => {
      window.removeEventListener("resize", updateWindowResolution);
    };
  });

  

  return (
    <div
      className={
        // windowResolution.width > 1024 ? "container-fluid px-2" : "container"
        "container-fluid px-2 product-section"
      }
      id={`${idText}`}
    >
      <div className="row productName d-flex justify-content-between productName align-items-center my-2 mt-3 px-2">
        {/* <div className="col-12  d-flex justify-content-end del-btn" onClick={() => setShow(true)} >
          
          <span>
            <FontAwesomeIcon icon={faTrash}/>
          </span>
        </div> */}
      </div>
      <div
        className={`row h-100 ${
          productIndex === cartData.product_details.length - 1 ? "" : ""
        } justify-content-center product`}
      >
        <div
          className="col-xxl-5 col-xl-5 col-md-5 col-sm-5 col imgDiv"
          style={{
            backgroundImage: `${show_image_bg ? `url(${image})` : "none"}`,
          }}
        >
          {cart_to_fsv_redirection ? (
            <img
              // src={require("../assets/WebImage.png")}
              src={image ? image : ""}
              // height="100%"
              width="100%"
              style={{
                objectFit: "contain",
                maxWidth: "275px",
                minWidth: "80px",
                maxHeight: "300px",
              }}
              className="p-1 mx-auto mx-sm-0 mx-xl-auto"
              alt=""
              onClick={() => {
                const id = product_detail[`${product_detail?.which_master}_id`]
                navigate(redirection_to_FSV_URL(product_detail), {state: {cartData: product_detail?.default_selection_id[id][0]}})
              }}
            />
          ) : (
            <FullScreenImageSlider
              imagesArr={[image.replace("thumb", "zoom")]}
              showThumbnailPreview={false}
            >
              <button className="cart-zoom-image-btn">
                <MdOutlineZoomIn />
              </button>

              <img
                // src={require("../assets/WebImage.png")}
                src={image ? image : ""}
                // height="100%"
                width="100%"
                style={{
                  objectFit: "contain",
                  maxWidth: "275px",
                  minWidth: "130px",
                  maxHeight: "300px",
                }}
                className="p-1 mx-auto mx-sm-0 mx-xl-auto"
                alt=""
              />
            </FullScreenImageSlider>
          )}
        </div>
        <div className="col productDetail my-2 position-relative ">
          <div
            className="col-12  d-flex justify-content-end del-btn"
            onClick={() => setShowCartProductModal(true)}
          >
            <span>
              <FontAwesomeIcon icon={faTrash} />
            </span>
          </div>
          <div className=" col-12  text-start">
            <p>
              {show_product_name_in_cart_wishlist === "Yes" && productname && (
                <>{productname} &nbsp;</>
              )}
            </p>
          </div>
          <div className=" col-12  text-start mb-2">
            <p>{productCode}</p>
          </div>

          <div className="row cart-product-details">
            <div className="col-12 cart-product-details">
              <div className="row other-cart-details">
                {/********* PARAMS ************/}
                {!custom_repostioned_cart_params.length &&
                  params &&
                  params.length > 0 &&
                  params.map((cartData, index) => {
                    return (
                      <div
                        key={`params-${index}`}
                        className={`${
                          cartData.display_width === "b"
                            ? "full-width"
                            : "half-width"
                        }`}
                      >
                        <div className="label">
                          {cartData?.label}
                          {cartData?.label ? ":" : ""}
                        </div>
                        {product_detail[cartData.short_code + "_catalogue"] &&
                        Object.values(
                          product_detail[cartData.short_code + "_catalogue"]
                        ).length > 1 ? (
                          <CartDropDown
                            cartData={cartData}
                            product_detail={product_detail}
                            handleChange={handleChange}
                          />
                        ) : (
                          <div
                            className={`data ${
                              !cartData?.label ? "selectedValue" : ""
                            }`}
                          >
                            {!cartData?.label && (
                              <RiCheckboxCircleFill
                                style={{ color: "var(--highlight_color)" }}
                              />
                            )}
                            <span>{cartData?.selected_value}</span>
                          </div>
                        )}
                      </div>
                    );
                  })}

                {/********* CUSTOME_REPOSTIONED_PARAMS ************/}
                {custom_repostioned_cart_params.length > 0 &&
                  params &&
                  params.length > 0 &&
                  // eslint-disable-next-line array-callback-return
                  custom_repostioned_cart_params.map((cartData, index) => {
                    if (typeof cartData === "object") {
                      return (
                        <div
                          key={`custom_params_${index}`}
                          className={`${
                            cartData.display_width === "b"
                              ? "full-width"
                              : "half-width"
                          }`}
                        >
                          <div className="label">
                            {cartData?.label}
                            {cartData?.label ? ":" : ""}
                          </div>
                          {product_detail[cartData.short_code + "_catalogue"] &&
                          Object.values(
                            product_detail[cartData.short_code + "_catalogue"]
                          ).length > 1 ? (
                            <CartDropDown
                              cartData={cartData}
                              product_detail={product_detail}
                              handleChange={handleChange}
                            />
                          ) : (
                            <div
                              className={`data ${
                                !cartData?.label ? "selectedValue" : ""
                              }`}
                            >
                              {!cartData?.label && (
                                <RiCheckboxCircleFill
                                  style={{ color: "var(--highlight_color)" }}
                                />
                              )}
                              <span>{cartData?.selected_value}</span>
                            </div>
                          )}
                        </div>
                      );
                    } else if (typeof cartData === "string") {
                      
                      // eslint-disable-next-line no-lone-blocks
                      {
                        /* ---------- QAUTITY -------- */
                      }
                      if (cartData.toLowerCase() === "qty") {
                        if (
                          show_quantity_stepper_in_cart_wishlist === "Yes" &&
                          which_master === "inventory_master" &&
                          show_qty_stepper_in_inventory === "Yes"
                        ) {
                          return (
                            <CartQuantityStepper
                              handleChange={handleChange}
                              product_detail={product_detail}
                              clsName="repositioned-params"
                            />
                          );
                        } else if (
                          show_quantity_stepper_in_cart_wishlist === "Yes"
                        ) {
                          return (
                            <CartQuantityStepper
                              handleChange={handleChange}
                              product_detail={product_detail}
                              clsName="repositioned-params"
                            />
                          );
                        }
                      } else if (cartData.toLowerCase() === "remark") {
                        if (
                          show_remarks_in_cart_wishlist === "Yes" &&
                          which_master === "inventory_master" &&
                          show_remarks_in_inventory === "Yes"
                        ) {
                          return (
                            <CartRemark
                              remarks={product_detail?.remarks}
                              handleChange={handleChange}
                            />
                          );
                        } else if (show_remarks_in_cart_wishlist === "Yes") {
                          return (
                            <CartRemark
                              remarks={product_detail?.remarks}
                              handleChange={handleChange}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                    }
                  })}
              </div>
            </div>
          </div>

          {!is_remark_in_custom_repostioned_params &&
          show_remarks_in_cart_wishlist === "Yes" &&
          which_master === "inventory_master" &&
          show_remarks_in_inventory === "Yes" ? (
            <CartRemark
              remarks={product_detail?.remarks}
              handleChange={handleChange}
            />
          ) : !is_remark_in_custom_repostioned_params &&
            show_remarks_in_cart_wishlist === "Yes" ? (
            <CartRemark
              remarks={product_detail?.remarks}
              handleChange={handleChange}
            />
          ) : null}

          

          {!is_qty_in_custom_repostioned_params &&
          show_quantity_stepper_in_cart_wishlist === "Yes" &&
          which_master === "inventory_master" ? (
            show_qty_stepper_in_inventory === "Yes" ? (
              <CartQuantityStepper
                handleChange={handleChange}
                product_detail={product_detail}
              />
            ) : null
          ) : 
          !is_qty_in_custom_repostioned_params &&
            show_quantity_stepper_in_cart_wishlist === "Yes" ? (
            <CartQuantityStepper
              handleChange={handleChange}
              product_detail={product_detail}
            />
          ) : null}

          {/* --------- PCS STEPPER ------------------------- */}
          {!is_qty_in_custom_repostioned_params && enable_set_and_pcs_logic ? (
            <CartPcsStepper handleChange={handleChange}
            product_detail={product_detail}/>
          ) : null
            
          }

          {type_of_cart === "temp_cart" && show_named_wishlist ? null : (
            <div className="row single-prod-transfer-btn">
              <div
                className={`col-12 d-flex justify-content-end mt-2 mb-3 cart-btn ${
                  type_of_cart === "temp_wishlist"
                    ? "cartlist-btn"
                    : "wishlist-btn"
                }`}
              >
                <button
                  className="btn btn-primary button_background_color"
                  onClick={() => {
                    transferProduct();
                    window.scrollTo(0, 0);
                  }}
                >
                  + {type_of_cart === "temp_wishlist" ? "Cart" : "shortlist"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <DeleteCartProductModal
        type_of_cart={type_of_cart}
        deleteItem={deleteItem}
        showCartProductModal={showCartProductModal}
        setShowCartProductModal={setShowCartProductModal}
        productCode={productCode}
        idText={idText}
      />
    </div>
  );
};

export default CartProductNew;
