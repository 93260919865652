import "./NamedWishlist.css";
import NamedWishListCard from "./Components/NamedWishListCard"
import { ApiList } from "../../Api/ApiList";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import Loader from "../../Component/Loader";
import NoProduct from "../../Component/NoProduct/NoProduct";
import AddWishlistBtn from "./Components/AddWishlistBtn";
import { get_informationFromSessionID, get_property_settings } from "../../Component/Helper";
import { Navigate } from "react-router-dom";


function NamedWishlist() {
  const userId = get_informationFromSessionID("userID")
  const named_wishlist_query = useQuery({
    queryKey: ["named_wishlist"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.get_named_wishlist_list, "named_wishlist", {
        product_id: "0"
      }),
    
  });

  const shortlist_label = get_property_settings("shortlist_label")

  if(!userId){
    return <Navigate to="/login"/>
  }

  return (
    <section className="named-wishlist-page">
        <header>
            <h2 className="named-wishlist-title">{shortlist_label}</h2>
            <AddWishlistBtn />
        </header>

        <section className={`named-wishlist-card-section ${named_wishlist_query.data?.length < 4 ? "less-shortlist-card" : ""}`}>
          {
            named_wishlist_query.isLoading ? (
              <Loader isLoading={true}/>
            ) : named_wishlist_query.status === "success" && named_wishlist_query.data?.length > 0 ? (
              <>
                {
                    named_wishlist_query.data?.map(data => <NamedWishListCard key={data.id} data={data}/>)
                }
              </>
            ) : (
              <NoProduct errMsg={"No data found"} hideHomeBtn={true}/>
            )
          }
            
            
        </section>
    </section>
  )
}

export default NamedWishlist