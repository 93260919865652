import React, { memo } from 'react'
import {  useNavigate } from 'react-router-dom'
import "./StatusButtonsBar.css"
import { createSearchURLParams } from '../Helper'

function StatusButtonsBar({status_group, active_status, handle_active_status, title = "STATUS"}) {

  const navigate = useNavigate() 
  
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );

 

  function Button ({order_status, is_selected, id, short_code, caption}) {
    const default_selected_id = is_selected === "1"
    return (
        <li className={`${(active_status === (id || short_code)) || (!active_status && default_selected_id === (id || short_code)) ? 'group-by-buttons-selected' : 'group-by-buttons-unselected'} text-nowrap`}
        onClick={() => {
          const pathName = document.location.pathname.replace("/", "") 
          handle_active_status(id || short_code);
          navigate(createSearchURLParams(pathName, {...searchParams, status_id: id || short_code || "", page: searchParams?.page || "0", sort: searchParams?.sort || "0"}))
        }}
        >{order_status || caption}</li>
        
        
    )
  }

  return (
    <section className='status-buttons-bar'>
      <div className='status text-nowrap'>{title} :</div>
      <ul className='status-button-group overflow-scroll example'>
        {
          status_group?.map((status, index) => <Button key={`status-bar-${index}`} {...status}/>)
        }
        
      </ul>
    </section>
  )
}

export default memo(StatusButtonsBar)
