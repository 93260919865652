import { useEffect, useState } from "react";
import TwoColPage from "../../_CommonPage/TwoColPage";
import "./VideoCall.css";
import EnquiryFormModal from "../../_CommonPage/EnquiryFormModal";
import iosBtn from "../../../../../assets/images/kalashaonline/ios_button.svg"
import androidBtn from "../../../../../assets/images/kalashaonline/android_button.svg"
import { get_bulk_settings } from "../../../../../Component/Helper";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";

function VideoCall() {
    const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {android_app_url, ios_app_url} = get_bulk_settings(["android_app_url", "ios_app_url"])

  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className="video-call-page" >
        {open && <EnquiryFormModal open={open} handleClose={handleClose} related_enquiry="Video Call">
            
        I authorize Kalasha Jewels and its representatives to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DND / NDNC.
          
    </EnquiryFormModal>}
        <TwoColPage>
        <div className='image-section'>
        <iframe width="100%" height="450" src="https://www.youtube.com/embed/3PFCec4Yw54" title="Video Call Shopping" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        <div className='detail-section'>
            <header>
                <h2>VIDEO CALL SHOPPING</h2>
            </header>

            <div className="info-section">
                <p>With KALASHA FINE JEWELS Video Shopping, you will never miss the boat to classy, high-end, exclusive fine jewellery – wherever you might be across the globe! With KALASHA’s Video Calling App get a conveniently timed Video Call. Take yourself and even your family through KALASHA FINE JEWELS magical world of handcrafted fine jewellery.</p>
                <h4>FEATURES:</h4>
                <ul>
                    <li>Conference video call shopping</li>
                    <li>Browse through 1000’s of designs</li>
                    <li>4k video quality</li>
                    <li>Live gold rates.</li>
                </ul>
                <h4>TO SCHEDULE A VIDEO CALL:</h4>
                <ul>
                    <li>Register</li>
                    <li>Login</li>
                    <li>Video call – schedule video call</li>
                    <li>Enter your details and products you are looking for.</li>
                </ul>
            </div>
            <div className="btn" onClick={handleOpen}>Enquire Now</div>
        <div className="app-download-btns">
            <a href={ios_app_url} target="_blank" rel="noreferrer"><img src={iosBtn} alt="ios btn" /></a>
            <a href={android_app_url} target="_blank" rel="noreferrer"><img src={androidBtn} alt="android btn" /></a>
        </div>
        </div>
        </TwoColPage>

    </section>
  )
}

export default VideoCall