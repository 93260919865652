/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import styles from "../../../CSS/HomeCSS/CardSection.module.css";
import {
  createSearchURLParams,
  get_bulk_settings,
  set_informationToSessionID,
} from "../../Helper";
import { useNavigate } from "react-router-dom";
import { IoCodeSharp } from "react-icons/io5";

function CardHeader({
  title,
  subtitle,
  btnName,
  setShowCategoryModal,
  categoryModalListFunction,
  clsName = "",
  categoriesData,
}) {
  const navigate = useNavigate();

  const {default_sort, default_image_type_matrix} = get_bulk_settings(["default_sort", "default_image_type_matrix"])

  const All_products_count = useMemo(() => {
      if(btnName !== "All Products") return 0
        return categoriesData.reduce((total, list) => {
            total += parseInt(list?.product_count) || 0
            return total
      }, 0)
  }, [btnName, categoriesData]
)
  return (
    <div className="row">
      <div
        className={`col-md-12 ${styles["section-header"]} ${clsName} home-card-header-sec`}
      >
        <div className={styles["section-title"]}>
          <h2 className={`${styles["ec-title"]} home-card-header-title`}>
            {title}
          </h2>
          {subtitle && <p className={styles.subTitle}>{subtitle}</p>}
        </div>
        <IoCodeSharp className="slider-arrow-icons" />
        {btnName && (
          <div
            className={styles["section-btn"]}
            onClick={() => {
              if (btnName === "View All Categories") {
                setShowCategoryModal(true);
                categoryModalListFunction();
              } else if (btnName === "All Products") {
                navigate(
                  createSearchURLParams("matrix-page", {
                    cat_id: "0",
                    mode: "all",
                    page: "0",
                    sort: default_sort || "0",
                    img_type: default_image_type_matrix || "",
                  })
                );
              } else {
                set_informationToSessionID({ isFData: false });
                navigate("/matrix-page");
              }
            }}
          >
            <a
              className={styles["btn-primary"]}
              href="#"
              onClick={(e) => e.preventDefault()}
            >
              {btnName}
              {All_products_count > 0 ? ` (${All_products_count})` : ''}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardHeader;
