import React from 'react'
import { LuSearchX } from "react-icons/lu";

function NoResultFound({search, setSearch}) {
    return (
        <div className='no-result'>
            <LuSearchX className='no-search-icon'/>
            <p className='no-result-text'>
                <span>
                    No results for {" "}
                </span>
                <span className='fw-bold'>
                    {`"${search}"`}
                </span>
            </p>

            {/* <button className='no-result-btn'
            onClick={()=>setSearch("")}
            >View List</button> */}
        </div>
    )
}

export default NoResultFound
