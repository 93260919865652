
import Select from "react-select";
import { createSelectArrayForReactSelect } from "../../../Component/Helper";


function Selectfield({ formParams, clsName, Controller, control, errors, options}) {
  
  let Option = options
  if(!Option) {
    const options = formParams?.show_custom_input === "1" ? [...formParams.control_value, formParams?.custom_input_placeholder] : formParams.control_value
    Option = createSelectArrayForReactSelect(options);
    
  }
  

 return (
    <span className={clsName}>
      <label className="label">
        {formParams.field_name}
        {formParams?.required === "1" && (
          <span className="required-field">*</span>
        )}
      </label>
      <Controller
        name={formParams?.short_code}
        control={control}
        rules={{
          required: {
            value: formParams?.required === "1",
            message: `${formParams?.field_name} is required`
          },
        }}
        render={({ field }) => (
          <Select
            // tabIndex={i + 1}
            menuPlacement="top"
            placeholder={`Enter ${formParams.field_name}`}
            options={Option || []}
            className="react-select-container r-container"
            classNamePrefix="react-select r-select"
            {...field}
          />
        )}
      />

      <div className="required-field">
        {errors[formParams?.short_code]?.message || ""}
      </div>
    </span>
  );
}

export default Selectfield;
