import React from "react";
import { get_informationFromSessionID } from "../../../Component/Helper";
import JewelSmithContact from "./JEWELSMITH/JewelSmith_Contact";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import CHAINHOUSE24Contact from "./CHAINHOUSE24/CHAINHOUSE24Contact";


function CustomContactRedirection() {
  const company_code = get_informationFromSessionID("companyCode");
  const decrypted_company_code = atob(company_code);

  const Location = useQuery({
    queryKey: ["location"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.Location, "location"),
  });

  const CUSTOM_CONTACT_PAGE = (function () {
    switch (decrypted_company_code) {
      case "JEWELSMITH":
        return JewelSmithContact;
      case "CHAINHOUSE24" :
        return CHAINHOUSE24Contact
      default:
        return null;
    }
  })();

  return (
    <>
      {CUSTOM_CONTACT_PAGE ? <CUSTOM_CONTACT_PAGE
        Location={Location.data}
        isLoading={Location.isLoading}
      /> : null}
    </>
  );
}

export default CustomContactRedirection;
