import React, { useEffect,   useState } from 'react'

import { fetch_data_from_api_from_query } from '../../../../Api/APICalls';
import { useQuery } from '@tanstack/react-query';
import { ApiList } from '../../../../Api/ApiList';
import { get_bulk_settings } from '../../../../Component/Helper';
import "./LiveRateBar.css";
import DateTimeUpdate from './Components/DateTimeUpdate';
import { Link } from 'react-router-dom';
import useLiveRate from '../../../../Hooks/useLiveRate';
import useLiveRate_Socket_IO from '../../../../Hooks/useLiveRate_Socket_IO';



function LiveRateBar() {
    const [show_rate_value, set_show_rate_value] = useState(null)
    const {live_rate_homescreen_default_shortcode} = get_bulk_settings(["live_rate_homescreen_default_shortcode"])
  
    const Live_rate_query = useQuery({
        queryKey: ["live-rate"],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.Live_rates_sc, "live-rate"),
      });

      const { live_rate } = useLiveRate(live_rate_homescreen_default_shortcode);
      // const { live_rate } = useLiveRate_Socket_IO(live_rate_homescreen_default_shortcode);

      

      useEffect(() => {
        if(Live_rate_query.data && Array.isArray(Live_rate_query.data) && Live_rate_query.data.length > 0){
            const liveData = Object.values(Live_rate_query.data[0]).flat()
           if(Array.isArray(liveData)){
            const defaultLive = liveData.find(live => live.short_code === live_rate_homescreen_default_shortcode)
            if(defaultLive){
                set_show_rate_value({display_name: defaultLive?.display_name, short_code: defaultLive?.short_code})
            }
           }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [Live_rate_query.isSuccess])
      
      
      if(!show_rate_value || !live_rate){
        return null
      }
      

     
  return (
    <Link className='live-rate-updated-bar' to={"/live-rates"}>
       
        <span className='rate'>{`${show_rate_value?.display_name} RATE: `}{live_rate?.bid_rate}</span>
        <DateTimeUpdate />
    </Link>
  )
}

export default LiveRateBar