import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ChangeCurrencyModal.css";
import { get_bulk_settings, ToastSuccess } from "../../../../../../Helper";
import ic_inr from "../../../../../../../assets/images/currency/ic_inr.png";
import ic_inr_select from "../../../../../../../assets/images/currency/ic_inr_select.png";
import ic_usd from "../../../../../../../assets/images/currency/ic_usd.png";
import ic_usd_select from "../../../../../../../assets/images/currency/ic_usd_select.png";
import { useQuery } from "@tanstack/react-query";
import { ApiList } from "../../../../../../../Api/ApiList";
import { fetch_data_from_api_from_query } from "../../../../../../../Api/APICalls";
import { set_currency_details_to_local } from "./change_currency_helper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_selected_currency, set_selected_currency } from "../../../../../../../redux/Reducer/reducer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(350px, 90%)",
  bgcolor: "background.paper",
  // position: "relative",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function ChangeCurrencyModal({
  openCurrencydModal,
  handleCloseCurrencyModal,
  handleUserProfileMenuClose = () => {}
}) {
  const { default_image, cdn } = get_bulk_settings(["cdn", "default_image"]);
  const company_logo = default_image
    ? `${cdn}uploads/default_image/${default_image}`
    : "";

  // const [selected_currency, set_selected_currency] = useState(() => get_currency_details_from_local())
  const selected_currency = useSelector(get_selected_currency)
  const dispatch = useDispatch()

  
  const currency_query = useQuery({
    queryKey: ["currency"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.Currency),
  });

  const get_currency_icon = (currency) => {
      switch (currency) {
        case "Rupee" : return {icon: ic_inr, selected_icon: ic_inr_select, text: "Indian Rupee"}
        case "USD" : return {icon: ic_usd, selected_icon: ic_usd_select, text: "USD"}
        default: return {icon: ic_inr, selected_icon: ic_inr_select, text: "Indian Rupee"}
      }
  }

  const handleCurrency = (currency_data) => {
    
    dispatch(set_selected_currency(currency_data))
    set_currency_details_to_local(currency_data)
    ToastSuccess(`Curency changed to ${currency_data?.currency}`)
    handleCloseCurrencyModal()
    handleUserProfileMenuClose()
    
  }

  useEffect(() => {
    document.body.classList.add("hide-user-profile-dropdown")
  }, [])
  
  return (
    <Modal
      open={openCurrencydModal}
      onClose={() => {
       handleCloseCurrencyModal()
       handleUserProfileMenuClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="change-currency-modal"
    >
      <Box sx={style}>
        {currency_query.isLoading ? (
          null
          // <Loader isLoading={true} height={100}/>
        ) : (
          <section className="currency-section">
            {company_logo && (
              <div className="company-logo">
                <img src={company_logo} alt="company-logo" />
              </div>
            )}
            <section>
              <header>
                <h3>Select you desired currency</h3>
              </header>
              <div className="currency-container">
                {currency_query?.data?.map((currency_data) => {
                  const {currency, default: default_curency} = currency_data || {};
                  const currency_icon = get_currency_icon(currency)
                  const default_selected_curency = selected_currency && selected_currency?.currency === currency ? true : selected_currency && selected_currency?.currency !== currency ? false : default_curency === "1"
                  
                  return (
                    <div className="currency" key={currency} onClick={() => handleCurrency(currency_data)}>
                      {
                        default_selected_curency ? <img src={currency_icon.selected_icon} alt="currency" className="currency-icon" /> : 
                        <img src={currency_icon.icon} alt="currency" className="currency-icon" />
                      }
                      
                      <p className="currency-name">{currency_icon.text}</p>
                    </div>
                  );
                })}
              </div>
            </section>
          </section>
        )}
      </Box>
    </Modal>
  );
}

export default ChangeCurrencyModal;
