/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import "rc-slider/assets/index.css";
import "./assets/css/plugins/jquery-ui.min.css";
import "./assets/css/plugins/countdownTimer.css";
import "./assets/css/plugins/slick.min.css";
import "./assets/css/plugins/bootstrap.css";
import "./assets/css/plugins/nouislider.css";
import "./assets/css/backgrounds/bg-4.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/vendor/ecicons.min.css";
import "./NewStyle.css";
import "./NewResponsive.css";
import "react-phone-input-2/lib/style.css";
import "./variables.css";
import "./utils.css";
import "./main.css";
import "./Home.css";
import "./UI.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import { useState, useEffect } from "react";
import {
  checkUserAlreadyLogin,
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings,
  setSettingStylesToRootElement,
  set_informationToSessionID,
  set_settings,
} from "./Component/Helper";
import AuthRoutes from "./AuthRoutes";
import Home from "./Pages/Home/Home";
import StaticPage from "./Pages/StaticPage/StaticPage";
import MatrixViewNew from "./Pages/Shop/MatrixViewNew";
import { fetch_data_from_api_from_query } from "./Api/APICalls";
import { ApiList } from "./Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingData,
  get_is_myc_web,
  setCatMaster,
  set_catalogue_data,
} from "./redux/Reducer/reducer";
import HomeHeader from "./Component/HomeComponents/HomeHeader/HomeHeader";
import FooterSection from "./Component/HomeComponents/FooterSection/FooterSection";
import FooterNavPanel from "./Component/FooterNavPanel";
import Loader from "./Component/Loader";
import CartNew from "./Pages/Cart/CartNew";
import CheckoutNew from "./Pages/Checkout/CheckoutNew";
import Redirection from "./Redirection";
import LoginNew from "./Pages/Login/LoginNew";
import LoginRedirection from "./Pages/Login";
import RegistrationNew from "./Pages/Registration/RegistrationNew";
import { useQuery } from "@tanstack/react-query";
import FullScreenView from "./Pages/Product/FullScreenView";
import SuccessPayment from "./Pages/Payment/SuccessPayment";
import FailedPayment from "./Pages/Payment/FailedPayement";
import ShippingBilling from "./Pages/ShippingBilling/ShippingBilling";
import PreventPinchZoom from "./Component/PreventPinchZoom";
import { HelmetProvider } from "react-helmet-async";
import SEO from "./Component/SEO/SEO";
import { get_company_code_from_website_domain } from "./utils";
import AboutUs from "./Pages/AboutUs";
import Contact from "./Pages/Contact/Contact";
import CustomOrder from "./Pages/CustomOrder/CustomOrder";
import CustomOrderDetails from "./Pages/CustomOrderDetails/CustomOrderDetails";
import CustomNewOrder from "./Pages/CustomNewOrder/CustomNewOrder";
import CustomStaticPage from "./Pages/StaticPage/CustomStaticPage/CustomStaticPage";
import EditProfile from "./Pages/EditProfile/EditProfile";
import NoProduct from "./Component/NoProduct/NoProduct";
import NamedWishlist from "./Pages/NamedWishlist/NamedWishlist";
import AllOrder from "./Pages/AllOrder/AllOrder";
import AllOrderDetail from "./Pages/AllOrder/AllOrderDetail/AllOrderDetail";
import NewsUpdates from "./Pages/CustomMenu/NewsUpdates/NewsUpdates";
import LiveRates from "./Pages/LiveRates/LiveRates";
import LiveRatesReact from "./Pages/LiveRates/LiveRatesReact";
import LivePageRedirection from "./Pages/LiveRates/LivePageRedirection";


function App() {
  const params = new URL(document.location).searchParams;
  const is_myc_web = useSelector(get_is_myc_web);
  const userID = get_informationFromSessionID("userID");
  const companyCode =
    params.get("company_code") || get_company_code_from_website_domain();
  const APP_MODE = params.get("mode") === "app";
  const myc_id_params = params.get("rs");
  const [checkout_redirection, set_checkout_redirection] = useState(null);
  const helmetContext = {};
  let kam_module_title = "/";

  const [error, setError] = useState("");

  //AVOID ZOOM DEVICE WHILE TYPING (IN iPhone Only)
  if (navigator.userAgent.indexOf("iPhone") > -1) {
    document
      .querySelector("[name=viewport]")
      .setAttribute(
        "content",
        "width=device-width, initial-scale=1, maximum-scale=1"
      );
  }

  const dispatch = useDispatch();
  if (companyCode) {
    set_informationToSessionID({ companyCode });
  }

  //Fixed initial bug issue
  const exitingCompanyCodeFromStorage =
    get_informationFromSessionID("companyCode");

  if (companyCode && exitingCompanyCodeFromStorage !== companyCode) {
    sessionStorage.clear();
  } else {
    checkUserAlreadyLogin();
  }

  const [company_code, set_company_code] = useState(
    get_informationFromSessionID("companyCode")
  );

  //Change myc if myc params and myc session store is differance
  const myc_session_storage_value = get_informationFromSessionID("rs");
  //console.log(myc_session_storage_value)
  if (is_myc_web && myc_id_params && myc_session_storage_value) {
    if (myc_id_params !== myc_session_storage_value) {
      //sessionStorage.clear();
      set_informationToSessionID({ rs: myc_id_params });
    }
  }
  let myc_id = myc_id_params || myc_session_storage_value;

  if (!userID && company_code) {
    checkUserAlreadyLogin();
  }

  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );

  if (is_myc_web) {
    if (myc_id) {
      set_informationToSessionID({ rs: myc_id });
    }
  } else {
    //If There is no session and serach params then set default company setting
    if (!company_code && Object.keys(searchParams).length === 0) {
      set_informationToSessionID({ companyCode: "TElWRURFTU8=" });
      get_informationFromSessionID("companyCode");
    } else if (
      Object.keys(searchParams).length > 0 &&
      Object.keys(searchParams).includes("company_code")
    ) {
      const userID = get_informationFromSessionID("userID") || "";
      set_informationToSessionID({
        companyCode: searchParams.company_code,
        userID: userID,
      });
    }
  }

  const myc_web_query = useQuery({
    queryKey: ["myc"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.MycWeb, "mycweb", {
        myc_short_code: myc_id,
      }),
    enabled: !!is_myc_web,
  });

  const setting_query = useQuery({
    queryKey: ["setting"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.setting, "settings"),
    enabled: !!company_code,
    staleTime: Infinity,
  });

  useEffect(() => {
    setError("");
    if (
      is_myc_web &&
      myc_web_query.isSuccess &&
      myc_web_query.data?.ack === "1"
    ) {
      set_informationToSessionID({
        companyCode: myc_web_query.data?.company_code,
        catalogueID: myc_web_query.data?.my_catalogue_id,
      });
      set_company_code(myc_web_query.data?.company_code);
      //Set Master Here
      dispatch(
        setCatMaster(myc_web_query.data?.my_catalogue_data?.which_master)
      );
      dispatch(set_catalogue_data(myc_web_query.data));
    } else if (
      is_myc_web &&
      myc_web_query.isSuccess &&
      myc_web_query.data?.ack === "0"
    ) {
      setError(
        myc_web_query.data?.error.replace(/_/g, " ") || "Something went wrong"
      );
    }

    if (setting_query.isSuccess) {
      const setting = setting_query?.data[0];
      setSettingStylesToRootElement(setting);
      dispatch(getSettingData(setting));
      set_settings(setting);

      //HandleCheckout Redirection - B2B & B2C
      const { show_finalise_fields, show_shipping_billing_section } =
        get_bulk_settings(
          ["show_finalise_fields", "show_shipping_billing_section"],
          setting
        );

      // document.title = company_name;
      const redirection =
        show_finalise_fields === "Yes"
          ? "B2B"
          : show_finalise_fields === "No" &&
            show_shipping_billing_section === "Yes"
          ? "B2C"
          : "B2B";
      set_checkout_redirection(redirection);
    }
  }, [setting_query.isSuccess, myc_web_query.isSuccess]);

  document.body.style.setProperty("--device-pixel-ratio-height", `100vh`);

  if (error) {
    return <PageNotFound errorMsg={error} />;
  }

  if (is_myc_web && myc_web_query?.data?.error) {
    return <PageNotFound errorMsg={myc_web_query?.data?.error} />;
  }

  if (
    (setting_query.isSuccess && setting_query?.data[0]?.kam_module_title) ||
    get_property_settings("kam_module_title")
  ) {
    if (setting_query.isSuccess && setting_query?.data[0]?.kam_module_title) {
      kam_module_title = setting_query.data[0]?.kam_module_title
        ?.replace(/\s/g, "-")
        ?.toLowerCase();
    } else {
      kam_module_title = get_property_settings("kam_module_title")
        ?.replace(/\s/g, "-")
        ?.toLowerCase();
    }
  }

  let decryptCompanyName = "";
  if (companyCode || get_informationFromSessionID("companyCode")) {
    decryptCompanyName = atob(
      companyCode || get_informationFromSessionID("companyCode")
    );
  }

  return (
    <HelmetProvider context={helmetContext}>
      <div className="App">
        <PreventPinchZoom>
          <Router>
            {setting_query.status !== "success" ? (
              <Loader isLoading={true} />
            ) : (
              <>
                {/* <Favicon
                  url={`${setting_query?.data[0]?.cdn}uploads/favicon_image/${setting_query?.data[0]?.favicon}`}
                /> */}

                <SEO
                  title={setting_query?.data[0]?.company_name}
                  description="Powered by JewelFlow"
                />

                {APP_MODE ? null : (
                  <HomeHeader clsName={"position-relative top-0"} />
                )}
                <Routes>
                  {/* <Route
              path="/"
              element={<Navigate to={`/home?company_code=${company_code}`} />}
            /> */}

                  <Route path="/" element={<Redirection />} />

                  <Route
                    path="/matrix-page"
                    element={
                      <ProtectedRoutes>
                        <MatrixViewNew />
                      </ProtectedRoutes>
                    }
                  />
                  <Route
                    path="/product-page/:id"
                    element={
                      <ProtectedRoutes>
                        <FullScreenView />
                        {/* <FullScreenViewNew /> */}
                      </ProtectedRoutes>
                    }
                  />
                  <Route
                    path="/cart"
                    element={
                      <ProtectedRoutes>
                        <CartNew />
                      </ProtectedRoutes>
                    }
                  />
                  <Route
                    path="/short-list"
                    element={
                      <ProtectedRoutes>
                        <CartNew />
                        {/* <ShortList /> */}
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/named-wishlist"
                    element={
                      <ProtectedRoutes>
                        <NamedWishlist />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/checkout"
                    element={
                      <ProtectedRoutes>
                        {checkout_redirection === "B2C" &&
                        decryptCompanyName === "KALASHAONLINE" ? (
                          <ShippingBilling />
                        ) : checkout_redirection === "B2B" ? (
                          <CheckoutNew />
                        ) : (
                          <NoProduct errMsg="No Page Found" />
                        )}
                        {/* <Checkout /> */}
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/edit-profile"
                    element={
                      <ProtectedRoutes>
                        <EditProfile />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/home"
                    element={
                      <ProtectedRoutes>
                        <Home />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/static-page/*"
                    element={
                      <ProtectedRoutes>
                        <StaticPage />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/static-page/our-legacy/:staticID"
                    element={<CustomStaticPage />}
                  />

                  <Route
                    path="/static-page/services/:staticID"
                    element={<CustomStaticPage />}
                  />

                  <Route
                    path={`/${kam_module_title}`}
                    element={
                      <ProtectedRoutes>
                        <CustomOrder />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path={`/${kam_module_title}/:order_no`}
                    element={
                      <ProtectedRoutes>
                        <CustomOrderDetails />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path={`/${kam_module_title}/new-${kam_module_title}`}
                    element={
                      <ProtectedRoutes>
                        <CustomNewOrder />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/all-order"
                    element={
                      <ProtectedRoutes>
                        <AllOrder />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/all-order/:order_id"
                    element={
                      <ProtectedRoutes>
                        <AllOrderDetail />
                      </ProtectedRoutes>
                    }
                  />

                  <Route path="/news-updates" element={<NewsUpdates />} />

                  {/* <Route path="/live-rates" element={<LiveRates />} /> */}
                  {/* <Route path="/live-rates" element={<LiveRatesReact />} /> */}
                  <Route path="/live-rates" element={<LivePageRedirection />} />

                  <Route
                    path="/registration"
                    element={
                      <AuthRoutes>
                        {/* <Registration /> */}
                        <RegistrationNew />
                      </AuthRoutes>
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <AuthRoutes>
                        <ForgotPassword />
                      </AuthRoutes>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <AuthRoutes>
                        <LoginRedirection />
                        {/* <LoginNew /> */}
                        
                      </AuthRoutes>
                    }
                  />
                  <Route
                    path="/success"
                    element={
                      <ProtectedRoutes>
                        <SuccessPayment />
                      </ProtectedRoutes>
                    }
                  />

                  <Route
                    path="/failed"
                    element={
                      <ProtectedRoutes>
                        <FailedPayment />
                      </ProtectedRoutes>
                    }
                  />

                  <Route path="/about-us" element={<AboutUs />} />

                  <Route path="/contact-us" element={<Contact />} />

                  <Route
                    path="*"
                    element={
                      <ProtectedRoutes>
                        <PageNotFound />
                      </ProtectedRoutes>
                    }
                  />
                </Routes>
                {APP_MODE ? null : <FooterNavPanel isLoggedIn={true} />}
                {APP_MODE ? null : <FooterSection />}
              </>
            )}
          </Router>
        </PreventPinchZoom>
      </div>
    </HelmetProvider>
  );
}

export default App;
