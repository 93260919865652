import { useEffect, useRef } from "react"
import { get_property_settings } from "../../../../Component/Helper"
import UserPreferenceModal from "../../../../Component/UserPreferenceModal/UserPreferenceModal"
import { useSelector } from "react-redux"
import { get_open_preferences_modal } from "../../../../redux/Reducer/reducer"


export default function UserPreferenceHome() {

  let has_user_preferences = get_property_settings("has_user_preferences")
  has_user_preferences = has_user_preferences === "1"

  const open_user_preference_modal_ref = useRef(null)
  const open_preferences_modal = useSelector(get_open_preferences_modal)
  useEffect(() => {
    if(has_user_preferences && open_preferences_modal && open_user_preference_modal_ref.current){
      setTimeout(() => {
        open_user_preference_modal_ref.current?.open_modal()
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  if(!has_user_preferences){
    return null
  }
  
  return (
    <UserPreferenceModal has_user_preferences = {has_user_preferences} ref={open_user_preference_modal_ref}/>
  )
}
