import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import LoginContainer from "../Login/Components/LoginContainer";

function LoginPopUp_for_secure_user_modal({ showLoginPopup }) {
  const [showOptContainer, setShowOptContainer] = useState(false);
  return (
    <Modal show={showLoginPopup} className="login-popup-modal-matrix">
      <Modal.Body>
        
        <div className="ec-login-wrapper login-wrapper">
          <LoginContainer
            showOptContainer={showOptContainer}
            setShowOptContainer={setShowOptContainer}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoginPopUp_for_secure_user_modal;
