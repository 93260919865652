import dayjs from "dayjs"
import { useFormContext } from "react-hook-form";


function DateInput({field}) {
  const {field_name, short_code, required_field, control_value} = field || {}
  const { formState: {errors}, register } = useFormContext() 

  let selectedValue = dayjs().format('YYYY-MM-DD');
  if(Array.isArray(control_value) && control_value.length > 0){
    const date = control_value[0].split('-')
    selectedValue = `${date.reverse().join('-')}`
  }
  
  return (
    <div className="form-input-group">
      <label>
        <span>{field_name}</span>
        {required_field === "1" && <span className="required">*</span>}
      </label>
      
      <input type="date" className="date-calender" min={selectedValue} defaultValue={selectedValue} {...register(short_code, {
              required: {
                value: required_field === "1",
                message: `${field_name} is required`
              }
            })}/>
      {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default DateInput;
