import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import verified from "../assets/images/verified.png";
import { get_property_settings } from "./Helper";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";

const Notifier = ({
  show,
  header,
  showFooter,
  title,
  subtitle,
  order_url,
  setShowOrderSuccess,
}) => {
  let show_qr_code_for_past_orders = get_property_settings(
    "show_qr_code_for_past_orders"
  );
  show_qr_code_for_past_orders = show_qr_code_for_past_orders === "Yes";
  const navigate = useNavigate();
  
  const onHide = (e) => {
    const {name} = e?.target || {};
   
     if (name === "View PDF" && show_qr_code_for_past_orders) {
      window.open(order_url, "_blank");
    }
    navigate("/home");
    setShowOrderSuccess(false);
  };

  return (
    <Modal
      show={show}
      centered
      // onExit={() => setShow(false)}
      close
      onHide={onHide}
      className="notifier"
    >
      <ModalHeader closeButton>
        <ModalTitle className="fw-bold fs-3">{header}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <img src={verified} alt="" className="verified" />
        {title && (
          <>
            <h3>
              <b>{title}</b>
              {/* <b>Your Order (#2014) has been placed successfully</b> */}
            </h3>
          </>
        )}
        <span className="label">{subtitle}</span>
        {/* <span className="label">You will recieve your order update shortly.</span> */}
        {show_qr_code_for_past_orders && !!order_url ? (
          <div class="qr-code-container">
            <QRCode
              size={window.innerWidth > 768 ? 256 : 200}
              value={order_url}
            />
          </div>
        ) : null}
      </ModalBody>
      {showFooter && (
        <ModalFooter style={{ width: "100%", justifyContent: "center" }}>
          
            <button
              className="btn btn-primary button_background_color modal-btn"
              onClick={onHide}
              style={{ minWidth: "min(180px, 100%)", width: "100%" }}
              name={show_qr_code_for_past_orders ? "View PDF" : "OK"}
            >
              {show_qr_code_for_past_orders ? "View PDF" : "OK"}
            </button>
          
        </ModalFooter>
      )}
    </Modal>
  );
};

export default Notifier;
