import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { get_property_settings } from "./Helper";

const PhoneNumberInput = ({ handleChange, onBlur, short_code, tabIndex }) => {
  const country_name_shortcode = get_property_settings("country_name_shortcode")
  
  const [value, setValue] = useState();
  const getNumber = async (phoneNumber, country) => {
    const countryCode = country.dialCode;
    let obj = {};
    if (short_code === "whats_app_no") {
      obj = {
        whats_app_no: phoneNumber,
        // country_code: `+${countryCode}`,
      };
    } else {
      obj = {
        mobile_no: phoneNumber.replace(countryCode, ""),
        country_code: `+${countryCode}`,
      };
    }
    handleChange(`+${countryCode}`, "country_code", obj);
    // handleChange(phoneNumber.replace(countryCode, ""), "mobile_no");
    setValue(phoneNumber);
  };
  
  
  return (
    
    <PhoneInput
      placeholder=""
      // countryCodeEditable={false}
      // country={value ? undefined : "in"} // Set country to "in" when value is empty
      country={(country_name_shortcode && country_name_shortcode.toLowerCase()) || 'in'}
      // defaultMask={}
      value={value}
      // autoFormat={false}
      onChange={(phoneNumber, country) => {
        if (phoneNumber === "") {
          // If the input is empty, set the country to "in"
          getNumber("", "in");
        } else {
          getNumber(phoneNumber, country);
        }
      }}
      inputProps={{ tabIndex: tabIndex ? tabIndex : 1 }}
      // onlyCountries={["in", "at"]}
      masks={{ in: ".........." }}
      // alwaysDefaultMask={true}
      // masks={"9999 999999"}
      onBlur={onBlur}
      inputClass="number-input fieldValue"
      buttonClass="contryCodeDropDown"
    />
  );
};

export default PhoneNumberInput;
