import { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

function WeightInputs({ field }) {
  const { field_name, required_field, short_code, default_select } = field || {};
  const {
    formState: { errors },
    control,
    setValue
  } = useFormContext();

  useEffect(() => {
    if(default_select && default_select.includes("-")){
        const value = default_select.split("-")
        setValue(`from_${short_code}`, value[0])
        setValue(`to_${short_code}`, value[1])
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  

  return (
    <div className="form-input-group">
      <label>
        <span>{field_name}</span>
        {required_field === "1" && <span className="required">*</span>}
      </label>
      <div className="input-weight-group">
        <div className="from input-section">
          <span className="text">From</span>
          <Controller
            rules={{
              required: {
                value: required_field === "1",
                message: `${field_name} is required`
              }
            }}
            control={control}
            name={`from_${short_code}`}
            render={({ field }) => <NumericFormat {...field} />}
          />
        </div>
        <span className="divider">-</span>
        <div className="to input-section">
          <span className="text">To</span>
          <Controller

            control={control}
            name={`to_${short_code}`}
            rules={{
              required: {
                value: required_field === "1",
                message: `${field_name} is required`
              }
            }}
            render={({ field }) => <NumericFormat {...field} />}
          />
        </div>
      </div>
      {(errors[`from_${short_code}`] || errors[`to_${short_code}`]) && (
        <p className="error-input">{field_name} is required</p>
      )}
    </div>
  );
}

export default WeightInputs;
