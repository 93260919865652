import { Helmet } from 'react-helmet-async';
import { get_bulk_settings } from '../Helper';
export default function SEO({title, description}) {

const {cdn, whatsapp_thumbnail_image, favicon} = get_bulk_settings(["cdn", "whatsapp_thumbnail_image", "favicon"])

return (
<Helmet>

{ /* Standard metadata tags */ }

<title>{title}</title>
<meta name='description' content={description} />
<link rel="icon" href={`${cdn}uploads/favicon_image/${favicon}`} />
{ /* End standard metadata tags */ }



{ /* Facebook tags */ }
<meta property="og:site_name" content="Download App"/>
<meta property="og:title" content={title}/>
<meta property="og:description" content={description}/>
<meta property="og:locale" content="en_GB" />
{whatsapp_thumbnail_image && <meta property="og:image" content={`${cdn}uploads/whatsapp_thumbnail/${whatsapp_thumbnail_image}`}  />}
{whatsapp_thumbnail_image && <meta property="og:image:url"  content={`${cdn}uploads/whatsapp_thumbnail/${whatsapp_thumbnail_image}`} />}
{ /* End Facebook tags */ }



{ /* Twitter tags */ }

{/* <meta name="twitter:creator" content={name} />
<meta name="twitter:card" content={type} />
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} /> */}

{ /* End Twitter tags */ }
</Helmet>
)
}