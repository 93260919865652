import dayjs from "dayjs";
import { get_property_settings } from "../../../Component/Helper";
import VideoComponent from "./VideoComponent";


function NewsUpdatesCards({
  news_date,
  image_name,
  video_name,
  title,
  description,
}) {
  const formated_date = dayjs(news_date).format("ddd, DD MMM YYYY");
  const cdn = get_property_settings("cdn");
  const image = /(.png|.jpg|.jpeg)$/.test(image_name)
    ? `${cdn}${image_name}`
    : null;
  const video = /(.mp4)$/.test(video_name) ? `${cdn}${video_name}` : null;
  const p = description.split("\n");
 
  
  



  return (
    <div className="news-updates-card">
      <div className="image-section">
        {image ? (
          <img src={image} alt="" />
        ) : video ? (
        <VideoComponent video={video}/>
        ) : null}
      </div>

      <div className="details-section">
        <h3 className="news-title">{title}</h3>
        <span className="news-date">{formated_date}</span>
        {p.map((para, index) => (
          <p className="news-desc" key={index}>
            {para}
          </p>
        ))}
      </div>
    </div>
  );
}

export default NewsUpdatesCards;
