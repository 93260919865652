import React from 'react'
import payment from '../../../assets/images/payment.png'

function FooterPayment() {
  return (
    <div className="footer-bottom-right">
        <div className="footer-bottom-payment d-flex justify-content-center footer-payments">
            <div className="payment-link">
                <img src={payment} alt="payment"/>
            </div>

        </div>
    </div>
  )
}

export default FooterPayment
