import React from "react";
import { sentenceCase } from "../../../utils";

function Textfield({formParams, clsName = "",  errors, onChange, value}) {
   
  return (
    <span className={clsName}>
      <label className="label">
        {formParams.field_name}
        {formParams?.required === "1" && <span className="required-field">*</span>}
      </label>

      <input
        // tabIndex={i + 1}
        className="RegInp fieldValue"
        type={formParams?.short_code === "email_id" ? "email" : "text"}
        name={formParams?.short_code}
        placeholder={`Enter ${formParams.field_name}`}
        onChange={onChange}
        value={formParams?.short_code === "full_name" ? sentenceCase(value) : value}
      />
      <div className="required-field">
        {errors[formParams?.short_code]?.message || ""}
      </div>
    </span>
  );
}

export default Textfield;
