import React from 'react'
import StartDotsSelected from "../../../../assets/images/starDots_selected.svg"
import StartDots from "../../../../assets/images/starDots.svg"
import "./CustomPadding.css"

const CustomDots = () => {
    return (
      
        <div className={`custom-banner-star-dot`}>
          <img src={StartDotsSelected} alt="" className='start-dot-img selected'/>
          <img src={StartDots} alt="" className='start-dot-img'/>
        </div>
      
    );
  };
  
  export default CustomDots;

