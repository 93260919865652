import React, { useEffect, useState } from 'react'
import TwoColPage from '../../_CommonPage/TwoColPage'
import EnquiryFormModal from '../../_CommonPage/EnquiryFormModal';
import { ScrollToTop } from '../../_CommonPage/ScrollToTop';

const styles = {
    padding: "1.5em",
    width: "min(1440px, 100%)",
    margin: "0 auto"
}


function Customization() {
    const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const CustomizationImg = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/Customitzation/Customization.jpg";
  
  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className="customization-page" style={styles} >
        {open && <EnquiryFormModal open={open} handleClose={handleClose} related_enquiry="Customization">
        I authorize Kalasha Jewels and its representatives to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DND / NDNC.
            </EnquiryFormModal>}
        <TwoColPage>
        <div className='image-section'>
            <img src={CustomizationImg} alt="pic" />
        </div>

        <div className='detail-section'>
            <header>
                <h2>CUSTOMIZATION</h2>
            </header>
            <div className="info">
            <p>Your imagination is our design. Get your visualization take life at Kalasha. Get customised jewellery designs just like the way you want, think of the best we give you the finest. At Kalasha we finely craft, closely observe and precisely design your dream ideas to perfection.</p>
            </div>
            <div className="btn" type="reset" onClick={handleOpen}>Book an appointment now</div>
        </div>
            
        </TwoColPage>
    </section>
  )
}

export default Customization