import React, { useEffect, useState } from "react";
import "./NoProduct.css";
import noProd from "../../assets/images/no-prod.png";
import secure_image from "../../assets/images/secure-image.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { get_informationFromSessionID, show_custom_order_menu } from "../Helper";
import { get_myc_is_secured } from "../../redux/Reducer/reducer";
import { useSelector } from "react-redux";

function NoProduct({ errMsg, showLoader, showLoginPopup, request_access, hideHomeBtn }) {
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const company_code = get_informationFromSessionID("companyCode");
  const userID = get_informationFromSessionID("userID");
  const location = useLocation()

  const is_myc_secure = useSelector(get_myc_is_secured);

  const errorMsg =
    (is_myc_secure && request_access) || (is_myc_secure && !userID)
      ? "You are not authorized"
      : errMsg;
  const image =
    (is_myc_secure && request_access) || (is_myc_secure && !userID)
      ? secure_image
      : noProd;
  // const errorDetail =
  //   (is_myc_secure && request_access) || (is_myc_secure && !userID)
  //     ? "You're not authorized to access this page"
  //     : "Whooops... this information is not available for a movement";
  const kamPath = show_custom_order_menu().label

  const noScroll = location.pathname.includes(kamPath.toLowerCase().replace(/\s/g, "-"))
  
  useEffect(() => {
    if(!noScroll) window.scrollTo(0,0);

    if (!showLoader) return;
    if (showLoginPopup) {
      setLoader(false);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="noprod-container">
      {showLoader && loader ? (
        <Loader isLoading={true} />
      ) : (
        <>
          <img src={image} alt="no-prod" />
          <h2>{errorMsg}</h2>
          {/* <p>{errorDetail}</p> */}
          {hideHomeBtn ? null : !is_myc_secure && company_code ? (
            <button onClick={() => navigate("/")}>Go Home</button>
          ) : null}
        </>
      )}
    </div>
  );
}

export default React.memo(NoProduct);
