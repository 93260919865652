import React, { useContext, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { get_custom_order_items } from '../../../redux/Reducer/reducer'
import { CustomOrderFileContext } from '../../CustomOrder/Context/CustomOrderContext'
import { BeatLoader } from 'react-spinners'


function CustomNewOrderFooter(props , ref) {
  const MobileNavBarHeight = sessionStorage.getItem("MobileNavBarHeight") ? sessionStorage.getItem("MobileNavBarHeight") + "px" : "0px"
 
  const {file, setFile} = useContext(CustomOrderFileContext)

  useImperativeHandle(ref, () => (
    {
      imageFile: file,
      setImageFile: setFile
    }
  ))
  
  const items = useSelector(get_custom_order_items)
  const is_single_width = items.every(item => !!item?.single_wt)
  
  let singleWt = ""
  if(is_single_width){
    singleWt = items.reduce((total, curVal) => {
      total += Number(curVal.single_wt)
      return total
    }, 0)
  }
  const TotalWt = items.reduce((obj, currVal) => {
    obj.from_wt += (Number(currVal.from_wt) * Number(currVal.quantity));
    obj.to_wt += (Number(currVal.to_wt) * Number(currVal.quantity));
    return obj
  }, {from_wt: 0, to_wt: 0})

  
  return (
    <footer style={{bottom: MobileNavBarHeight}}>
        <div className="footer-wrapper">
          <div className='total-wt'>
              <span>Total Wt</span>
              {
                is_single_width ? (<h4>{typeof singleWt === "number" ? singleWt.toFixed(3) : singleWt || 0}</h4>) : (

                  <h4>{TotalWt?.from_wt || 0}.000 to {TotalWt?.to_wt || 0}.000</h4>
                ) 
              }
          </div>
          {<button className='place-order-btn ui button-ui' type='button' disabled={props.placingOrder}
            onClick={props.place_order}
          >{props.placingOrder ? <BeatLoader color={'rgba(255,255,255,0.7)'}/> : "PLACE ORDER"}</button>}
        </div>
    </footer>
  )
}

export default React.forwardRef(CustomNewOrderFooter)