import { useQuery } from '@tanstack/react-query';
import { useFormContext, Controller } from 'react-hook-form';
import Select from "react-select";
import { fetch_data_from_api_from_query } from '../../../Api/APICalls';
import { ApiList } from '../../../Api/ApiList';
import { createSelectArrayForReactSelect, get_property_settings } from '../../Helper';
import { useEffect } from 'react';
import { get_currency_details_from_local } from '../../HomeComponents/HomeHeader/components/UserProfileDropDownMenu/Components/ChangeCurrencyModal/change_currency_helper';

function Currency({fields}) {
    const {field_name, short_code, required, currency_id} = fields || {};
    const { formState: {errors}, control, setValue} = useFormContext()
    const primary_BG_color = get_property_settings("btn_primary_color");

    const currency_query = useQuery({
        queryKey: ["currency"],
        queryFn: () => fetch_data_from_api_from_query(`${ApiList.Currency}`, "currency").then(res => {
            return createSelectArrayForReactSelect(res, "currency", "id")
          }),
     });

     useEffect(() => {
        if(!currency_query?.data) return;
        const get_currency_value_from_local = get_currency_details_from_local()
        if(get_currency_value_from_local){
          const selected_currency = currency_query?.data.find(currency => currency.id === get_currency_value_from_local.id)
          setValue(short_code, selected_currency)
        }else{
          const selected_currency = currency_query?.data.find(currency => currency.id === currency_id) || currency_query?.data[0]
          setValue(short_code, selected_currency)
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [currency_query?.data])


  return (
    <div className='input-group dropdown'>
        <label htmlFor={short_code}>
          <span>{field_name}</span>
          {required === "1" && <span className="required">*</span>}
        </label>
        <Controller
        control={control}
        name={short_code}
        rules={{
          required:{
            value: required === "1",
            message: `${field_name} is required`
          }
        }}
        render={({ field }) => (
          <Select
            className="react-select-container r-container"
            classNamePrefix="react-select r-select"
            id={short_code}
            options={currency_query?.data || []}
            {...field}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25:
                  (primary_BG_color &&
                    primary_BG_color
                      .replace("rgb", "rgba")
                      .replace(")", ",0.2)")) ||
                  "inherit",
                primary: primary_BG_color,
              },
            })}
          />
        )}
        />
        {errors[short_code] && <p className="error-text">{errors[short_code]?.message}</p>}
    </div>
  )
}

export default Currency