import React from 'react'

function ScanToDownload() {
  return (
    <div className='scan-to-download-section'>
        <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/images/barcodes.png" alt="scan" />
    </div>
  )
}

export default ScanToDownload