import { useEffect } from "react";
import { NumericFormat } from 'react-number-format';
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";
import { oms_wight_calculation } from "../../custom_order_utils";

function TextInputWithText({field, add_items_by, production_category, size_chart}) {
  const {field_name, short_code, required_field, control_value, default_select, default_unit} = field || {}
  const { register, control, formState: { errors }, setValue, watch} = useFormContext()
  const calculate_oms_weight_based_on_design_no = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.calculate_oms_weight_based_on_design_no)
  const pcs_watch = useWatch({control, name: short_code})
  // const pcs_watch = watch(short_code)

  

  useEffect(() => {
    if(control_value){
      setValue("unit_of_measurement", default_unit || control_value[0])
    }
    if(default_select){
      setValue(short_code, default_select)
    }
    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!calculate_oms_weight_based_on_design_no || add_items_by !== "by design no" || !pcs_watch) return
    
    const updated_weight = oms_wight_calculation(production_category, size_chart, watch)
    setValue("single_wt", updated_weight)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcs_watch])
  
  return (
    <div className="form-input-group">
      <label>
        <span>{field_name}</span>
        {required_field === "1" && <span className="required">*</span>}
      </label>
      <div className="input-group text-input-group">
      <Controller
        rules={{
          required: {
            value: required_field === "1",
            message: `${field_name} is requried`
          }
        }} 
        control={control}
        name={short_code}
        render={({ field }) => (
          <NumericFormat 
          {...field}
          defaultValue={default_select}
          />
        )}
      />
     
     
        
        
          <select className="select-drop text" {...register("unit_of_measurement")}>
          {
            control_value.map(value => <option value={value} key={value} >{value}</option>)
          }
            
            
          </select>
      </div>
      {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default TextInputWithText;
