import { MenuItem } from '@mui/material'
import React, { useRef, useState } from 'react'
import { MdSettings } from "react-icons/md";
import UserPreferenceModal from '../../../../../../UserPreferenceModal/UserPreferenceModal';

function UserPreferencesMenu({handleClose}) {
  const open_user_preference_modal_ref = useRef(null)
  return (
    <>
    
        <UserPreferenceModal
          handleUserProfileMenuClose={handleClose}
          ref={open_user_preference_modal_ref}
        />
      
    <MenuItem className="menu-list" 
    
    onClick={() => {
        if(open_user_preference_modal_ref.current){
          open_user_preference_modal_ref.current?.open_modal()
        }
      }}>
        <MdSettings className="icon" />
        <span>Default Preferences</span>
      </MenuItem>
    </>
  )
}

export default UserPreferencesMenu