import React from 'react'
import "./Contact.css"
import ContactLocation from './components/ContactLocation'
import WhatsappIconNew from '../../Component/WhatsappIcon/WhatsappIconNew'
import { getCustomValue } from '../../Custom/CompanyList'
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../Custom/CompanyCustomization'
import CustomContactRedirection from './Custom_Contact/Custom_Contact_Redirection'
import ExternalHTML from '../../Component/ExternalHTML/ExternalHTML'
import { get_informationFromSessionID } from '../../Component/Helper'

function Contact() {

  let custom_menu = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.custom_menu)
  
  const is_custom_link_contact = Array.isArray(custom_menu) && custom_menu.find(menu => menu.link === "/contact-us" && menu.custom_link ) ? true : false
  const is_external_iframe_link = Array.isArray(custom_menu) && custom_menu.find(menu => menu.link === "/contact-us" && menu.external_iframe_link ) ? true : false

  const companyCode = get_informationFromSessionID("companyCode");
  const decryptCompanyName = atob(companyCode);
  
  const URL = () => {
    switch(decryptCompanyName){
      case "CHAINHOUSE24": return "https://prodone.jewelflow.pro/chainhouse/frontend/Contact_Us"; 
      default: return ""
    }
  }

  return (
    <>
    <WhatsappIconNew />
    {is_custom_link_contact ? <CustomContactRedirection /> : (<div className='contact-us'>
      {
        is_external_iframe_link ? <ExternalHTML url={URL()}/> : <ContactLocation/> 
      }
       
    </div>)}
    </>
  )
}

export default Contact
