import { Link } from "react-router-dom";
import "./About_Us_Section.css"
import { get_bulk_settings } from "../../Helper";
function About_Us_Section({title, children, url = "/about-us"}) {
    const {company_name, cdn, about_us_page_image} = get_bulk_settings(["company_name", "cdn", "about_us_page_image"])
    const img = `${cdn}uploads/about_us_page_image/${about_us_page_image}`
    return (
        <section className="common-home-about-section">
          <div className="about-image-section" style={{backgroundImage: `url(${img})`}}>
            <img
              src={img}
              alt="4003-web-banner1"
            />
          </div>
    
          <div className="about-detail-section">
            <h2>{title || company_name}</h2>
            {children}
            <Link to={url} className="explore-btn">Know More</Link>
              
          </div>
        </section>
      );
}

export default About_Us_Section