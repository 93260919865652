
import { useEffect, useRef } from "react";
import Slider from "react-slick";
import "./ReactSlider.css"
const defaultSettings = {
    dot: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
    
    

};




function ReactSlider({children, additionalSetting, responsive, className, pauseDuration}) {

  let sliderRef = useRef(null);

  useEffect(() => {
    if(!pauseDuration || pauseDuration?.length === 0 || !sliderRef) return;
          sliderRef.slickPause();
          setTimeout(() => {
            sliderRef.slickPlay();
          }, (pauseDuration[0] * 1000))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    // <div className="react-global-slider">
        <Slider {...defaultSettings} {...additionalSetting}
        responsive={responsive || null}
        className={className || ""}
        ref={slider => (sliderRef = slider)}
       
        beforeChange={(_, currentSlide) => {
          if(!pauseDuration || pauseDuration?.length === 0 || !sliderRef) return;
          sliderRef?.slickPause();
          setTimeout(() => {
            sliderRef?.slickPlay();
          }, (pauseDuration[currentSlide] * 1000))

          // if(currentSlide === 0){
          //   sliderRef.slickPause();
          //   setTimeout(() => {
          //     sliderRef.slickPlay();
          //   }, 20000)
          // }
        }}
        >
            {children}
        </Slider> 
    // </div>
  )
}

export default ReactSlider
