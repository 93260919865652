/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import leftArrow from "../../assets/images/left-angle.png";
import rightArrow from "../../assets/images/right-angle.png";
import ArrowKeysReact from "arrow-keys-react";
import {
  createSearchURLParams,
  debounce,
  get_designStatus,
  get_informationFromSessionID,
  get_property_settings,
  set_informationToSessionID,
} from "../../Component/Helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import NoProduct from "../../Component/NoProduct/NoProduct";
import ProductPreview from "./components/ProductPreview/ProductPreview";
import FSVFooter from "./components/FSVFooter/FSVFooter";
import FSVDetails from "./components/FSVDetails/FSVDetails";
import FSVInputs from "./components/FSVInputs/FSVInputs";
import { FSV_ACTIONS, merge_product_and_subproduct } from "./Helper";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import {
  getCatMaster,
  get_is_myc_web,
  set_FSV_Handler,
} from "../../redux/Reducer/reducer";
import Loader from "../../Component/Loader";
import { useQuery } from "@tanstack/react-query";
import FSVSimilerProductCard from "./components/FSVSimilerProductCard/FSVSimilerProductCard";
import FSVCustomVariantDetails from "./components/FSVCustomVariantDetails/FSVCustomVariantDetails";
import { getCustomValue } from "../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../Custom/CompanyCustomization";
import FSVScrollToView from "./components/ProductPreview/components/FSVScrollToView";
import { ScrollToTopCustom } from "../../utils";

function FullScreenView() {
  const navigate = useNavigate();
  const location = useLocation();
  const fsvContainerRef = useRef(null);
  const FSVTopOffset = useRef(null);
  const next_prev_btn_ref = useRef(null);
  const { id } = useParams();
  const [page, setPage] = useState(id);
  const [hideArrow, setHideArrow] = useState(parseInt(id) === 0 ? "left" : "");
  const [RTL, setRTL] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  // const [AllProducts, setAllProducts] = useState([]);

  const userId = get_informationFromSessionID("userID");
  const is_myc_web = useSelector(get_is_myc_web);
  const master = useSelector(getCatMaster);

  const design_status = get_designStatus(master);
  const filterParams = get_informationFromSessionID("filterParams") || {};
  const total_product_count =
    parseInt(get_informationFromSessionID("totalProductCount")) || 0;

  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const defaultData = get_property_settings("default_matrix_page_load");

  const dispatch = useDispatch();

  const show_fsv_custom_varient_details = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_fsv_custom_varient_details)

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  //(Don't remove enable when image preview zoomType = "hover" [side hover zoom], <HoverImageZoomPreview/> )
  // const { scrollYProgress } = useScroll({
  //   target: zoomTargetRef
  // });
  //const top = useTransform(scrollYProgress, [0, 1], ["0px", `${window.innerHeight}px`]);
  if(filterParams.page && page){
    filterParams.page = id || filterParams.page
  }
  
  const {
    isLoading,
    isRefetching,
    data: AllProducts,
    isError,
  } = useQuery({
    queryKey: [page, id, searchParams?.mode, searchParams, "full-screen-view"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fullScreen, "full-screen-view", {
        user_id: userId || "",
        collection_id: 0,
        data: 1,
        table: master,
        design_status: design_status,
        cat_id: searchParams?.cat_id || 0,
        ...searchParams,
        ...filterParams,
        mode: is_myc_web ? "catalogue_filter" : searchParams?.mode || "filter",
        page: searchParams?.mode === "homescreen" ? 0 : id || page,

        // page: searchParams?.mode === "homescreen" ? 0 : page,
      }).then((products) => {

       
        
        if (products[0]?.subproducts?.length > 0) {
          const products_with_subproducts = merge_product_and_subproduct(
            products[0]
          );
          dispatch(
            set_FSV_Handler({
              type: FSV_ACTIONS.SET_PRODUCTS,
              payload: products_with_subproducts,
            })
          );
          dispatch(
            set_FSV_Handler({
              type: FSV_ACTIONS.SET_SELECTED_PRODUCT,
              payload: products_with_subproducts[0],
            })
          );

          return products_with_subproducts;
        } else {
          dispatch(
            set_FSV_Handler({
              type: FSV_ACTIONS.SET_PRODUCTS,
              payload: products,
            })
          );
          dispatch(
            set_FSV_Handler({
              type: FSV_ACTIONS.SET_SELECTED_PRODUCT,
              payload: products[0],
            })
          );

          return products;
        }
      }),
    initialData: [],
  });

  
  const nextProduct = (page, sort, cat_id) => {
    const nextpage = parseInt(page) + 1;
    if (hideArrow !== "right") {
      setPage(nextpage);
    }
    hideArrow !== "right" &&
      navigate(createSearchURLParams(`product-page/${nextpage}`, {...searchParams}));
      // navigate(`/product-page/${nextpage}?sort=${sort}&cat_id=${cat_id}`);
  };
  const previousProduct = (page, sort, cat_id) => {
    const prevpage = parseInt(page) - 1;
    if (hideArrow !== "left") {
      setPage(prevpage);
    }

    hideArrow !== "left" &&
      navigate(createSearchURLParams(`product-page/${prevpage}`, {...searchParams}));
      // navigate(`/product-page/${prevpage}?sort=${sort}&cat_id=${cat_id}`);
  };

  const handleDebouncedKeyPress = 
  (
    debounce((e) => {
      if (!isLoading) {
        if (e.key === "Escape") {
          const filter = get_informationFromSessionID("filter") || {
            sort: 0,
            page: 0,
          };
          const gotopage = Math.ceil((parseInt(id) + 1) / defaultData) - 1 || 0;

          const matrixPagePath = createSearchURLParams("matrix-page", {
            ...filter,
            ...filterParams,
            cat_id: searchParams?.cat_id,
            page: gotopage,
          });
          const currProd = get_informationFromSessionID("currProd");

          navigate(matrixPagePath, { state: location?.pathname, currProd });
        }
      }
    }, 300), // Adjust the delay (in milliseconds) as needed
    [id]
  );

  useEffect(() => {
  
    sessionStorage.setItem("FSV_Swipe", JSON.stringify(true));
    const id = Number(page);
    if (id === 0) {
      setHideArrow("left");
    } else if (id === total_product_count - 1) {
      setHideArrow("right");
    } else {
      setHideArrow("");
    }

    fsvContainerRef?.current?.focus();

    document.addEventListener("keydown", handleDebouncedKeyPress);

    set_informationToSessionID({ currProd: Number(page) });
    dispatch(
      set_FSV_Handler({
        type: FSV_ACTIONS.SET_PRODUCT_CALC,
        payload: { action: "reset" },
      })
    );
    return () => {
      document.removeEventListener("keydown", handleDebouncedKeyPress);
    };
  }, [page]);

  useEffect(() => {
    if (!next_prev_btn_ref?.current) return;
    const next_prev_btn_coords =
      next_prev_btn_ref?.current.getBoundingClientRect();
    const show_zoom_on_Fsv_web =
      get_property_settings("show_zoom_on_Fsv_web") === "Yes";

    if (!show_zoom_on_Fsv_web) {
      next_prev_btn_ref.current.style.top = "200px";
    } else {
      const MobileNavBarHeight = Number(
        sessionStorage.getItem("MobileNavBarHeight") || 0
      );
      const FSVFooterHeight = Number(
        sessionStorage.getItem("FSVFooterHeight") || 0
      );
      const next_prev_btn_top_value =
        (window.innerHeight -
          (next_prev_btn_coords?.y + MobileNavBarHeight + FSVFooterHeight)) /
        2;

      next_prev_btn_ref.current.style.top = next_prev_btn_top_value + "px";
    }
  }, [next_prev_btn_ref?.current]);

  useEffect(() => {
    ScrollToTopCustom()
   return () => dispatch(set_FSV_Handler({type: FSV_ACTIONS.RESET_ALL}))
  }, [])
 
  // const isFSV = Object.values(document.activeElement.classList).includes("fsv-container")
  ArrowKeysReact.config(
    searchParams?.mode === "homescreen"
      ? {}
      : {
          left: () => {
            previousProduct(page, searchParams?.sort, searchParams?.cat_id);
          },
          right: () => {
            nextProduct(page, searchParams?.sort, searchParams?.cat_id);
          },
        }
  );
  
 

  return (
    <>
      
      {!isLoading && !isRefetching && AllProducts?.length > 0 ? (
        <div
          {...ArrowKeysReact.events}
          tabIndex="1"
          className="products-page fsv-container position-relative"
          id="products-page"
          ref={fsvContainerRef}
          onLoad={(e) => {
            if(!is_myc_web){
              fsvContainerRef?.current?.focus();

            }
            const FSV_Page_Height = document.getElementById("products-page");
            FSVTopOffset.current = (FSV_Page_Height.getBoundingClientRect()?.y)
            sessionStorage.setItem(
              "FSV_Page_Height",
              FSV_Page_Height.offsetHeight
            );

            

          }}
        >
          {searchParams?.mode !== "homescreen" && total_product_count > 1 && (
            <div
              // className="next-prev-buttons-container next-prev-buttons-container-sm d-none d-md-flex "
              className="next-prev-buttons-container next-prev-buttons-container-sm d-flex "
              ref={next_prev_btn_ref}
            >
              <div id="left-button" className="next-prev-button-container prev">
                {hideArrow !== "left" && (
                  <img
                    alt="previous product"
                    src={leftArrow}
                    rel="prev"
                    className="left-angle"
                    onClick={() => {
                      previousProduct(
                        page,
                        searchParams?.sort,
                        searchParams?.cat_id
                      );
                    }}
                  />
                )}
              </div>
              {hideArrow !== "right" && (
                <div
                  id="right-button"
                  className="next-prev-button-container next"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    alt="next product"
                    src={rightArrow}
                    rel="next"
                    className="right-angle"
                    onClick={() => {
                      nextProduct(
                        page,
                        searchParams?.sort,
                        searchParams?.cat_id
                      );
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {!isLoading ? (
            <Slider
              infinite={false}
              arrows={false}
              swipe={window.innerWidth < 768}
              // swipeToSlide={true}
              onSwipe={(direction) => {
                if (direction === "left") {
                  nextProduct(page, searchParams?.sort, searchParams?.cat_id);
                  setRTL(false);
                } else if (direction === "right") {
                  setRTL(true);
                  previousProduct(
                    page,
                    searchParams?.sort,
                    searchParams?.cat_id
                  );
                }
              }}
              speed={0}
              edgeFriction={0}
              initialSlide={1}
              rtl={RTL}
            >
              <>
                {isError ? (
                  <section className="ec-page-content section-space-p container-xxl container-fluid">
                    <NoProduct errMsg={"No Product Found"} />
                  </section>
                ) : (
                  <>
                    <section className="ec-page-content section-space-p container-fluid fsv-section">
                      <div className="container-fluid  mx-auto p-0">
                        <div className="row">
                          <div className="ec-pro-rightside ec-common-rightside col-lg-12 col-sm-12">
                            <div className="single-pro-block">
                              <div className="single-pro-inner">
                                <div className="row justify-content-center fsv-col">
                                  <ProductPreview
                                    nav1={nav1}
                                    nav2={nav2}
                                    setNav1={setNav1}
                                    setNav2={setNav2}
                                  />

                                  {/* details */}
                                  <div
                                    className="col-xl-4 col-lg-4 col-md-5 col-11 mt-sm-0 mt-5 fsv-prod-details-section position-relative"
                                    style={{ marginBottom: "100px" }}
                                  >
                                    {/* //(Don't remove enable when image preview zoomType = "hover" [side hover zoom], <HoverImageZoomPreview/> ) */}

                                    {/* <motion.div
                                      ref={zoomTargetRef}
                                      id="hoverZoomPreivew"
                                      style={{
                                        position: "absolute",
                                        zIndex: "999",
                                        top: top
                                      }}
                                    ></motion.div> */}

                                    {show_fsv_custom_varient_details ? <FSVCustomVariantDetails/> : null}
                                    
                                    <FSVInputs
                                      nav1={nav1}
                                      nav2={nav2}
                                      master={master}
                                    />

                                    <FSVDetails master={master} />
                                    
                                  </div>
                                  <FSVScrollToView page={id}/>
                                </div>
                                {/* {data && showScrollBtn && <div
                                  className="top-Btn d-flex justify-content-center"
                                  id="scroll-to-top-btn"
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  <img
                                    src={require("../../assets/images/favicon/up-arrow.png")}
                                    height="20"
                                    width="20"
                                  />

                                  <button>Scroll To Top</button>
                                </div>} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                )}
              </>
            </Slider>
          ) : (
            <NoProduct errMsg={"No Data Found"} showLoader={true} />
          )}
          <FSVSimilerProductCard products={AllProducts[0]?.related_product}/>
          <FSVFooter/>
        </div>
      ) : (
        <Loader
          isLoading={true}
          height={Number(sessionStorage.getItem("FSV_Page_Height")) || 1000}
          style={{justifyContent: "flex-start"}}
          clsName={`position-sticky top-0`}
        />
      )}
    </>
  );
}

export default FullScreenView;
