/* eslint-disable no-unused-vars */
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import MUIModal from '../../../MUIModal/MUIModal'
import { IoMdClose } from 'react-icons/io';
import "./FSVMatrixModal.css";
import useFSVResponse from './useFSVResponse';
import Loader from '../../../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { get_fsv_selected_product, getCatMaster, set_FSV_Handler } from '../../../../redux/Reducer/reducer';
import FSVInputs from '../../../../Pages/Product/components/FSVInputs/FSVInputs';
import FSVCustomVariantDetails from '../../../../Pages/Product/components/FSVCustomVariantDetails/FSVCustomVariantDetails';
import FSVFooter from '../../../../Pages/Product/components/FSVFooter/FSVFooter';
import { FSV_ACTIONS } from '../../../../Pages/Product/Helper';
import FSVDetails from '../../../../Pages/Product/components/FSVDetails/FSVDetails';

function FSVMatrixModal({page, sort, set_ATC_button_label}, ref) {
  const dispatch = useDispatch()
  const [open_MUI_modal, set_open_MUI_modal] = useState(false);
  const handle_close_MUI_modal = useCallback(() => {
    dispatch(set_FSV_Handler({type: FSV_ACTIONS.RESET_ALL}))
    set_open_MUI_modal(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handle_open_MUI_modal = () => set_open_MUI_modal(true);
  const master = useSelector(getCatMaster);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  useImperativeHandle(ref, () => ({
    open_modal: () => handle_open_MUI_modal(),
  }));

  const {isLoading} = useFSVResponse(open_MUI_modal, page, sort) || {}
  const selectedProduct = useSelector(get_fsv_selected_product)

  if(!open_MUI_modal){
    return null
  }



  return (
    <MUIModal
    className="fsv-matrix-modal"
      open_MUI_modal={open_MUI_modal}
      handle_close_MUI_modal={handle_close_MUI_modal}
      style={{ p: 0, width: "min(500px, 90%)"}}
    >
      {
        isLoading || Object.keys(selectedProduct).length === 0 ? (<Loader isLoading={true}/>) : (
          <>
               <header>
        <h3>{selectedProduct?.category_name}</h3>
        <IoMdClose className="close-icon" onClick={handle_close_MUI_modal} />
      </header>

      <section className='fsv-matrix-modal-section fsv-prod-details-section'>
      <section className="ec-page-content section-space-p container-fluid fsv-section">
      <div className="row justify-content-center fsv-col">
          <FSVCustomVariantDetails/>
          <FSVInputs nav1={nav1} nav2={nav2} master={master}/>
          <FSVDetails master={master} />
      </div>
      </section>

      <footer>
        <FSVFooter handle_close_fsv_matrix_modal = {handle_close_MUI_modal} set_ATC_button_label={set_ATC_button_label}/>
      </footer>
      </section>
          </>
        )
      }
     

    </MUIModal>
  )
}

export default forwardRef(FSVMatrixModal)