/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from "react-router-dom";
import BrandingImg from "../../../../assets/images/matrix-branding-img.jpg";
import "./MatrixBranding.css";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { useSelector } from "react-redux";
import {
  getCatMaster,
  get_is_myc_web,
} from "../../../../redux/Reducer/reducer";
import {
  get_informationFromSessionID,
  get_property_settings,
} from "../../../../Component/Helper";
import { ApiList } from "../../../../Api/ApiList";
import { useMemo } from "react";
import ContactUs from "./Components/ContactUs/ContactUs";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";

function MatrixBranding() {
  const location = useLocation();

  let { cat_id } =
    Object.fromEntries(new URLSearchParams(location.search)) || {};

  const master = useSelector(getCatMaster);
  const userID = get_informationFromSessionID("userID");
  const is_myc_web = useSelector(get_is_myc_web);
  const cdn = get_property_settings("cdn");
  const show_contact_us_in_matrix_branding = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.show_contact_us_in_matrix_branding
  );

  const category_query = useQuery({
    queryKey: ["category", master, userID],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
        which_master: master,
      }),
    initialData: [],
    enabled: !is_myc_web,
  });

  const findID = (categoryData, cat_id, nestingKey) => {
    if (!Array.isArray(categoryData)) return null;
    // eslint-disable-next-line array-callback-return
    return categoryData?.reduce((result, currentItem) => {
      if (result) return result;
      if (currentItem.id === cat_id) return currentItem;
      if (currentItem[nestingKey])
        return findID(currentItem[nestingKey], cat_id, nestingKey);
    }, null);
  };

  const subCategories = useMemo(
    () => findID(category_query?.data, cat_id, "subcategory"),
    [cat_id, category_query?.data]
  );
  const mainCategory = useMemo(
    () =>
      findID(category_query?.data, subCategories?.cat_parent_id, "subcategory"),
    [subCategories]
  );
  const branding_img_root_path = `${cdn}uploads/branding_image/category/`;
  const branding_img =
    window.innerWidth > 576 && subCategories?.cat_web_img
      ? `${branding_img_root_path}${subCategories?.cat_web_img}`
      : window.innerWidth < 576 && subCategories?.cat_android_img
      ? `${branding_img_root_path}${subCategories?.cat_android_img}`
      : BrandingImg;

  if (!subCategories) return null;

  return (
    <>
    <section className="matrix-branding-section">
      <div className="branding-image-container">
        <img src={branding_img} alt="branding" />
      </div>

      <article className="brading-text-section">
        {mainCategory?.cat_name && subCategories?.cat_name ? (
          <h2>
            {mainCategory?.cat_name} - {subCategories?.cat_name}
          </h2>
        ) : null}
        <p>⁠Unlock the World of Unparalleled Beauty</p>
        
      </article>
    </section>
    {/* OUTSIDE OF BRANDING  */}
    {show_contact_us_in_matrix_branding.status &&
        show_contact_us_in_matrix_branding?.category?.includes(
          mainCategory?.cat_name
        ) ? (
          <ContactUs body={show_contact_us_in_matrix_branding.body} />
        ) : null}
    </>
  );
}

export default MatrixBranding;
