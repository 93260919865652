import { Controller, useFormContext } from "react-hook-form";
import "../InputField.css";
import { MOBILE_REGEX } from "../../../utils";
import PhoneInput from "react-phone-input-2";
import { get_property_settings } from "../../Helper";

function PhoneNumber({fields}) {
    const {formState: {errors}, control} = useFormContext()
    const {field_name, short_code, required} = fields || {};

    const country_name_shortcode = get_property_settings(
      "country_name_shortcode"
    );
    

  return (
    <div className='input-group textfield'>
        <label>{field_name}{required === "1" ? <span className="required">*</span> : null}</label>
        <Controller
        control={control}
        name={short_code}
        rules={{
          required: {
            value: required === "1",
            message: `${field_name} is required`
          },
          validate: (value) => {
              const {phoneNumber, country_code} = value || {}
              if(phoneNumber && country_code){
                const number = phoneNumber.replace(country_code)
             
                if(number < 10) return "Invaild mobile no."

              }
          }
        }}
        render={({ field: { onChange,  value} }) => {
          return (
            <PhoneInput
              inputClass="number-input fieldValue"
              buttonClass="contryCodeDropDown"
              placeholder=""
              value={value?.phoneNumber}
              country={
                country_name_shortcode
                  ? country_name_shortcode.toLowerCase()
                  : "in"
              }
              masks={{ in: ".........." }}
              inputProps={
                {
                  // tabIndex: tabIndex + 1,
                }
              }
              onChange={(phoneNumber, country) => {
                 onChange({phoneNumber, country_code: country.dialCode});
              }}
            />
          );
        }}
      />
        {errors[short_code] ? <p className="error-text">{errors[short_code]?.message}</p> : null}
    </div>
  )
}

export default PhoneNumber