import React from 'react'

function ProfileCard({image, details: {name, designation, info}}) {
  return (
    <div className='profile-card'>
        <div className='image-section'>
            <img src={image} alt={name} />
        </div>

        <div className='details-section'>
            <h3 className='name'>{name}</h3>
            <p className='designation'>{designation}</p>
            <p className='info'>
            {info}
            </p>
        </div>
    </div>
  )
}

export default ProfileCard