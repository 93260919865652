import React, { useCallback, useImperativeHandle, useState } from "react";
import MUIModal from "../../../../../../MUIModal/MUIModal";
import { forwardRef } from "react";
import { IoMdClose } from "react-icons/io";

function CallUsModal({ call_data }, ref) {
  
  const [open_MUI_modal, set_open_MUI_modal] = useState(false);
  const handle_close_MUI_modal = useCallback(
    () => set_open_MUI_modal(false),
    []
  );

  const handle_open_MUI_modal = () => set_open_MUI_modal(true);

  useImperativeHandle(ref, () => ({
    open_modal: () => handle_open_MUI_modal(),
  }));



  return (
    <MUIModal
      className="whats-app-modal"
      open_MUI_modal={open_MUI_modal}
      handle_close_MUI_modal={handle_close_MUI_modal}
      style={{ p: 0, width: "min(320px, 90%)" }}
    >
      <section className="whatsApp-modal-section">
        <header>
          <h3>Call Us</h3>
          <IoMdClose className="close-icon" onClick={handle_close_MUI_modal} />
        </header>

        <ul className="list">
          {Array.isArray(call_data) && call_data?.map((data, index) => {

            
            return( <li key={`${data?.location}${index}`}>
              <a
                href={`tel:${data?.contact_no}`}
                className="link"
                rel="noreferrer"
                onClick={(e) => {
                  if(window.innerWidth > 576){
                    e.preventDefault()
                  }
                }}
              >
                <p className="field-name">{data?.location}</p>
                <p className="mobile-no">{data?.contact_no}</p>
              </a>
            </li>
          )
          })}
        </ul>
      </section>
    </MUIModal>
  );
}

export default forwardRef(CallUsModal);
