import { useZoomImageHover, useZoomImageMove } from "@zoom-image/react";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./HoverImageZoomPreview.css"

function HoverImageZoomPreview({ img, typeOfZoom = "move" }) {
  const imageHoverContainerRef = useRef(null);
  const imageMoveContainerRef = useRef(null);
  const zoomTargetRef = document.getElementById("hoverZoomPreivew");
  const { createZoomImage: createZoomImageHover } = useZoomImageHover();
  const { createZoomImage: createZoomImageMove } = useZoomImageMove();
  const { id } = useParams();

  //const img = "https://cdn.jfone.jewelflow.pro/kalashaonline/uploads/design_uploads/zoom_image/M95OnInNqlMs.jpg"

  useEffect(() => {
    if (typeOfZoom === "hover") {
      if (!zoomTargetRef) return;
      const imageContainer = imageHoverContainerRef.current;
      // const zoomTarget = zoomTargetRef.current
      const zoomTarget = zoomTargetRef;
      createZoomImageHover(imageContainer, {
        zoomImageSource: img,
        customZoom: { width: window.innerWidth < 992 ? 400 : 500, height: 400 },
        zoomTarget,
        scale: 2,
      });
    } else if (typeOfZoom === "move") {
      const imageContainer = imageMoveContainerRef.current;
      createZoomImageMove(imageContainer, {
        zoomImageSource: img,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomTargetRef, id]);

  return (
    <>
      {typeOfZoom === "hover" ? (
        <div ref={imageHoverContainerRef} className="position-relative">
          <img alt="Small Pic" src={img} />
        </div>
      ) : typeOfZoom === "move" ? (
        <div ref={imageMoveContainerRef} className="position-relative imageMoveContainer" style={{cursor: "crosshair"}}>
        <img alt="Large Pic" src={img} />
        </div>
      ) : null}
    </>
  );
}

export default HoverImageZoomPreview;
