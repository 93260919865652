import React from 'react'

function SingleTestmonialCard({client_name, testimonial_data}) {
    
    return (
        <li className="ec-test-item">
            <i className="fi-rr-quote-right top"></i>
            <div className="ec-test-inner">
                <div className="ec-test-img"><img alt="testimonial" title="testimonial"
                    src="assets/images/testimonial/1.jpg" /></div>
                <div className="ec-test-content">
                    <div className="ec-test-desc">{testimonial_data}</div>
                    <div className="ec-test-name">{client_name}</div>
                    {/* <div className="ec-test-designation">General Manager</div> */}
                    <div className="ec-test-rating">
                        <i className="ecicon eci-star fill"></i>
                        <i className="ecicon eci-star fill"></i>
                        <i className="ecicon eci-star fill"></i>
                        <i className="ecicon eci-star fill"></i>
                        <i className="ecicon eci-star fill"></i>
                    </div>
                </div>
            </div>
            <i className="fi-rr-quote-right bottom"></i>
        </li>
    )
}

export default SingleTestmonialCard
