import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { FaTimesCircle } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import NoProduct from '../../../Component/NoProduct/NoProduct';
import { decryptData } from '../../../Api/APICalls';

function PaymentCard({type}) {
  const location = useLocation()
 
  let data = Object.fromEntries(new URLSearchParams(location.search));
  
  data = decryptData(data?.rs)
  
  const style = {
    color: type === "success" ? 'green' : 'crimson'
  }

 

  return (
    <>
    {data?.payment_id ? <div className="payment-card">
        {type === "success" ? <FaCheckCircle className='pay-icon' style={style}/> : <FaTimesCircle className='pay-icon' style={style}/>}


        <main>
          <h3 className='payment-title' style={style}>{type === "success" ? "Payment Successful" : "Payment Failed"}</h3>

          {type === "success" ? (
          <div className='sub-text'>
            <p>Your payment has been processed!</p>
            <p>Details of transaction are includes below</p>
          </div>
        ) : (
            <div className='sub-text'>
            {/* <p>Something went wrong</p> */}
            <p>{data?.description || ""}</p>
            </div>
        )
        
        }

          <hr />

          <p className='transaction-ref'>
            <span>Transaction ID :{" "}</span> <span>{data?.payment_id || ""}</span>
          </p>
        </main>


       <footer>
            <ul>
                {/* <li>
                    <span>TOTAL AMOUNT PAID</span>
                    <span>$45.5</span>
                </li> */}

                <li>
                    <span>ORDER ID</span>
                    <span>{data?.order_id || ""}</span>
                </li>

                <li>
                    <span>TRANSACTION DATE</span>
                    <span>{data?.timeStamp || ""}</span>
                </li>
            </ul>
        </footer>
    </div> : <NoProduct errMsg="Page Not Found"/>}
    </>
  )
}

export default PaymentCard
