import React, { useState } from "react";
import ModalDialog from "../../ModalDialog";
import {
  
  get_informationFromSessionID,
  get_property_settings,
  logOutUser,
} from "../../Helper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Cart from "../../../assets/images/cart_icon_20@3x.png";
import Shortlist from "../../../assets/images/shortlist@3x.png";
import UserProfileDropDownMenu from "./components/UserProfileDropDownMenu/UserProfileDropDownMenu";
import { get_is_myc_web } from "../../../redux/Reducer/reducer";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";

function HeaderUserMenu() {
  //const [openUserMenu, setOpenUserMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userID = get_informationFromSessionID("userID");
  const cartcounts = useSelector((state) => state?.reducer?.constants?.count);
  const enable_shortlist = get_property_settings("enable_shortlist");
  const show_named_wishlist_app = get_property_settings("show_named_wishlist_app");
  const company_code = get_informationFromSessionID("companyCode");
  const is_myc_web = useSelector(get_is_myc_web)
  const show_shortlist_icon_as_named_shortlist = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_shortlist_icon_as_named_shortlist)

  const show_named_wishlist = show_named_wishlist_app === "Yes";
  
  // useEffect(()=>{

  //     window.addEventListener('click', (e) => closeMenuWhenClickedOutside(e, openUserMenu, setOpenUserMenu, ['fa-user', 'dropdown-toggle', 'dropdown-item']))
  //     return () => {
  //         window.removeEventListener('click', (e) => closeMenuWhenClickedOutside(e, openUserMenu, setOpenUserMenu, ['fa-user', 'dropdown-toggle', 'dropdown-item']))
  //     }
  // }, [openUserMenu])

  const shortlist_label = get_property_settings("shortlist_label")

  return (
    <>
     {showModal && <ModalDialog
        show={showModal}
        setShow={setShowModal}
        action={logOutUser}
        toastMsg={"Your Successfully logout"}
      />}

      {/* -- Header wishlist Start -- */}
      {(!show_named_wishlist || (show_named_wishlist && show_shortlist_icon_as_named_shortlist)) && enable_shortlist === "Yes" ? (
        <Link
          to={show_named_wishlist && show_shortlist_icon_as_named_shortlist ? '/named-wishlist' : `/short-list?company_code=${company_code}`}
          className="ec-header-btn ec-header-wishlist header-buttons d-sm-block d-none"
          title={show_named_wishlist && show_shortlist_icon_as_named_shortlist ? shortlist_label : "short-list"}
        >
          <div className="header-icon">
            {/* <i className="fi-rr-heart"></i> */}
            <img src={Shortlist} alt="wishlist" />
          </div>
          <span className="ec-header-count">
            {cartcounts && cartcounts?.shortlist_count
              ? cartcounts?.shortlist_count
              : 0}
          </span>
        </Link>
      ) : null}

      {/* -- Header wishlist End -- */}
      {/* -- Header Cart Start -- */}
      <Link
        to={`/cart?company_code=${company_code}`}
        className="ec-header-btn ec-side-toggle header-buttons d-sm-block d-none"
        title="cart"
      >
        <div className="header-icon">
          {/* <i className="fi-rr-shopping-bag"></i> */}
          <img src={Cart} alt="cart" />
        </div>
        <span className="ec-header-count cart-count-lable">
          {cartcounts && cartcounts?.count ? cartcounts?.count : 0}
        </span>
      </Link>

      {(is_myc_web && userID) || !is_myc_web ? <div className="ec-header-btn ec-side-toggle header-buttons d-sm-block d-none">
        <UserProfileDropDownMenu/>
      </div> : null}
      {/* -- Header Cart End -- */}
    </>
  );
}

export default React.memo(HeaderUserMenu);
