import Box from "@mui/material/Box";
import { MdOutlineClose } from "react-icons/md";
import "./ZoomImageDrawer.css";
import ZoomImageSection from "./components/ZoomImageSection";
import ZoomSliderSection from "./components/ZoomSliderSection";


function ZoomImageDrawer({
  DRAWER_OPEN_DIRECTION,
  toggleZoomDrawer,
  allThumbImages,
  imageRootPath,
  currentSlide,
  className = ""
}) {



  return (
    <Box
      sx={{
        width:
          DRAWER_OPEN_DIRECTION === "top" || DRAWER_OPEN_DIRECTION === "bottom"
            ? "auto"
            : 250,
      }}
      role="presentation"
      //   onClick={toggleZoomDrawer(DRAWER_OPEN_DIRECTION, false)}
      //   onKeyDown={toggleZoomDrawer(DRAWER_OPEN_DIRECTION, false)}
    >
      <div className="ZoomImageDrawer">
        <header className="image-header">
          <MdOutlineClose
            onClick={() => {
              toggleZoomDrawer(DRAWER_OPEN_DIRECTION, false)
              sessionStorage.setItem("FSV_Swipe", JSON.stringify(true))
            }}
            className="close-btn"
          />
        </header>

        <section className={`image-body ${className}`}>
          {allThumbImages.length === 1 ? (
            <ZoomImageSection imagepath={`${imageRootPath}${allThumbImages[0]}`}/>
            // <ZoomSliderSection/>
          ) : allThumbImages.length > 1 ? (
            <ZoomSliderSection imageRootPath={imageRootPath} allThumbImages={allThumbImages} currentSlide={currentSlide}/>
            // <ZoomImageSection imagepath={`${imageRootPath}${allThumbImages[0]}`}/>
          ) : null}

        </section>
        
      </div>
    </Box>
  );
}

export default ZoomImageDrawer;
