import React from 'react'
import { IoCloseSharp } from "react-icons/io5";

function Tags({name, id, setValue, tags, setTags, short_code, disabled}) {

  function handle_remove_tag(id){
    if(disabled) return
    const filter_tags = tags.filter(tag => tag.id !== id)
    setTags(filter_tags)
    const updated_tag_id = filter_tags.map(tag => tag.id)
    setValue(short_code, updated_tag_id)
  }

  return (
    <div className='checkout-tag'>
            <span>{name}</span>
            <IoCloseSharp className='close-icon' onClick={() => handle_remove_tag(id)}/>
    </div>
   
  )
}

export default Tags