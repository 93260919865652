import React, { useRef } from "react";
import WhatsAppModal from "../../../Component/WhatsAppModal/WhatsAppModal";

function WhatsappForLargeDevice({
  whatsAppNo,
  encodedMessage,
  linkName = "Whatsapp",
  otherSocialLink,
  customStyle = {},
  whatsApp_contact_count,
}) {
  const whatsAppFloatRef = useRef(null);
  const open_whatsapp_modal_ref = useRef(null);

  const rightOffset = whatsAppFloatRef?.current?.offsetWidth
    ? `-${whatsAppFloatRef?.current?.offsetWidth - 36}px`
    : "-75px";

  const Link = otherSocialLink
    ? otherSocialLink
    : `https://api.whatsapp.com/send?phone=${whatsAppNo}&text=${encodedMessage}`;

  const icon_cls =
    linkName === "Whatsapp"
      ? "fa-brands fa-whatsapp filter-color"
      : linkName === "Facebook"
      ? "fa-brands fa-square-facebook"
      : linkName === "Instagram"
      ? "fa-brands fa-instagram"
      : linkName === "LinkedIn"
      ? "fa-brands fa-linkedin"
      : linkName === "YouTube"
      ? "fa-brands fa-youtube"
      : linkName === "Locate Us"
      ? "fa-solid fa-map"
      : linkName === "Pinterest" 
      ? "fa-brands fa-pinterest"
      :"";
  return (
    <div
      className="ec-style ec-right-bottom d-none d-sm-block"
      ref={whatsAppFloatRef}
      style={{ right: rightOffset, ...customStyle }}
    >
      {whatsApp_contact_count > 1 && <WhatsAppModal
        encodedMessage={encodedMessage}
        ref={open_whatsapp_modal_ref}
      />}
      <div className="ec-right-bottom">
        <div className="ec-box d-flex align-items-center btn_primary_color btn_primary_foreground_color button_border">
          <div className="ec-button ">
            <i className={icon_cls} style={{ fontSize: "25px" }}></i>
          </div>
          <a
            className="hovered"
            target="_blank"
            rel="noopener noreferrer"
            href={Link}
            onClick={(e) => {
              if (
                whatsApp_contact_count > 1 &&
                open_whatsapp_modal_ref.current
              ) {
                e.preventDefault();

                open_whatsapp_modal_ref.current?.open_modal();
              }
            }}
          >
            <span>{linkName}</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default WhatsappForLargeDevice;
