import { useEffect } from "react";
import "./CHAINHOUSE24AboutUs.css";
import { ScrollToTopCustom } from "../../../utils";

function CHAINHOUSE24AboutUs() {

  useEffect(() => {
    ScrollToTopCustom()
  }, [])

  return (
    <div className="chainhouse24-about-us">
      <h2>ABOUT US</h2>
      <p>Established in 1980, Rohtak Chain and Chain House are one of the most esteemed & trusted jewellery manufacturers and wholesalers of Gold Chains, Gold & Diamond Jewellery, and Solitaires in North India. A strong emphasis on exquisite craftsmanship, superior quality and exceptional customer experience has allowed us to maintain our position as a top leading jewellery brand. We have our presence in both the wholesale jewellery hubs of Delhi - Chandni Chowk and Karol Bagh, where we are eager to show you our entire range of products to win your heart and tie a bond of friendship, trust and confidence.</p>
      <h2>OUR PHILOSOPHY</h2>
      <p>Purity and Honesty are the crown jewels at Rohtak Chain. From the hand crafted jewellery by karigars to automated machines running continuously, the highest level of quality checks are implemented to ensure par excellence. Our factories & showrooms are equipped with the world's latest computerized equipment, imported from Helmut-Fishcer Gmbh, Germany to detect foul mixing of iridium/ruthenium and various other group of metals to ensure the ultimate purity, quality, and transparency, you deserve.</p>
      <p>Inculcated in our ethical beliefs and fundamentals, we will forever adhere to our promises of purity, superior creations and to delight every customer equally and honestly.</p>
      <img src="https://prodone.jewelflow.pro/chainhouse/public/about/philosophy.jpg" alt="philosophy" />
      <h2>PRODUCT RANGE</h2>
      <p>We have possibly the widest range of plain, multi-coloured, solid and hollow, machine-made and hand-made chains crafted by our latest imported machines & innovative artisans, ranging from 18 carat to 22 carat. We offer you the latest gold jewellery designs from across the world ranging from traditional to contemporary designs, so that you can always find what you are looking for.</p>
      <img src="https://prodone.jewelflow.pro/chainhouse/public/about/product_range.jpg" alt="product_range" />
      <h2>ROHTAK CHAIN ADVANTAGE</h2>
      <p>When you shop at Rohtak Chain, you can always be assured of the highest quality standards. With our state-of-the-art infrastructure, all our products undergo stringent quality checks before they reach you so that you can get the quality you deserve. We are an ethical company and we adhere to the highest code of conduct and business practices. Not just competitive but also honest, each product we offer is open to scrutiny. You can view the complete product details right down to individual weights and sizes.</p>
      <p>We offer free shipping to our customers all over India. All goods are insured by us until they reach you, to deliver your order safely and securely. If ever you wish to return your old jewellery purchased from us, you can either exchange the product or get a refund for its current value.</p>
      <img src="https://prodone.jewelflow.pro/chainhouse/public/about/rc_advantage.jpg" alt="rc_advantage" />
      <p>Find us on Facebook, Instagram and Youtube. We welcome you to witness an entire new world of Gold chains and Gold & Diamond Jewellery backed by world class manufacturing techniques, elegant designs & innovation in the warmth of a personalized buying experience.</p>
    </div>
  )
}

export default CHAINHOUSE24AboutUs
