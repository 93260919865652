import { useEffect, useState } from "react";
import Select from "react-select";
import { createSelectArrayForReactSelect, createSelectOptionForReactSelect, get_property_settings, hasPropertyInObj } from "../../../../../Component/Helper";

function CartDropDown({product_detail, cartData, handleChange}) {

    const primary_BG_color = get_property_settings("btn_primary_color");

    const [selectedKaratOption, setSelectedKaratOption] = useState({});
  const [karatOptions, setKaratOptions] = useState(
    createSelectArrayForReactSelect(product_detail.karat_param_catalogue)
  );
  const [selectedInchOption, setSelectedInchOption] = useState({});
  const [sizeOptions, setSizeOptions] = useState(
    createSelectArrayForReactSelect(product_detail.size_param_catalogue)
  );
  const [selectedHookOption, setSelectedHookOption] = useState({});
  const [hookOptions, setHookOptions] = useState(
    createSelectArrayForReactSelect(
      product_detail.cc_hook_preference_param_catalogue
    )
  );

  useEffect(() => {
    setKaratOptions(
      createSelectArrayForReactSelect(product_detail.karat_param_catalogue)
    );
    setSizeOptions(
      createSelectArrayForReactSelect(product_detail.size_param_catalogue)
    );
    setHookOptions(
      createSelectArrayForReactSelect(
        product_detail.cc_hook_preference_param_catalogue
      )
    );
  }, [product_detail]);

  return (
    <div className="data karat_dropdown">
      <Select
        // menuPlacement= {isLastProduct ? "top" : "bottom"}
        menuPlacement="bottom"
        value={
          cartData.label === "Karat" && hasPropertyInObj(selectedKaratOption)
            ? selectedKaratOption
            : cartData.label === "Inch" && hasPropertyInObj(selectedInchOption)
            ? selectedInchOption
            : cartData.label === "Hook" && hasPropertyInObj(selectedHookOption)
            ? selectedHookOption
            : createSelectOptionForReactSelect(cartData?.selected_value)
        }
        options={
          cartData.label === "KT" ||
          cartData.label === "Karat" ||
          cartData.label === "Kt"
            ? karatOptions
            : cartData.label === "Size" || cartData.label === "Inch"
            ? sizeOptions
            : cartData.label === "Hook"
            ? hookOptions
            : []
        }
        isSearchable={false}
        isClearable={false}
        onChange={(option) => {
          //cartData.label === "Karat" ? setSelectedKaratOption(option) : cartData.label === "Inch" ? setSelectedInchOption(option) : cartData.label === "Hook" ? setSelectedHookOption(option) : null;

          if (cartData.label === "KT" || cartData.label === "Karat") {
            setSelectedKaratOption(option);
          } else if (cartData.label === "Size" || cartData.label === "Inch") {
            setSelectedInchOption(option);
          } else if (cartData.label === "Hook") {
            setSelectedHookOption(option);
          }

          handleChange(cartData?.short_code, option.value);
        }}
        className="react-select-container r-container"
        classNamePrefix="react-select r-select"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          border: "none",
          colors: {
            ...theme.colors,
            primary25:
              (primary_BG_color &&
                primary_BG_color
                  .replace("rgb", "rgba")
                  .replace(")", ",0.2)")) ||
              "inherit",
            primary: primary_BG_color,
          },
        })}
      />
    </div>
  );
}

export default CartDropDown;
