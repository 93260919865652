import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import "./PastOrderInfoModal.css"
import MUIModal from "../../../../../../Component/MUIModal/MUIModal";
import { IoMdClose } from "react-icons/io";


import CheckoutNew from "../../../../../Checkout/CheckoutNew";

function PastOrderInfoModal({order_data}, ref) {

  const [open_MUI_modal, set_open_MUI_modal] = useState(false);
  const handle_close_MUI_modal = useCallback(
    () => set_open_MUI_modal(false),
    []
  );

  
  

  const handle_open_MUI_modal = () => set_open_MUI_modal(true);

  useImperativeHandle(ref, () => ({
    open_modal: () => handle_open_MUI_modal(),
  }));

  

  return (
    <MUIModal
    className="past-order-info-modal"
    open_MUI_modal={open_MUI_modal}
    handle_close_MUI_modal={handle_close_MUI_modal}
    style={{ p: 0, width: "min(500px, 90%)" }}
  >
    <header>
      <h3>Order Information</h3>
      <IoMdClose className="close-btn" onClick={handle_close_MUI_modal}/>
    </header>

    <section className="past-order-info-modal-body">
      <CheckoutNew order_data={order_data}/>
    </section>
  </MUIModal>
  )
}

export default forwardRef(PastOrderInfoModal)
