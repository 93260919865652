import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Accordion,
} from "react-bootstrap";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionItem from "react-bootstrap/esm/AccordionItem";

import { get_bulk_settings } from "../../../Component/Helper";
import NoProduct from "../../../Component/NoProduct/NoProduct";


import CartRemoveAll from "./CartRemoveAll";
import CartProductNew from "./CartProductNew/CartProductNew";

const CartProductSection = ({
  cartData,
  group_in_wishlist,
  group_in_cart,
  fetchData,
  show,
  setShow,
  emptyCart,
  type_of_cart,
}) => {
  
  
  
  // const [activeKey, setActiveKey] = useState(0);
  const [toggleAccordion, setToggleAccordion] = useState(true);
  
 
  // const scrollToTargetDiv = () => {
  //   // let currindex = localStorage.getItem("currProd");
  //   const targetDiv = document.getElementById("accordian0");
  //   // 
  //   if (targetDiv) {
  //     const rect = targetDiv.getBoundingClientRect();

  //     window.scrollTo({ top: rect.top - 500, behavior: "instant" });
  //     //   localStorage.removeItem("currProd");
  //   }
  // };
  
  const gen_settings = get_bulk_settings([
    "cart_grouped_code_based_on",
    "show_remarks_in_cart_wishlist",
    "show_remarks_in_inventory",
    "show_quantity_stepper_in_cart_wishlist",
    "show_qty_stepper_in_inventory",
    "show_product_name_in_cart_wishlist",
    "allow_lessthan_moq",
    "confirm_order_button_text",
    "cdn",
    "show_remove_all_cartshortlist",
    "default_image",
    "enable_shortlist",
    "show_named_wishlist_app",
    "set_image_bg_based_on_product_image",
    "show_set_and_pcs_in_cart_grouped"
  ])
  const show_set_and_pcs_in_cart_grouped = gen_settings?.show_set_and_pcs_in_cart_grouped === "Yes"
  

  const show_remove_all_cartshortlist_btn = gen_settings?.show_remove_all_cartshortlist === "Yes"
  

  // useEffect(() => {
  //   scrollToTargetDiv();
  // }, [activeKey]);
  

  return (
    <div>
      
      <>
        
        <div className={`${type_of_cart === "temp_wishlist" ? "cart-page" : "shortlist-page"} cart-container`}>
          

          {show_remove_all_cartshortlist_btn && (cartData && cartData.length > 0) ? <CartRemoveAll setShow={setShow}/> : null}

          
        <section className="ec-page-content mt-5 pt-4 cart-main">
           

           
              <>
                
                  <div
                    className="container-fluid  px-4 mt-5 pt-5 pt-sm-2 cart-inner-body"
                    
                  >
                    {cartData &&
                    cartData.length > 0 &&
                    cartData.filter((d) => d.product_details.length).length !==
                      0 ? (
                      <Accordion>
                        {cartData.map((cart_data, _) => {
                          return (
                            <span key={_}>
                              {" "}
                              {cartData.length !== 1 ? (
                                <>
                                  <AccordionItem
                                    eventKey={_}
                                    id={`accordian${_}`}
                                  >
                                    {cart_data?.product_details &&
                                      cart_data?.product_details.length > 0 && (
                                        <AccordionHeader
                                          onClick={() => {
                                            setToggleAccordion(
                                              !toggleAccordion
                                            );
                                           // setActiveKey(_);
                                          }}
                                          className="prod-accordian-header"
                                        >
                                          <span className="accordian-body w-100">
                                            <div className="d-flex flex-wrap g-2 justify-content-between prod-accordian-header">
                                              <div>
                                                <span className="fw-bold">
                                                  {cart_data?.labour_type_name.toUpperCase()}
                                                  
                                                </span>
                                              </div>
                                              <div className="d-flex mr-2 right-side">
                                                <div className="wt">
                                                  <span className="fw-light">
                                                    {cart_data?.total_gross_wt} gm
                                                  </span>
                                                </div>
                                                <div className="vr mx-2"></div>
                                                {
                                                  show_set_and_pcs_in_cart_grouped ? (
                                                    <>
                                                    <div className="qt">
                                                    <span className="fw-light">
                                                      {cart_data?.total_quantity} sets
                                                    </span>
                                                    </div>
                                                    <div className="vr mx-2"></div>
                                                    <div className="qt">
                                                    <span className="fw-light">
                                                      {cart_data?.total_piece} pcs
                                                    </span>
                                                    </div>
                                                    </>
                                                  ) : (
                                                    <div className="qt">
                                                  <span className="fw-light">
                                                    {cart_data?.total_quantity} pcs
                                                  </span>
                                                </div>
                                                  )
                                                }
                                                
                                              </div>
                                            </div>
                                          </span>
                                        </AccordionHeader>
                                      )}
                                    {cart_data?.product_details.length > 0 && (
                                      <AccordionBody
                                        className="py-0 w-100 cart-accordation-body"
                                        style={
                                          cart_data?.product_details.length === 1
                                            ? {
                                                // height: "45vh",
                                                overflowY: "scroll",
                                              }
                                            : {
                                                // height: "100vh",
                                                overflowY: "scroll",
                                              }
                                        }
                                      >
                                       
                                        {cart_data?.product_details.length > 0 &&
                                          cart_data?.product_details.map((dd, __) => {
                                            return (
                                              
                                                // <CartProduct
                                                //   key={dd?.id}
                                                //   id={dd?.id}
                                                //   cartData={cart_data}
                                                //   group_in_wishlist={group_in_wishlist}
                                                //   group_in_cart={group_in_cart}
                                                //   fetchData={fetchData}
                                                //   productIndex={__}
                                                //   product_detail={dd}
                                                //   general_settings = {gen_settings}
                                                //   isLastProduct={__ === cart_data?.product_details.length - 1}
                                                //   type_of_cart={type_of_cart}
                                                //   show_zoom_btn_in_cart_product = {show_zoom_btn_in_cart_product}
                                                  
                                                //   />

                                                  <CartProductNew
                                                  key={dd?.id}
                                                  id={dd?.id}
                                                  cartData={cart_data}
                                                  group_in_wishlist={group_in_wishlist}
                                                  group_in_cart={group_in_cart}
                                                  fetchData={fetchData}
                                                  productIndex={__}
                                                  product_detail={dd}
                                                  general_settings = {gen_settings}
                                                  isLastProduct={__ === cart_data?.product_details.length - 1}
                                                  type_of_cart={type_of_cart}
                                                  
                                                  
                                                  />
                                              
                                            );
                                          })}
                                          {/* {!is_num_even(d?.product_details.length) && <EmptyCart/>} */}
                                      </AccordionBody>
                                    )}
                                  </AccordionItem>
                                </>
                              ) : (
                                <>
                                  {cart_data?.product_details &&
                                    cart_data?.product_details.length > 0 && (
                                      <>
                                        <h2 className="accordion-header ">
                                          <button
                                            type="button"
                                            aria-expanded={
                                              toggleAccordion ? "true" : "false"
                                            }
                                            onClick={() =>
                                              setToggleAccordion(
                                                !toggleAccordion
                                              )
                                            }
                                            className={
                                              toggleAccordion
                                                ? "accordion-button prod-accordian-header "
                                                : "accordion-button prod-accordian-header collapsed"
                                            }
                                          >
                                            <span className="accordian-body w-100">
                                              <div className="d-flex flex-wrap g-2 justify-content-between prod-accordian-header">
                                                <div>
                                                  <span className="fw-bold">
                                                    {cart_data?.labour_type_name.toUpperCase()}
                                                  </span>
                                                </div>
                                                <div className="d-flex mr-2 right-side">
                                                  <div className="wt">
                                                    <span className="fw-light">
                                                      {cart_data?.total_gross_wt} gm
                                                    </span>
                                                  </div>
                                                  <div className="vr mx-2"></div>
                                                  <div className="qt">
                                                    <span className="fw-light">
                                                      {cart_data?.total_quantity} pcs
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </button>
                                        </h2>

                                        <div
                                          className={`accordion-collapse collapse ${
                                            toggleAccordion ? "show" : ""
                                          } `}
                                        >
                                          <div className="accordion-body px-sm-3 pt-0 px-0 cart-accordation-body">
                                            {cart_data?.product_details.length > 0 &&
                                              cart_data?.product_details.map(
                                                (product_data, __) => {
                                                  return (
                                                    
                                                      // <CartProduct
                                                      // key={product_data?.id}
                                                      // id={product_data?.id}
                                                      // cartData={cart_data}
                                                      // group_in_wishlist={group_in_wishlist}
                                                      // group_in_cart={group_in_cart}
                                                      // fetchData={fetchData}
                                                      // productIndex={__}
                                                      // product_detail={product_data}
                                                      // general_settings = {gen_settings}
                                                      // isLastProduct={__ === cart_data?.product_details.length - 1}
                                                      // type_of_cart={type_of_cart}
                                                      // show_zoom_btn_in_cart_product = {show_zoom_btn_in_cart_product}
                                                      
                                                      // />

                                                      <CartProductNew
                                                      key={product_data?.id}
                                                      id={product_data?.id}
                                                      cartData={cart_data}
                                                      group_in_wishlist={group_in_wishlist}
                                                      group_in_cart={group_in_cart}
                                                      fetchData={fetchData}
                                                      productIndex={__}
                                                      product_detail={product_data}
                                                      general_settings = {gen_settings}
                                                      isLastProduct={__ === cart_data?.product_details.length - 1}
                                                      type_of_cart={type_of_cart}
                                                      
                                                      
                                                      />
                                                    
                                                  );
                                                }
                                              )}
                                              {/* {!is_num_even(d?.product_details.length) && <EmptyCart/>} */}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                </>
                              )}
                            </span>
                          );
                        })}
                      </Accordion>
                    ) : (
                      <>
                        {/* <h1>
                          {" "}
                          {shortList === "1"
                            ? "No products in Shortlist"
                            : "No Products in cart"}
                        </h1> */}

                        <NoProduct showLoader={true} errMsg={type_of_cart === "temp_wishlist" ? "No products in Shortlist" : "No Products in cart"} hideHomeBtn={true}/>
                      </>
                    )}
                  </div>
                
              </>
            
          </section>
          
          <Modal
            show={show}
            centered
            // onExit={() => setShow(false)}
            close
            onHide={() => setShow(false)}
          >
            <ModalHeader closeButton>
              <ModalTitle>Alert</ModalTitle>
            </ModalHeader>
            <ModalBody>{`Are you sure you want to Empty ${type_of_cart === "temp_wishlist" ? "Shortlist" : "Cart"}?`}</ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={() => {
                  
                  document.body.style.setProperty("overflow", "visible")
                  emptyCart();
                }}
              >
                {" "}
                Yes
              </button>
              <button
                className="btn btn-primary button_background_color modal-btn"
                onClick={() => {
                  setShow(false);
                  document.body.style.setProperty("overflow", "visible")
                }}
              >
                No
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    </div>
  );
};

export default CartProductSection;
