/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import "./CheckoutModalOrderInfo.css";
import { ApiList } from "../../Api/ApiList";
import Notifier from "../../Component/Notifier";
import { useLocation } from "react-router-dom";
import {
  ToastError,
  depended_child_func,
  get_informationFromSessionID,
  get_property_settings,
  get_userData_from_activeUser,
} from "../../Component/Helper";
import { useDispatch, useSelector } from "react-redux";
import {
  get_inCartWishList,
  set_inCartWishList,
  setConstants,
} from "../../redux/Reducer/reducer";
import { fetch_data_from_api } from "../../Api/APICalls";
import Textfield from "./Components/Textfield";
import Telephone from "./Components/Telephone";
import TextArea from "./Components/TextArea";
import Loader from "../../Component/Loader";
import { useForm, Controller } from "react-hook-form";
import { MOBILE_REGEX } from "../../utils";
import DropdownSelect from "./Components/DropDown/Components/DropdownSelect";
import RangeDropdownSelect from "./Components/DropDown/Components/RangeDropdownSelect";
import Button from "./Components/DropDown/Components/Button";
import ButtonFlow from "./Components/DropDown/Components/ButtonFlow";
import FormLabel from "./Components/FormLabel";
import FormError from "./Components/FormError";
import OrderTags from "./Components/OrderTags/OrderTags";
import CheckoutOrderInfoModalConfig from "./Components/CheckoutOrderInfoModalConfig";
import "./CheckoutSideModal.css";
import MUISideBar from "../../Component/MUISideBar/MUISideBar";
import AddRetailerForm from "../../Component/AddRetailerForm/AddRetailerForm";
import SingleSelectDropdown from "./Components/DropDown/Components/SingleSelectDropdown";
import SelectDropdownGlob from "./Components/DropDown/Components/SelectDropdownGlob";

const CheckoutNew = ({ order_data }) => {
  const [parameterData, setParameterData] = useState([]);

  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [notifierData, setNotifierData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [depended_child, set_depended_child] = useState({});

  const company_code = get_informationFromSessionID("companyCode");
  const user_id = get_informationFromSessionID("userID");
  const userName = user_id && get_userData_from_activeUser().full_name;
  const userMobile = user_id && get_userData_from_activeUser().mobile_no;
  const client_type =
    (user_id && get_userData_from_activeUser().client_type) || "1";

  const cartcounts = useSelector((state) => state?.reducer?.constants?.count);
  const dispatch = useDispatch();
  const catalogue_selection_based_on_web = get_property_settings(
    "catalogue_selection_based_on_web"
  );
  const location = useLocation();
  const retailer_slider_modal_ref = useRef(null);
  // const selectType = "button";
  const inCartWishList = useSelector(get_inCartWishList);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    defaultValues: {
      contact_person_name: userName,
      contact_person_number: userMobile.replace(/\s/g, ""),
    },
  });

  const distributor_client_master_id = watch("distributor")?.value || "";

  //Open Checkout as page or Modal (in order info)
  const open_checkout_modal_in_cart = location.pathname === "/cart";
  const open_checkout_modal_in_past_order =
    location.pathname.includes("/all-order");
  const modal_past_order_finalize_fields_data =
    order_data?.finalize_fields_data || [];

  const fetch_get_parameter = async () => {
    setIsLoading(true);
    let get_parameter_response = await fetch_data_from_api(
      `${ApiList.Finalize}/${ApiList.get_parameters}`,
      "get-parameter",
      {
        client_type,
      }
    );

    if (get_parameter_response.constructor === Array) {
      //update value if checkout is open as modal
      if (
        open_checkout_modal_in_past_order &&
        modal_past_order_finalize_fields_data
      ) {
        const updated_parameter_response = get_parameter_response.map(
          (params) => {
            const finalize_data = modal_past_order_finalize_fields_data.find(
              (fields_data) => fields_data.key === params.field_name
            );
            params.value = finalize_data?.value || "";
            return params;
          }
        );
        
        setParameterData(updated_parameter_response);
      } else {
        setParameterData(get_parameter_response);
      }

      //set default value
      get_parameter_response.forEach((res) => {
        if (res?.short_code === "contact_person_name") {
          setValue("contact_person_name", res?.control_value);
        } else if (res?.short_code === "contact_person_number") {
          setValue(
            "contact_person_number",
            typeof res?.control_value === "string"
              ? res?.control_value?.replace(/-/g, "").replace(/\s/g, "")
              : ""
          );
        }
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
      document.body.scrollTo = 0;
    }, 100);
    fetch_get_parameter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  function modifyData(data) {
    //Set retailer & distributor
    if (data.retailer || data.distributor) {
      data.retailer = data?.retailer?.value || "";
      data.distributor = data?.distributor?.value || "";
    }

    //Set Rangedropdown
    const keys = Object.keys(data);

    // eslint-disable-next-line array-callback-return
    const RangeDropdownKeys = [
      ...new Set(
        keys
          .map((key) => {
            if (key.includes("_from")) {
              return key.replace("_from", "");
            }

            if (key.includes("_to")) {
              return key.replace("_to", "");
            }
          })
          .filter((key) => key)
      ),
    ];

    if (RangeDropdownKeys.length > 0) {
      RangeDropdownKeys.forEach((key) => {
        data[key] = `${data[`${key}_from`].value} to ${
          data[`${key}_to`].value
        }`;
        delete data[`${key}_from`];
        delete data[`${key}_to`];
      });
    }

    keys.forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key] = data[key].join(",");
      }else{
        data[key] =  data[key]?.value || data[key] 
      }
    });

    return data;
  }

  const onSubmit = async (data) => {
    let edited_data = modifyData(data);

    setIsLoading(true);
    const place_order_params = {
      device: "web",
      client_type,
      ...edited_data,
    };
  //  return console.log(place_order_params)
    const place_order_response = await fetch_data_from_api(
      ApiList?.Finalize,
      "finalize",
      place_order_params
    );

    if (place_order_response?.ack === "1") {
      dispatch(setConstants({ count: { ...cartcounts, count: 0 } }));

      setShowOrderSuccess(true);

      dispatch(
        set_inCartWishList({
          ...inCartWishList,
          in_cart_id_dm: [],
          in_cart_id_im: [],
        })
      );

      setNotifierData({
        subtitle: place_order_response.subtitle,
        title: place_order_response.title,
        order_url: place_order_response?.order_url,
      });
    } else {
      ToastError("Something went wrong !!");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (errors.tag_id) {
      ToastError(errors.tag_id?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!errors.tag_id]);

  useEffect(() => {
    const checkoutModal = document.querySelector(".checkout-side-modal");
    if (!showOrderSuccess && checkoutModal) {
      checkoutModal?.classList?.remove("hide-checkout-side-modal");
    } else {
      checkoutModal?.classList?.add("hide-checkout-side-modal");
    }
  }, [showOrderSuccess]);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  const ignore_catalogue_selection_based_on = [
    "distributor",
    "retailer",
    "client",
  ];

  // const is_only_one_textfield =
  //   parameterData.filter((paramData) => paramData?.control_type === "Textfield")
  //     .length === 1;
  // return null
  
  return (
    <section
      className={`checkout mt-5 p-3 p-md-5 checkout-container ${
        open_checkout_modal_in_past_order ? "checkout-modal-order-info" : ""
      }`}
    >
      {open_checkout_modal_in_past_order && (
        <CheckoutOrderInfoModalConfig
          setValue={setValue}
          parameterData={parameterData}
        />
      )}
      <MUISideBar
       width={"min(400px, 100%)"}
        ref={retailer_slider_modal_ref}
        title="Add retailer"
      >
        <AddRetailerForm
          distributor_client_master_id={distributor_client_master_id}
        />
      </MUISideBar>

      <div className="ec-register-wrapper">
        {open_checkout_modal_in_cart ? null : (
          <h2 className="my-4 ec-title title">
            <span>Confirm Order</span>
          </h2>
        )}
        <div className="ec-register-container checkout-container">
          <div className="ec-register-form">
            <form
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className="ui form-ui"
            >
              {parameterData &&
                parameterData.length > 0 &&
                parameterData.map((paramsData, paramsDataIndex) => {
                  const is_full_field =
                    parameterData.length - 1 === paramsDataIndex &&
                    parameterData.length % 2 > 0;

                  const default_select = paramsData?.control_value.includes(
                    paramsData?.default_select
                  )
                    ? paramsData?.default_select
                    : paramsData?.value || "";

                  //If there
                  let show_childs_base_on_dependant_shortcodes =
                    Object.keys(depended_child).length !== 0;
                  if (Object.keys(depended_child).length === 0) {
                    if (
                      (typeof paramsData?.dependant_shortcodes === "string" &&
                        paramsData?.dependant_shortcodes !== "") ||
                      (Array.isArray(paramsData?.dependant_shortcodes) &&
                        paramsData?.dependant_shortcodes.length > 0)
                    ) {
                      depended_child_func(paramsData, null, set_depended_child);
                      show_childs_base_on_dependant_shortcodes = true;
                    }
                  }

                  return (
                    <React.Fragment key={paramsDataIndex}>
                      {paramsData?.control_type === "Label" ? (
                        paramsData?.control_value.map((label, index) => {
                          return (
                            <p className="checkout-note" key={`lable-${index}`}>
                              {label}
                            </p>
                          );
                        })
                      ) : paramsData?.control_type ===
                          "SingleselectDropdownListview" &&
                        paramsData?.short_code === "voucher_order_type" ? (
                        <span
                          className={`ec-register-wrap ${
                            is_full_field ? "" : "ec-register-half"
                          }`}
                        >
                          {/* LABEL */}
                          <FormLabel
                            paramsData={paramsData}
                            show_childs_base_on_dependant_shortcodes={
                              show_childs_base_on_dependant_shortcodes
                            }
                            depended_child={depended_child}
                          />

                          {/* FEILD This is for Voucher Order Type Only*/}
                          <SingleSelectDropdown
                            clsName="fieldValue"
                            paramsDataIndex={paramsDataIndex}
                            paramsData={paramsData}
                            client_type={client_type}
                            control={control}
                            disabled={open_checkout_modal_in_past_order}
                            order_data={order_data}
                            setValue={setValue}
                          />

                          {/* ERROR MSG */}
                          <FormError errors={errors} paramsData={paramsData} />
                        </span>
                      ) : paramsData?.control_type === "Textfield" ? (
                        <span
                          className={`ec-register-wrap ${
                            is_full_field ? "" : "ec-register-half"
                          }`}
                        >
                          {/* LABEL */}
                          <FormLabel
                            paramsData={paramsData}
                            show_childs_base_on_dependant_shortcodes={
                              show_childs_base_on_dependant_shortcodes
                            }
                            depended_child={depended_child}
                          />

                          {/* FEILD */}
                          <Textfield
                            clsName="fieldValue"
                            paramsDataIndex={paramsDataIndex}
                            paramsData={paramsData}
                            register={register}
                            disabled={open_checkout_modal_in_past_order}
                          />

                          {/* ERROR MSG */}
                          <FormError errors={errors} paramsData={paramsData} />
                        </span>
                      ) : paramsData?.control_type === "Telephone" ? (
                        <span
                          className={`ec-register-wrap ${
                            is_full_field ? "" : "ec-register-half"
                          }`}
                          key={paramsDataIndex}
                        >
                          {/* LABEL */}
                          <FormLabel
                            paramsData={paramsData}
                            show_childs_base_on_dependant_shortcodes={
                              show_childs_base_on_dependant_shortcodes
                            }
                            depended_child={depended_child}
                          />

                          {/* FEILD */}
                          <Controller
                            control={control}
                            name={paramsData?.short_code}
                            rules={{
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                              pattern: {
                                value: MOBILE_REGEX,
                                message: "Invalid mobile no.",
                              },
                            }}
                            render={({ field }) => {
                              return (
                                <Telephone
                                  clsName="fieldValue"
                                  paramsDataIndex={paramsDataIndex}
                                  paramsData={paramsData}
                                  disabled={open_checkout_modal_in_past_order}
                                  {...field}
                                />
                              );
                            }}
                          />

                          {/* ERROR MSG */}
                          <FormError errors={errors} paramsData={paramsData} />
                        </span>
                      ) : paramsData?.control_type === "Textarea" ? (
                        <span
                          className={`ec-register-wrap ${
                            is_full_field ? "" : "ec-register-half"
                          }`}
                          key={paramsDataIndex}
                        >
                          {/* LABEL */}
                          <FormLabel
                            paramsData={paramsData}
                            show_childs_base_on_dependant_shortcodes={
                              show_childs_base_on_dependant_shortcodes
                            }
                            depended_child={depended_child}
                          />

                          {/* FEILD */}
                          <TextArea
                            clsName="remarks fieldValue"
                            paramsDataIndex={paramsDataIndex}
                            paramsData={paramsData}
                            disabled={open_checkout_modal_in_past_order}
                            register={register}
                          />

                          {/* ERROR MSG */}
                          <FormError errors={errors} paramsData={paramsData} />
                        </span>
                      ) : //Drop down section start
                      paramsData?.control_type === "Dropdown" &&
                        ignore_catalogue_selection_based_on.includes(
                          paramsData?.short_code
                        ) ? (
                        <span
                          className={`ec-register-wrap ${
                            is_full_field ? "" : "ec-register-half"
                          }`}
                          key={paramsDataIndex}
                        >
                          {/* LABEL */}
                          <FormLabel
                            paramsData={paramsData}
                            show_childs_base_on_dependant_shortcodes={
                              show_childs_base_on_dependant_shortcodes
                            }
                            depended_child={depended_child}
                          />
                          {/* FEILD */}

                          <div className="mb-3 col-sm-7 ec-register-wrap">
                            <section className="user-section">
                              <DropdownSelect
                                clsName=""
                                paramsDataIndex={paramsDataIndex}
                                paramsData={paramsData}
                                control={control}
                                Controller={Controller}
                                setValue={setValue}
                                watch={watch}
                                disabled={open_checkout_modal_in_past_order}
                                order_data={order_data}
                              />

                              {paramsData?.short_code === "retailer" ? (
                                <button
                                  tabIndex={paramsDataIndex + 1}
                                  className="add-btn label ui button-ui"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (!retailer_slider_modal_ref.current)
                                      return;
                                    retailer_slider_modal_ref.current?.open_side_modal(
                                      "right"
                                    );
                                  }}
                                  disabled={open_checkout_modal_in_past_order}
                                >
                                  Add
                                </button>
                              ) : null}
                            </section>
                          </div>

                          {/* ERROR MSG */}
                          <FormError errors={errors} paramsData={paramsData} />
                        </span>
                      ) : paramsData?.control_type === "Dropdown" &&
                        catalogue_selection_based_on_web === "button" ? (
                        <>
                          {paramsData?.condition_shortcode === "" ? (
                            <span
                              className={`ec-register-wrap ${
                                is_full_field ? "" : "ec-register-half"
                              }`}
                              key={paramsDataIndex}
                            >
                              {/* LABEL */}
                              <FormLabel
                                paramsData={paramsData}
                                show_childs_base_on_dependant_shortcodes={
                                  show_childs_base_on_dependant_shortcodes
                                }
                                depended_child={depended_child}
                              />

                              {/* FEILD */}
                              <Button
                                clsName="ec-pro-variation-content"
                                elementId="btnView"
                                paramsDataIndex={paramsDataIndex}
                                paramsData={paramsData}
                                default_select_value={default_select}
                                register={register}
                                setValue={setValue}
                                depended_child={depended_child}
                                set_depended_child={set_depended_child}
                                disabled={open_checkout_modal_in_past_order}
                              />

                              {/* ERROR MSG */}
                              <FormError
                                errors={errors}
                                paramsData={paramsData}
                              />
                            </span>
                          ) : show_childs_base_on_dependant_shortcodes &&
                            depended_child?.child_short_code.includes(
                              paramsData?.short_code
                            ) ? (
                            <span
                              className={`ec-register-wrap ${
                                is_full_field ? "" : "ec-register-half"
                              }`}
                              key={paramsDataIndex}
                            >
                              {/* LABEL */}
                              <FormLabel
                                paramsData={paramsData}
                                show_childs_base_on_dependant_shortcodes={
                                  show_childs_base_on_dependant_shortcodes
                                }
                                depended_child={depended_child}
                              />

                              {/* FEILD */}
                              <Button
                                clsName="ec-pro-variation-content"
                                elementId="btnView"
                                paramsDataIndex={paramsDataIndex}
                                paramsData={paramsData}
                                default_select_value={default_select}
                                register={register}
                                setValue={setValue}
                                depended_child={depended_child}
                                set_depended_child={set_depended_child}
                                disabled={open_checkout_modal_in_past_order}
                              />

                              {/* ERROR MSG */}
                              <FormError
                                errors={errors}
                                paramsData={paramsData}
                              />
                            </span>
                          ) : null}
                        </>
                      ) : paramsData?.control_type === "Dropdown" &&
                        catalogue_selection_based_on_web === "buttonflow" ? (
                        <>
                          {/* FEILD */}
                          {paramsData?.condition_shortcode === "" ? (
                            <span
                              className={`ec-register-wrap ${
                                is_full_field ? "" : "ec-register-half"
                              }`}
                              key={paramsDataIndex}
                            >
                              {/* LABEL */}
                              <FormLabel
                                paramsData={paramsData}
                                show_childs_base_on_dependant_shortcodes={
                                  show_childs_base_on_dependant_shortcodes
                                }
                                depended_child={depended_child}
                              />
                              <ButtonFlow
                                clsName="ec-pro-variation-content"
                                paramsDataIndex={paramsDataIndex}
                                paramsData={paramsData}
                                default_select_value={default_select}
                                register={register}
                                setValue={setValue}
                                depended_child={depended_child}
                                set_depended_child={set_depended_child}
                                disabled={open_checkout_modal_in_past_order}
                              />
                              {/* ERROR MSG */}
                              <FormError
                                errors={errors}
                                paramsData={paramsData}
                              />
                            </span>
                          ) : show_childs_base_on_dependant_shortcodes &&
                            depended_child?.child_short_code.includes(
                              paramsData?.short_code
                            ) ? (
                            <span
                              className={`ec-register-wrap ${
                                is_full_field ? "" : "ec-register-half"
                              }`}
                              key={paramsDataIndex}
                            >
                              {/* LABEL */}
                              <FormLabel
                                paramsData={paramsData}
                                show_childs_base_on_dependant_shortcodes={
                                  show_childs_base_on_dependant_shortcodes
                                }
                                depended_child={depended_child}
                              />

                              <ButtonFlow
                                clsName="ec-pro-variation-content"
                                paramsDataIndex={paramsDataIndex}
                                paramsData={paramsData}
                                default_select_value={default_select}
                                register={register}
                                setValue={setValue}
                                set_depended_child={set_depended_child}
                                depended_child={depended_child}
                                disabled={open_checkout_modal_in_past_order}
                              />
                              {/* ERROR MSG */}
                              <FormError
                                errors={errors}
                                paramsData={paramsData}
                              />
                            </span>
                          ) : null}
                        </>
                      ) : paramsData?.control_type === "Dropdown" &&
                        catalogue_selection_based_on_web ===
                          "dropdown" ? (
                            <span
                              className={`ec-register-wrap ${
                                is_full_field ? "" : "ec-register-half"
                              }`}
                              key={paramsDataIndex}
                            >
                              {/* LABEL */}
                              <FormLabel
                                paramsData={paramsData}
                                show_childs_base_on_dependant_shortcodes={
                                  show_childs_base_on_dependant_shortcodes
                                }
                                depended_child={depended_child}
                              />
                            <SelectDropdownGlob
                            clsName="fieldValue"
                            paramsDataIndex={paramsDataIndex}
                            paramsData={paramsData}
                            control={control}
                            disabled={open_checkout_modal_in_past_order}
                            order_data={order_data}
                            setValue={setValue}
                          />
                           {/* ERROR MSG */}
                           <FormError errors={errors} paramsData={paramsData} />
                              </span>
                          ) : paramsData?.control_type === //Select come here
                        "RangeDropdown" ? (
                        <span
                          className={`ec-register-wrap ${
                            is_full_field ? "" : "ec-register-half"
                          }`}
                          key={paramsDataIndex}
                        >
                          {/* LABEL */}
                          <FormLabel
                            paramsData={paramsData}
                            show_childs_base_on_dependant_shortcodes={
                              show_childs_base_on_dependant_shortcodes
                            }
                            depended_child={depended_child}
                          />
                          {/* FEILD */}
                          <RangeDropdownSelect
                            clsName="d-flex justify-content-between col-6 drop-down-select"
                            paramsDataIndex={paramsDataIndex}
                            paramsData={paramsData}
                            Controller={Controller}
                            control={control}
                            disabled={open_checkout_modal_in_past_order}
                          />

                          {/* ERROR MSG */}
                          <FormError errors={errors} paramsData={paramsData} />
                        </span>
                      ) : paramsData?.control_type ===
                        "MultiselectDropdownListview" ? (
                        // null
                        <OrderTags
                          paramsData={paramsData}
                          show_childs_base_on_dependant_shortcodes={
                            show_childs_base_on_dependant_shortcodes
                          }
                          depended_child={depended_child}
                          is_full_field={is_full_field}
                          paramsDataIndex={paramsDataIndex}
                          client_type={client_type}
                          register={register}
                          setValue={setValue}
                          getValues={getValues}
                          disabled={open_checkout_modal_in_past_order}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}

              <span className="ec-register-wrap ec-register-btn ui button-ui place-order-button">
                <button
                  className={`${
                    isSubmitted && !isValid ? "disabled-btn" : ""
                  } btn ui button-ui`}
                  tabIndex={parameterData && parameterData.length + 1}
                  type="submit"
                  onClick={(e) => {
                    handleSubmit(company_code, user_id, e);
                  }}
                  disabled={isSubmitted && !isValid ? true : false}
                >
                  Place Order
                </button>
              </span>
            </form>
          </div>
        </div>
      </div>
      {showOrderSuccess ? (
        <Notifier
          show={showOrderSuccess}
          setShowOrderSuccess={setShowOrderSuccess}
          header="Order Confirmation"
          showFooter={true}
          title={notifierData && notifierData?.title}
          subtitle={notifierData && notifierData?.subtitle}
          order_url={notifierData?.order_url}
        />
      ) : null}
    </section>
  );
};

export default CheckoutNew;
