import Slider from "react-slick";
import ZoomImageSection from "./ZoomImageSection";

function ZoomSliderSection({imageRootPath, allThumbImages, currentSlide}) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentSlide,
      };
      return (
        <div className="slider-container">
          <Slider {...settings}>
            {
                allThumbImages.map(img => <ZoomImageSection imagepath={`${imageRootPath}${img}`} key={img}/>)
            }
            
          </Slider>
        </div>
      );
}

export default ZoomSliderSection;