import React from 'react'
import ChainHouse24LIvePageReact from './CustomLiveRatePage/ChainHouse24/ChainHouse24LIvePageReact';
import LiveRatesReact from './LiveRatesReact';
import { get_informationFromSessionID } from '../../Component/Helper';

const redirection = () => {
    const companyCode = get_informationFromSessionID("companyCode");
    const decryptCompanyName = atob(companyCode);

    switch(decryptCompanyName){
        case "CHAINHOUSE24": return <ChainHouse24LIvePageReact />;
        default : return <LiveRatesReact /> 
    }
}

export default function LivePageRedirection() {
    return redirection()
    
}
