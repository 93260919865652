import React from 'react'
import CustomizedMenus from './CustomizedMenus/CustomizedMenus'
import "./InlineFilter.css";
import { useQuery } from '@tanstack/react-query';
import { fetch_data_from_api_from_query } from '../../../../Api/APICalls';
import { ApiList } from '../../../../Api/ApiList';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCatMaster, get_is_myc_web } from '../../../../redux/Reducer/reducer';
import { get_designStatus } from '../../../../Component/Helper';
import FilteredLabel from './FilteredLabel';
import { useForm, FormProvider } from "react-hook-form"


function InlineFilter() {
  // return null;
  
  let location = useLocation();
  let searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const is_myc_web = useSelector(get_is_myc_web);
  const master = useSelector(getCatMaster);
  const methods = useForm()
  
  const { data, isSuccess } = useQuery({
    queryKey: [searchParams?.cat_id, searchParams.table, "fetchparams"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fetchParams, "fetchparams", {
        cat_id: searchParams.cat_id || "0",
        featured_id: searchParams?.featured_id || "",
        design_status: get_designStatus(searchParams.table || master),
        table: searchParams.table || master,
        mode: is_myc_web ? "my_catalogue" : searchParams.mode === "named_wishlist" ?  "named_wishlist" : searchParams?.mode === "all" ? "all" : "",
        wishlist_id: searchParams?.wishlist_id || ""
      }).then((res) => {
        if(typeof res === "string"){
          return []
        }else{

          return res;
        }
      }),
  });
  
  if(!isSuccess || (Array.isArray(data) && data.length === 0)){
    return null;
  }
  
 return (
    <>
    <FormProvider {...methods}>
      <div className='InlineFilter-section d-lg-flex d-none'>
        <div className='dropdown-options'>
        {
          isSuccess && data[0].sort.map(sortData => {
            if (data[0]?.data[sortData?.field]?.length === 0) return null;
            return (
              <CustomizedMenus key={sortData?.field} option= {sortData} optionData={data[0]?.data[sortData?.field]} allOptionData = {data[0]?.data}/>
            )
          })
        }
        </div>
        <div className='filtered-label'>
          <FilteredLabel optionData={data[0]?.data} sortData={data[0].sort}/>
        </div>
      
      
      </div>
    </FormProvider>
    
    </>
  )
}

export default InlineFilter