// developemnt
const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development"
export const BASE_URL = IS_DEVELOPMENT_MODE ? "https://demo.jewelflow.pro/online_api/v3/" : "https://prodone.jewelflow.pro/online_api/v3/";
// export const PROXY_BASE_URL = "http://192.168.1.11:5000";

//production
// export const BASE_URL = "http://prodone.jewelflow.pro/";
// export const BASE_URL = "https://onlineapi.jewelflowdev.com/v3/";
export const PROXY_BASE_URL = IS_DEVELOPMENT_MODE ? "https://v2.jewelflow.pro" : "https://node.jewelflow.link";

// export const PROXY_BASE_URL =
//   "https://triologic-back-5mjtvjpuh-pavan-triologic.vercel.app";

// export const company_code = "TElWRURFTU8=";
// export const constants =
//   "?catalogue_id=31&user_id=1182&company_code=TElWRURFTU8=";

export const ApiList = {
  setting: "Setting",
  master_setting: "master_setting",
  homeScreen: "Homescreen",
  fetchCat: "FetchCat",
  fetchStaticpages: "FetchStaticpages",
  staticpage: "StaticPage",
  matrix: "Matrix",
  sortParams: "SortParams",
  productMatrixCount: "Product_Matrix_Count",
  fetchParams: "FetchParams",
  login: "Login",
  generateOtp: "generate_login_otp",
  cart: "Cart",
  cartCount: "CartCount",
  addToCartMatrix: "AddToCartMatrix",
  fullScreen: "Fullscreen",
  addToCart: "AddtoCart",
  updateCart: "UpdateCart",
  fetchCartListGrouped: "FetchCartListGrouped",
  clearCart: "ClearCart",
  transferAllProducts: "TransferAllProducts",
  DeleteFromCart: "DeleteFromCart",
  TransferProduct: "TransferProduct",
  UpdateCartParams: "UpdateCartParams",
  Finalize: "Finalize",
  payment_failed: "Finalize/payment_failed",
  get_parameters: "get_parameters",
  UserRegistration: "UserRegistration",
  get_registration_form_params: "get_registration_form_params",
  get_loc_data: "get_loc_data",
  generate_verification_keys: "generate_verification_keys",
  forgot_password_code_gen: "forgot_password_code_gen",
  reset_password: "reset_password",
  Delete_From_Cart_Wishlist_Matrix: "Delete_From_Cart_Wishlist_Matrix",
  Currency: "Currency",
  Whatsapp: "Whatsapp",
  Search_keywords: "Search_keywords",
  get_distributor: "get_distributor",
  get_retailer: "get_retailer",
  add_retailer_get_parameters: "add_retailer_get_parameters",
  add_retailer_check_sendOTP: "add_retailer_check_sendOTP",
  add_retailer: "add_retailer",
  MycWeb: "MycWeb/get_link_master_details",
  Location:"Location",
  kam_order_list: "kam_order_list",
  get_kam_order_details: "getOrderDetail",
  jewelkam_multi_welcome_screen: "JewelkamMultipleItems/fetch_default_general_fields_list",
  fetch_cat_list: "fetch_cat_list",
  fetch_category_wise_fields_list:"JewelkamMultipleItems/fetch_category_wise_fields_list",
  fetch_fields_list: "fetch_fields_list",
  jewelkam_multi_order_place: "JewelkamMultipleItems/multiple_order",
  jewelkam_cancel_order: "updateOrderStatus",
  NewsLetterSubscribe: "Newsletter/subscribe",
  get_edit_profile_form_params: "get_edit_profile_form_params",
  change_password: "change_password",
  update_profile: "update_profile",
  get_named_wishlist_list: "Named_wishlist/get_named_wishlist_list",
  create_named_wishlist:"Named_wishlist/create_named_wishlist",
  rename_named_wishlist:"Named_wishlist/rename_named_wishlist",
  delete_wishlist: "Named_wishlist/delete_wishlist",
  empty_wishlist: "Named_wishlist/empty_wishlist",
  wishlist_addToCartAll: "Named_wishlist/AddToCartAll",
  named_wishlist_manage_product: "Named_wishlist/manage_product",
  remove_from_named_wishlist: "Named_wishlist/remove_from_wishlist",
  order_list: 'Orders/order_list',
  order_list_grouped: 'Orders/FetchOrderListGrouped',
  AddToCartRandom: "Named_wishlist/AddToCartRandom",
  News_updates: "News_updates",
  CallUs: "CallUs",
  Jewelkam_check_design_exist: "JewelKam/check_design_exist",
  Live_rates_sc: "Live_rates_sc",
  finalize_get_master_data: "Finalize/get_master_data",
  myaccount_get_user_checkout_favourites: "Finalize/myaccount_get_user_checkout_favourites",
  myaccount_set_user_checkout_favourites: "Finalize/myaccount_set_user_checkout_favourites",
  cancel_past_order: "Orders/cancel_order",
  get_behalf_client_details:"JewelKam/get_behalf_client_details",
  fetch_fields_list_client_master: "JewelKam/fetch_fields_list_client_master",
  set_client_master_data: "JewelKam/set_client_master_data",
  fetch_order_filter_param: "JewelKam/fetch_order_filter_param",
  get_karigar_details: "JewelKam/get_karigar_details"
};
