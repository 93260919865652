import { get_informationFromSessionID } from "../../../../Component/Helper"

export function show_homescreen_popup (popup_code) {
    
    const companyCode = get_informationFromSessionID("companyCode")
    const homescreen_popup = JSON.parse(localStorage.getItem("homescreen_popup"))
    const homescreen_popup_already_shown = JSON.parse(sessionStorage.getItem("homescreen_popup_already_shown"))
    if(!homescreen_popup || !homescreen_popup_already_shown){
        
        return true
    }else{
        const popup = homescreen_popup.find(popup => popup.companyCode === companyCode)
        if(popup.popup_code.includes(popup_code) || homescreen_popup_already_shown) {
            return false
        }else{
            if(!homescreen_popup_already_shown){

                return true
            }else{
                return false
            }
        }
    }
}

export function dont_show_homescreen_popup (popup_code) {
    const companyCode = get_informationFromSessionID("companyCode")
    const homescreen_popup = JSON.parse(localStorage.getItem("homescreen_popup"))

    if(!homescreen_popup){
        localStorage.setItem("homescreen_popup", JSON.stringify([{companyCode, popup_code: [popup_code]}]))
    }else{
        const edited_homescreen_popup = homescreen_popup.map(popup => {
            if(popup?.companyCode === companyCode){
                popup.popup_code = [...popup.popup_code, popup_code]
                return popup
            }else{
                return popup
            }
        })

        localStorage.setItem("homescreen_popup", JSON.stringify(edited_homescreen_popup))
    }
}