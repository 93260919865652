import { useEffect, useRef, useState } from 'react';
import { get_informationFromSessionID } from '../../Component/Helper';
import { ApiList } from '../../Api/ApiList';
import { useLocation } from "react-router-dom"
import "./StaticPage.css";
import { fetch_data_from_api } from '../../Api/APICalls';
import Loader from "../../Component/Loader"
import NoProduct from '../../Component/NoProduct/NoProduct';

function StaticPage() {
    const location = useLocation()
    const searchParams = Object.fromEntries(
        new URLSearchParams(location.search)
    )
    const [iframeHeight, setIframeHeight] = useState("auto")

    const iframeRef = useRef(null)

    const companyCode = get_informationFromSessionID("companyCode");
    const [data, setData] = useState(null)
    const [dummyData, setDummyData] = useState(null)
   
    const [loading, setLoading] = useState(false)
    const fetchHTMLData = async () => {
        setLoading(true)
        const staticPageResponse = await fetch_data_from_api(ApiList.staticpage, 'StaticPage', { company_code: companyCode, page: searchParams.id })
        setData(staticPageResponse)
        let d1 = staticPageResponse.replace(/body.+{/g, '.body{').replace(/.body{/g, '.body{')
        d1 = d1.replace(/<body/g, `<body class="body"`)

        
        setDummyData(d1)
        setLoading(false)

    }


    useEffect(() => {
        fetchHTMLData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.id])


    function iframeDynamicHeight () {
        const iframeContentHeight = document.getElementById("iframeStatic")?.contentWindow?.document?.body?.offsetHeight
            const offsetHeight = 30
            if(!iframeContentHeight) return
           
            setIframeHeight(`${iframeContentHeight + offsetHeight}px`)
    }
    

    useEffect(() => {
        
        if(!iframeRef.current) return
       
            
        iframeDynamicHeight ()
       
        
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.getElementById("iframeStatic")?.contentWindow?.document?.body?.offsetHeight, searchParams])

    
    const NoData = typeof data === "string" && data?.includes("No Data Available")


    
   
    return (
        <section className='static-page'>
            {
                loading ? <Loader isLoading={loading} /> : NoData ? <NoProduct errMsg={"No Data Available"} hideHomeBtn={true}/> : (
                    <article >
                        <div className="dummy-static-page-container" dangerouslySetInnerHTML={{ __html: dummyData }}></div>
                        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                        <iframe srcDoc={data} frameBorder="0" width='100%' style={{ overflow: 'hidden', height: iframeHeight }} ref={iframeRef} id="iframeStatic" onLoad={() => {
                            iframeDynamicHeight ()
                        }}></iframe>
                    </article>
                )
            }

        </section>
    )
}

export default StaticPage
