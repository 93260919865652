import React, {  useMemo, memo } from "react";
import Modal from "react-bootstrap/Modal";
import CategoryListGroup from "./CategoryListGroup";
import { useSelector } from "react-redux";
import { getCatMaster,  get_is_myc_web } from "../../redux/Reducer/reducer";
import { useLocation, useNavigate } from "react-router-dom";
import "./RecursiveCategoryModal.css";
import {
  createSearchURLParams,
  get_informationFromSessionID,
  get_property_settings,
} from "../Helper";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import ButtonImageGroup from "./ButtonImage/ButtonImageGroup";
import Loader from "../Loader";
import { getCustomValue } from "../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../Custom/CompanyCustomization";
import { append_all_product_menu_in_categories } from "../../utils";

function RecursiveCategoryModal({ showCategoryModal, setShowCategoryModal }) {
  const hide_product_count = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.hide_product_count)
  const show_hide_category_counts =
    get_property_settings("show_hide_category_counts") === "show" && !hide_product_count;
  // const [subCategories, setSubCategory] = useState(null);
  const web_parent_category_from_for_navigation_bar_text = get_property_settings("web_parent_category_from_for_navigation_bar_text")
  const web_parent_category_from_for_navigation_bar_text_url_string = web_parent_category_from_for_navigation_bar_text.toLowerCase().replace(/\s/g,"_")
  const all_category_button =
  get_property_settings("all_category_button") === "Yes";

  const location = useLocation();
  const navigate = useNavigate();
  const master = useSelector(getCatMaster);
  const userID = get_informationFromSessionID("userID");
  const is_myc_web = useSelector(get_is_myc_web);
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const cat_id = searchParams.cat_id;
  const is_our_collection = searchParams?.cat_id === web_parent_category_from_for_navigation_bar_text_url_string

  const category_query = useQuery({
    queryKey: ["category", master, userID],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
        which_master: master,
      }),
    initialData: [],
    enabled: !is_myc_web 
  });

  

  const findID = (categoryData, cat_id, nestingKey) => {
    if(!Array.isArray(categoryData) || is_our_collection) return null;
    // eslint-disable-next-line array-callback-return
    return categoryData?.reduce((result, currentItem) => {
      if (result) return result;
      if (currentItem.id === cat_id) return currentItem;
      if (currentItem[nestingKey])
        return findID(currentItem[nestingKey], cat_id, nestingKey);
    }, null);
  }
    

  
  
  // useEffect(() => {
  //   if (!cat_id) return;
  //     const get_recurssive_data = findID(category_query?.data, cat_id, "subcategory")
  //     setSubCategory(get_recurssive_data);
      
  //   }, [cat_id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const category_list = useMemo(() => append_all_product_menu_in_categories(category_query?.data), [category_query?.data])
  const subCategories = useMemo(() => findID(category_list, cat_id, "subcategory"), [cat_id])
  
  
  return (
    <Modal
      show={showCategoryModal}
      onHide={() => { 
        navigate("/home")
        setShowCategoryModal(false);
      }
    }
      className="categoryModal"
    >
      {(!subCategories && !is_our_collection) || category_query?.isLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg d-flex align-items-center">
              
                <IoMdArrowRoundBack
                  className="backbtn"
                  onClick={() => {
                    if(subCategories?.cat_parent_id !== "0" && !is_our_collection){
                      navigate(
                        createSearchURLParams("home", {
                          cat_id: subCategories?.cat_parent_id,
                        })
                      );
                    }else{
                      if(searchParams.cat_id !== web_parent_category_from_for_navigation_bar_text_url_string){
                        navigate(
                          createSearchURLParams("home", {
                            cat_id: web_parent_category_from_for_navigation_bar_text_url_string,
                          })
                        );
                      }else{
                        setShowCategoryModal(false);
                        navigate(
                          createSearchURLParams("home", {})
                        );
                        
                      }
                    }
                    
                    
                  }}
                />
              
              <span className="modal-title">{is_our_collection ? web_parent_category_from_for_navigation_bar_text : subCategories?.cat_name}</span>

              {show_hide_category_counts && !is_our_collection &&
                subCategories?.product_count !== null ? (
                  <span className="modal-count modal-title">
                    {subCategories?.product_count}
                  </span>
                ) : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {subCategories?.subcategory_view_style === "tableview" || is_our_collection ? (
            <CategoryListGroup
                subCategories={is_our_collection ? category_list : subCategories.subcategory}
                setShowCategoryModal={setShowCategoryModal}
                all_category_button = {all_category_button && subCategories ? {cat_id: subCategories?.id, product_count: subCategories?.product_count }: null}
              />
            ) : (
              
              <ButtonImageGroup
                subCategories={is_our_collection ? category_list : subCategories.subcategory}
                setShowCategoryModal={setShowCategoryModal}
                layoutStyle={subCategories?.display_style_for_imagebutton}
              />
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

export default memo(RecursiveCategoryModal);
