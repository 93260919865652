import AboutUsSection from '../About_Us_Section';
import "./IGJE_Home_About_Us_Comp.css";


function IGJE_Home_About_Us_Comp() {
  return (
    <AboutUsSection title="IGJE FOR MANUFACTURERS">
        <div className="igje-about-comp-info">
            

            <ol>
                <li>At IGJE Pvt Ltd, we collaborate with vendors and manufacturers in India, empowering them to export jewellery worldwide. </li>
                <li>We provide jewellery manufacturers with apps and websites, simplifying the complexities of selling, payments, shipping, and service processes.</li>
                <li>IGJE is registered with various Indian agencies to facilitate exports and obtain export benefits directly. Manufacturers and vendors do not need separate registrations with these agencies to export.</li>
                <li>
                Our platform enables manufacturers and vendors to scale globally within minutes, leveraging all the advantages of exports and favorable foreign exchange rates. We offer instant integration with various payment methods and ensure competitive foreign exchange rates.
                </li>
                <li>
                Most items enjoy free or minimal shipping costs, further enhancing the accessibility of global markets for our partners.
                </li>
            </ol>
        </div>
    </AboutUsSection>
  )
}

export default IGJE_Home_About_Us_Comp