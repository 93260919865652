import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";
import { createSearchURLParams, get_property_settings } from "../Helper";
import { IoMdArrowRoundForward } from "react-icons/io";
import { getCustomValue } from "../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../Custom/CompanyCustomization";

function CategoryListGroup({
  subCategories,
  setShowCategoryModal,
  all_category_button,
}) {
  const navigate = useNavigate();
  const hide_product_count = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.hide_product_count
  );
  const show_hide_category_counts =
    get_property_settings("show_hide_category_counts") === "show" &&
    !hide_product_count;

  return (
    <ListGroup>
      {all_category_button ? (
        <ListGroup.Item
          onClick={() => {
            setShowCategoryModal(false);
            navigate(
              createSearchURLParams("matrix-page", {
                cat_id: all_category_button?.cat_id,
                mode: "filter",
                page: 0,
              })
            );
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="" onClick={(e) => e.preventDefault()}>
            All
          </a>
          {show_hide_category_counts && (
            <span className="product-count">
              {all_category_button?.product_count || 0}
            </span>
          )}
          {
            <IoMdArrowRoundForward
              className="forword-arrow"
              style={{ visibility: "hidden" }}
            />
          }
        </ListGroup.Item>
      ) : null}

      {subCategories.map((category) => {
        const default_image_type_matrix = get_property_settings("default_image_type_matrix");
        const default_sort = get_property_settings("default_sort");
        const all_products_params = category.cat_name.toLowerCase() === "all products" ? {mode: "all", img_type: default_image_type_matrix, sort: default_sort} : {}
        return (
          <ListGroup.Item
            key={category.id}
            onClick={() => {
              if (
                category.show_subcat === "Yes" &&
                category?.subcategory?.length > 0
              ) {
                navigate(
                  createSearchURLParams("home", { cat_id: category?.id })
                );
              } else {
                setShowCategoryModal(false);
                navigate(
                  createSearchURLParams("matrix-page", {
                    cat_id: category?.id,
                    mode: "filter",
                    page: 0,
                    img_type: category?.img_type || "",
                    ...all_products_params,
                  })
                );
              }
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" onClick={(e) => e.preventDefault()}>
              {category.cat_name}
            </a>
            {show_hide_category_counts && (
              <span className="product-count">
                {category?.product_count || 0}
              </span>
            )}
            {
              <IoMdArrowRoundForward
                className="forword-arrow"
                style={{
                  visibility:
                    category.show_subcat === "Yes" &&
                    category?.subcategory.length > 0
                      ? "visible"
                      : "hidden",
                }}
              />
            }
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

export default CategoryListGroup;
