/* eslint-disable react/jsx-no-duplicate-props */
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { get_property_settings } from "../../../../../Component/Helper";





function DropdownMenu({ open, anchorEl, handleClose, handleOpenModal, handle_open_QR_code_modal, order_data }) {

  let show_qr_code_for_past_orders = get_property_settings("show_qr_code_for_past_orders")
  show_qr_code_for_past_orders = show_qr_code_for_past_orders === "Yes"

 
  
  const order_url = order_data?.order_url;
  
  return (
    <div>
      
      <Menu
        id="basic-menu"
        className="past-order-dropdown-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose} className="past-order-dropdwon-item">
          <a href={order_url} target="_blank" rel="noreferrer">
            Order Pdf
          </a>
        </MenuItem>

        {show_qr_code_for_past_orders && <MenuItem onClick={() => {
          handle_open_QR_code_modal()
          handleClose()
        }} className="past-order-dropdwon-item">
          Order QR Code
        </MenuItem>}

        <MenuItem className="past-order-dropdwon-item"
            onClick={() => {
                handleOpenModal()
                handleClose()
            }}
        >
          Order Info
        </MenuItem>
      </Menu>
    </div>
  );
}

export default DropdownMenu;
