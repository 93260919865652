export function is_default_value(field, fieldType, optionData, selectedValue){
    let default_value = false
    optionData.forEach((data) => {
        if (fieldType === "range") {
          const default_min_value = Number(data[`min_${field}`]);
          const default_max_value = Number(data[`max_${field}`]);
          default_value = selectedValue.minmax[0] === default_min_value && selectedValue.minmax[1] === default_max_value
        } else {
            
          
        }
      });

      return default_value;
}

export function convert_filterd_to_capsul_string(filteredData, optionData, sortData){
   
  let key = Object.keys(filteredData)[0]
  //No capsule if design_status is not array (or is string)
  if(key === "design_status" && typeof filteredData?.design_status === "string") return null
    const editedKey = key.includes("price") ? "price" : key; //for kalasha_price
    const custom_label = sortData.find(sort => sort.field === editedKey)?.custom_label
    
    if(filteredData[key] && typeof filteredData[key] === "object" && "minmax" in filteredData[key]){

      return {key, str: `${custom_label}: ${filteredData[key].minmax[0]} -  ${filteredData[key].minmax[1]}`}
    }else{
      if(typeof filteredData[key] === "string"){
        const option = optionData[key].find(data => data.id === filteredData[key] )
        if(!filteredData[key]) return null;
        return {key, str: `${custom_label}: ${option[`${key}_value`]}`}

      }else if(Array.isArray(filteredData[key])){
        return filteredData[key].map(filter => {
          const option = optionData[key] ? optionData[key].find(data => data.id === filter ) : null;
          if(!option) return null
          return {key, str: `${custom_label}: ${option[`${key}_value`]}`, id: option.id}
        })
        
      }else{
        return null
      }
    }
}