export const CUSTOMIZIED_COMPANY_PROPERTY = {
    new_arrivals: "new_arrivals",
    custom_menu: "custom_menu",
    horizontal_menu: "horizontal_menu",
    center_horizontal_menu: "center_horizontal_menu",
    show_total_in_cart_footer: "show_total_in_cart_footer",
    show_about_us_comp_on_home_page : "show_about_us_comp_on_home_page",
    custom_footer_links: "custom_footer_links",
    center_compnay_logo: "center_compnay_logo",
    show_floating_social_icons: "show_floating_social_icons",
    fsv_hover_zoom_image: "fsv_hover_zoom_image",
    show_homescreen_animated_category: "show_homescreen_animated_category",
    show_matrix_pagination_at_bottom: "show_matrix_pagination_at_bottom",
    show_matrix_pagination_at_both: "show_matrix_pagination_at_both",
    show_matrix_branding_banner: "show_matrix_branding_banner",
    show_horizontal_align_location_in_footer_section: "show_horizontal_align_location_in_footer_section",
    show_inline_matrix_filter_option: "show_inline_matrix_filter_option",
    hide_elements_from_cart_footer: "hide_elements_from_cart_footer",
    hide_background_store_location_card: "hide_background_store_location_card",
    show_footer_custom_location_link: "show_footer_custom_location_link",
    show_only_product_image_in_home_productbase_section: "show_only_product_image_in_home_productbase_section",
    custom_reposition_cart_params: "custom_reposition_cart_params",
    store_location_bg_banner_image_position: "store_location_bg_banner_image_position",
   show_hover_shadow_effect_on_home_product_card: "show_hover_shadow_effect_on_home_product_card",
   show_shortlist_icon_as_named_shortlist: "show_shortlist_icon_as_named_shortlist",
   show_random_product_option_in_named_wishlist: "show_random_product_option_in_named_wishlist",
   show_fsv_custom_varient_details: "show_fsv_custom_varient_details",
   fsv_custom_varient_details: "fsv_custom_varient_details",
   show_small_video_for_branding: "show_small_video_for_branding",
   footer_height_to_adjest_download_app_image: "footer_height_to_adjest_download_app_image",
   show_left_header_extra_company_logo: "show_left_header_extra_company_logo",
   show_extracted_subcategory_in_desktop_dropdown_menu : "show_extracted_subcategory_in_desktop_dropdown_menu",
   show_contact_us_in_matrix_branding: "show_contact_us_in_matrix_branding",
   hide_product_count: "hide_product_count",
   cart_to_fsv_redirection: "cart_to_fsv_redirection",
   show_matrix_details_in_grid: "show_matrix_details_in_grid",
   hide_menu_when_user_not_loggedin: "hide_menu_when_user_not_loggedin",
   show_pcs_sets_in_cart_footer: "show_pcs_sets_in_cart_footer",
   calculate_oms_weight_based_on_design_no: "calculate_oms_weight_based_on_design_no",
   show_thumbnail_in_menu_dropdown: "show_thumbnail_in_menu_dropdown",
   shift_upward_home_banner_gradient: "shift_upward_home_banner_gradient"
}


export class Company {
  constructor(value) {
    this.new_arrivals = value?.new_arrivals || "New Arrivals";
    this.custom_menu = value?.custom_menu || [{
      name: "Custom Order",
      link: "/custom-order",
      external_link: false,
      static_group: false,
      custom_link: true,
    },
    {
      name: "Menu",
      link: "/categories",
      external_link: false,
      static_group: false,
      custom_link: true,
    },
    {
      name: "Live Rates",
      link: "/live-rates",
      external_link: false,
      static_group: false,
      custom_link: true,
    },];
    this.custom_footer_links = value?.custom_footer_links || null;
    this.center_compnay_logo = value?.center_compnay_logo || null;
    this.fsv_hover_zoom_image = value?.fsv_hover_zoom_image || false;
    this.show_horizontal_align_location_in_footer_section = value?.show_horizontal_align_location_in_footer_section || false;
    this.show_floating_social_icons = value?.show_floating_social_icons || false;
    this.show_matrix_branding_banner = value?.show_matrix_branding_banner || false;
    this.show_matrix_pagination_at_bottom = value?.show_matrix_pagination_at_bottom || false;
    this.horizontal_menu = value?.horizontal_menu || {status: false};
    this.center_horizontal_menu = value?.center_horizontal_menu || false;
    this.show_inline_matrix_filter_option = value?.show_inline_matrix_filter_option || false;
    this.hide_elements = value?.hide_elements || [];
    this.show_total_in_cart_footer = value?.show_total_in_cart_footer || false
    this.show_about_us_comp_on_home_page = value?.show_about_us_comp_on_home_page || false
    this.show_homescreen_animated_category = value.show_homescreen_animated_category || false
    this.hide_elements_from_cart_footer = value.hide_elements_from_cart_footer || []
    this.hide_background_store_location_card = value.hide_background_store_location_card || false
    this.show_footer_custom_location_link = value.show_footer_custom_location_link || false
    this.show_only_product_image_in_home_productbase_section = value.show_only_product_image_in_home_productbase_section || false
    this.custom_reposition_cart_params = value.custom_reposition_cart_params || []
    this.store_location_bg_banner_image_position = value.store_location_bg_banner_image_position || ""
    this.show_hover_shadow_effect_on_home_product_card = value.show_hover_shadow_effect_on_home_product_card || false
    this.show_shortlist_icon_as_named_shortlist = value.show_shortlist_icon_as_named_shortlist || false;
    this.show_random_product_option_in_named_wishlist = value.show_random_product_option_in_named_wishlist || false;
    this.show_fsv_custom_varient_details = value.show_fsv_custom_varient_details || false;
    this.fsv_custom_varient_details = value.fsv_custom_varient_details || [];
    this.show_small_video_for_branding = value.show_small_video_for_branding || false;
    this.footer_height_to_adjest_download_app_image = value.footer_height_to_adjest_download_app_image || "";
    this.show_left_header_extra_company_logo = value.show_left_header_extra_company_logo || false;
    this.show_extracted_subcategory_in_desktop_dropdown_menu = value.show_extracted_subcategory_in_desktop_dropdown_menu || {};
    this.show_contact_us_in_matrix_branding = value.show_contact_us_in_matrix_branding || {};
    this.hide_product_count = value.hide_product_count || false;
    this.cart_to_fsv_redirection = value.cart_to_fsv_redirection || false;
    this.show_matrix_details_in_grid = value.show_matrix_details_in_grid || false;
    this.hide_menu_when_user_not_loggedin = value.hide_menu_when_user_not_loggedin || false;
    this.show_pcs_sets_in_cart_footer = value.show_pcs_sets_in_cart_footer || false;
    this.calculate_oms_weight_based_on_design_no = value.calculate_oms_weight_based_on_design_no || false;
    this.show_thumbnail_in_menu_dropdown = value.show_thumbnail_in_menu_dropdown || false;
    this.shift_upward_home_banner_gradient = value.shift_upward_home_banner_gradient || false;
    this.show_matrix_pagination_at_both = value.show_matrix_pagination_at_both || false;
  }
}




