import "./NewsSubscriptionModal.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import { EMAIL_REGEX } from "../../../../utils";
import { fetch_data_from_api } from "../../../../Api/APICalls";
import { ApiList } from "../../../../Api/ApiList";
import { ToastError, ToastSuccess } from "../../../../Component/Helper";
import { BeatLoader } from "react-spinners";
import { useState } from "react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(600px, 90%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };



function NewsSubscriptionModal({openSubcriptionModal, handleSubscriptionClose}) {
    const [loading, setLoading] = useState(false)
   

    const {
        register,
        handleSubmit,
        formState: {errors}
      } = useForm()

    const onSubmit = async (data) => {
      setLoading(true)
      try{
        const news_letter_subscribe_response = await fetch_data_from_api(ApiList.NewsLetterSubscribe, ApiList.NewsLetterSubscribe, data)
      
      if(news_letter_subscribe_response?.ack === "1" || news_letter_subscribe_response?.ack === 1 ){
        ToastSuccess(news_letter_subscribe_response?.msg)
        handleSubscriptionClose()
        
      }else if(news_letter_subscribe_response?.ack === "0" || news_letter_subscribe_response?.ack === 0){
        ToastError(news_letter_subscribe_response?.error)
      }else{
        ToastError("Subscription failed")
      }
      }catch(e){
        ToastError("Something went wrong")
      }finally{
        setLoading(false)
      }
      
    }

  return (
    <Modal
        open={openSubcriptionModal}
        onClose={handleSubscriptionClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="news-subscription-modal"
      >
        <Box sx={style}>
           <section>
               <IoClose className="close-btn" onClick={handleSubscriptionClose}/>
               <h3>Sign Up</h3>
               <p>
               Sign up below to be the first to know about new products,
               latest news and upcoming offers.
               </p>
               <form className="input-section"  onSubmit={handleSubmit(onSubmit)} noValidate>
                   <div>
                       <input type="text" placeholder="Enter Name" {...register("name", {
                        required: {
                            value: true,
                            message: "Name is required"
                        }
                       })}/>
                       {errors?.name && <p className="error">{errors.name.message }</p>}
                   </div>
                   <div>
                       <input type="text" placeholder="Enter Email" {...register("email_id", {
                        required: {
                            value: true,
                            message: "Email is required"
                        },
                        pattern: {
                            value: EMAIL_REGEX,
                            message: "Invaild email"
                        }
                       })}/>
                       {errors?.email_id && <p className="error">{errors.email_id.message }</p>}
                   </div>
                   <button type="submit" disabled={loading}>{loading ? <BeatLoader color={'rgba(255,255,255,0.2)'}/> : 'Subscribe'}</button>
               </form>
           </section>
        </Box>
    </Modal>
   
  )
}

export default NewsSubscriptionModal