import { useEffect,  useMemo, useState } from "react";
import io from "socket.io-client";
import {  live_rate_margin_calc } from "./live_rate_utils";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";

export default function useLiveRateReact(default_short_code = null) {
  const lmaxsockerurl = "https://bullionprice.logimaxindia.com:3004/";

  
  
  
  const [live_rate_data, set_live_rate_data] = useState(null);
  

  const Live_rate_query = useQuery({
    queryKey: ["live-rate"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.Live_rates_sc, "live-rate"),
    
  });

  // console.log(document.location.pathname)

  useEffect(() => {

    const lmaxsocket = io(lmaxsockerurl, {
      forceNew: true,
      secure: true,
      transports: ["websocket"],
      rejectUnauthorized: false,
      reconnect: true,
    });

    function onConnect() {
      // console.log("live_rate_socket_conneted");
    }

    function onDisconnect() {
      // console.log("live_rate_socket_disconnected");
    }

    function onError(err) {
      console.log("live_rate_socket_error", err);
      lmaxsocket.disconnect()
      lmaxsocket.off("connect", onConnect);
      lmaxsocket.off("disconnect", onDisconnect);
      // lmaxsocket.off("stockpriceupdate", stockpriceupdate);
      lmaxsocket.off("connect_error", onError);
      if(document.location.pathname.includes("live-rates")){
        window.location.reload()
      }
      

    }

    function stockpriceupdate(value) {
      //1. update prev live 
      // prev_live_rate_data.current = current_live_rate_data.current
     
      //2. Calculation of live rate based on Margin
      const LIVE_JSON_DATA = JSON.parse(JSON.parse(value));
      
      let calc_live_data = [];
      if (Array.isArray(LIVE_JSON_DATA) && !!Live_rate_query.data) {
        LIVE_JSON_DATA.forEach((liverate) => {
          const live_rate_DB_data = Object.values(Live_rate_query.data[0]).flat()
          const db_data = live_rate_DB_data.filter(
            (data) => data.socket_sc === liverate.gold1_symbol
          );

          if (db_data.length > 0) {
            db_data.forEach((data) => {
              const calc_data = live_rate_margin_calc(data, liverate);
              calc_live_data.push(calc_data);
            });
          }
        });

        
      }

      
      //This is fpr Live Bar
      if(default_short_code){
        const calc_rate = calc_live_data.find(calc_data => calc_data.short_code === default_short_code)
        set_live_rate_data(calc_rate)
        
        //3. Set Live Rate with Live Rate Query (This is for Live Page)
      }else if(Live_rate_query.isSuccess && !!Live_rate_query.data){
        let Live_rate_query_array = Object.values(Live_rate_query.data[0])
        Live_rate_query_array = Live_rate_query_array.map(live_rate => {
           return live_rate.map(rate => {
            const calc_rate = calc_live_data.find(calc_data => calc_data.short_code === rate.short_code)
            if(calc_rate){
              return {...rate, ...calc_rate, desc: rate.desc}
            }else{
              return rate
            }
           })
        })
        
        // current_live_rate_data.current = Live_rate_query_array
        set_live_rate_data(Live_rate_query_array)

      

      }

    }

    

    lmaxsocket.on("connect", onConnect);
    lmaxsocket.on("disconnect", onDisconnect);
    lmaxsocket.on("stockpriceupdate", stockpriceupdate);
    lmaxsocket.on("connect_error", onError);

    return () => {
      
      lmaxsocket.off("connect", onConnect);
      lmaxsocket.off("disconnect", onDisconnect);
      lmaxsocket.off("stockpriceupdate", stockpriceupdate);
      lmaxsocket.off("connect_error", onError);
      // lmaxsocket.disconnect()
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Live_rate_query.isSuccess]);

  const TimeStamp = useMemo(() => Date.now(), [live_rate_data])
 
  return {live_rate_data, TimeStamp}
  
}
