
import CancelPastOrderModal from './CancelOrderModal/CancelPastOrderModal'
import { useRef } from 'react'


function CancelOrderButton({order_data}) {

   
  
   
   const open_cancel_order_modal = useRef(null)
   function open_modal(){
      
      
     open_cancel_order_modal.current?.open_modal()
   }


  if(order_data?.can_cancel_order === "No"){
    return null
  }
  

    
  return (
    <>
      {order_data?.can_cancel_order === "Yes" ? ( 
      <>
      <CancelPastOrderModal ref={open_cancel_order_modal}/>
    <div className='cancel-past-order-button'>
      <button onClick={open_modal}>Cancel Order</button>
    </div>
    </>
    ) : null}
    </>
  )
}

export default CancelOrderButton
