/* eslint-disable jsx-a11y/iframe-has-title */

import ExternalHTML from "../../Component/ExternalHTML/ExternalHTML"
import { get_informationFromSessionID } from "../../Component/Helper";


function AboutUs() {
  const companyCode = get_informationFromSessionID("companyCode");
  const decryptCompanyName = atob(companyCode);

  const URL = () => {
    switch(decryptCompanyName){
      case "CHAINHOUSE24": return "https://prodone.jewelflow.pro/chainhouse/frontend/About_Us"; 
      default: return ""
    }
  }

return (
    <div className="aboutUs">
      
      <ExternalHTML url={URL()}/>
    </div>
  )
}

export default AboutUs
