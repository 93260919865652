import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import { Controller, useWatch } from "react-hook-form";
import { calculate_prod, FSV_ACTIONS, get_short_code_name } from "../../../../Helper";
import {
  createSelectArrayForReactSelect,
  get_property_settings,
} from "../../../../../../Component/Helper";
import { useDispatch, useSelector } from "react-redux";
import { get_fsv_products_calc, get_fsv_quantity, get_fsv_sets_quantity, set_FSV_Handler } from "../../../../../../redux/Reducer/reducer";
import { useLocation } from "react-router-dom";

function DropdownControl({ selectedProduct, item, control, setValue }) {
  const primary_BG_color = get_property_settings("btn_primary_color");
  const dispatch = useDispatch();
  const short_code = get_short_code_name(item.short_code);
  const options = useMemo(() => {
    return createSelectArrayForReactSelect(selectedProduct[item.short_code]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.short_code]);
  const products_calc = useSelector(get_fsv_products_calc)
  const fsv_count = useSelector(get_fsv_quantity)
  const fsv_sets_count = useSelector(get_fsv_sets_quantity);
  const location = useLocation();
  const cartData = location.state?.cartData;

  const selectedOption = useWatch({
    control,
    name: short_code,
  });
  
  useEffect(() => {
    if(short_code === 'cc_hook' || short_code === 'tone'){
      dispatch(
        set_FSV_Handler({
          type: FSV_ACTIONS.SET_PRODUCT_CALC,
          payload: {
            product_name: selectedProduct?.category_name,
            data: { [short_code]: selectedOption },
          },
        })
      );
    }else{

      calculate_prod(selectedProduct, products_calc, short_code, selectedOption, fsv_count, dispatch, fsv_sets_count)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  

  useEffect(() => {
    if (!cartData) return;

  
    //set custom input value with cartdata
    // if(cartData[short_code]){
    //   if(!catalogue.includes(cartData[short_code]) && show_custom_input){
    //     setValue(`custom_${short_code}`, cartData[short_code])

    //     const custom_input = document.getElementById(
    //       `${short_code}_custom_input_id`
    //     );
    //     if (custom_input) {
    //       custom_input.classList.add(
    //         "custom-input-btn-mode-enable"
    //       );
    //     }
    //   }
    // }

    //Run Calcuation with size which affect on GrossWt
    if (short_code === "size" && cartData["size"]) {

      const selected_size = options.find(option => option.value === cartData["size"])
      
      
      setTimeout(() => {
      setValue(short_code, selected_size)
        calculate_prod(
          selectedProduct,
          products_calc,
          "size",
          cartData["size"],
          fsv_count,
          dispatch,
          fsv_sets_count,
          cartData
        );
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  return (
    <Controller
      control={control}
      name={short_code}
      render={({ field }) => (
        <Select
          options={options}
          isDisabled={selectedProduct[item.short_code].length === 1}
          {...field}
          
          className="react-select-container r-container"
          classNamePrefix="react-select r-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25:
                (primary_BG_color &&
                  primary_BG_color
                    .replace("rgb", "rgba")
                    .replace(")", ",0.2)")) ||
                "inherit",
              primary: primary_BG_color,
            },
          })}
        />
      )}
    />
  );
}

export default DropdownControl;
