import { useQuery } from "@tanstack/react-query";
import { RiArrowRightSLine } from "react-icons/ri";
import { fetch_data_from_api_from_query } from "../../../../../../Api/APICalls";
import { ApiList } from "../../../../../../Api/ApiList";
import CallUsModal from "./CallUsModal/CallUsModal";
import { useRef } from "react";

function CallUs() {
    const open_call_us_modal_ref = useRef()
    const call_us_query = useQuery({
        queryKey: ["CallUs"],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.CallUs, "CallUs"),
        
      });

  if(call_us_query.isLoading){
    return null
  }

  return (
    <li style={{marginTop: "1.5em !important"}}>
    {call_us_query.isSuccess ? <CallUsModal ref={open_call_us_modal_ref} call_data={call_us_query?.data}/> : null}
      <a href={`tel:${call_us_query?.data[0]?.contact_no}`} className="general-link"
        onClick={(e) =>  {
            
            if(window.innerWidth > 576 || call_us_query.data?.length > 1){
                e.preventDefault()
                if(!open_call_us_modal_ref.current) return
                
                open_call_us_modal_ref.current?.open_modal()
            }
        }} 
        rel="noreferrer"
      >
        <span>Call Us</span>
        <RiArrowRightSLine className="r-arrow" />
      </a>
    </li>
  );
}

export default CallUs;
