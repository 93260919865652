import React from 'react'
import { get_property_settings } from '../../../../../../Component/Helper'
import Select from "react-select"

function ReactSelectDropdown({options, fetchData, onChange, value}) {
    const primary_BG_color = get_property_settings("btn_primary_color")
    
    return (
        <Select
            options={options}
            
            value={value}
            onChange={(option) => {
                
                onChange(option)
                fetchData && fetchData(option.value)
            }}
            className="react-select-container r-container"
            classNamePrefix="react-select r-select"
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25:
                        (primary_BG_color &&
                            primary_BG_color.replace("rgb", "rgba").replace(")", ",0.2)")) ||
                        "inherit",
                    primary: primary_BG_color,
                },
            })}


        />
    )
}

export default ReactSelectDropdown
