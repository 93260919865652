import { useEffect } from "react"
import { get_search_params, ScrollToTopCustom } from "../../../utils"
import AssociatedSection from "./Components/AssociatedSection"
import HeaderSection from "./Components/HeaderSection"
import MiddleSection from "./Components/MiddleSection"
import "./IGJE_About_Us.css"

function IGJE_About_Us() {
  
  const { mode } = get_search_params()
  useEffect(() => {
    ScrollToTopCustom(null, 300)
  }, [])
  return (
    <section className='igje-about-us-page'>
       {mode === "app" ? <HeaderSection/> : null}
        <MiddleSection AppMode={mode === "app"}/>
        <AssociatedSection />
       {/* {mode === "app" ? <FooterSection /> : null} */}
    </section>
  )
}

export default IGJE_About_Us