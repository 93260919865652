import React from "react";
import { Link } from "react-router-dom";
import { createSearchURLParams } from "../../Helper";
import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";

function FooterLinkCard({ links }) {
  let dummyShowMenu = [];

  const custom_footer_links = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.custom_footer_links
  );

  function LinkElement({ link, menu, custom_footer_link = null }) {
    

    return (
      <>
        {custom_footer_link ? (
          <>
            {custom_footer_link.external_link ? (
              <a href={custom_footer_link.link || "/"} target="_blank" rel="noreferrer">
                {menu?.item}
              </a>
            ) : custom_footer_link?.custom_link ? (
              <Link to={createSearchURLParams(custom_footer_link.link, {external_string: "4476446347"})} onClick={() => {
                window.scrollTo(0,0)
              }}>{custom_footer_link.name}</Link>
            ) : null
            
            }
          </>
        ) : (
          <Link
            to={createSearchURLParams(
              `static-page/${link?.item?.replace(/ /g, "-").toLowerCase()}`,
              { id: menu?.static_pages_id }
            )}
            state={{ id: menu?.static_pages_id }}
          >
            {menu?.item}
          </Link>
        )}
      </>
    );
  }

  if (!Array.isArray(links)) return null;

  return links?.map((link, linkIndex) => {
    const show_submenu = Boolean(Number(link.show_submenu));
    if (!dummyShowMenu.length) {
      dummyShowMenu = links;
    }

    return (
      <div className="col-sm-12 col-lg-3 ec-footer-account" key={linkIndex}>
        <div className="ec-footer-widget footer-link-container">
          {show_submenu && (
            <div
              
              // to={createSearchURLParams(
              //   `static-page/${link?.item?.replace(/ /g, "-").toLowerCase()}`,
              //   { id: link.static_pages_id }
              // )}
              // state={{ id: link?.static_pages_id }}
              
            >
              <h4 className="ec-footer-heading">{link?.item}</h4>
            </div>
          )}

          <div className="ec-footer-links">
            <ul className="align-items-center">
              {show_submenu
                ? link.submenu.map((menu, index) => {
                    let custom_footer_link = "";
                    if (custom_footer_links) {
                      custom_footer_link = custom_footer_links.find(
                        (link) => link.name === menu?.item
                      );
                    }
                    
                    return (
                      <li
                        className="ec-footer-link"
                        key={index}
                        onClick={() => {
                          !custom_footer_link && window.scrollTo(0, 0);
                        }}
                      >
                        <LinkElement
                          menu={menu}
                          link={link}
                          custom_footer_link={custom_footer_link}
                        />
                        {/* <Link
                        to={createSearchURLParams(
                          `static-page/${link?.item
                            ?.replace(/ /g, "-")
                            .toLowerCase()}`,
                          { id: menu?.static_pages_id }
                        )}
                        state={{ id: menu?.static_pages_id }}
                      >
                        {menu?.item}
                      </Link> */}
                      </li>
                    );
                  })
                : linkIndex === 0 &&
                  dummyShowMenu.map((menu, index) => {
                    let custom_link = null
                    if(Array.isArray(custom_footer_links)){
                        custom_link = custom_footer_links.find(link => link?.name.toLowerCase() === menu?.item.toLowerCase())
                    }
                    if(custom_link && custom_link?.external_link){
                     return <li className="ec-footer-link" key={`footer_link_${index}`}>
                          <a
                            href={custom_link?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {menu.item}
                          </a>
                        </li>

                    }else if(custom_link && !custom_link.external_link){
                      return (
                        <li className="ec-footer-link" key={`footer_link_${index}`}>
                          <Link
                            to={custom_link?.link}
                            
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            {menu.item}
                          </Link>
                        </li>
                      )
                    }else{
                      return (
                        <li className="ec-footer-link" key={`footer_link_${index}`}>
                          <Link
                            to={createSearchURLParams(
                              `static-page/${menu.item
                                .replace(/ /g, "-")
                                .toLowerCase()}`,
                              { id: menu?.static_pages_id }
                            )}
                            state={{ id: menu.static_pages_id }}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            {menu.item}
                          </Link>
                        </li>
                      )
                    }
                    
                  }
                  )}
            </ul>
          </div>
        </div>
      </div>
    );
  });
}

export default React.memo(FooterLinkCard);
