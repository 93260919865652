/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";
import "./AllOrderDetail.css";
import { ApiList } from "../../../Api/ApiList";
import { get_search_params, ScrollToTopCustom } from "../../../utils";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { useCallback, useEffect,  useState } from "react";
import { useParams } from "react-router-dom";
import {  get_userData_from_activeUser } from "../../../Component/Helper";
import StatusButtonsBar from "../../../Component/StatusButtonsBar/StatusButtonsBar";
import OrderAccordation from "./OrderAccordation";
import Loader from "../../../Component/Loader";
import AllOrderFooter from "./AllOrderFooter";
import OrderDetailsMenu from "./Components/OrderDetailsMenu/OrderDetailsMenu";
import ProductOnlyNameHeader from "../../Shop/Components/ProductNameHeader/ProductOnlyNameHeader";
import CancelOrderButton from "./Components/CancelOrderButton";
import AllOrderTotalFooter from "./AllOrderTotalFooter";

function AllOrderDetail() {
    const searchParams = get_search_params()

    const headerMenuHeight = sessionStorage.getItem("HeaderMenu")
    ? sessionStorage.getItem("HeaderMenu") + "px"
    : "0px";

    const {order_id} = useParams()
    
    let show_past_order = get_userData_from_activeUser()?.show_past_order;
    show_past_order = show_past_order === "1";

    const [status_group, set_status_group] = useState(null);
    const [total, set_total] = useState(null);
    const [active_status, set_active_status] = useState(searchParams.type || "category")
  const handle_active_status = useCallback((id) => {
    set_active_status(id)
  }, [])

    const order_list_grouped_query = useQuery({
        queryKey: ["all-order-list-grouped", active_status],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.order_list_grouped, "all-order-list-grouped", {
            voucher_id: order_id,
            type: active_status,
            ...searchParams,
          }).then((res) => {
            if (typeof res === "object") {
              if (!status_group) {
                set_status_group(res?.group_by);
              }

              
    
              return res;
            }
          }),
        enabled: show_past_order,
      });

   
      const all_order_query = useQuery({
        queryKey: ["all-order-list", searchParams?.voucher_no],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.order_list, "all-order-list", {
            order_no: searchParams?.voucher_no,
            status_id: "",
            page: "",
            page_size: "10",
            
          }).then((res) => {
            if (typeof res === "object") {
              
              if(res?.order_list.length > 0){

                return res?.order_list[0];
              }

              return null
            }
          }),
          refetchOnMount: "always",
          staleTime: 0,
          
        // enabled: !!searchParams.voucher_no,
      });

          
    
  useEffect(() => {
    
    if(total || !order_list_grouped_query?.data?.cart_data) return 
      const cart_data = order_list_grouped_query?.data?.cart_data || []
      
      const total_cart = cart_data.reduce((total, data) => {
        total.total_gross_wt +=  Number(data?.total_gross_wt || 0)
        total.total_net_wt +=  Number(data?.total_net_wt || 0)
        total.total_quantity +=  Number(data?.total_quantity || 0)
        total.total_piece +=  Number(data?.total_piece || 0)
        return total
      }, {total_gross_wt: 0, total_net_wt: 0, total_quantity: 0, total_piece: 0})

      set_total(total_cart)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_list_grouped_query?.data?.cart_data])

  useEffect(() => {
    ScrollToTopCustom()
    
  }, [])

 
      
  return (
    <div className="all-order-grouped-page">
        {status_group ? (
        <header style={{ top: headerMenuHeight }}>
          <section className="header-inner">
            <StatusButtonsBar status_group={status_group} active_status={active_status} handle_active_status={handle_active_status} title="GROUP BY"/>
          </section>
            <OrderDetailsMenu order_data={all_order_query.data}/>
        </header>
      ) : null}
      <ProductOnlyNameHeader>
      <div className={`product_title_only all-order-details-header`}>
        <header>
          <h2>
            {`Order No - #${searchParams?.voucher_no}`}

          </h2>
          <CancelOrderButton order_data={all_order_query.data}/>
        </header>
        {total && <AllOrderTotalFooter total={total}/>}
      </div>
      </ProductOnlyNameHeader>
      <section className="grouped-section">
      {/* <NoProduct errMsg="No data Found" hideHomeBtn={true}/> */}
      {
        order_list_grouped_query.isLoading || order_list_grouped_query.isRefetching ? (
          <Loader isLoading={true}/>
        ) : (

          <OrderAccordation cart_data={order_list_grouped_query?.data?.cart_data}/>
        )
      }
      {/* <ScrollViewIntoElement dependancy={[order_id, searchParams.status_id, searchParams.page]} delay={500}/> */}
      </section>

    {/* {order_list_grouped_query.isSuccess && <AllOrderFooter total={total} order_data={all_order_query.data}/>} */}
    </div>
  )
}

export default AllOrderDetail