/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { get_property_settings } from "../../../Component/Helper";
import ZoomImageComp from "../../../Component/ZoomImageComp/ZoomImageComp";
import { MdOutlineZoomIn } from "react-icons/md";
import FullScreenImageSlider from "../../../Component/FullScreenImageSlider/FullScreenImageSlider";

function CustomOrderDetailImage({ images, image_path }) {
  const cdn = get_property_settings("cdn");
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  return (
    <div className="custom-order-detail-image-container">
      {window.innerWidth < 576 ? (
        <FullScreenImageSlider imagesArr={images} imagePath={image_path}>
          <div className="main-image-preview">
            <div className="zoom-in d-block d-sm-none">
              <MdOutlineZoomIn className="zoom-in-btn" />
            </div>
            <Slider
              asNavFor={nav2}
              ref={(slider) => (sliderRef1 = slider)}
              infinite={false}
              arrows={window.innerWidth > 576}
            >
              {images.map((image, index) => {
                const img = `${cdn}${image_path}${image}`;
                return (
                  <div className={`main-img-container`} key={image}>
                    <div className="d-none d-sm-block w-100">
                      <ZoomImageComp imgURL={img} />
                    </div>
                    <img
                      src={img}
                      key={image}
                      className="d-block d-sm-none main-img"
                      onClick={() =>
                        sessionStorage.setItem(
                          "active_order_details_slide",
                          index
                        )
                      }
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </FullScreenImageSlider>
      ) : (
        <div className="main-image-preview">
          <div className="zoom-in d-block d-sm-none">
            <MdOutlineZoomIn className="zoom-in-btn" />
          </div>
          <Slider
            asNavFor={nav2}
            ref={(slider) => (sliderRef1 = slider)}
            infinite={false}
          >
            {images.map((image) => {
              const img = `${cdn}${image_path}${image}`;
              return (
                <div className={`main-img-container`} key={image}>
                  <div className="d-none d-sm-block w-100">
                    <ZoomImageComp imgURL={img} />
                  </div>
                  <img src={img} key={image} className="d-block d-sm-none" />
                </div>
              );
            })}
          </Slider>
        </div>
      )}

      {images.length > 1 && (
        <div className="thumb-image-preview">
          <Slider
            asNavFor={nav1}
            ref={(slider) => (sliderRef2 = slider)}
            slidesToShow={window.innerWidth < 567 ? 3 : 5}
            swipeToSlide={true}
            focusOnSelect={true}
            infinite={false}
          >
            {images.map((image) => {
              const img = `${cdn}${image_path}${image}`;
              return (
                <div className={`thumb-img-container`} key={image}>
                  <img src={img} key={image} />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default CustomOrderDetailImage;
