import React from 'react'

function Banner1() {
  return (
    <div className='banner-1'>
      <picture>
            <source media="(min-width:576px)" srcSet="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/images/banner.jpg" />
            
        <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/images/mobile_branding.png" alt="banner-1" />
          </picture>
    </div>
  )
}

export default Banner1