import React, { useEffect } from 'react'
import {useDispatch, useSelector} from "react-redux"
import {get_fsv_remark, set_FSV_Handler} from "../../../../../../../redux/Reducer/reducer"
import { FSV_ACTIONS } from '../../../../../Helper'

function Remarks({cartRemark}) {

  const remarks = useSelector(get_fsv_remark)
  const dispatch = useDispatch()

  useEffect(() => {
    if(!cartRemark) return
    dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_REMARKS, payload: cartRemark}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartRemark])
  

  return (
    <div className="container">
        <div className="row remarks-group-sm product-details-inner-group-sm">
          <div className="col-lg-5 col-md-3 col-sm-5 align-self-center d-flex label product-details-label">
            {" "}
            Remarks:
          </div>
          <div className="remarks col-sm-7">
            {" "}
            <textarea
              value={remarks}
              className="remarks remarks-input"
              rows={3}
              onChange={(e) => {
                dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_REMARKS, payload: e.target.value}))
              }}
            />
          </div>
        </div>
      </div>
  )
}


export default Remarks