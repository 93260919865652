import React from 'react'

function Banner2() {
  return (
    <div className='banner-2' style={{backgroundImage: `url(https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/images/scheme.jpg)`}}>
        <section className="banner-2-container">
          <header>
            <h2>SCHEME FEATURES</h2>
          </header>
          <p>Swarna Kalasham is a monthly savings plan for purchase of jewellery only.
          A customer can begin with a minimum monthly investment of Rs. 5000 /-.</p>
          <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/images/Table.png" alt="table" />
        </section>
    </div>
  )
}

export default Banner2