import React, { useCallback, useEffect, useState } from "react";
import HomeBanner from "../../Component/HomeComponents/HomeBanner/HomeBanner";
import USPSection from "../../Component/HomeComponents/USPSection/USPSection";
import {
  ToastSuccess,
  delete_informationFromSessionID,
  get_bulk_settings,
  get_informationFromSessionID,
  get_property_settings,
  show_live_rate_menu,
} from "../../Component/Helper";
import { ApiList } from "../../Api/ApiList";
import Testmonial from "../../Component/HomeComponents/Testmonial/Testmonial";

import ProductBasedSection from "../../Component/HomeComponents/ProductBasedSection/ProductBasedSection";
import BrandingBasedSection from "../../Component/HomeComponents/BrandingBasedSection/BrandingBasedSection";

import { useSelector } from "react-redux";
import {
  get_is_myc_web,
  getCatMaster,
  getUserDetails,
} from "../../redux/Reducer/reducer";
import RecursiveCategoryModal from "../../Component/RecursiveCategory/RecursiveCategoryModal";
import CategoriesSectionNew from "../../Component/RecursiveCategory/CategoriesSectionNew";
import Loader from "../../Component/Loader";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { useQuery } from "@tanstack/react-query";
import StoreSection from "../../Component/HomeComponents/StoreSection/StoreSection";
import { useLocation } from "react-router-dom";
import { getCustomValue } from "../../Custom/CompanyList";
import AboutUsSection from "../../Component/HomeComponents/AboutUsSection";
import CategoryAnimation from "../../Component/Animated/CategoryAnimation/CategoryAnimation";
import { ScrollToTop } from "../StaticPage/CustomStaticPage/_CommonPage/ScrollToTop";
// eslint-disable-next-line no-unused-vars
import LiveRateBar from "./Components/LiveRateBar/LiveRateBar";
import BrandingThumbVideos from "./Components/BrandingThumbVideos/BrandingThumbVideos";
import LiveRateBarReact from "./Components/LiveRateBar/LiveRateBarReact";
import UserPreferenceHome from "./Components/UserPreferenceHome/UserPreferenceHome";
import HomePagePopUp from "./Components/HomePagePopUp/HomePagePopUp";

function Home() {
  const master = useSelector(getCatMaster);
  const userID =
    useSelector(getUserDetails)?.userID ||
    get_informationFromSessionID("userID");
  const location = useLocation();
  let searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const is_myc_web = useSelector(get_is_myc_web);

  const show_about_us_comp_on_home_page = getCustomValue(
    "show_about_us_comp_on_home_page"
  );
  const show_homescreen_animated_category = getCustomValue(
    "show_homescreen_animated_category"
  );

  const show_small_video_for_branding = getCustomValue(
    "show_small_video_for_branding"
  );

  const { show_gold_rate_homescreen_bar } = get_bulk_settings([
    "show_gold_rate_homescreen_bar",
  ]);

  const category_query = useQuery({
    queryKey: ["category", master, userID],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.fetchCat, "FetchCat", {
        which_master: master,
      }),
    placeholderData: (previousData, previousQuery) => previousData,
    initialData: [],
    enabled: !is_myc_web,
  });

  const homescreen_query = useQuery({
    queryKey: ["homescreen"],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.homeScreen, "homescreen", {
        device: "web",
      }),
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: Infinity,
  });

  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const parent_category_from = get_property_settings("parent_category_from");
  // const parent_category_from = "static"
  const parent_category_text = get_property_settings("parent_category_text");
  const web_show_store_locator_homescreen = get_property_settings(
    "web_show_store_locator_homescreen"
  );

  let show_all_product_button_homescreen_menu = get_property_settings(
    "show_all_product_button_homescreen_menu"
  );

  show_all_product_button_homescreen_menu = show_all_product_button_homescreen_menu === "show"

  const handleShowCategoryModal = useCallback((bool) => {
    setShowCategoryModal(bool);
  }, []);

  useEffect(() => {
    ScrollToTop();
    const isUserLoggedOut = get_informationFromSessionID("isUserLoggedOut");

    if (isUserLoggedOut) {
      ToastSuccess("Your logout successfully");
      delete_informationFromSessionID("isUserLoggedOut");
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showCategoryModal || !searchParams?.cat_id) return;
    if (category_query?.data?.length > 0) {
      setShowCategoryModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.cat_id, category_query?.data]);

  if (category_query?.isLoading || homescreen_query?.isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <section className="home-page">
      <UserPreferenceHome />
      <HomePagePopUp
        homescreen_popup={homescreen_query?.data?.homescreen_popup}
        cat_id={searchParams?.cat_id}
      />
      {showCategoryModal && (
        <RecursiveCategoryModal
          showCategoryModal={showCategoryModal}
          setShowCategoryModal={setShowCategoryModal}
        />
      )}
      {/* {show_live_rate_menu() && show_gold_rate_homescreen_bar === "Yes" && <LiveRateBar />} */}
      {show_live_rate_menu() && show_gold_rate_homescreen_bar === "Yes" && <LiveRateBarReact />}
      {homescreen_query?.data?.branding ? (
        <HomeBanner brandingData={homescreen_query?.data?.branding} />
      ) : null}

      {show_small_video_for_branding && <BrandingThumbVideos />}

      {/* parent_category_from === "static") */}
      {!show_homescreen_animated_category &&
      Array.isArray(category_query?.data) &&
      category_query?.data?.length > 0 &&
      parent_category_from === "static" ? (
        <CategoriesSectionNew
          title={parent_category_text}
          subtitle={""}
          btnName={show_all_product_button_homescreen_menu ? "All Products" : ""}
          clsName={"categories-slick"}
          categoriesData={category_query?.data}
          setShowCategoryModal={handleShowCategoryModal}
          index=""
        />
      ) : parent_category_from === "static" &&
        !show_homescreen_animated_category ? (
        <Loader isLoading={category_query?.data?.length !== 0} />
      ) : null}

      {/* parent_category_from === "fetchcat") */}

      {!show_homescreen_animated_category &&
      Array.isArray(category_query?.data) &&
      category_query?.data?.length > 0 &&
      parent_category_from === "fetchcat" ? (
        category_query?.data?.map((category, index) => (
          <CategoriesSectionNew
            key={category?.id}
            title={category?.cat_name}
            subtitle={""}
            btnName={""}
            clsName={"categories-slick"}
            categoriesData={category.subcategory}
            setShowCategoryModal={handleShowCategoryModal}
            index={index.toString()}
          />
        ))
      ) : parent_category_from === "fetchcat" &&
        !show_homescreen_animated_category ? (
        <Loader isLoading={category_query?.data?.length !== 0} />
      ) : null}

      {show_homescreen_animated_category ? (
        <section className="animated-category">
          {Array.isArray(category_query?.data) &&
            category_query?.data?.length > 0 &&
            category_query?.data?.map((category) => {
              return (
                <CategoryAnimation
                  key={category.id}
                  category={category}
                  setShowCategoryModal={handleShowCategoryModal}
                />
              );
            })}
        </section>
      ) : null}

      {show_about_us_comp_on_home_page && <AboutUsSection />}

      {homescreen_query?.data?.featured_list?.length
        ? // eslint-disable-next-line array-callback-return
          homescreen_query?.data?.featured_list?.map((data, index) => {
            if (data?.featured_display_type === "product_based") {
              return (
                <ProductBasedSection
                  title={data?.name}
                  subtitle={null}
                  btnName={data.show_view_all === "Yes" ? "View All" : null}
                  clsName={"trending-slick"}
                  branding_img={data.branding_img}
                  key={data?.id}
                  data={data?.dataArray}
                  product_count={data?.product_count}
                  cat_id={data?.id}
                  web_container_bg_color={
                    data.web_container_bg_color || "transparent"
                  }
                  web_container_fg_color={
                    data.web_container_fg_color || "var(--highlight_color)"
                  }
                  index={index.toString()}
                />
              );
            } else if (data.featured_display_type === "branding_based") {
              return (
                <BrandingBasedSection
                  title={data?.name}
                  cat_id={data?.view_all_cat_id}
                  btnName={data?.show_view_all === "Yes" ? "View All" : null}
                  imgName={data?.branding_img ? data.branding_img : null}
                  key={data?.id}
                />
              );
            }
          })
        : null}

      {homescreen_query?.data?.usp &&
      homescreen_query?.data?.usp?.length > 0 ? (
        <USPSection uspData={homescreen_query?.data?.usp} />
      ) : null}

      {web_show_store_locator_homescreen === "Yes" && <StoreSection />}

      {homescreen_query?.data?.testimonial?.length ? (
        <Testmonial testmonialData={homescreen_query?.data?.testimonial} />
      ) : null}
    </section>
  );
}

export default React.memo(Home);
