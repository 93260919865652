import React from "react";
import Stepper from "./components/Stepper";
import Remarks from "./components/Remarks"
import { getCustomValue } from "../../../../../../Custom/CompanyList";
import SetsStepper from "./components/SetsStepper";
import { useSelector } from "react-redux";
import { get_fsv_selected_product } from "../../../../../../redux/Reducer/reducer";
import { useLocation } from "react-router-dom";

function Qty_Remark({clsName = ""}) {
  
  const hide_elements = getCustomValue("hide_elements")?.includes("Qty_Remark")
  const selectedProduct = useSelector(get_fsv_selected_product);
  let enable_set_and_pcs_logic = selectedProduct.enable_set_and_pcs_logic;
  enable_set_and_pcs_logic = enable_set_and_pcs_logic === "1"
  const location = useLocation()
  const cartData = location.state?.cartData || {}
  if(hide_elements){
    return null
  }
  
  let cartCount = null;
  let cartSetCount = null;
  if(enable_set_and_pcs_logic){
    cartSetCount = cartData?.quantity
    cartCount = cartData?.piece
  }else{
    cartCount = cartData?.quantity
  }
  
  

   return (
    <div className={`ec-pro-variation-inner ec-pro-variation-size ${clsName}`}>
      
        {enable_set_and_pcs_logic && <SetsStepper selectedProduct={selectedProduct} cartCount={cartSetCount}/>}
        <Stepper selectedProduct={selectedProduct} cartCount={cartCount}/>
      
      <Remarks cartRemark={cartData?.remarks}/>
    </div>
  );
}

export default Qty_Remark;
