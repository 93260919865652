import { FaCheckCircle } from "react-icons/fa";
import { formatDate } from "../../../utils";

function CustomOrderDetailsStatus({ status_details }) {
  

  return (
    <div className="custom-order-details-status">
      <h3>Order Status</h3>
      {status_details.map((detail) => (
        <div className="custom-order-details-status-details" key={detail?.status_id}>
          <FaCheckCircle className="check-icon" />
          <div className="status-details">
            <h4>{detail?.order_status}</h4>
            <p>{formatDate(detail?.created_date)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CustomOrderDetailsStatus;
