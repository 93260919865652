import React from "react";
import "./KALASHGOLD_Home_About.css";
import { Link } from "react-router-dom";
import { get_bulk_settings } from "../../../Helper";

function KALASHGOLD_Home_About() {
  const {about_us_page_image, cdn} = get_bulk_settings(["about_us_page_image", "cdn"])

  const img = `${cdn}uploads/about_us_page_image/${about_us_page_image}`

  return (
    <section className="home-about-section">
      <div className="about-image-section">
        <img
          src={img}
          alt="4003-web-banner1"
        />
      </div>

      <div className="about-detail-section">
        <h2>KALASH GOLD & ORNAMENTS PVT LTD</h2>
        <p>Established in 2003, KALASH GOLD AND ORNAMENTS Pvt. Ltd. is a leading manufacturer of Turkish, Italian and CZ gold jewellery.
            </p>

           <p>
           With an extensive R&D team working with 200+ in-house creative artisans , our designs are updated daily to fulfil market demands. We manufacture jewellery in 10kt, 14kt, 18kt and 22kt gold and have the capability to produce other carats on order basis with minimum MOQs. Ranging from heavy weight necklaces, chains, bangles & bracelets, to light weight rings, studs & earrings - we boast a large inventory of categories.
           </p>

           <p>
           Headquartered in Mumbai, we serve clients pan-India & export internationally to 5 continents. We have been working with top corporate retail brands that have multiple stores across India and abroad. Additionally we also work with local wholesalers and retailers .
           </p>

           <p>
           Due to our expertise in the manufacturing process, we are able to deliver large quantities and at the same time fulfil individual customised orders for our customers. Keeping our customers at the centre of all our activities, we strive to provide the best after sales services. Consequently we have been rewarded with the most loyal customer base.
           </p>
        <Link to="/about-us" className="explore-btn">Know More</Link>
          
      </div>
    </section>
  );
}

export default KALASHGOLD_Home_About;
