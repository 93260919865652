import { MenuItem } from "@mui/material";
import React, { useState } from "react";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { get_informationFromSessionID, show_custom_order_menu } from "../../../../../../Helper";
import AllOrderModal from "../AllOrderModal/AllOrderModal";

function AllOrders({ handleClose }) {
  const navigate = useNavigate();
  const companyCode = get_informationFromSessionID("companyCode");
  const decryptCompanyName = atob(companyCode);
  /* All Order Modal Open / Close */
  const [openAllOrderModal, setOpenAllOrderModal] = useState(false);
  const handleCloseAllOrderModal = () => setOpenAllOrderModal(false);
  const handleOpenAllOrderModal = () => setOpenAllOrderModal(true);
  const show_kam_module_custom_order = show_custom_order_menu();
 
  return (
    <>
      {openAllOrderModal && (
        <AllOrderModal
          openAllOrderModal={openAllOrderModal}
          handleCloseAllOrderModal={handleCloseAllOrderModal}
          handleUserProfileMenuClose={handleClose}
          custom_order_label={show_kam_module_custom_order.label}
        />
      )}
      <MenuItem
        className="menu-list"
        onClick={() => {
          if(show_kam_module_custom_order.status){
            handleOpenAllOrderModal();
          }else{
            navigate("/all-order");
            handleClose()
          }
        }}
      >
        <BsFillBoxSeamFill className="icon" />
        <span>
          {decryptCompanyName === "FINEMFG" ? "Past Selections" : "All Orders"}
        </span>
      </MenuItem>
    </>
  );
}

export default AllOrders;
