import React from 'react'
import { get_search_params } from '../../../../utils'


function InputReadOnlyDesignNo() {
  
  const {oms_app_add_by_design_no} = get_search_params()
  
  return (
    <div className='form-input-group disabled'>
        <label>
            <span>Design No</span>
            
            </label>
            <input type="text"
            readOnly
            disabled
            value={oms_app_add_by_design_no}
            style={{userSelect: "none"}}
            />
          
        </div>
  )
}

export default InputReadOnlyDesignNo