import React from "react";
import { get_property_settings } from "../../../Component/Helper";
import ButtonsWithInput from "./Inputs/ButtonsWithInput";
import ButtonsOnly from "./Inputs/ButtonsOnly";
import TextAreaInput from "./Inputs/TextAreaInput";
import SelectDropDownInput from "./Inputs/SelectDropDownInput";
import TextInput from "./Inputs/TextInput";

function CustomOrderGeneral({ general_fields }) {
  let oms_web_app_catalogue_selection_based_on = get_property_settings(
    "oms_web_app_catalogue_selection_based_on"
  );
  // oms_web_app_catalogue_selection_based_on = "dropdown"
  

  return (
    <div className="custom-new-order-general">
      {/* eslint-disable-next-line array-callback-return */}
      {general_fields?.map((field) => {
        if (
          field?.control_type === "Dropdown" &&
          (oms_web_app_catalogue_selection_based_on === "buttonflow" ||
          oms_web_app_catalogue_selection_based_on === "button") &&
            field?.show_custom_input === "1"
        ) {
          return  <ButtonsWithInput field={field} key={field.id}/>
          ;
        }else if(field?.control_type === "Dropdown" && (oms_web_app_catalogue_selection_based_on === "buttonflow" || oms_web_app_catalogue_selection_based_on === "button")){
          return  <ButtonsOnly field={field} key={field.id}/>
        }else if(field?.control_type === "Textarea"){
          return  <TextAreaInput field={field} key={field.id}/>
        }else if(field?.control_type === "Dropdown"){
          return  <SelectDropDownInput field={field} key={field.id}/>
        }else if(field?.control_type === "Textfield"){
          return  <TextInput field={field} key={field.id}/>
        }
      })}
    </div>
  );
}

export default CustomOrderGeneral;
