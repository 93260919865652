import React from "react";
import "./COPL_Home_About.css";
import { Link } from "react-router-dom";

function COPL_Home_About() {
  return (
    <section className="home-about-section">
      <div className="about-image-section">
        <img
          src="https://cdn.jfone.jewelflow.pro/copl/uploads/branding_image/web/4003-web-banner1.png"
          alt="4003-web-banner1"
        />
      </div>

      <div className="about-detail-section">
        <h2>CLASSIC ORNAMENTS PRIVATE LIMITED</h2>
        <p>
          CLASSIC ORNAMENTS PRIVATE LIMITED… formerly known as CLASSIC JEWELLERS
          were Est. 1996 who have carved a Niche’ for themselves in the exports
          business and are fore mostly known for manufacturing of casting and
          handmade Jewellery.
        </p>

        <p>
          CLASSIC ORNAMENTS PRIVATE LIMITED has been able to fulfill the
          everlasting demand of the Indian market covering major retail outlets
          and showrooms across India. It is no wonder that our integrity and
          reliability has become a name synonymous with classic ornaments among
          the inhabitants.
        </p>

        <p>
          With over a decade of experience, CLASSIC ORNAMENTS PRIVATE LIMITED
          have expanded tremendously in a short span of time and dream to be the
          most hunted organization when it comes to commemorating special
          occasions.
        </p>

        <Link to="/about-us" className="explore-btn">Know More</Link>
      </div>
    </section>
  );
}

export default COPL_Home_About;
