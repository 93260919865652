import React, { useCallback, useEffect, useState } from "react";
import FormLabel from "../FormLabel";
import "./OrderTags.css";
import Tags from "./Tags";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { ApiList } from "../../../../Api/ApiList";
import MUIModal from "../../../../Component/MUIModal/MUIModal";
import OrderTagsModal from "./OrderTagsModal/OrderTagsModal";

function OrderTags({
  paramsDataIndex,
  paramsData,
  show_childs_base_on_dependant_shortcodes,
  depended_child,
  client_type,
  register,
  setValue,
  getValues,
  disabled
}) {
  const [open_MUI_modal, set_open_MUI_modal] = useState(false);
  const handle_close_MUI_modal = useCallback(
    () => set_open_MUI_modal(false),
    []
  );

  const handle_open_MUI_modal = () => set_open_MUI_modal(true && !disabled);

  const Register = register(paramsData?.short_code, {
    required: {
      value: true,
      message: "Please add at least one order tag"
    }
  });
  const [tags, setTags] = useState([])

  const order_tags_query = useQuery({
    queryKey: ["finalize-order-tags"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.finalize_get_master_data,
        "order-tags",
        {
          col_name: "order_tag_name",
          table: "order_tag_master",
          client_type: client_type,
        }
      ),
  });

 useEffect(() => {
  const default_select = paramsData?.control_value?.map(value => value.id)
  setValue(paramsData?.short_code, default_select)
  setTags(paramsData?.control_value)
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

  return (
    <span
      className={`ec-register-wrap ec-register-full checkout-order-tags-container`}
      key={paramsDataIndex}
    >
      <MUIModal
        className="finalize-order-tags-modal"
        open_MUI_modal={open_MUI_modal}
        style={{ p: 0, width: "min(600px, 90%)" }}
      >
        <OrderTagsModal
          order_tags_data={order_tags_query.data}
          loading={order_tags_query.isLoading}
          handle_close_MUI_modal={handle_close_MUI_modal}
          Register={Register}
          getValues={getValues}
          setValue={setValue}
          short_code={paramsData?.short_code}
          setTags={setTags}
        />
      </MUIModal>
      <div className="tag-label">
        <FormLabel
          paramsData={paramsData}
          show_childs_base_on_dependant_shortcodes={
            show_childs_base_on_dependant_shortcodes
          }
          depended_child={depended_child}
        />

        <div className="add-tags-btn" onClick={handle_open_MUI_modal}>
          + Add
        </div>
      </div>

      <div className="tags-wrapper">
        {tags?.map((tag) => (
          <Tags key={tag.id} {...tag} setValue={setValue} tags={tags} setTags={setTags} short_code={paramsData?.short_code} disabled={disabled}/>
        ))}
      </div>
    </span>
  );
}

export default OrderTags;
