import "./BrandingThumbVideos.css"

const video1 =
  "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/jewelsmith/static_pages/jewelsmithpicks/Reel+15+Product+Mix.mp4";
const video2 =
  "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/jewelsmith/static_pages/jewelsmithpicks/Reel+2.mp4";
const video3 =
  "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/jewelsmith/static_pages/jewelsmithpicks/Reel+5.mp4";
const video4 =
  "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/jewelsmith/static_pages/jewelsmithpicks/Reel+6.mp4";

const videos = [video1, video2, video3, video4];

function BrandingThumbVideos() {
  return (
    <div className="branding-thumb-video-container">
      {videos.map((video) => (
        <video
          src={video}
          playsInline
          className={"video"}
          width={"100%"}
          controls={false}
          autoPlay={true}
          loop
          muted
          preload="metadata"
          onLoad={(e) => {
            setTimeout(() => {
              e.target.play();
            }, 1000);
          }}
          onClick={(e) => e.target.play()}
        ></video>
      ))}
    </div>
  );
}

export default BrandingThumbVideos;
