import { useEffect, useState } from "react";
import NewsEventCard from "./NewsEventCard";
import "./NewsEvents.css";
import { NewsEventsData } from "./NewsEventsData";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";

function NewsEvents() {
  const MAX_THUMB_SHOW = 6
  const [newsEvents, setNewsEvents] = useState(NewsEventsData.slice(0, MAX_THUMB_SHOW))
  const [hideShowMoreBtn, setHideShowMoreBtn] = useState(false)

  function HandleShowMore(){
    let addThumCount = newsEvents.length + MAX_THUMB_SHOW;
    addThumCount = addThumCount > NewsEventsData.length ? NewsEventsData.length : addThumCount;
    
    if(addThumCount !== NewsEventsData.length){
        setNewsEvents(NewsEventsData.slice(0,addThumCount))
    }else{
        setNewsEvents(NewsEventsData.slice(0,addThumCount))
        setHideShowMoreBtn(true)
    }
  }

  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className="news-events-page">
        <header>
            <h2>News</h2>
        </header>

        <section className="news-events-cards-section">
            {
                newsEvents.map((data, index) => <NewsEventCard key={`news_${index}`} {...data}/>)
            }
            
        </section>
        <div className="show-more-section">

        {!hideShowMoreBtn && <button className="show-more-btn" onClick={HandleShowMore}>Show More</button>}
        </div>
    </section>
  )
}

export default NewsEvents