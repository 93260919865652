
import React, { useState } from "react";
import {useDispatch} from "react-redux"
import { set_FSV_Handler } from "../../../../../redux/Reducer/reducer";
import { FSV_ACTIONS } from "../../../Helper";

function ProductNavTab({ products, nav1, nav2 }) {
  const [activeTab, setActiveTab] = useState(products[0]?.cat_alias);
  const dispatch = useDispatch()
  
  const mediumDevice = window.innerWidth <= 768
  
  return (
    <div className={`heading mb-3 ProductNavTab ${mediumDevice ? 'overflow-scroll example' : 'overflow-auto'}`}>
      
        {products?.map((product, index) => {
          return (
            <h5
              className={`ec-single-title tab ${
                activeTab === product?.cat_alias ? "active" : ""
              }`}
              key={product?.cat_alias}
              onClick={() => {
                if(products.length <= 1) return 
                nav1?.slickGoTo(index)
                nav2?.slickGoTo(index)
                setActiveTab(product?.cat_alias);
                dispatch(set_FSV_Handler({type: FSV_ACTIONS.SET_SELECTED_PRODUCT, payload: index}))
               }}
              tabIndex={index}
            >
              {product?.cat_alias}
            </h5>
          );
        })}
      
    </div>
  );
}

export default React.memo(ProductNavTab);
