import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { handle_singl_named_product_delete } from "../../../Pages/NamedWishlist/named_wishlist_helper";
import { useQueryClient } from "@tanstack/react-query";
import { set_inCartWishList } from "../../../redux/Reducer/reducer";
import { useDispatch } from "react-redux";

function NamedWishlistDropMenu({
    openCardMenu,
    anchorEl,
    handleCloseCardMenu,
    product_id,
    wishlist_id,
    master,
    matrix_data_count,
    handleOpenseWishlistpopupModal
  }) {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const queryClient = useQueryClient();

    async function handle_delete_named_wishlist() {
        const single_named_product_delete_response = await handle_singl_named_product_delete(
          product_id,
          wishlist_id,
          master
        );
        
        await queryClient.invalidateQueries({ queryKey: ["named_wishlist"] });
        await queryClient.invalidateQueries({ queryKey: ["named_wishlist_initial"] });
        
        if(single_named_product_delete_response?.ack === "1" || single_named_product_delete_response?.ack === 1){

          dispatch(set_inCartWishList({
            in_cart_id_dm: single_named_product_delete_response.in_cart_id_dm,
            in_cart_id_im: single_named_product_delete_response.in_cart_id_im,
            in_wishlist_id_dm: single_named_product_delete_response.in_wishlist_id_dm,
            in_wishlist_id_im: single_named_product_delete_response.in_wishlist_id_im,
          }))
        }
        //await queryClient.invalidateQueries({ queryKey: ["login"] });
        if (matrix_data_count === 1) {
          navigate("/named-wishlist");
        } else {
          await queryClient.invalidateQueries(["matrix-count"]);
          await queryClient.invalidateQueries(["matrix-data"]);
        }

        handleCloseCardMenu()
      }

      function handle_transfer_product(){
        handleOpenseWishlistpopupModal()
        handleCloseCardMenu()
      }
    

  return (
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openCardMenu}
        onClose={handleCloseCardMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="named-wishlist-card-menu"
      >
        <MenuItem onClick={handle_transfer_product}>Transfer Product</MenuItem>
        <MenuItem onClick={handle_delete_named_wishlist}>Delete Product</MenuItem>
      </Menu>
  )
}

export default NamedWishlistDropMenu