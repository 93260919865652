export const NewsLetterData = [
    {
        "title": "MAY 2024 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/2001717658884.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/2001717658886.pdf"
    },
    {
        "title": "APRIL 2024 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1971714816576.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1971714719831.pdf"
    },
    {
        "title": "MARCH 2024 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1941713764479.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1941713764482.pdf"
    },
    {
        "title": "FEBRUARY 2024 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1911709902801.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1911709902801.pdf"
    },
    {
        "title": "JANUARY 2024 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1881707126860.jpeg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1881707126860.pdf"
    },
    {
        "title": "DECEMBER 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1861704867015.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1861704792069.pdf"
    },
    {
        "title": "NOVEMBER 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1821702105379.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1821702105379.pdf"
    },
    {
        "title": "OCTOBER 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1801699967298.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1801699967298.pdf"
    },
    {
        "title": "SEPTEMBER 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1791697804695.jpeg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1791697804695.pdf"
    },
    {
        "title": "AUGUST 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1771695719005.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1771695719005.pdf"
    },
    {
        "title": "JULY 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1741692956013.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1741692956013.pdf"
    },
    {
        "title": "JUNE 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1651689066674.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1651689066674.pdf"
    },
    {
        "title": "MAY 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1621687163591.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1621687163591.pdf"
    },
    {
        "title": "APRIL 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1591683887519.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1591683887519.pdf"
    },
    {
        "title": "MARCH 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1711691489360.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1711691489360.pdf"
    },
    {
        "title": "FEBRUARY 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1681691489222.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1681691489222.pdf"
    },
    {
        "title": "JANUARY 2023 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1561676534472.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1561676534377.pdf"
    },
    {
        "title": "DECEMBER - 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1531673075843.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1531673075843.pdf"
    },
    {
        "title": "NOVEMBER 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1501670910398.png",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1501670910398.pdf"
    },
    {
        "title": "OCTOBER 2022  NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1471667368450.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1471667368450.pdf"
    },
    {
        "title": "SEPTEMBER 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1441665472328.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1441665472328.pdf"
    },
    {
        "title": "AUGUST 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1411662544616.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1411662544616.pdf"
    },
    {
        "title": "JULY 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1381660824061.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1381660824061.pdf"
    },
    {
        "title": "JUNE 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1351708602822.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1351708602822.pdf"
    },
    {
        "title": "MAY 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1321655290041.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1321655290041.pdf"
    },
    {
        "title": "APRIL 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1291652342580.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1291652342580.pdf"
    },
    {
        "title": "MARCH 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1261650358879.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1261650358879.pdf"
    },
    {
        "title": "FEBRUARY 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1231649059818.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1231659436570.pdf"
    },
    {
        "title": "JANUARY 2022 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1201643892651.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1201643892651.pdf"
    },
    {
        "title": "DECEMBER  2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1171642055491.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1171642055491.pdf"
    },
    {
        "title": "NOVEMBER  2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1141638942266.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1141638942266.pdf"
    },
    {
        "title": "OCTOBER 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1111637212755.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1111637212755.pdf"
    },
    {
        "title": "SEPTEMBER  2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1081635243237.png",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1081635243237.pdf"
    },
    {
        "title": "AUGUST 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1051630654160.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1051630654160.pdf"
    },
    {
        "title": "JULY 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/1021629696703.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/1021629696703.pdf"
    },
    {
        "title": "JUNE 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/991629696564.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/991629696564.pdf"
    },
    {
        "title": "MAY 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/561708603057.png",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/561708603651.pdf"
    },
    {
        "title": "APRIL 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/541620398698.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/541620398698.pdf"
    },
    {
        "title": "MARCH  2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/521620398658.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/521620398658.pdf"
    },
    {
        "title": "FEBRUARY 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/501620398617.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/501620398617.pdf"
    },
    {
        "title": "JANUARY 2021 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/421613120385.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/421613120385.pdf"
    },
    {
        "title": "DECEMBER  2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/401613120327.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/401613120327.pdf"
    },
    {
        "title": "NOVEMBER 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/481620398539.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/481620398539.pdf"
    },
    {
        "title": "OCTOBER 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/461621071994.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/461620398493.pdf"
    },
    {
        "title": "SEPTEMBER  2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/381613120273.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/381613120273.pdf"
    },
    {
        "title": "AUGUST 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/441620398441.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/441620398441.pdf"
    },
    {
        "title": "JULY 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/361613120216.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/361613120216.pdf"
    },
    {
        "title": "JUNE 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/341613120095.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/341613120095.pdf"
    },
    {
        "title": "MAY 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/321592552337.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/321592552337.pdf"
    },
    {
        "title": "APRIL 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/301591256657.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/301591256657.pdf"
    },
    {
        "title": "MARCH 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/291591256630.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/291591256630.pdf"
    },
    {
        "title": "FEBRUARY 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/281591256604.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/281591256604.pdf"
    },
    {
        "title": "JANUARY 2020 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/271591256576.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/271591256576.pdf"
    },
    {
        "title": "DECEMBER  2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/261591256424.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/261591256424.pdf"
    },
    {
        "title": "NOVEMBER 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/251591255242.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/251591255749.pdf"
    },
    {
        "title": "OCTOBER 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/231591254830.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/231591255683.pdf"
    },
    {
        "title": "SEPTEMBER 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/221591254293.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/221591255613.pdf"
    },
    {
        "title": "AUGUST  2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/211591254243.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/211591185221.pdf"
    },
    {
        "title": "JULY 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/41582631684.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/41582631684.pdf"
    },
    {
        "title": "JUNE 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/51582631943.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/51582631943.pdf"
    },
    {
        "title": "APRIL 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/61582631987.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/61582632011.pdf"
    },
    {
        "title": "MARCH 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/71582632467.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/71582632066.pdf"
    },
    {
        "title": "FEBRUARY 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/81582632494.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/81582632092.pdf"
    },
    {
        "title": "JANUARY 2019 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/91582632507.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/91582632117.pdf"
    },
    {
        "title": "DECEMBER 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/101582632523.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/101582632146.pdf"
    },
    {
        "title": "NOVEMBER 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/111582632543.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/111582632228.pdf"
    },
    {
        "title": "OCTOBER 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/121582632555.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/121582632258.pdf"
    },
    {
        "title": "SEPTEMBER 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/131582632565.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/131582632275.pdf"
    },
    {
        "title": "AUGUST 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/141621072306.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/141582632295.pdf"
    },
    {
        "title": "JULY 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/151582632633.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/151582632319.pdf"
    },
    {
        "title": "JUNE 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/161582632643.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/161582632339.pdf"
    },
    {
        "title": "MAY 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/171582632652.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/171582632359.pdf"
    },
    {
        "title": "APRIL  2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/181582632664.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/181582632386.pdf"
    },
    {
        "title": "FEBRUARY 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/191582632674.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/191659009319.pdf"
    },
    {
        "title": "JANUARY 2018 NEWSLETTER",
        "image": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/images/201582632683.jpg",
        "link": "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsletter/pdf/201582632429.pdf"
    }
]