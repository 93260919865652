import { useEffect } from "react";
import { FeaturesData } from "./FeaturesData";
import FeaturesCard from "./FeaturesCard";


function Features() {
  
    
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        })
    

        
    }, [])

  return (
    <section className="news-letters-page" >

    
      <header id="news-letters">
        <a href="#news-letters" id="blank_link">&nbsp;</a>
        <h2 style={{fontWeight: "600"}}>Features</h2>
      </header>

      {/* <section className="banner-iframe-video" id="banner-iframe-video">
        <iframe
        
          height="420"
          src="https://www.youtube.com/embed/ahEytZ-1A3E"
          title="Kalasha Newsletter - September"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          style={{width: 'min(1440px, 100%)'}}
        ></iframe>
      </section> */}

      <section className="news-letters-section">
            {
                FeaturesData.map((data, index) => <FeaturesCard key={`news-letter-${index}`} {...data}/>)
            }
      </section>
    </section>
  );
}

export default Features;
