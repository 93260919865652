import React, { useCallback, useEffect, useState } from "react";
import "./AllOrder.css";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import { get_property_settings, get_userData_from_activeUser } from "../../Component/Helper";
import NoProduct from "../../Component/NoProduct/NoProduct";
import StatusButtonsBar from "../../Component/StatusButtonsBar/StatusButtonsBar";
import SearchBar from "../../Component/SearchBar/SearchBar";
import Loader from "../../Component/Loader";
import AllOrderCard from "./AllOrderCard";
import PaginationGlob from "../../Component/PagiantionComp/PagiantionComp";

function AllOrder() {
  let show_past_order = get_userData_from_activeUser()?.show_past_order;
  show_past_order = show_past_order === "1";

  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  ); 
  

  const headerMenuHeight = sessionStorage.getItem("HeaderMenu")
    ? sessionStorage.getItem("HeaderMenu") + "px"
    : "0px";

  const [status_group, set_status_group] = useState(null);
  const [search_key, set_search_key] = useState("");
  const [total_order_count, set_total_order_count] = useState(0);
  const [page, set_page] = useState(() => Number(searchParams?.page) || 0);
  const handle_page = useCallback((pageNo) => set_page(pageNo), []);
  const [active_status, set_active_status] = useState(searchParams.status_id || "0")
  const handle_active_status = useCallback((id) => {
    set_active_status(id)
  }, [])
  let hide_group_by_status_order_list_view = get_property_settings("hide_group_by_status_order_list_view")
  hide_group_by_status_order_list_view = hide_group_by_status_order_list_view === "hide"
  
  const handle_search_key = useCallback(
    (search_key) => {
      set_search_key(search_key);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search_key]
  );

  


  const all_order_query = useQuery({
    queryKey: ["all-order-list", active_status, search_key, page],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.order_list, "all-order-list", {
        order_no: search_key,
        status_id: active_status,
        page: searchParams.page || page,
        page_size: "10",
        ...searchParams,
      }).then((res) => {
        if (typeof res === "object") {
          if (!status_group) {
            set_status_group(res?.status_group);
          }

          return res;
        }
      }),
    enabled: show_past_order,
  });

  useEffect(() => {
    window.scrollTo(0,0)
    if (!status_group) return;
    const selected_status = status_group.find(
      (group) => group.id === active_status
    );

    if(search_key){
      set_total_order_count(0)
    }

    else if (selected_status) {
      set_total_order_count(Number(selected_status?.order_count));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_status, status_group, search_key]);

 
  if (!show_past_order) {
    return <NoProduct errMsg="No Page Found" />;
  }
  
  return (
    <section className="all-order-page">
      {status_group ? (
        <header style={{ top: headerMenuHeight }}>
          <section className="header-inner">
            {!hide_group_by_status_order_list_view && <StatusButtonsBar status_group={status_group} active_status={active_status} handle_active_status={handle_active_status}/>}
           <SearchBar handle_search_key={handle_search_key} />
            {total_order_count > 1 ? (
                <PaginationGlob
                  size={window.innerWidth > 992 ? "large" : "medium"}
                  totalProductCount={total_order_count}
                  page={page}
                  handle_page={handle_page}
                  page_size={"10"}
                />
              ) : null}
          </section>
        </header>
      ) : null}

      {all_order_query.isLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
        {
          all_order_query.data?.error ? (
            <NoProduct errMsg={all_order_query.data?.error} hideHomeBtn={true}/>
          ) : (
            <section className="all-order-card-section">
          {all_order_query.data?.order_list?.map(order =>  <AllOrderCard key={order.id} {...order} />)}
        </section>
          )
        }
        
        </>
      )}
    </section>
  );
}

export default AllOrder;
