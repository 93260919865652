import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import MUIModal from "../MUIModal/MUIModal";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import "./WhatsAppModal.css";
import { IoMdClose } from "react-icons/io";

function WhatsAppModal({encodedMessage = ""}, ref) {
  const [open_MUI_modal, set_open_MUI_modal] = useState(false);
  const handle_close_MUI_modal = useCallback(
    () => set_open_MUI_modal(false),
    []
  );
  const handle_open_MUI_modal = () => set_open_MUI_modal(true);

  useImperativeHandle(ref, () => ({
    open_modal: () => handle_open_MUI_modal(),
  }));

  const whatsApp_query = useQuery({
    queryKey: ["whatsApp"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.Whatsapp, "whatsApp"),
    enabled: open_MUI_modal,
  });
  
  return open_MUI_modal && whatsApp_query.isSuccess ? (
    <MUIModal
      className="whats-app-modal"
      open_MUI_modal={open_MUI_modal}
      handle_close_MUI_modal={handle_close_MUI_modal}
      style={{ p: 0,  width: "min(320px, 90%)" }}
    >
      <section className="whatsApp-modal-section">
        <header>
          <h3>Whatsapp Us</h3>
          <IoMdClose className="close-icon" onClick={handle_close_MUI_modal}/>
        </header>
        <ul className="list">
          {whatsApp_query.data?.map((data, index) => {
            const whatsAppNo = (`${data?.whatsapp_mobile_number_country_code}${data?.whatsapp_mobile_number}`).replace(/\D/g, "")

           return (
            <li key={`${data?.person_name}-${index}`}>
              <a href={`https://api.whatsapp.com/send?phone=${whatsAppNo}&text=${encodedMessage}`} target="_blank" className="link" rel="noreferrer">
                <p className="field-name">{data?.person_name}</p>
                <p className="mobile-no">{data?.whatsapp_mobile_number_country_code}{data?.whatsapp_mobile_number}</p>

              </a>
            </li>
          )
          })}
        </ul>
      </section>
    </MUIModal>
  ) : null;
}

export default forwardRef(WhatsAppModal);
