// import KalashaMobileApp from "../../../../../assets/images/kalashaonline/kalasha-mobile-app.png"
import PlayStore from "../../../../../assets/images/Google-Play.png"
import IosStore from "../../../../../assets/images/ios-store.png"
import "./DownloadAppSection.css"
import { get_bulk_settings } from "../../../../Helper"
import { getDownloadAppImage } from "./DownloadApp"

function DownloadAppSection({clsName=""}) {
  const {android_app_url, ios_app_url} = get_bulk_settings(["android_app_url", "ios_app_url"])
  const DownloadAppImage = getDownloadAppImage()
  
 
  return (
    <>
    {DownloadAppImage ? <section className={`download-app-section ${clsName}`}>
      <div className='mobile'>
        <img src={DownloadAppImage} alt="mobile" />
      </div>
      <div className='detail-section'>
        <h3>Download Our App</h3>
        <p>Shining new app,
          made just
          for you. It's Free, Easy & Smart
        </p>
        <div className='store-btns'>
          <a href={android_app_url} target="_blank" rel="noreferrer">
            <img src={PlayStore} alt="play-store"/>
           </a>
           <a href={ios_app_url} target="_blank" rel="noreferrer">
           <img src={IosStore} alt="ios-store"/>
           </a>
        </div>
      </div>
    </section> : null}
    </>
  )
}

export default DownloadAppSection