import React from 'react'
import { get_property_settings } from '../../Helper'
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from '../../../Api/APICalls';
import { ApiList } from '../../../Api/ApiList';
import SocialLink from './components/SocialLink';
import { getCustomValue } from '../../../Custom/CompanyList';
import { CUSTOMIZIED_COMPANY_PROPERTY } from '../../../Custom/CompanyCustomization';


function FooterLocation() {

    const social_link = get_property_settings("social_link") || []
    let show_social_link = get_property_settings("show_social_link")
    show_social_link = show_social_link === "Yes"
    const show_floating_social_icons = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_floating_social_icons)
    
    // const locationData = useSelector(getLocation) || []

    const location_query = useQuery({
        queryKey: ["location"],
        queryFn: () => fetch_data_from_api_from_query(ApiList.Location, "location", {
            device: "web"
         }),
         staleTime: Infinity,
      });

    if(!Array.isArray(location_query?.data?.location) || (Array.isArray(location_query?.data?.location) && location_query?.data?.location?.length === 0)){
        return null
    }
   
    return (
        
            <div className="ec-footer-widget">
                
                <h4 className="ec-footer-heading">Location</h4>
                <div className="ec-footer-links">
                    <ul className="align-items-center my-3 ">
                        {
                           location_query?.isSuccess && location_query?.data?.location?.map((location, index) => (
                                <li className="ec-footer-link address-section" key={index}>
                                    <div>
                                        {/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
                                        <p>&nbsp;&nbsp;&nbsp;</p>
                                        <b>{location?.title}</b>

                                    </div>

                                    <div className="address">
                                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                                        <div>
                                        {location?.address.split('\n').map((loc, index) => <p key={index}>{loc}</p>)}
                                        </div>
                                    </div>

                                    <div>
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        <p>{location?.mobile}</p>
                                    </div>

                                </li>
                            ))
                        }

                    </ul>
                    {!show_floating_social_icons && show_social_link && <div className="col-sm-12 col-lg-3 ec-footer-social">
                        <div className="ec-footer-widget social-icons">
                            <div className="ec-footer-links">
                                <ul className="align-items-center social-footer-links">
                                    {
                                        social_link.map(link => <SocialLink social_link={link} key={link.short_code}/>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        
    )
}

export default React.memo(FooterLocation)
