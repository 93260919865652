import { memo } from 'react';
import { Link } from 'react-router-dom'
import { createSearchURLParams } from '../../../../../Component/Helper'

function CampaignCard({image, title, collectionID, year, index, url, searchParams}) {
 
  const editURL = createSearchURLParams(url.replace('/',''), {...searchParams, collection: collectionID})
 
  return (
    <div className='campaign-card'>
        <div className='image-section'>
        {/* <FullScreenImageSlider imagesArr={CampaignDataAllImages}> */}
            <img src={image} alt={title} onClick={() => sessionStorage.setItem("active_order_details_slide", (index + 1))}/>
        {/* </FullScreenImageSlider> */}
        </div>


        <div className="detail-section">
            <div>
                <h4 className='year'>{year}</h4>
                <h4 className='campaign-title'>{title}</h4>
            </div>
            <Link to={editURL} className='view-more-btn' onClick={() => window.scrollTo(0,0)}>View More</Link>
        </div>
    </div>
  )
}

export default memo(CampaignCard)