import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./EnquiryFormModal.css";
import PhoneInput from "react-phone-input-2";
import { useForm, Controller } from "react-hook-form";
import { EMAIL_REGEX, MOBILE_REGEX } from "../../../../utils";
import { ToastError, ToastSuccess, get_informationFromSessionID, set_informationToSessionID } from "../../../../Component/Helper";
import OTPModal from "./OTPModal";
import { get_enqiure_response, get_webServiceName } from "./EnquiryForm";
import { BeatLoader } from "react-spinners";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(500px, 90%)",
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EnquiryFormModal({ open, handleClose, related_enquiry, children }) {

  const [openOTPModal, setOpenOTPModal] =  React.useState(false);
  const [loading, setLoading] =  React.useState(false);
  const handleOTPModalOpen = () => setOpenOTPModal(true);
  const handleOTPModalClose = () => setOpenOTPModal(false);

  const [otpRes, setOtpRes] = React.useState("")
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    getValues
  } = useForm();


  
  React.useEffect(() => {
    reset()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onSubmit = async (data) => {
    setLoading(true)
    const countryCode = get_informationFromSessionID("country_code")
    const edited_data = {...data, dial_code: `+${countryCode}`, mobile_no: data.mobile_no.replace(countryCode, "")}
    const webServiceName = get_webServiceName()
    try{

      const get_otp_response = await get_enqiure_response(webServiceName, "Thirdparty_otp", edited_data)
      //console.log(get_otp_response)
      if(get_otp_response?.ack === "1" || get_otp_response?.ack === 1){
        setOtpRes(get_otp_response?.otp)
        handleOTPModalOpen()
      }else if(get_otp_response?.ack === "0" || get_otp_response?.ack === 0){
        ToastError(get_otp_response?.error || "Something went wrong")
      }
    }catch(err){
      console.log(err)
      ToastError("Something went wrong")
    }finally{
      setLoading(false)
    }
  }

  const saveEnquire = async () => {
      const {name, message, mobile_no, email} = getValues()
      const countryCode = get_informationFromSessionID("country_code")
      //const companyCode = get_informationFromSessionID("companyCode")
      const webServiceName = get_webServiceName().toUpperCase()
      const params = {
        company_code: webServiceName,
        first_name: name,
        message,
        mobile_no: mobile_no.replace(countryCode, ""),
        lead_source: "Website",
        lead_through_name: "Contact - Hyderabad",
        country_code: `+${countryCode}`,
        email_id: email,
        related_enquiry: related_enquiry,
        mfg_code: "",
      }
      //console.log(params)
      const get_save_enquire_response = await get_enqiure_response(null, "Website_enquiry_api", params, true)
      //console.log(get_save_enquire_response)
      if(get_save_enquire_response?.ack === "1" || get_save_enquire_response?.ack === 1){
        handleOTPModalClose()
        ToastSuccess(get_save_enquire_response?.msg)
        handleClose()
      }else if(get_save_enquire_response?.ack === "0" || get_save_enquire_response?.ack === 0){
        ToastError(get_save_enquire_response?.msg)
      }else{
        ToastError("Data not saved")
      }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="enquiry-form-modal"
      >
        <Box sx={style} className="enquiry-form-container">
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <input
              placeholder="Your Full Name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Full name is required",
                },
                minLength: {
                    value: 6,
                    message: "Min. 6 characters required",
                  },
              })}
            />
           {errors.name && <p className="error-txt">{errors.name.message}</p>}
            <input placeholder="Email Address" {...register("email", {
                required: {
                    value: true,
                    message: "Email is required"
                },
                pattern: {
                    value: EMAIL_REGEX,
                    message: "Invaild Email ID"
                }
            })} />
            {errors.email && <p className="error-txt">{errors.email.message}</p>}
            <Controller
            rules={{
                required: {
                    value: true,
                    message: "Mobile no. is required"
                },
                validate: (fieldValue) => {
                    const country_code =
                      get_informationFromSessionID(
                        "country_code"
                      );
                    const onlyMobileNo = fieldValue.replace(
                      country_code,
                      ""
                    );
                    return (
                      MOBILE_REGEX.test(onlyMobileNo) ||
                      `Mobile no. is invaild`
                    );
                  },
            }}
        control={control}
        name="mobile_no"
        render={({ field: { onChange,  value } }) => (
            <PhoneInput 
            country={"in"}
            masks={{ in: ".........." }} 
            value={value}
            onChange={(phoneNumber, country) => {
                set_informationToSessionID({ country_code: country.dialCode });
                onChange(phoneNumber);
              }}
            />
        )}
      />
            
           
            {errors.mobile_no && <p className="error-txt">{errors.mobile_no.message}</p>}
            <textarea
              rows={3}
              placeholder="Message"
              {...register("message")}
            ></textarea>
            <p>

            {children}
            </p>
            {/* <button className="btn">Enquire Now</button> */}
            <button className="btn" disabled={loading}>{loading ? <BeatLoader color={'rgba(255,255,255,0.2)'}/> : "Enquire Now"}</button>
          </form>
        </Box>
      </Modal>

     {openOTPModal ? <OTPModal openOTPModal={openOTPModal} otpRes={otpRes} saveEnquire={saveEnquire}/> : null}
    </div>
  );
}
