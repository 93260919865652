import Select from "react-select"
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { ApiList } from "../../../../Api/ApiList";
import { createSelectArrayForReactSelect, get_property_settings } from "../../../../Component/Helper";
import AddSelectClientBtn from "./AddSelectClientBtn";

function SelectDropDownInputWithButton({field}) {
  
  const {field_name, short_code, required_field, control_value, default_select} = field || {}
  const {formState: {errors}, setValue, control } = useFormContext() 
  const [behalf_client_details, set_behalf_client_details] = useState([])
  
  const primary_BG_color = get_property_settings("btn_primary_color");
 
  
  // eslint-disable-next-line no-unused-vars
  const get_behalf_client_details = useQuery({
    queryKey: ["get_behalf_client_details"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.get_behalf_client_details, "get_behalf_client_details").then(res => {
      if(res?.behalf_client_details){
          return set_behalf_client_details(createSelectArrayForReactSelect(res?.behalf_client_details, "company_name", "id"))
      }
      return []
    }),
    // enabled: !!is_myc_web,
  }); 

  

  return (
    <div className="form-input-group">
      <label>
        <span>{field_name}</span>
        <span className="required">*</span>
      </label>
      <div className="dropdown-with-btn-container">
      <Controller
          control={control}
          name={short_code}
          rules={{
            required: {
              value: required_field === "1",
              message: field_name + " is required",
            },
          }}
          render={({ field }) => {
            return (
              <Select
               options={behalf_client_details}
                className="react-select-container r-container"
                classNamePrefix="react-select r-select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25:
                      (primary_BG_color &&
                        primary_BG_color
                          .replace("rgb", "rgba")
                          .replace(")", ",0.2)")) ||
                      "inherit",
                    primary: primary_BG_color,
                  },
                })}
                isSearchable={true}
                {...field}
              />
            );
          }}
      />

      
     
     <AddSelectClientBtn />
      </div>
      {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default SelectDropDownInputWithButton;
