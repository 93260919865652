import PaymentCard from "./components/PaymentCard";
import "./Payment.css"


function FailedPayment() {
  return (
    <div className="payment-container">
      <PaymentCard type="failed"/>
    </div>
  );
}

export default FailedPayment;