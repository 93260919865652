import React from "react";
import {  get_property_settings  } from "../../../../../Component/Helper";
import { formatPrice } from "../../../../../Component/HomeComponents/HomeHeader/components/UserProfileDropDownMenu/Components/ChangeCurrencyModal/change_currency_helper";
import { useSelector } from "react-redux";
import { get_selected_currency } from "../../../../../redux/Reducer/reducer";

function TotalCost({item, calc_total_after_tax_amt, data, index}) {
  const labour_amount_column_show_rupee_symbol = get_property_settings("labour_amount_column_show_rupee_symbol")
  const selected_currency = useSelector(get_selected_currency)
  return (
    <div className="total product-details-bottom-group">
      <div className="d-flex justify-content-between total-cost">
        <div>
          <span>{item}</span>{" "}
        </div>
        <div className="ml-auto total-amt">
          {labour_amount_column_show_rupee_symbol === "Yes" ? (
            <span>
              {" "}
              {formatPrice(
                calc_total_after_tax_amt || data?.values[index], true, selected_currency
              )}
            </span>
          ) : (
            <span> {calc_total_after_tax_amt || data?.values[index]}</span>
          )}
        </div>
        <br />
      </div>
    </div>
  );
}

export default React.memo(TotalCost);
