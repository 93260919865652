import "./ProductOnlyNameHeader.css";


function ProductOnlyNameHeader({name, children}) {
  return (
   children
  )
}

export default ProductOnlyNameHeader
