import { copyToClipboard } from "../../../../../../utils"
import "./ContactUs.css"
import { IoIosCall } from "react-icons/io";

function ContactUs({body}) {

  function handleContact (e) {
    if(window.innerWidth > 576){
        e.preventDefault()
        copyToClipboard(body.mobile_no, "Number copied")
    }
  }

  return (
    <div className="matrix-branding-contact-us">
        <h3>{body.title}</h3>
        <a href={`tel:${body.mobile_no}`} onClick={handleContact} className="contact-us-shake-bottom">
          <IoIosCall/>
        </a>
    </div>
  )
}

export default ContactUs