import { BiSolidNavigation } from "react-icons/bi";
import NoImg from "../../../../assets/images/no_image_available.png";
import { get_bulk_settings } from "../../../Helper";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";

function StoreCard({title, address, mobile, file, google_map_url}) {

  const {cdn, default_image} = get_bulk_settings(["cdn", "default_image"]) || {}
  const store_img = file ? file : default_image ? `${cdn}uploads/default_image/${default_image}` : NoImg;

  const hide_background_store_location_card = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.hide_background_store_location_card)

  return (
    <section className={`store-card ${hide_background_store_location_card ? "remove-bg" : ""}`}>
      <img src={store_img} alt="store-card-img" className="store-card-img"/>
      <div className='store-card-details'>
        <h3 className='store-card-title'>{title}</h3>
        <p className='store-card-address'>{address}</p>
        <p className='store-card-mobile'>{mobile}</p>
        <a href={google_map_url || ""} className='store-direction-btn' target="_blank" rel="noopener noreferrer">
            <BiSolidNavigation/>
            <span>Get Directions</span>
        </a>
      </div>
    </section>
  )
}

export default StoreCard
