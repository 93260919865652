function TermsAndCondition() {
  return (
    <div className="terms-section">
        <header>
            <h3>TERMS AND CONDITIONS :</h3>
        </header>

        <ol>
            <li>On purchase of jewellery all government norms towards jewellery would be applicable and under no instance, refund of cash would be made.</li>
            <li>It is advisable not to make any deviations or changes in monthly instalment during the period of Saving Scheme. If aforesaid need arises, a new enrolment can be initiated.</li>
            <li>You will have to pay your monthly instalment before the due date at your nearest CapsGold office or you may also call our customer representative and request for a free pick up.</li>
            <li>You can pay your instalments through cheque/post-dated cheque in favour of CapsGold Private Limited, cash can also be paid at our flagship store Kalasha Fine Jewels or any of our designated sales offices.</li>
            <li>The monthly instalment will have to be paid on or before 5th of every month from the date of enrolment and a maximum of five days grace period would be given to make payments.</li>
            <li> If the scheme is discontinued by a customer for whatsoever reason then the customer can purchase jewellery only to the extent of amount paid and will also have to pay the first month's discounted amount. No bonus shall be applicable.</li>
            <li>You will need to submit the passbook in original along with a valid ID proof to purchase your jewellery.</li>
            <li>CapsGold Private Limited reserves the right to forfeit any amount of the customer, in case of purchase is not made within the stipulated time.</li>
            <li>Non payment of three instalments will lead to termination of the Saving Scheme for the particular customer.</li>
            <li>Any terms, which may not have been covered in this leaflet or the application form, would be at the discretion of the company and would be deemed as in irrevocable and final.</li>
            <li>No ongoing offer will be applicable while purchasing jewellery with the Saving Scheme.</li>
            <li>The scheme amount is only valid for redeeming jewellery & silver articles. Not valid on gold coins & silver coins.</li>
            <li>The Spcl. Benefits are valid only for the amount invested in the Scheme. (Not for the extra purchase made)</li>
            <li> Gold rate on the date of advance or on the date of purchase whichever is less will be applicable for purchase.</li>
            <li>Benefit of lower gold rate will be applicable only for the gold weight equivalent to the amount advanced.</li>
            
        </ol>
        <b>"Kalasha Fine Jewels is the brand owned by CapsGold Pvt Ltd All Disputes Subject to Hyderabad Jurisdiction."</b>
    </div>
  )
}

export default TermsAndCondition