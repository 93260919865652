import React from 'react'
import dayjs from 'dayjs'
import useRealTime from '../../../../../Hooks/useRealTime'

function DateTimeUpdate() {
  const date_time = useRealTime()
  return (
    <span className='date-time'>{`${dayjs().format("DD/MM/YYYY")} ${date_time}`}</span>
  )
}

export default React.memo(DateTimeUpdate)