import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import WhatsappForLargeDevice from "./components/WhatsappForLargeDevice";
import WhatsappForSmallDevice from "./components/WhatsappForSmallDevice";
import {  fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import {useSelector} from "react-redux"
import { useQuery } from "@tanstack/react-query";
import { getCustomValue } from "../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../Custom/CompanyCustomization";
import { get_property_settings } from "../Helper";
import { get_search_params } from "../../utils";

const WhatsappIconNew = ({ showInMobile }) => {
  const [whatsAppNo, setWhatsAppNo] = useState("");
  const whatsAppMsg = useSelector(state => state?.reducer?.whatsAppMsg)
  const {mode} = get_search_params()
  
  const whatsApp_query = useQuery({
    queryKey: ["whatsApp"],
    queryFn: () => fetch_data_from_api_from_query(ApiList.Whatsapp, "whatsApp"),
    enabled: mode !== "app"
  });

 

  
 const show_floating_social_icons = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_floating_social_icons)
 const social_link = get_property_settings("social_link")
 const TOP_MARGIN = 65;
 const OFFSET_MARGIN = 7;
 
 

  useEffect(() => {
    if(Array.isArray(whatsApp_query?.data)){
      const data = whatsApp_query?.data[0]
      const whatsapp_mobile_number = `${data?.whatsapp_mobile_number_country_code}${data?.whatsapp_mobile_number}`
      setWhatsAppNo(whatsapp_mobile_number)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatsApp_query?.isSuccess]);

  if(mode === "app"){
    return null
  }

  return (
    <>
    <WhatsappForLargeDevice whatsAppNo={whatsAppNo} encodedMessage={whatsAppMsg} customStyle={show_floating_social_icons ? {top: `${TOP_MARGIN}%`} : {}} whatsApp_contact_count = {whatsApp_query?.data?.length || 0}/>
    
    {
      show_floating_social_icons && social_link?.length > 0 ? social_link.map((link, index) => {
        const top = (TOP_MARGIN + ((index + 1) * OFFSET_MARGIN)) + "%"
        const zIndex = (13 + ((index + 1) + OFFSET_MARGIN))
        return <WhatsappForLargeDevice linkName={link?.social_label} otherSocialLink={link?.url} key={link?.social_label} customStyle={{top, zIndex}} whatsApp_contact_count = {whatsApp_query?.data?.length || 0}/>

      }) : null
    }
    

    <WhatsappForSmallDevice whatsAppNo={whatsAppNo} encodedMessage={whatsAppMsg} showInMobile={showInMobile} whatsApp_contact_count = {whatsApp_query?.data?.length || 0}/>
    
    </>
    
  );
};

export default WhatsappIconNew;
