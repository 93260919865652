import React from "react";
import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import { get_property_settings } from "../../../../Component/Helper";

function Telephone({ field, control, errors}) {
  const {short_code, field_name, required} = field

  const country_name_shortcode = get_property_settings(
    "country_name_shortcode"
  );
  
  
  return (
    <div>
      <label htmlFor="name">
        {field_name}<span className="required-field">*</span>
      </label>
      <Controller
        control={control}
        name={short_code}
        rules={{
          required: {
            value: required === "1",
            message: `${field_name} is required`
          },
          
        }}
        render={({ field: { onChange,  value} }) => {
          return (
            <PhoneInput
              inputClass="number-input fieldValue"
              buttonClass="contryCodeDropDown"
              placeholder=""
              value={value?.phoneNumber}
              country={
                country_name_shortcode
                  ? country_name_shortcode.toLowerCase()
                  : "in"
              }
              masks={{ in: ".........." }}
              inputProps={
                {
                  // tabIndex: tabIndex + 1,
                }
              }
              onChange={(phoneNumber, country) => {
                 onChange({phoneNumber, country_code: country.dialCode});
              }}
            />
          );
        }}
      />

     {errors[short_code]?.message && <p className="error-msg">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default Telephone;
