/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import image from "../../assets/images/common/404.png";
import styles from "./PageNotFound.module.css"
import patternImg from "../../assets/images/jewelry-seamless-background.jpg"

const PageNotFound = ({error, errorMsg = ""}) => {
  // console.log("error => ", error)
  return (
    <section className={`${styles.PageNotFound_Section} page-not-found`}>

        <img src={patternImg} className={styles.bgImg}/>
        <img src={image} className={styles.img}/>
        <div className={styles.details}>
          <h2>{`${errorMsg ? 'Sorry, No page found' : "Something went wrong !!"}`}</h2>
          <p>{errorMsg}</p>
          {!errorMsg && <button className={styles.refreshBtn} onClick={()=> window.location.reload()}>
            Refresh Page
          </button>}
        </div>
    </section>
  );
};

export default PageNotFound;
