
function ExclusiveOfferCard({title, image, info}) {
  return (
    <div className='exclusive-offer-card'>
        <div className='card-image-section'>
            <img src={image} alt="" />
        </div>

        <div className='card-details-section'>
        <p><b>{title}:</b> {info}</p>
        </div>
    </div>
  )
}

export default ExclusiveOfferCard