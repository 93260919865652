import img1 from "../../../../../assets/images/kalashaonline/static-page/exclusive-nri-offer/Kalasha7.jpg"
import img2 from "../../../../../assets/images/kalashaonline/static-page/exclusive-nri-offer/Kalasha5.jpg"
import img3 from "../../../../../assets/images/kalashaonline/static-page/exclusive-nri-offer/Kalasha1.jpg"
import img4 from "../../../../../assets/images/kalashaonline/static-page/exclusive-nri-offer/Kalasha8.jpg"


export const ExclusiveNRIOfferData = [
    {
        id: 1,
        image: img1,
        title: "Incredible Discounts",
        info: "Take advantage of our special 13.75% discount on gold rates, available exclusively to NRI customers. This means you can buy your favourite gold jewellery without worrying about additional duties or taxes."
    },
    {
        id: 2,
        image: img2,
        title: "Free Shipping",
        info: "We offer complimentary shipping on all orders above INR 5 lakhs. No matter where you are in the world, your precious jewellery will be delivered to your doorstep without any extra charges."
    },
    {
        id: 3,
        image: img3,
        title: "Hassle-Free Delivery",
        info: "Enjoy a seamless shopping experience with our hassle-free doorstep delivery service. Our efficient logistics ensure that your jewellery reaches you safely and promptly."
    },
    {
        id: 4,
        image: img4,
        title: "Virtual Shopping Experience",
        info: "Discover our stunning collections from the comfort of your home through our HD video calling app. Our app offers crystal-clear visuals, allowing you to see every intricate detail of our exquisite pieces. Plus, you can shop with your friends and family using the group calling feature, making the experience even more enjoyable and interactive."
    }

]