import React, { useEffect, useRef } from 'react'
import { AiOutlineReload } from 'react-icons/ai';
import { MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import "./ZoomImageComp.css";

function ZoomImageComp({imgURL}) {
  const zoom_reset_btn_ref = useRef(null)
  
  useEffect(() => {
    if(!zoom_reset_btn_ref.current) return
    zoom_reset_btn_ref.current.click()
    
  })

  return (
    <TransformWrapper initialScale={1} maxScale={window.innerWidth > 768 ? 1 : 2}>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <div className='zoom-image-container'>
              {/* <div className="tools d-none d-md-flex">
                <button
                  onClick={() => zoomIn()}
                  className="zoom-in-btn"
                  title="Zoom in"
                >
                  <MdOutlineZoomIn />
                </button>
                <button
                  onClick={() => zoomOut()}
                  className="zoom-out-btn"
                  title="Zoom out"
                >
                  <MdOutlineZoomOut />
                </button>
                <button
                  onClick={() => resetTransform()}
                  className="zoom-reset-btn"
                  title="Reset zoom"
                  ref={zoom_reset_btn_ref}
                >
                  <AiOutlineReload />
                </button>
              </div> */}
              <TransformComponent wrapperClass="zoom-pan-pinch-wrapper">
                
                <img
                  src={imgURL?.src || imgURL}
                  alt="zoom"
                  className="main-zoom-img"
                  onLoad={(e) => {
                    e.target.style.maxWidth = `${e.target.naturalWidth} px`
                  }}
                  
                />
                {/* <div>Example text</div> */}
              </TransformComponent>
            </div>
          )}
        </TransformWrapper>
  )
}

export default ZoomImageComp