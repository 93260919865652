
import React, {  useRef, useState } from "react";
import { MdEdit } from "react-icons/md";

function CartRemark({ remarks = "", handleChange }) {
  const [remark, setRemark] = useState(remarks);
  const [show_textArea, set_show_textarea] = useState(false);
  const textarea_ref = useRef(null);

  function handle_open_textarea () {
    set_show_textarea(true)
    setTimeout(() => {
      if(textarea_ref.current){
        textarea_ref.current.focus()
      }
    }, 300)
  }
  
  function handle_submit_remark(e) {
    if(e.key === "Enter" || e.key === undefined){
      set_show_textarea(false)
      if(remarks === remark) return
      handleChange("remark", remark)
    }
  }

  
  return (
    <div className="row remarks ">
      <div className="col col-md-3 remarks label">Remark:</div>
      <div className={ ` ${!show_textArea ? 'col-2' : 'col-12'} col-md-9 data`}>
      {!show_textArea && <div className="edit-remark">
           <div className="edit-btn" onClick={handle_open_textarea}>
            <MdEdit className="edit-icon"/>
            {/* <div>{remark}</div> */}
          </div>
        </div>}
       {show_textArea && <textarea
          ref={textarea_ref}
          value={remark}
          className="  form-control form-control-sm"
          placeholder="Add Remark"
          rows={1}
          onChange={(e) => {
            setRemark(e.target.value)
          }}
          onBlur={handle_submit_remark}
          onKeyDown={handle_submit_remark}
        />}
        {/* <button className="update-btn"
        onClick={() => handleChange("remark", remark)}
        >
        Update
        </button> */}
      </div>
      {/* <div className="col-3 d-flex justify-content-end">
              <FontAwesomeIcon icon={faPencil} />
            </div> */}
    </div>
  );
}

export default CartRemark;
