
import AWS from 'aws-sdk';
import { get_property_settings } from '../Component/Helper';

const aws_get_image = async (credentials, image_url, image_name) => {
  
      const jewelkam_bucket_name = get_property_settings("jewelkam_bucket_name")
      const image_path = image_url.replace(/^.+\/\/(cdn.jfone.jewelflow.pro)\//, "")
    
      // Configure the AWS SDK
      const s3 = new AWS.S3({
        // accessKeyId: credentials.accessKeyID,
        // secretAccessKey: credentials.secretAccessKey,
        accessKeyId: "AKIAT66DG3J3FYLFUUOD", // Store in .env file for security
        secretAccessKey: "QLLQmrXvCfJdUIKkdqLJmTqljWsQCoXJyT98K6qf",
        region: "ap-southeast-1" // e.g., 'us-east-1'
      });

      const params = {
        Bucket: jewelkam_bucket_name,
        Key: image_path, // The key of the image in the S3 bucket
      };

      try {
        const data = await s3.getObject(params).promise(); // Fetch the image data
        
        // Create a Blob from the image data
        const blob = new Blob([data.Body], { type: data.ContentType });

        // Create a new File object from the Blob
        const file = new File([blob], image_name, {
          type: data.ContentType, // Use the same MIME type as the original file
          lastModified: Date.now(), // Optional: Set the last modified date
        });
        
        return file
      } catch (error) {
        console.error('Error fetching image from S3:', error);
      }
    
 

 
};

export default aws_get_image;
