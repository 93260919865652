
import PaymentCard from "./components/PaymentCard";
import "./Payment.css"



function SuccessPayment() {
  
  
  
  return (
    <div className="payment-container">
      <PaymentCard type="success"/>
    </div>
  );
}

export default SuccessPayment;
