import img1 from "../../../../assets/images/igje/about-us/rs=w_104,h_100,cg_true.webp";
import img2 from "../../../../assets/images/igje/about-us/rs=w_263,h_100,cg_true.webp";
import img3 from "../../../../assets/images/igje/about-us/cr=w_100,h_100.webp";
import img4 from "../../../../assets/images/igje/about-us/rs=w_299,h_100,cg_true.webp";
import img5 from "../../../../assets/images/igje/about-us/rs=h_100,cg_true,m.webp";


function AssociatedSection() {
  return (
    <section className='associated-section'>
        <header>
        Whom are we associated with
        </header>

        <div className='logo-section'>
            <img src={img1} alt="pic" />
            <img src={img2} alt="pic" />
            <img src={img3} alt="pic" />
            <img src={img4} alt="pic" />
            <img src={img5} alt="pic" />
        </div>
    </section>
  )
}

export default AssociatedSection