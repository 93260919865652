import { useEffect } from "react";
import { copyToClipboard, ScrollToTopCustom } from "../../../../utils";
import "./CHAINHOUSE24Contact.css";

function CHAINHOUSE24Contact() {

    useEffect(() => {
        ScrollToTopCustom()
      }, [])

  return (
    <div className='chain-house-24-contact-us'>
        <section className='location'>
            <img src="https://prodone.jewelflow.pro/chainhouse/public/contact/CHLogo.png" alt="CHLogo" className="CHLogo"/>
            <div className="location-title">
                <h4>Chain House International Pvt. Ltd.</h4>
                <h4>Rohtak Chain & Jewellery Pvt. Ltd.</h4>
            </div>
            <p className='address'>2719, 1st Floor, Bank Street,<br/>
            Karol Bagh, Delhi - 110005.</p>
            <p>
                <span>Tel:{" "}</span>
                <a href="tel:+91-11-45352222"
                onClick={(e) => {
                    if(window.innerWidth > 576){
                        e.preventDefault()
                        copyToClipboard("+91-11-45352222", "Number copied")
                    }
                }}
                >+91-11-45352222</a>
            </p>

            <p>
                <span>Email:{" "}</span>
                <a href="mailto:info@chainhouseinternational.com">info@chainhouseinternational.com</a>
            </p>

            <a className='button' href='https://goo.gl/maps/u3hRYaLcxe8XFRJK6' target='_blank' rel="noreferrer">
                Get Direction
            </a>
        </section>

        <section className='social'>
            <h2>FOLLOW US ON</h2>
            <div className='social-links'>
                <a href="https://www.facebook.com/RohtakChain" target='_blank' rel="noreferrer">
                    <img src="https://prodone.jewelflow.pro/chainhouse/public/contact/fb_logo.png" alt="" />
                    <span>FACEBOOK</span>
                </a>

                <a href="https://www.instagram.com/rohtakchain" target='_blank' rel="noreferrer">
                    <img src="https://prodone.jewelflow.pro/chainhouse/public/contact/insta_logo.png" alt="" />
                    <span>INSTAGRAM</span>
                </a>

                <a href="https://www.youtube.com/channel/UCCYMvpARPiqTsk-21GhuzJg" target='_blank' rel="noreferrer">
                    <img src="https://prodone.jewelflow.pro/chainhouse/public/contact/youtube.png" alt="" />
                    <span>YOUTUBE</span>
                </a>
            </div>
        </section>
    </div>
  )
}

export default CHAINHOUSE24Contact
