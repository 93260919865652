import { get_informationFromSessionID } from "../Helper";
import "./ExternalHTML.css";

function ExternalHTML({ url, extraBottomMargin = 0 }) {
  const companyCode = get_informationFromSessionID("companyCode");
  const decryptCompanyName = atob(companyCode);
  
  return (
    <iframe
      style={{ border: "none", width: "100%" }}
      src={url}
      title="externalHTML"
      className={`externalHTML ${decryptCompanyName}`}
    //   onLoad={() => iframeDynamicHeight()}
    ></iframe>
  );
}

export default ExternalHTML;
