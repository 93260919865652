import Slider from "react-slick";
import { get_property_settings } from "../../../../../Component/Helper";

function ThumbnailPreview({
  infinite = false,
  slider_clsName,
  vertical = false,
  nav,
  setNav,
  slidesToShow,
  focusOnSelect,
  arrows = false,
  swipeToSlide,
  variableWidth = false,
  single_slide_clsName = "",
  single_slide_id = "",
  video_tag_clsName = "",
  img_tag_clsName = "",
  adjestZoomImageHeight = null,
  allThumbImages,
  selectedProduct,
}) {

   const cdn = get_property_settings("cdn")

  // if(products_and_subproducts?.length > 1 && window.innerWidth < 576) return null

  return (
    <Slider
      infinite={infinite}
      className={slider_clsName}
      vertical={vertical}
      asNavFor={nav}
      ref={(slider2) => {
        setNav(slider2);
      }}
      slidesToShow={slidesToShow}
      focusOnSelect={focusOnSelect}
      arrows={arrows}
      swipeToSlide={swipeToSlide}
      variableWidth={variableWidth}
    
    >
      {selectedProduct &&
        allThumbImages.length > 1 &&
        allThumbImages.map((design_file, index) => {
          return (
            <div
              className={single_slide_clsName}
              id={single_slide_id}
              key={index}
            >
              {design_file.includes("mp4") ? (
                <video
                  muted
                  className={video_tag_clsName}
                  width={"100%"}
                  controls={false}
                  autoPlay={true}
                  style={{
                    aspectRatio: "15/15",
                    objectFit: "contain",
                    cursor: "pointer"
                  }}
                  loop
                  src={`${cdn}${`uploads/design_video/`}${design_file}`}
                ></video>
              ) : (
                <img
                  className={`${img_tag_clsName} fsv-thumb-img`}
                  src={`${cdn}${selectedProduct?.small_image_path}${design_file}`}
                  style={{
                    objectFit: "contain",
                    aspectRatio: 1 / 1,
                    width: "100%",
                    backgroundImage: `url(${cdn}${selectedProduct?.small_image_path}${design_file})`
                  }}
                  
                  onLoad={() => {
                    if(!adjestZoomImageHeight) return 
                    if(allThumbImages.length - 1 === index){
                        adjestZoomImageHeight(index) 
                    }
                  }}
                  alt="pic"
                />
              )}
            </div>
          );
        })}
    </Slider>
  );
}

export default ThumbnailPreview;
