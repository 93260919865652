import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

function ButtonsWithInput({ field }) {
  const {
    field_name,
    short_code,
    required_field,
    control_value,
    default_select,
  } = field || {};
  const {
    register,
    formState: { errors },
    setValue,
    control
  } = useFormContext();

  useEffect(() => {
    if (default_select) {
      setValue(short_code, default_select);
    } else {
      setValue(short_code, control_value.length === 1 ? control_value[0] : "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_select]);

  const custom_input_value_watch = useWatch({
    control,
    name: `${short_code}_custom_input`,
  })

  

  return (
    <div className="form-input-group">
      <label>
        <span>{field_name}</span>
        {required_field === "1" && <span className="required">*</span>}
      </label>
      <div className="button-group-with-input">
        {control_value?.map((value) => (
          <React.Fragment key={value}>
            <input
              type="radio"
              style={{ display: "none" }}
              id={short_code + value}
              name={short_code}
              value={value}
              {...register(short_code, {
                required: {
                  value: required_field === "1" && !custom_input_value_watch,
                  message: `${field_name} is required`,
                },
              })}
            />
            <label htmlFor={short_code + value} onClick={() => {
              if(custom_input_value_watch){
                setValue(`${short_code}_custom_input`, '')
                const custom_input = document.getElementById(`${short_code}_custom_input_id`)
                if(custom_input){
                  custom_input.classList.remove("custom-input-btn-mode-enable")
                }
              }
            }}>{value} dsd</label>
          </React.Fragment>
        ))}

        <input
          type="number"
          placeholder="Enter"
          id={`${short_code}_custom_input_id`}
          {...register(`${short_code}_custom_input`)}
          onBlur={(e) => {
            if(e.target.value){
              e.target.classList.add("custom-input-btn-mode-enable")
              setValue(short_code, "")
            }else{
              if (default_select) {
                setValue(short_code, default_select);
              } else {
                setValue(short_code, control_value.length === 1 ? control_value[0] : "");
              }
            }
          }
        
        }
        onKeyDown={(event) => {
          if(event.key === "Enter"){
            event.target?.blur()
          }
        }}
          onClick={(e) => e.target.classList.remove("custom-input-btn-mode-enable")}
        />
      </div>
      {errors[short_code] && (
        <p className="error-input">{errors[short_code]?.message}</p>
      )}
    </div>
  );
}

export default ButtonsWithInput;
