import { FaSearch } from "react-icons/fa";
import debounce from 'debounce';
import { createSearchURLParams, get_property_settings } from "../../../Component/Helper";
import { useNavigate } from "react-router-dom";




function CustomOrderSearch({set_search_key}) {

  const navigate = useNavigate()
  
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );

  function handleSearch (e) {
    const kam_module_title = get_property_settings("kam_module_title")
    set_search_key(e.target.value)
    
    navigate(createSearchURLParams(kam_module_title.replace(/\s/g, "-").toLowerCase(), {...searchParams, order_no: e.target.value}))
  }
  
  return (
    <div className='custom-order-search'>
      <FaSearch className="search-icon"/>
      <input type="text" placeholder='Search by Order No' defaultValue={searchParams?.order_no || ""}
      onChange={debounce(handleSearch, 500)}
      
      />
    </div>
  )
}

export default CustomOrderSearch
