import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import { createSelectArrayForReactSelect, get_property_settings } from "../../../../Component/Helper";
import { getCustomValue } from "../../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../../Custom/CompanyCustomization";
import { oms_wight_calculation } from "../../../CustomOrder/custom_order_utils";
import { useEffect } from "react";
import { get_search_params } from "../../../../utils";
import { useSelector } from "react-redux";
import { get_custom_order_items } from "../../../../redux/Reducer/reducer";

function SelectDropDownInput({field, add_items_by, production_category, size_chart}) {
  const {field_name, short_code, required_field, control_value, default_select} = field || {}
  const { control, formState: {errors}, watch, setValue } = useFormContext() 
  const primary_BG_color = get_property_settings("btn_primary_color");
  const size_watch = useWatch({ control, name: "Size" || "size" });
  const options = createSelectArrayForReactSelect(control_value)
  const default_selected = options.find(option => option.value === default_select) || null
  const calculate_oms_weight_based_on_design_no = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.calculate_oms_weight_based_on_design_no
  );

  

  const {update_id} = get_search_params()
  const added_items = useSelector(get_custom_order_items);

  useEffect(() => {
    if (
      !calculate_oms_weight_based_on_design_no ||
      add_items_by !== "by design no" ||
      !size_watch
    )
      return;

    const updated_weight = oms_wight_calculation(
      production_category,
      size_chart,
      watch
    );
    setValue("single_wt", updated_weight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size_watch]);
  
  useEffect(() => {
      if(!update_id) return;
      setTimeout(() => {
        added_items.forEach((item, index) => {
          if (index.toString() === update_id) {
            for (const key in item) {
              if (short_code === key) {
                setValue(key, {label: item[key], value: item[key]});
              }
            }
          }
        });
      }, 500)
      
  }, [update_id])
 
  return (
    <div className="form-input-group">
        <label htmlFor={short_code}>
          <span>{field_name}</span>
          {required_field === "1" && <span className="required">*</span>}
        </label>
        <Controller
        control={control}
        name={short_code}
        rules={{
          required:{
            value: required_field === "1",
            message: `${field_name} is required`
          }
        }}
        defaultValue={default_selected}
        render={({ field }) => (
          <Select
            isSearchable={false}
            className="react-select-container r-container"
            classNamePrefix="react-select r-select"
            id={short_code}
            options={options}
            {...field}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25:
                  (primary_BG_color &&
                    primary_BG_color
                      .replace("rgb", "rgba")
                      .replace(")", ",0.2)")) ||
                  "inherit",
                primary: primary_BG_color,
              },
            })}
          />
        )}
        />
        {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
      </div>
  )
}

export default SelectDropDownInput
