function UserDefaults({ title, data }) {
  return (
    <div className="user-defaults">
      <header>
        <h4>{title}</h4>
      </header>

      <section className="user-defaults-body">
        <div className="input-groups">
          {data.map((defaults) => {
            return (
              <>
                <span className="label">{defaults?.field_name}</span>
                <div className="inputs">
                  {defaults?.control_value.map((value) => {
                    return (
                      <>
                        <input
                          type="radio"
                          name={defaults.short_code}
                          value={value}
                          id={`${defaults.short_code}_${value}`}
                          style={{ display: "none" }}
                          defaultChecked={value === defaults?.value}
                        />
                        <label htmlFor={`${defaults.short_code}_${value}`}>
                          {value}
                        </label>
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default UserDefaults;
