function PressReleaseCard({image, title, link}) {
    return (
      <div className="press-release-card">
          <a href={link} className="image-section" target="_blank" rel="noreferrer">
              <img src={image} alt="" />
          </a>
  
          <div className="detail-section">
              <p>{title}</p>
          </div>
      </div>
    )
  }
  
  export default PressReleaseCard