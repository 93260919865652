import { useEffect } from "react"

function FSVScrollToView({page}) {
  

  const FSV_Scroll_To_View_Product = () => {
    
    const menu = document.getElementById("ec-main-menu-desk")
    if(!menu) return
    const menu_height = menu.offsetHeight;
    
    const fsvProductOffset = parseInt(sessionStorage.getItem("fsv-product-page-offset-height"))
    const extraOffset = 20;
    if(!fsvProductOffset) return
    const scrollToTop = parseInt(fsvProductOffset) - (menu_height - extraOffset)
    
    window.scrollTo(0, scrollToTop)
    // menu.scrollIntoView()
    
  
    }
   
  useEffect(() => {
    
    const fsvcontainer = document.querySelector('.products-page.fsv-container')
    const fsvProductOffset = parseInt(sessionStorage.getItem("fsv-product-page-offset-height"))
    if( !fsvcontainer || fsvProductOffset) return 
    
    const topOffset = fsvcontainer.getBoundingClientRect().y
    sessionStorage.setItem("fsv-product-page-offset-height", Math.round(topOffset))
    
  },[])

  useEffect(() => {
    setTimeout(() => {
      FSV_Scroll_To_View_Product()
    }, 300)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])


  

  return null
}

export default FSVScrollToView
