import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createSearchURLParams,
  delete_informationFromSessionID,
  get_property_settings,
} from "../../../../../Helper";

function NestedList({ category, setOpenMobileMenu, all_category_button }) {
  
  const navigate = useNavigate()
  let { cat_name, product_count, show_subcat, subcategory, id } = category || {};
  show_subcat = show_subcat === "Yes"
  const [openInnerList, showOpenInnerList] = useState(false);
  if (category.subcategory && category.subcategory.length > 0) {
    return (
      <ul>
        <li className={`dropdown ${openInnerList ? "active" : ""} category-menu`}>
         {show_subcat && <span
            className="menu-toggle"
            onClick={() => showOpenInnerList((pre) => !pre)}
          ></span>}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              showOpenInnerList((pre) => !pre);
            if(!show_subcat){
              navigate(createSearchURLParams("matrix-page", {
                cat_id: id,
                mode: "filter",
                page: 0,
              }))
              setOpenMobileMenu(false);
              
            }
            }}
          >
            <span>{cat_name}</span>
            <span className="product-count">{product_count}</span>
          </a>
          {show_subcat && <ul
            className="sub-menu"
            style={{ display: openInnerList ? "block" : "none" }}
          > 
           {
              all_category_button ? (
                <li className="category-menu">
                  <Link
                  to={createSearchURLParams("matrix-page", {
                    cat_id: id,
                    mode: "filter",
                    page: 0,
                  })}
                  onClick={() => {
                    
                    setOpenMobileMenu(false);
                  }}
                  className="categoryLinks"
                  
                >
                  <span>All</span>
                  <span className="product-count">{product_count}</span>
                </Link>
                </li>
              ) : null
           }
            {subcategory?.map((cat) => (
              <li key={cat?.id} className="category-menu">
                <Link
                  to={createSearchURLParams("matrix-page", {
                    cat_id: cat.id,
                    mode: "filter",
                    page: 0,
                  })}
                  onClick={() => {
                    setOpenMobileMenu(false);
                  }}
                  className="categoryLinks"
                  key={cat.id}
                >
                  <span>{cat.cat_name}</span>
                  <span className="product-count">{cat.product_count}</span>
                </Link>
              </li>
            ))}
          </ul>}
        </li>
      </ul>
    );
  } else {
    return (
      <Link
        to={createSearchURLParams("matrix-page", {
          cat_id: category.id,
          mode: "filter",
          page: 0,
        })}
        onClick={() => {
          delete_informationFromSessionID("filterParams");
          // setTimeout(() => window.location.reload(), 100)
          setOpenMobileMenu(false);
        }}
        className="categoryLinks"
      >
        <span>{category.cat_name}</span>
        <span className="product-count">{category.product_count}</span>
      </Link>
    );
  }
}


function MobileSingleMenuList({ category, setOpenMobileMenu }) {
  const [openList, showOpenList] = useState(false);
  const navigate = useNavigate()
  let { cat_name, product_count, show_subcat, subcategory, id } = category || {};
  show_subcat = show_subcat === "Yes";
  let all_category_button = get_property_settings("all_category_button")
  all_category_button = all_category_button === "Yes"
 
  return (

    <li className={`dropdown ${openList ? "active" : ""} category-menu`}>
      {show_subcat && (
        <span
          className="menu-toggle"
          onClick={() => showOpenList((pre) => !pre)}
        ></span>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if(!show_subcat){

            navigate(createSearchURLParams("matrix-page", {
              cat_id: id,
              mode: "filter",
              page: 0,
            }))
            setOpenMobileMenu(false);
          }else{
            
            showOpenList((pre) => !pre);
          }
        }}
      >
        <span>{cat_name}</span>
        <span className="product-count">{product_count}</span>
      </a>
      {show_subcat && <ul className="sub-menu" style={{ display: openList ? "block" : "none" }}>
         {
          all_category_button ? (
            <li className="ec-menu-content category-menu">
              <Link 
               to={createSearchURLParams("matrix-page", {
                cat_id: id,
                mode: "filter",
                page: 0,
              })}
              onClick={() => {
                delete_informationFromSessionID("filterParams");
                setOpenMobileMenu(false);
              }}
              >
                <span>All</span>
                <span className="product-count">{product_count}</span>
              </Link>
            </li>
          ) : null
         }
          
          <li className="ec-menu-conten category-menu">
            {subcategory?.map((cat) => {
              return <NestedList category={cat} setOpenMobileMenu= {setOpenMobileMenu} key={cat.id} all_category_button/>;
            })}
          </li>
        
      </ul>}
    </li>
  );
}

export default MobileSingleMenuList;
