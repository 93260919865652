import { useEffect, useState } from "react";
import "./PressRelease.css";
import { PressReleaseData } from "./PressReleaseData";
import PressReleaseCard from "./PressReleaseCard";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";



function PressRelease() {
  const MAX_THUMB_SHOW = 6
  const [pressEvents, setPressEvents] = useState(PressReleaseData.slice(0, MAX_THUMB_SHOW))
  const [hideShowMoreBtn, setHideShowMoreBtn] = useState(false)

  function HandleShowMore(){
    let addThumCount = pressEvents.length + MAX_THUMB_SHOW;
    addThumCount = addThumCount > PressReleaseData.length ? PressReleaseData.length : addThumCount;
    
    if(addThumCount !== PressReleaseData.length){
        setPressEvents(PressReleaseData.slice(0,addThumCount))
    }else{
        setPressEvents(PressReleaseData.slice(0,addThumCount))
        setHideShowMoreBtn(true)
    }
  }

  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className="press-release-page">
        <header>
            <h2>Press Release</h2>
        </header>

        <section className="press-release-cards-section">
            {
                pressEvents.map((data, index) => <PressReleaseCard key={`press_${index}`} {...data}/>)
            }
            
        </section>
        <div className="show-more-section">

        {!hideShowMoreBtn && <button className="show-more-btn" onClick={HandleShowMore}>Show More</button>}
        </div>
    </section>
  )
}

export default PressRelease