export default function CustomOrderTotalFooter({total, is_karigar, handleNewOrderRedirection, ack}) {
    
  return (
    <div className="custom-order-setion-footer">
      {ack !== "0" && <section className="footer-data-section">
        <div className="footer-data">
          <span>Total Orders:</span>
          <span className="value">{total?.total_orders}</span>
        </div>
        <div className="footer-data">
          <span>Total Quantity:</span>
          <span className="value">{total?.total_quantity}</span>
        </div>
        <div className="footer-data">
          <span>Total Weight:</span>
          <span className="value">{total?.total_weight}</span>
        </div>
      </section>}

      {!is_karigar && <div className={`button-container ${ack === "0" ? 'center-align' : ""}`}>
        <button className="new-order-btn" onClick={handleNewOrderRedirection}>
          New Order
        </button>
      </div>}
    </div>
  );
}
