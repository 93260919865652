import React, { memo } from 'react'
import FullScreenImageSlider from '../../../../../Component/FullScreenImageSlider/FullScreenImageSlider'

function CampaignDetailsCard({title, images, year}) {
  return (
    <div className='campaign-details-card'>
        <header>
            <h3 className='year'>{year}</h3>
            <h3 className='campaign-details-title'>{title}</h3>
        </header>

        <section className='campaign-details-image-grid'>
            {
                

                    images.map((image, index) => (
                        <FullScreenImageSlider key={index} imagesArr={images}>
                            <img src={image} alt={title} onClick={() => sessionStorage.setItem("active_order_details_slide", (index + 1))}/>
                        </FullScreenImageSlider>
                    ))
                
            }
            
        </section>
    </div>
  )
}

export default memo(CampaignDetailsCard)