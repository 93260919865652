

function FeaturesCard({title, link, image}) {
  return (
    <a href={link} target="_blank" className="news-letters-card" rel="noreferrer" style={{boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.3)"}}>
        <img src={image} alt={title} />
        <p>
            {title}
        </p>
    </a>
  )
}

export default FeaturesCard