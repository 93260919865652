

function NewsLettersCard({title, link, image}) {
  return (
    <a href={link} target="_blank" className="news-letters-card" rel="noreferrer">
        <img src={image} alt={title} />
        <p>
            {title}
        </p>
    </a>
  )
}

export default NewsLettersCard