import React from 'react'

function Banner3() {
  return (
    <div className='banner-3' style={{backgroundImage: `url(https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/images/banner1.jpg)`}}>
        <section className="banner-3-container">
          <section className="left-side">
            <header>
              <h2>Swarna Kalasha Scheme offers</h2>
            </header>
            <ol>
              <li>No Making Charges on Gold Jewellery</li>
              <li>No Making Charges on Jadau Jewellery</li>
              <li>No Making Charges on Diamond Jewellery</li>
              <li>No VA on Silver Articles</li>
            </ol>
          </section>
          <img src="https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/savings_scheme/savings_scheme/images/model.png" alt="table" className='right-side'/>
        </section>
    </div>
  )
}

export default Banner3