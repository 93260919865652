import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import MUIModal from "../MUIModal/MUIModal";
import "./UserPreferenceModal.css";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api, fetch_data_from_api_from_query } from "../../Api/APICalls";
import { ApiList } from "../../Api/ApiList";
import UserDefaults from "./Components/UserDefaults";
import { IoMdClose } from "react-icons/io";
import Loader from "../Loader";
import { get_property_settings, ToastError, ToastSuccess } from "../Helper";
import { useDispatch, useSelector } from "react-redux";
import { get_open_preferences_modal, set_open_preferences_modal } from "../../redux/Reducer/reducer";

function UserPreferenceModal(
  {
    
    handleUserProfileMenuClose = () => {},
  },
  ref
) {

  const open_preferences_modal = useSelector(get_open_preferences_modal)
  const [open_MUI_modal, set_open_MUI_modal] = useState(
    false
  );

  const dispatch = useDispatch()

  const handle_close_MUI_modal = useCallback(
    () => {
      if(open_preferences_modal){
        dispatch(set_open_preferences_modal(false))
      }
      set_open_MUI_modal(false)
      handleUserProfileMenuClose()

    },
    []
  );

  let has_user_preferences = get_property_settings("has_user_preferences");
  has_user_preferences = has_user_preferences === "1";
  const handle_open_MUI_modal = () => {
    set_open_MUI_modal(true)
    // handleUserProfileMenuClose()

  };

  useImperativeHandle(ref, () => ({
    open_modal: () => handle_open_MUI_modal(),
  }));

  const user_perference_query = useQuery({
    queryKey: ["user-preference"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.myaccount_get_user_checkout_favourites,
        "user-preference"
      ),
    enabled: has_user_preferences,
  });

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const obj = Object.fromEntries(formData.entries());
    
    const user_preference_response = await fetch_data_from_api(ApiList.myaccount_set_user_checkout_favourites, "myaccount_set_user_checkout_favourites", obj)
    // console.log(user_preference_response)
    if(user_preference_response.ack === "1"){
      ToastSuccess(user_preference_response?.msg)
      handle_close_MUI_modal()
      handleUserProfileMenuClose()
    }else if(user_preference_response.ack === "0"){
      ToastError(user_preference_response.error || "somthing went wrong")
    }
  }

  useEffect(() => {
    if(open_MUI_modal){

      document.body.classList.add("hide-user-profile-dropdown");
    }
  }, [open_MUI_modal]);

  return (
    <MUIModal
      className="user-prefernce-modal"
      open_MUI_modal={open_MUI_modal}
      //   handle_close_MUI_modal={handle_close_MUI_modal}
      style={{ p: 0, width: "min(500px, 90%)" }}
    >
      <header className="main-header">
        <h3>Default preferences </h3>
        <IoMdClose className="close-icon" onClick={handle_close_MUI_modal} />
      </header>
      <section className="user-preference-section">
        {user_perference_query.isLoading ? (
          <Loader isLoading={true} />
        ) : (
          <form
            className="user-preference-section-body"
            onSubmit={handleSubmit}
          >
            <p className="desc">
              Please set your default preferences for ease of use in the app
            </p>

            <UserDefaults
              title="Product Defaults"
              data={user_perference_query.data?.product_preferences}
            />

            <UserDefaults
              title="Checkout Defaults"
              data={user_perference_query.data?.finalise_preferences}
            />

            <footer>
              <button type="submit">Submit</button>

              {/* <button type="button" onClick={handle_close_MUI_modal}>
                Cancel
              </button> */}
            </footer>
          </form>
        )}
      </section>
    </MUIModal>
  );
}

export default forwardRef(UserPreferenceModal);
