import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form';

function ButtonsWithInput({field}) {
    const { field_name, short_code, required_field, control_value, default_select } =
    field || {};
    const { register, formState: {errors}, watch, setValue } = useFormContext() 
    const { onChange } = register(short_code);
    const custom_input = watch(`${short_code}_custom_input`)
    
    useEffect(() => {
      if(!custom_input) return
      setValue(short_code, custom_input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custom_input])

  return (
    <div className="form-input-group">
      <label htmlFor={short_code}>
        {field_name}
        {required_field && <span className="required">*</span>}
      </label>
      <div className="buttons">
        {control_value?.map((value) => {
          return (
            <React.Fragment key={value}>
              <input
                type="radio"
                id={value}
                value={value}
                style={{ display: "none" }}
                name={short_code}
                defaultChecked={default_select === value}
                className="radio-input"
                {...register(short_code,  {
                  required: {
                    value: required_field === "1",
                    message: `${field_name} is required`
                  }
                })}
                onChange={onChange}
              />
              <label htmlFor={value} name={short_code} className='button-label'>{value}</label>
            </React.Fragment>
          );
        })}

        <input placeholder="Enter Karat" {...register(`${short_code}_custom_input`)}/>
      </div>
      {errors[short_code] && <p className="error-input">{errors[short_code]?.message}</p>}
    </div>
  );
}

export default ButtonsWithInput