import React from "react";


function DatePicker({formParams, clsName = "",  errors, register}) {
   
  return (
    <span className={clsName}>
      <label className="label">
        {formParams.field_name}
        {formParams?.required === "1" && <span className="required-field">*</span>}
      </label>

      <input
        // tabIndex={i + 1}
        className="RegInp fieldValue d-block"
        type="date"
        
        placeholder={`Enter ${formParams.field_name}`}
        {...register(formParams?.short_code, {
          required: {
            value: formParams?.required === "1",
            message: `${formParams.field_name} is required`
          }
        })}
        
      />
      <div className="required-field">
        {errors[formParams?.short_code]?.message || ""}
      </div>
    </span>
  );
}

export default DatePicker;
