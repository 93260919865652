import Select from "react-select";
import { createSelectArrayForReactSelect, get_property_settings } from "../../../../../Component/Helper";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";

function SelectDropdownGlob({disabled, paramsDataIndex, control, paramsData, order_data, setValue}) {
    const primary_BG_color = get_property_settings("btn_primary_color")
    const [options, setOptions] = useState(createSelectArrayForReactSelect(paramsData?.control_value))
 

  
  
  useEffect(() => {
    if(!order_data || !disabled) return
    const selected_value = order_data?.finalize_fields_data?.find(data => data?.key === paramsData?.field_name)?.value
   
    if(selected_value){
      setTimeout(() => {
        setOptions([{label: selected_value, value: selected_value}])
        setValue(paramsData?.short_code, {label: selected_value, value: selected_value})

      }, 200)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_data, disabled])

  return (
    <Controller
    control={control}
    name={paramsData?.short_code}
    rules={{
      required: {
        value: paramsData?.required === "1",
        message: `${paramsData?.field_name} is required`,
      },
    }}
    render={({ field }) => {
        return <Select
        isDisabled={disabled}
        tabIndex={paramsDataIndex + 1}
          options={options}
          className="react-select-container r-container"
          classNamePrefix="react-select r-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25:
                (primary_BG_color &&
                  primary_BG_color.replace("rgb", "rgba").replace(")", ",0.2)")) ||
                "inherit",
              primary: primary_BG_color,
            },
          })}
         
     
    
         isSearchable={true}

         {...field}
        />
    }}
    />
    
      
    
  )
}

export default SelectDropdownGlob
