import CartIconImg from "../../../assets/images/ic_matrix_cart@3x.svg";
import { get_bulk_settings } from "../../Helper";

function CartIcon({inCartWishList, item}) {

    const { matrix_shortlist_button_position, matrix_cart_button_position } =
    get_bulk_settings([
      "matrix_shortlist_button_position", "matrix_cart_button_position"
    ]) || {};
  const order =
    matrix_shortlist_button_position === "topleft" &&
    matrix_cart_button_position === "topright"
      ? "order-2"
      : matrix_shortlist_button_position === "topright" &&
        matrix_cart_button_position === "topleft"
      ? "order-1"
      : "";

  return (
    <div className={`${order}`}>
      {inCartWishList?.in_cart_id_dm?.length > 0 &&
        inCartWishList?.in_cart_id_dm?.includes(item.design_master_id) ? (
          <div className="cartIcon flex-shrink-0">
            <img className="mx-1 matrix-icons" src={CartIconImg} alt="" />
            {/* <i className="fa fa-shopping-cart mx-1 matrix-icons"></i> */}
          </div>
        ) : null}
    </div>
  );
}

export default CartIcon;
