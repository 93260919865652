import React from 'react'
import { get_informationFromSessionID } from '../../Component/Helper';
import KalashaOnlineLoginPage from './CustomLoginPage/KalashaOnline/KalashaOnlineLoginPage';
import LoginNew from './LoginNew';

function LoginRedirection() {
    const companyCode = get_informationFromSessionID("companyCode");

    const decryptCompanyName = atob(companyCode);
  switch (decryptCompanyName) {
    case "KALASHAONLINE": return <KalashaOnlineLoginPage />
    default: return <LoginNew />
  }
}

export default LoginRedirection
