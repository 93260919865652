import { useQuery } from "@tanstack/react-query";
import { ApiList } from "../../../../Api/ApiList";
import { fetch_data_from_api_from_query } from "../../../../Api/APICalls";
import { get_designStatus, get_informationFromSessionID } from "../../../Helper";
import { get_is_myc_web, getCatMaster, set_FSV_Handler } from "../../../../redux/Reducer/reducer";
import { FSV_ACTIONS, merge_product_and_subproduct } from "../../../../Pages/Product/Helper";
import { useDispatch, useSelector } from "react-redux";
import { get_search_params } from "../../../../utils";


export default function useFSVResponse(open_fsv_matrix_modal, page, sort) {

    const userId = get_informationFromSessionID("userID")
    const is_myc_web = useSelector(get_is_myc_web);
    const master = useSelector(getCatMaster);
    const searchParams = get_search_params();
    const design_status = get_designStatus(master);
    const filterParams = get_informationFromSessionID("filterParams") || {};
    const dispatch = useDispatch()
    searchParams.page = page

    const {
        isLoading,
        data: AllProducts,
        isError,
      } = useQuery({
        // queryKey: [page, id, searchParams, "full-screen-view"],
        queryKey: [page, "full-screen-view"],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.fullScreen, "full-screen-view", {
            user_id: userId || "",
            collection_id: 0,
            mode: is_myc_web ? "catalogue_filter" : "filter",
            data: 1,
            table: master,
            design_status: design_status,
            cat_id: searchParams?.cat_id || 0,
            sort: sort || 0,
            page: searchParams?.mode === "homescreen" ? 0 : page,
            ...searchParams,
            ...filterParams,
          }).then((products) => {
            // window.scrollTo(0,0)
            if (products[0]?.subproducts?.length > 0) {
              const products_with_subproducts = merge_product_and_subproduct(
                products[0]
              );
              dispatch(
                set_FSV_Handler({
                  type: FSV_ACTIONS.SET_PRODUCTS,
                  payload: products_with_subproducts,
                })
              );
              dispatch(
                set_FSV_Handler({
                  type: FSV_ACTIONS.SET_SELECTED_PRODUCT,
                  payload: products_with_subproducts[0],
                })
              );
    
              return products_with_subproducts;
            } else {
              dispatch(
                set_FSV_Handler({
                  type: FSV_ACTIONS.SET_PRODUCTS,
                  payload: products,
                })
              );
              dispatch(
                set_FSV_Handler({
                  type: FSV_ACTIONS.SET_SELECTED_PRODUCT,
                  payload: products[0],
                })
              );
    
              return products;
            }
          }),
        initialData: [],

        enabled: open_fsv_matrix_modal
      });

      return {isLoading, isError, AllProducts}
}
