import React from "react";

import { logOutUser, set_informationToSessionID} from "../Helper";

import { useDispatch } from "react-redux";
import { setConstants, setIsLoggedIn, set_inCartWishList } from "../../redux/Reducer/reducer";
import { useNavigate } from "react-router-dom";

function RequestAccessTelephone({ userMobileNo, userCountryCode }) {
  
  // const [mobileNo, setMobileNo] = useState(`${countryCode}${whatsapp_number}`);
  const mobileNo = `${userCountryCode.includes("+") ? userCountryCode : `+${userCountryCode}`}-${userMobileNo}`;
  

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  function logoutCurrentUser () {
    logOutUser()
    set_informationToSessionID({isUserLoggedOut: true});
    dispatch(
      setConstants({})
    );
    dispatch(setIsLoggedIn({status: false, userID: ""}))
    dispatch(set_inCartWishList(null))
    navigate('/');
  }

  


  return (
    <>
      
      <div className="mobile-no-readOnly">{mobileNo}</div>
      <button className="change-no-btn" onClick={logoutCurrentUser}>Change number</button>
      
    </>
  );
}

export default RequestAccessTelephone;
