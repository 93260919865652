import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import { get_property_settings } from "../../../Component/Helper";
import Loader from "../../../Component/Loader";
import { useFormContext } from "react-hook-form";

function CustomOrderModalStepOne({cat_id, set_cat_id, set_category_name}) {
  const { setValue } = useFormContext()
  let kam_category_style = get_property_settings('kam_category_style')
  kam_category_style = !kam_category_style || kam_category_style === 'button' ? 'button-view' : 'tableview'
  const selected_cat_id = sessionStorage.getItem('custom_order_cat_id')
  const append_kam_path_folder = get_property_settings("append_kam_path_folder")
  const category_list_response = useQuery({
    queryKey: ["fetch_cat_list"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        `${append_kam_path_folder}/${ApiList.fetch_cat_list}`,
        "fetch_cat_list",
        {
          session_id: 0,
        }
      ),
    
  });

  
  

  function TableView ({list}) {
    return (

<ul className={`product-list ${kam_category_style}`}>
      {
        Array.isArray(list) && list?.map(data => {
            return <li key={data?.id} 
            onClick={() => {
              set_cat_id(data?.id)
              setValue("cat_id", data?.id)
              set_category_name(data?.name)
              if(kam_category_style !== "tableview"){
                sessionStorage.setItem('custom_order_cat_id', data?.id)
              }
            }}
            className={selected_cat_id === data?.id && kam_category_style !== "tableview" ? "selected" : ""}
            // onMouseDown={(e) => e.target.classList.add('selected')}
            //onMouseUp={(e) => e.target.classList.remove('selected')}
            >{data?.name}</li>
        })
      }
      
      
    </ul>
    )
  }

  if(category_list_response?.isLoading || !category_list_response?.isSuccess){
    return <Loader isLoading={true}/>
  }
  
  return (
    <TableView list={category_list_response?.data}/>
  );
}

export default CustomOrderModalStepOne;
