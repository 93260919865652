import Awards from "./KALASHA/Awards/Awards";
import BridalAppointment from "./KALASHA/BridalAppointment/BridalAppointment";
import Campaign from "./KALASHA/Campaign/Campaign";
import Customization from "./KALASHA/Customization/Customization";
import ExclusiveNRIOffer from "./KALASHA/ExclusiveNRIOffer/ExclusiveNRIOffer";
import GoldScheme from "./KALASHA/GoldScheme/GoldScheme";
import History from "./KALASHA/History/History";
import JewelleryCare from "./KALASHA/JewelleryCare/JewelleryCare";
import JewelleryRedesign from "./KALASHA/JewelleryRedesign/JewelleryRedesign";
import Management from "./KALASHA/Management/Management";
import NewsEvents from "./KALASHA/NewsEvents/NewsEvents";
import NewsLetters from "./KALASHA/NewsLetters/NewsLetters";
import PressRelease from "./KALASHA/PressRelease/PressRelease";
import SpecialJewllery from "./KALASHA/SpecialJewellery/SpecialJewellery";
import VideoCall from "./KALASHA/VideoCall/VideoCall";

import JewelSmithFeatures from "./JEWELSMITH/Features/Features";

export const CustomStaticRedirection = {
    "KALASHAONLINE" : {
        "press-release": PressRelease,
        "history": History,
        "awards": Awards,
        "management": Management,
        "news-events": NewsEvents,
        "bridal-appointment": BridalAppointment,
        "news-letters": NewsLetters,
        "special-jewellery": SpecialJewllery,
        "campaign": Campaign,
        "customization": Customization,
        "jewellery-redesign": JewelleryRedesign,
        "video-call": VideoCall,
        "jewellery-care": JewelleryCare,
        "swarna-kalasham": GoldScheme,
        "exclusive-offer-for-nri" : ExclusiveNRIOffer
    },

    "JEWELSMITH": {
        "features": JewelSmithFeatures
    }
}