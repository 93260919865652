import React from 'react'
import WhatsappIconNew from '../../../Component/WhatsappIcon/WhatsappIconNew'
import { CustomStaticRedirection } from './CustomStaticRedirection'
import { get_informationFromSessionID } from '../../../Component/Helper'
import { useParams } from 'react-router-dom'
import NoProduct from '../../../Component/NoProduct/NoProduct'

function CustomStaticPage() {
  const {staticID} = useParams()
  const company_code = get_informationFromSessionID("companyCode")
  const decrypted_company_code = atob(company_code)
  const Page = CustomStaticRedirection[decrypted_company_code][staticID]
 
  
  if(!Page){
    return <NoProduct errMsg={"Page Not Found"} hideHomeBtn={true}/>
  }
  
  return (
    <div>

       <WhatsappIconNew />
       <Page/>
        
    </div>
  )
}

export default CustomStaticPage