const IMG1091708942514 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1091708942514.jpg";
const IMG1081708942470 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1081708942470.jpg";
const IMG1071708942362 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1071708942362.jpg";
const IMG1061708942329 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1061708942329.jpg";
const IMG1051708942552 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1051708942552.jpg";
const IMG1041708942263 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1041708942263.jpg";
const IMG1031708942236 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1031708942236.jpg";
const IMG1021708942199 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1021708942199.jpg";
const IMG1011708942169 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1011708942169.jpg";
const IMG1001708942138 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/1001708942138.jpg";
const IMG991708942012 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/991708942012.jpg";
const IMG981708941964 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/981708941964.jpg";
const IMG971708941930 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/971708941930.jpg";
const IMG961708941873 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/961708941873.jpg";
const IMG951708941809 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/951708941809.jpg";
const IMG941708941787 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/941708941787.jpg";
const IMG931708941755 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/931708941755.jpg";
const IMG921708940881 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/921708940881.jpg";
const IMG911708940823 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/911708940823.jpg";
const IMG901708940763 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/901708940763.jpg";
const IMG891708940528 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/891708940528.jpg";
const IMG881686122174 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/881686122174.png";
const IMG871620397698 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/871620397698.png";
const IMG861620397632 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/861620397632.png";
const IMG851620397469 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/851620397469.png";
const IMG841620397421 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/841620397421.png";
const IMG831610531089 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/831610531089.jpeg";
const IMG821597058495 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/821597058495.jpeg";
const IMG811591176870 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/811591176870.png";
const IMG801591176810 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/801591176810.png";
const IMG791591176751 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/791591176751.png";
const IMG781591176679 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/781591176679.png";
const IMG771591176435 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/771591176435.png";
const IMG751582809210 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/751582809210.jpg";
const IMG741582809179 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/741582809179.jpg";
const IMG731582809138 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/731582809138.jpg";
const IMG721582809111 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/721582809111.jpg";
const IMG711582809052 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/711582809052.jpg";
const IMG701582809024 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/701582809024.jpg";
const IMG691582808996 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/691582808996.jpg";
const IMG681582808973 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/681582808973.jpg";
const IMG671582808945 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/671582808945.jpg";
const IMG661582808917 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/661582808917.jpg";
const IMG651582808891 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/651582808891.jpg";
const IMG641582808872 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/641582808872.jpg";
const IMG631582808852 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/631582808852.jpg";
const IMG621582808830 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/621582808830.jpg";
const IMG611582808812 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/611582808812.jpg";
const IMG601582808793 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/601582808793.jpg";
const IMG591582808769 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/591582808769.jpg";
const IMG581582808751 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/581582808751.jpg";
const IMG571582808730 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/571582808730.jpg";
const IMG561582808707 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/561582808707.jpg";
const IMG551582799420 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/551582799420.jpg";
const IMG541582528412 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/541582528412.jpg";
const IMG531582528394 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/531582528394.jpg";
const IMG521582528375 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/521582528375.jpg";
const IMG511582528356 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/511582528356.jpg";
const IMG501582528333 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/501582528333.jpg";
const IMG491582528286 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/491582528286.jpg";
const IMG481582527943 = "https://s3.ap-southeast-1.amazonaws.com/cdn.jfone.jewelflow.pro/kalashaonline/static_pages/newsevents/481582527943.jpg";

export const NewsEventsData = [
    {
        "title": "Kalasha Fine Jewel's 6th Anniversary",
        "image": IMG1091708942514,
        "link": "https://www.youtube.com/watch?v=jiGEPkMUeD8"
    },
    {
        "title": "The Modern Women Warriors - Dr. Shanta Thoutam, Chief Innovation Officer, Telangana",
        "image": IMG1081708942470,
        "link": "https://www.youtube.com/watch?v=3KgPhakTZqQ&list=UULF6_1Tb4rj8BsCZX4eVJSfAQ&index=19"
    },
    {
        "title": "The Modern Women Warriors - Mrs. Neeraja Kona, Celebrity Stylist, Costume Designer & Author!",
        "image": IMG1071708942362,
        "link": "https://www.youtube.com/watch?v=Qoqqn89o9IE"
    },
    {
        "title": "The Modern Women Warriors Mrs. Sridevi Jasti Holistic Nutritionist,Vegan Chef Founder-Vibrant Living",
        "image": IMG1061708942329,
        "link": "https://www.youtube.com/watch?v=ahjbEFPhyjg"
    },
    {
        "title": "The Modern Women Warriors - Mrs. B. Sumathi IPSDIG, Women Safety Wing, CID Telangana State.",
        "image": IMG1051708942552,
        "link": "https://www.youtube.com/watch?v=5P8K2i_crJE"
    },
    {
        "title": "The Modern Women Warriors - Mrs Architha Narayanam - Celebrity Costume Designer",
        "image": IMG1041708942263,
        "link": "https://www.youtube.com/watch?v=nBdmu_fOGNA"
    },
    {
        "title": "The Modern Women Warriors | Mrs. Seshanka Binesh | Social Activist, Entrepreneur",
        "image": IMG1031708942236,
        "link": "https://www.youtube.com/watch?v=o2SL2OXbamE"
    },
    {
        "title": "The Modern Women Warriors - Mrs Sowmya ChandaDirector - Vasavi group",
        "image": IMG1021708942199,
        "link": "https://www.youtube.com/watch?v=d1wkN_YELwY"
    },
    {
        "title": "The Modern Women Warriors with Kalasha - Dr. Manjula Anagani Padmashree Awardee",
        "image": IMG1011708942169,
        "link": "https://www.youtube.com/watch?v=Nf8ywqA8MDY"
    },
    {
        "title": "The Modern Women Warriors - Mrs. Bala LathaFormer Deputy Director - Ministry of Defence, IAS Coach.",
        "image": IMG1001708942138,
        "link": "https://www.youtube.com/watch?v=bOApwSZkTpw"
    },
    {
        "title": "Latest Gold Jewellery And Diamond Collections - Vanitha TV",
        "image": IMG991708942012,
        "link": "https://www.youtube.com/watch?v=1EFBOPNmysI"
    },
    {
        "title": "The CapsGold Group - Trusted Legacy since 1901",
        "image": IMG981708941964,
        "link": "https://www.youtube.com/watch?v=B7HrXA8oPV8"
    },
    {
        "title": "Coimbatore Exhibition and Sale - Curated by Ms. Aparna Sunku",
        "image": IMG971708941930,
        "link": "https://www.youtube.com/watch?v=deR3Fd0n1yw"
    },
    {
        "title": "Sravana Masam Special - 2023 Traditional Jewellery Collection",
        "image": IMG961708941873,
        "link": "https://www.youtube.com/watch?v=0om30Htd-tQ"
    },
    {
        "title": "Coimbatore Exhibition and Sale July'2023",
        "image": IMG951708941809,
        "link": "https://youtu.be/va9xG0nPw00"
    },
    {
        "title": "Coimbatore Exhibition and Sale July'2023",
        "image": IMG941708941787,
        "link": "https://youtu.be/7L-lJVkRf-k"
    },
    {
        "title": "Coimbatore Exhibition and Sale July'2023",
        "image": IMG931708941755,
        "link": "https://www.youtube.com/watch?v=krAh3jD0Dyo"
    },
    {
        "title": "Akshaya Tritiya Special Offers on Gold & Diamond Jewellery",
        "image": IMG921708940881,
        "link": "https://www.youtube.com/watch?v=wVwqXogapDo"
    },
    {
        "title": "Akshaya Tritiya Special Offers 2023 on Gold & Diamond Jewellery | Navya | Vanitha TV",
        "image": IMG911708940823,
        "link": "https://youtu.be/wVwqXogapDo"
    },
    {
        "title": "Kalasha's 6th Anniversary Event",
        "image": IMG901708940763,
        "link": "https://youtube.com/live/jiGEPkMUeD8?feature=share"
    },
    {
        "title": "Founder of Kalasha Fine Jewels the Abhishek Chanda on his journey as a 4th generation entrepreneur.",
        "image": IMG891708940528,
        "link": "https://www.youtube.com/watch?v=dnNzw3CrSuA"
    },
    {
        "title": "Akshaya Tritiya Collection 2023",
        "image": IMG881686122174,
        "link": "https://youtu.be/wVwqXogapDo"
    },
    {
        "title": "Tussi necklace And Diamond Choker Collection | Dhantrayodashi Jewellery Collection | Vanitha TV",
        "image": IMG871620397698,
        "link": "https://www.youtube.com/watch?v=Xcw4CtB1yh8"
    },
    {
        "title": "Dasavatharam Gold Vaddanam With Real Emeralds & Shiv Parivar Nakshi Haram | Vanitha TV",
        "image": IMG861620397632,
        "link": "https://www.youtube.com/watch?v=xJRC-Kbm4OM"
    },
    {
        "title": "Traditional And Fancy Jadau Jewellery & Mango Design Collection | Navya | Vanitha TV",
        "image": IMG851620397469,
        "link": "https://www.youtube.com/watch?v=qTQVeHnxm_s&t=1s"
    },
    {
        "title": "Ugadi Special Gold Jewellery | Pasidi Ugadi 2021 | Jewellery Designer Vani Collection | Vanitha TV",
        "image": IMG841620397421,
        "link": "https://www.youtube.com/watch?v=K6s7xNZ_gKI"
    },
    {
        "title": "Lavanya’s emerald kundan necklace makes kundan cosmopolitan",
        "image": IMG831610531089,
        "link": "https://thejewellerydiaries.com/celebrity/spotted/lavanyas-emerald-kundan-necklace-makes-kundan-cosmopolitan/"
    },
    {
        "title": "Gold Rate Hits all Time High",
        "image": IMG821597058495,
        "link": "https://www.youtube.com/watch?v=qfv-T3PSZDQ"
    },
    {
        "title": "కొండెక్కిన బంగారం | Special Discussion On Gold Rates | Kalasha Fine Jewels Director Vani | VanithaTV",
        "image": IMG811591176870,
        "link": "https://www.youtube.com/watch?v=GLXp6OShAHA&t=21s"
    },
    {
        "title": "Behind the scenes. ",
        "image": IMG801591176810,
        "link": "https://www.youtube.com/watch?v=chiiBmhlLAY"
    },
    {
        "title": "Kalasha fine jewels inaugurated an artistic jewellery exhibition",
        "image": IMG791591176751,
        "link": "https://www.youtube.com/watch?v=81wVV0S3O2A&t=10s"
    },
    {
        "title": "CUSTOMIZE YOUR RING - KALASHA FINE JEWELS",
        "image": IMG781591176679,
        "link": "https://www.youtube.com/watch?v=ym8INJi4HhA&t=10s"
    },
    {
        "title": "Kalasha fine jewels inaugurates Extraordinary Handcrafted jewellery Exhibition",
        "image": IMG771591176435,
        "link": "https://www.youtube.com/watch?v=IOuEm7qABrM&t=18s"
    },
    {
        "title": "Designer Wedding Diamond Jewellery Collection.",
        "image": IMG751582809210,
        "link": "https://www.youtube.com/watch?v=kWK8QrTmEDE"
    },
    {
        "title": "Hello Vizag Kalasha Jewels Exclusive Jewellery Expo on 2th to 14th July at Novotel in Visakhapatnam",
        "image": IMG741582809179,
        "link": "https://www.youtube.com/watch?v=-OFF03rAAYU"
    },
    {
        "title": "Kalsha Fine Jewels Organizes Show at Hyderabad | Manchu Lakshmi, Poonam Bajwa Glitters",
        "image": IMG731582809138,
        "link": "https://www.youtube.com/watch?v=VrJ9v9Qhag8"
    },
    {
        "title": "Launch of kalasha fine jwellers exhibition at Hyd",
        "image": IMG721582809111,
        "link": "https://www.youtube.com/watch?v=ZK6PO9dBCK4"
    },
    {
        "title": "Gorgeous Girls Stunning Ramp Walk in Saree at Kalash Fine Jewelry Show Hyderabad ☼ Suryaa News",
        "image": IMG711582809052,
        "link": "https://www.youtube.com/watch?v=5-_v3tAaaaY"
    },
    {
        "title": "Actress Archana Shastry Launch Kalasha Jewel Expo in Banjara Hills Hyderabad | ABN Entertainment",
        "image": IMG701582809024,
        "link": "https://kalashajewels.com/Actress%20Archana%20Shastry%20Launch%20Kalasha%20Jewel%20Expo%20in%20Banjara%20Hills%20Hyderabad%20%7C%20ABN%20Entertainment"
    },
    {
        "title": "Kalasha Jewellers Launched in Banjara Hills Hyderabad || No.1 News",
        "image": IMG691582808996,
        "link": "https://www.youtube.com/watch?v=DYhUkV486g0"
    },
    {
        "title": "Mehreen At Kalasha Jewellery Store Opening In Banjara Hills Kalasha Fine Jewels | ABN Entertainment",
        "image": IMG681582808973,
        "link": "https://www.youtube.com/watch?v=zby-_2Aq7lk"
    },
    {
        "title": "Shalu Chourasiya at Kalasha Fine Jewels 1st Anniversary",
        "image": IMG671582808945,
        "link": "https://www.youtube.com/watch?v=hg26tfyzQK0"
    },
    {
        "title": "Kalasha Jewellery Models Jewellary Show at Showroom Hyderabad",
        "image": IMG661582808917,
        "link": "https://www.youtube.com/watch?v=ESVPT0Fh9sk"
    },
    {
        "title": "Rakkesh Soni Photography Presents- Kalasha Fine Jewels A unit of Caps Gold Pvt. Ltd.",
        "image": IMG651582808891,
        "link": "https://www.youtube.com/watch?v=-cic06OFVqI"
    },
    {
        "title": "Mannara Chopra Launched Kalasha Bridal Jewellery Kalasha Jewels Bridal Jewellery Collection",
        "image": IMG641582808872,
        "link": "https://www.youtube.com/watch?v=LH7iC6rMAhc"
    },
    {
        "title": "Bangle and Earring Utsav launch at Kalasha Fine Jewels, Banjara Hills",
        "image": IMG631582808852,
        "link": "https://www.youtube.com/watch?v=gyMV853QqbY"
    },
    {
        "title": "Fashion Show Organised By Kalasha Fine Jewels In Banjara Hills Kalasha Jewels Exhibition Cum Sale",
        "image": IMG621582808830,
        "link": "https://www.youtube.com/watch?v=CYX9-puaMGY"
    },
    {
        "title": "Womens Day Celebrations | Kalasha Fine Jewels Organises Pavitra Fashion Show | V6 News",
        "image": IMG611582808812,
        "link": "https://www.youtube.com/watch?v=XosDXpqYn3c"
    },
    {
        "title": "DT NEWS.TV 24/7 KALASHA Fine Jewels Offers Grandeur & Royalty with its exclusive DIAMOND-JADAU",
        "image": IMG601582808793,
        "link": "https://www.youtube.com/watch?v=RKjxfTfqmr4"
    },
    {
        "title": "Heroine Mehrene Kaur Launches Signature Bridal Lounge At Kalasha Jewellery Showroom | V6",
        "image": IMG591582808769,
        "link": "https://www.youtube.com/watch?v=Ho4qsSMarUU"
    },
    {
        "title": "Heroine Regina Cassandra Launches Bridal Lounge At Kalasha Jewellery Showroom | V6",
        "image": IMG581582808751,
        "link": "https://www.youtube.com/watch?v=SvVacIemLWU"
    },
    {
        "title": "JEWELS OF NIZAMS, Kalasha Exhibition. Crowne Plaza 22nd and 23rd Only (ARASUMALAR)",
        "image": IMG571582808730,
        "link": "https://www.youtube.com/watch?v=p_5ddyYjjR4"
    },
    {
        "title": "Kalasha Jewellers Launches New Festive Collection Kalasha Fashion Show In Hyderabad",
        "image": IMG561582808707,
        "link": "https://www.youtube.com/watch?v=43KNOrMLL1o"
    },
    {
        "title": "Kalasha Jewellery's The Royal Heritage Collection for Diwali | 99TV Telugu",
        "image": IMG551582799420,
        "link": "https://www.youtube.com/watch?v=pitUa2sU5PE"
    },
    {
        "title": "Kalasha fine Jewels Opened at Jayanagar Bangalore",
        "image": IMG541582528412,
        "link": "https://www.youtube.com/watch?v=fJ_TXcV0K90"
    },
    {
        "title": "Regina Cassandra Launches Kalasha Jewellers New Collection Kalasha Fine Jewels | ABN Entertainment",
        "image": IMG531582528394,
        "link": "https://www.youtube.com/watch?v=0YkhwqVMSsM"
    },
    {
        "title": "Kalasha Fine Jewels Celebrates 2nd Anniversary & Models Showcases Jewellery | V6 News",
        "image": IMG521582528375,
        "link": "https://www.youtube.com/watch?v=FLliZYwlOtQ"
    },
    {
        "title": "Festive Bridal Utsav",
        "image": IMG511582528356,
        "link": "https://www.youtube.com/watch?v=8CsGRYBSc_Q"
    },
    {
        "title": "Kalasha Fine Jewels Bridal Collection launched by Mehrene Pirzada",
        "image": IMG501582528333,
        "link": "https://www.youtube.com/watch?v=RIxMF_4zZPk"
    },
    {
        "title": "Kalasha Fine Jewels Traditional Collection",
        "image": IMG491582528286,
        "link": "https://www.youtube.com/watch?v=8a0-kyto_Vg"
    },
    {
        "title": "Exquisite jewellery @ Kalasha Fine Jewels",
        "image": IMG481582527943,
        "link": "https://www.youtube.com/watch?v=9Z9fezqJczg"
    }
];