import img1 from "../../../../assets/images/igje/about-us/rs=w_600,h_600,cg_true.webp";
import img2 from "../../../../assets/images/igje/about-us/rs=w_600,h_600,cg_true (1).webp";

function MiddleSection({AppMode}) {
  return (
    <div className="middle-section">
      <article>
        <div className="image-section">
          <img src={img1} alt="pic1" />
        </div>
        <div className="info">
            <p className="header">IGJE FOR MANUFACTURERS</p>

            <ol>
                <li>At IGJE Pvt Ltd, we collaborate with vendors and manufacturers in India, empowering them to export jewellery worldwide. </li>
                <li>We provide jewellery manufacturers with apps and websites, simplifying the complexities of selling, payments, shipping, and service processes.</li>
                <li>IGJE is registered with various Indian agencies to facilitate exports and obtain export benefits directly. Manufacturers and vendors do not need separate registrations with these agencies to export.</li>
                <li>
                Our platform enables manufacturers and vendors to scale globally within minutes, leveraging all the advantages of exports and favorable foreign exchange rates. We offer instant integration with various payment methods and ensure competitive foreign exchange rates.
                </li>
                <li>
                Most items enjoy free or minimal shipping costs, further enhancing the accessibility of global markets for our partners.
                </li>
            </ol>
        </div>
      </article>


      <article>
        <div className="image-section">
          <img src={img2} alt="pic2" />
        </div>
        <div className="info">
            <p className="header">IGJE FOR END CUSTOMERS</p>

            <ol>
                <li>IGJE Provides 1000's of designs in every category from various selected Manufacturers/Vendors across the country</li>
                <li>Saves B2B and B2C Clients from spending extra premium buying in residing country.</li>
                <li>Free ACH payment for Jewelry transactions without need to pay wire charges.</li>
                <li>Provides a compliant export and customs certificate to carry jewelry along with you while travelling.</li>
                <li>IGJE Lab protects purchases by certifying each and every item prior to shipping to protect the end customer.</li>
                <li>IGJE Takes care of fixing minor issues with the sold Jewelry free or cost.</li>
            </ol>
        </div>
      </article>


      {/* {AppMode ? <section className="middle-footer">
        <p className="header">
        JOIN OUR IGJE FAMILY OF 30+ MANUFACTURERS/VENDORS
        </p>
        <p>Telangana, India</p>
        <div className="email-wa">
            <p>Email :  igje.india@gmail .com</p>
            <p>WhatsApp: +91 8106886553</p>
        </div>
      </section> : null} */}
    </div>
  );
}

export default MiddleSection;
