
import { useState } from "react";
import WishlistInputModal from "./WishlistInputModal";
import { get_property_settings } from "../../../Component/Helper";


function AddWishlistBtn() {
    const [openWishlistInputModal, setOpenWishlistInputModal] =
    useState(false);
  const handleCloseWishlistInputModal = () =>
    setOpenWishlistInputModal(false);
  const handleOpenWishlistInputModal = () => setOpenWishlistInputModal(true);
  const shortlist_label = get_property_settings("shortlist_label")
  return (
    <>
    {openWishlistInputModal && <WishlistInputModal openWishlistInputModal={openWishlistInputModal} handleCloseWishlistInputModal={handleCloseWishlistInputModal}/>}
  <button className="named-wishlist-add-btn" onClick={handleOpenWishlistInputModal}>+ Add {shortlist_label}</button>
    </>
);
}

export default AddWishlistBtn;
