import { useEffect } from "react";
import "./SpecialJewellery.css";
import SpecialJewelleryCard from "./SpecialJewelleryCard";
import { SpecialJewlleryData } from "./SpecialJewelleryData";
import { ScrollToTop } from "../../_CommonPage/ScrollToTop";

function SpecialJewllery() {

  useEffect(() => {
    ScrollToTop()
}, [])

  return (
    <section className="special-jewellery-page" >
        {
          SpecialJewlleryData.map(data => <SpecialJewelleryCard key={data.id} {...data}/>)
        }
    </section>
  )
}

export default SpecialJewllery