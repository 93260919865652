import React from 'react'

function FormError({errors, paramsData}) {
    return (
        errors[paramsData?.short_code]?.message
            ? <span className="required-field error-text">
             {errors[paramsData?.short_code]?.message.replace(/_/g, " ")}
               
        </span> : null
    )
}

export default FormError
