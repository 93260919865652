import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./OrderTagsModal.css";
import { FaSearch } from "react-icons/fa";
import debounce from "debounce";

function OrderTagsModal({
  order_tags_data,
  loading,
  handle_close_MUI_modal,
  Register,
  getValues,
  short_code,
  setValue,
  setTags
}) {
  const [order_tags, set_order_tags] = useState(order_tags_data || []);

  const prevTags = getValues(short_code)

  function handleSubmit() {
    const selected_tags = getValues(short_code)
    const filter_selected_tags = order_tags_data.filter(tag => selected_tags.includes(tag?.id))
    setTags(filter_selected_tags)
    handle_close_MUI_modal();
  }

  function handleClose() {
    setValue(short_code, prevTags)
    handle_close_MUI_modal();
  }

  function handleSearch(e) {
    const order_tags = [...order_tags_data];
    const filter_order_tags = order_tags.filter((tag) =>
      tag.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    set_order_tags(filter_order_tags);
  }

  

  return (
    <section className="order-tag-modal-container">
      <header>
        <h3>Order Tags</h3>
        <IoMdClose className="close-icon" onClick={handleClose} />
      </header>
      <section className="order-tag-modal-body">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search Order Tags"
            onChange={debounce(handleSearch, 300)}
          />
          <FaSearch className="search-icon" />
        </div>
        <ul className="list">
          {order_tags?.map((tag) => {
            return (
              <li key={tag?.id}>
                <input
                  type="checkbox"
                  id={`order-tag-${tag?.id}`}
                  value={tag?.id}
                  name={Register?.name}
                  onChange={Register?.onChange}
                  ref={Register.ref}
                  onBlur={Register.onBlur}
                />
                <label htmlFor={`order-tag-${tag?.id}`}>{tag?.name}</label>
              </li>
            );
          })}
        </ul>
        <footer>
          <div className="button" onClick={handleSubmit}>
            Submit
          </div>
          <div className="button" onClick={handleClose}>
            cancel
          </div>
        </footer>
      </section>
    </section>
  );
}

export default OrderTagsModal;
