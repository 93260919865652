import { FaVideo } from "react-icons/fa";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { get_bulk_settings, get_property_settings, show_custom_order_menu, show_live_rate_menu } from "../../../../../Helper";

function CustomMenuLink({ custom_menu, setOpenMobileMenu, static_group_link }) {


  const {gold_rate_menu_title} = get_bulk_settings(["gold_rate_menu_title"])
  const show_kam_module_custom_order = show_custom_order_menu();
  
  return (
    <li className={static_group_link ? "border-b" : ""}>
      
      {custom_menu?.map((menu, index) => {
        const isVideoMenu = menu.name.toLowerCase().includes("video");
        
        
        // eslint-disable-next-line no-lone-blocks
        {
          if (menu.external_link) {
            return (
              <a
                
                href={menu?.link}
                target="_blank"
                key={`external_${menu?.name+index}`}
                className={
                  isVideoMenu ? "video_call_menu  general-link" : "general-link"
                }
                onClick={() => setOpenMobileMenu(false)}
                rel="noopener noreferrer"
              >
                {isVideoMenu ? <FaVideo className="video_call_icon" /> : null}
                <span>{menu?.name}</span>
              </a>
            );
          } else {
            if(menu.link === "/categories") return null

            /************ LIVE RATE ******************************/
            if(menu.link === "/live-rates"){
              if(show_live_rate_menu()){
                
              return <Link
                to={menu.link}
                onClick={() => setOpenMobileMenu(false)}
                className="general-link"
                key={`live_${menu?.name+index}`}
              >
                <span>{gold_rate_menu_title}</span>
                <RiArrowRightSLine className="r-arrow" />
              </Link>
              }else{
                return null
              }
            }
            /************ CUSTOM ORDER ******************************/
            else if(menu.link === "/custom-order"){
              if(show_kam_module_custom_order?.status){

                let oms_navigate_direct_create_new_customer_order = get_property_settings("oms_navigate_direct_create_new_customer_order")
                oms_navigate_direct_create_new_customer_order = oms_navigate_direct_create_new_customer_order === "Yes"
                let link = "";
                let label = "";
                
                if(oms_navigate_direct_create_new_customer_order){
                  link = `/${show_kam_module_custom_order.label
                    .replace(/\s/g, "-")
                    .toLowerCase()}/new-${show_kam_module_custom_order.label
                    .replace(/\s/g, "-")
                    .toLowerCase()}`;
                  label = `New ${show_kam_module_custom_order.label}`

                }else{
                  link = `/${show_kam_module_custom_order.label
                          .replace(/\s/g, "-")
                          .toLowerCase()}`;
                  label = show_kam_module_custom_order.label
                }

                return <Link
                  to={link}
                  onClick={() => setOpenMobileMenu(false)}
                  className="general-link"
                  key={`custom_order_${menu?.name+index}`}
                >
                  <span>{label}</span>
                  <RiArrowRightSLine className="r-arrow" />
                </Link>
              }else{
                return null
              }
              
            }
            
            else{

              return (
                <Link
                  to={menu?.link}
                  key={`custom_oder_2_${menu?.name+index}`}
                  className={
                    isVideoMenu ? "video_call_menu general-link" : "general-link"
                  }
                  onClick={(e) => {
                    
                    setOpenMobileMenu(false)
                  }}
                  
                >
                  {isVideoMenu ? <FaVideo className="video_call_icon" /> : null}
                  <span>{menu?.name.toLowerCase() === "live rate" ? gold_rate_menu_title : menu?.name}</span>
                  <RiArrowRightSLine className="r-arrow" />
                </Link>
              );
            }

          }
        }
      })}
    </li>
  );
}

export default CustomMenuLink;
