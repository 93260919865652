import Select from "react-select";
import { createSelectArrayForReactSelect, get_property_settings } from "../../../../../Component/Helper";
import { Controller } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { fetch_data_from_api_from_query } from "../../../../../Api/APICalls";
import { ApiList } from "../../../../../Api/ApiList";
//This is for Voucher Order Type Only
function SingleSelectDropdown({disabled, paramsDataIndex, control, paramsData, client_type, order_data, setValue}) {
    const primary_BG_color = get_property_settings("btn_primary_color")

    const get_voucher_list_response = useQuery({
        queryKey: ["finalize-voucher-type"],
        queryFn: () =>
          fetch_data_from_api_from_query(ApiList.finalize_get_master_data, "finalize-voucher-type", {
            col_name: "order_type_name",
          table: "voucher_order_type",
          client_type: client_type,
          }).then(res => {
            if(Array.isArray(res)){
                if(disabled){
                  const selected_value = order_data?.finalize_fields_data?.find(data => data?.key === "Voucher Order Type")?.value
                  if(selected_value){
                    setValue(paramsData?.short_code, {label: selected_value, value: selected_value})
                    return [{label: selected_value, value: selected_value}]

                  }else{
                    return []
                  }
                }else{
                  const selectFormat = createSelectArrayForReactSelect(res, "name", "id");
                  return selectFormat

                }
                
            }else{
                return []
            }
          }),
        
      });


      

  return (
    <Controller
    control={control}
    name={paramsData?.short_code}
    rules={{
      required: {
        value: paramsData?.required === "1",
        message: `${paramsData?.field_name} is required`,
      },
    }}
    render={({ field }) => {
        return <Select
        isDisabled={disabled}
        tabIndex={paramsDataIndex + 1}
          options={get_voucher_list_response.data || []}
          className="react-select-container r-container"
          classNamePrefix="react-select r-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25:
                (primary_BG_color &&
                  primary_BG_color.replace("rgb", "rgba").replace(")", ",0.2)")) ||
                "inherit",
              primary: primary_BG_color,
            },
          })}
         
     
    
         isSearchable={true}

         {...field}
        />
    }}
    />
    
      
    
  )
}

export default SingleSelectDropdown
