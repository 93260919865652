import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import { fetch_data_from_api_from_query } from "../../../../../Api/APICalls";
import { ApiList } from "../../../../../Api/ApiList";
import Loader from "../../../../../Component/Loader";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "../../../../../Component/InputFields/TextField/TextField";
import CountryStateCity from "../../../../../Component/InputFields/CountryStateCity/CountryStateCity";
import DatePicker from "../../../../../Component/InputFields/DatePicker/DatePicker";
import PhoneNumber from "../../../../../Component/InputFields/PhoneNumber/PhoneNumber";
import './SelectClientFormModal.css';
import TextArea from "../../../../../Component/InputFields/TextArea/TextArea";
import { ToastError, ToastSuccess } from "../../../../../Component/Helper";
import { useQueryClient } from "react-query";

function SelectClientFormModal({
  show_select_client_modal,
  set_show_select_client_modal,
}) {

  const methods = useForm();
  const queryClient = useQueryClient()
  function modify_data(data) {
    const obj = {}
    for(const key in data){
      if(data[key] === undefined){
   
        obj[key] = "";
      }else if(typeof data[key] === "object" && data[key]?.phoneNumber &&  data[key]?.country_code ){
        obj[key] = data[key]?.phoneNumber.replace(data[key]?.country_code, "");
      }else{

        obj[key] = data[key]?.value || data[key]
      }
    }

    return obj
  }

  const handleForm = async () => {
    const is_vaild = await methods.trigger();
    if (!is_vaild) return;
    const data = methods.getValues();
    const modify_form_data = modify_data(data)
    
    const add_client_response = await fetch_data_from_api_from_query(ApiList.set_client_master_data, "set_client_master_data", modify_form_data)
    if(add_client_response.ack === "1"){
      ToastSuccess(add_client_response?.msg)
      queryClient.invalidateQueries({ queryKey: ['get_behalf_client_details'] })
      set_show_select_client_modal(false)
    }else if(add_client_response.error){
        ToastError("Please enter Mobile No.")
        return
    }

  }

  const onSubmit = (data) => {}
  
  const select_client_master_list_response = useQuery({
    queryKey: ["fetch_fields_list_client_master"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.fetch_fields_list_client_master,
        "fetch_fields_list_client_master"
      ),

    // enabled: !!is_myc_web,
  });

  function handleModalStep() {
    set_show_select_client_modal(false);
  }

  return (
    <Modal show={show_select_client_modal} className="custom-order-modal">
      <Modal.Header className="custom-order-modal-header">
        <Modal.Title className="custom-order-modal-title">
          <IoArrowBackOutline
            className="back-arrow-icon"
            onClick={handleModalStep}
          />
          <span>ADD CLIENT</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-order-modal-body">
        {select_client_master_list_response.isLoading ? (
          <Loader isLoading={true} />
        ) : (
          <FormProvider {...methods}>

          <form className="custome-order-select-client-form" onSubmit={methods.handleSubmit(onSubmit)}>
              {
                select_client_master_list_response.data?.map((field_data, index) => {
                  const { short_code, control_type} = field_data;
                  /* TEXTFIELD */
                  if(control_type === "Textfield"){
                      return <TextField fields={field_data} key={field_data.short_code + index}/>

                  /* COUNTRY STATE CITY */    
                  }else if(control_type === "Country" || control_type === "State" || control_type === "City"){
                    if(control_type === "State" || control_type === "City") return null
                    const state_field = select_client_master_list_response.data?.find(data => data?.control_type === "State" )
                    const city_field = select_client_master_list_response.data?.find(data => data?.control_type === "City" )
                    return <CountryStateCity  key={short_code} fields={{...field_data, country_id: "101"}} state_field={state_field} city_field={city_field}/>
                }

                /* DATE PICKER */
                else if(control_type === "Datepicker"){
                  return <DatePicker fields={{...field_data, value: "0000-00-00"}}/>
                }

                /* TelePhone */
                else if(control_type === "Telephone"){
                  return <PhoneNumber fields={field_data}/>
                }

                /* TextArea */
                else if(control_type === "Textarea"){
                  return <TextArea fields={field_data}/>
                }

                })
              }
              <div className="custome-order-select-client-form-footer">
          <div className="button" type="submit" onClick={handleForm}>Submit</div>
        </div>
          </form>
          </FormProvider>
        )}
        
      </Modal.Body>
    </Modal>
  );
}

export default SelectClientFormModal;
