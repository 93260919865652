import React from "react";

function FooterComapnyLogo({logoImg, biggerLogo}) {
  return (
    
        <div className={`ec-footer-logo ${biggerLogo ? "bigger-logo" : ""}`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">
            <img src={logoImg} alt="Logo" />
          </a>
        </div>
    
  );
}

export default FooterComapnyLogo;
