import React, { useRef } from "react";
import { BeatLoader } from "react-spinners";
import { MdEdit } from "react-icons/md";
import FSVMatrixModal from "./FSVMatrixModal/FSVMatrixModal";
import { get_informationFromSessionID, get_property_settings } from "../../Helper";
import { useNavigate } from "react-router-dom";

function ATCButton({
  handle_add_to_cart_button,
  ATC_BUTTON_CLASS,
  buttonLabel,
  addToCartloading,
  page,
  sort,
  set_ATC_button_label
}) {
  const open_FSV_matrix_modal_ref = useRef(null);
  const userID = get_informationFromSessionID("userID")
  const navigate = useNavigate()
  let pre_login_open_fsv_or_login_from_matrix = get_property_settings("pre_login_open_fsv_or_login_from_matrix")
  pre_login_open_fsv_or_login_from_matrix = pre_login_open_fsv_or_login_from_matrix === "login" && !userID;

  const show_edit_button_matrix_view = get_property_settings("show_edit_button_matrix_view")
  const hide_edit_button_matrix_view = show_edit_button_matrix_view === "hide"
  
  return (
    <div className="ec-product-button-group d-flex gap-2">
      {/* <a className="ec-product-like-icon" href="#"><i className="fi-rr-heart"></i></a> */}
      <FSVMatrixModal ref={open_FSV_matrix_modal_ref} page={page} sort={sort} set_ATC_button_label={set_ATC_button_label}/>
      {pre_login_open_fsv_or_login_from_matrix || hide_edit_button_matrix_view ? null : <button
        className="atc-matrix-edit-btn"
        onClick={() => {
          if(userID){
            if (!open_FSV_matrix_modal_ref.current) return;
            open_FSV_matrix_modal_ref.current?.open_modal();

          }else{
            navigate("/login")
          }

        }}
      >
        <MdEdit />
      </button>}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`ec-add-to-cart mt-2 ${ATC_BUTTON_CLASS}`}
        id="add-to-cart"
        onClick={handle_add_to_cart_button}
      >
        {addToCartloading ? (
          <BeatLoader color={"var(--button_foreground_color)"} />
        ) : (
          buttonLabel
        )}
      </a>
      {/* <a className="ec-product-compare-icon" href="#"><i className="fi fi-rr-arrows-repeat"></i></a> */}
    </div>
  );
}

export default ATCButton;
