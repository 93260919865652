import React from "react";

function TextAreafield({formParams, clsName = ""}) {
  return (
    <span className={clsName}>
      <label className="label">
        {formParams.field_name}
        {formParams?.required === "1" && (
          <span className="required-field">*</span>
        )}
      </label>
      <textarea
        
        // tabIndex={i + 1}
        className="remarks fieldValue"
        type="text"
        name={formParams?.short_code}
        placeholder={`Enter ${formParams.field_name}`}
        defaultValue={formParams?.control_value}
        required={formParams?.required === "1" ? true : false}
      />
      <div className="required-field">
            
      </div>
    </span>
  );
}

export default TextAreafield;
