import { useEffect } from "react"
import { get_property_settings } from "./Helper"

function PreventPinchZoom({children}) {

  let mycat_v2_allow_zoom = get_property_settings("mycat_v2_allow_zoom")
  
  
  mycat_v2_allow_zoom = mycat_v2_allow_zoom === "Yes"

  if(mycat_v2_allow_zoom){
   
    document.documentElement.querySelector("[name=viewport]").content = "width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes";
    document.body.classList.add("active-pinch-zoom")
  }else{
    document.documentElement.querySelector("[name=viewport]").content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    document.body.classList.remove("active-pinch-zoom")
  }

  useEffect(() => {
    if(mycat_v2_allow_zoom) return
    
    const disablePinchZoom = (e) => {
      if(mycat_v2_allow_zoom) return
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [mycat_v2_allow_zoom])


  return <>
    {children}
  </>;
}

export default PreventPinchZoom
