import React, { useEffect, useState } from "react";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import { getCatMaster, setCatMaster } from "../../../redux/Reducer/reducer";
import {useNavigate} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

function BrowseByCategory() {
  const [categoryButtons, setCategoryButtons] = useState([]);
  const selectedCategory = useSelector(getCatMaster);
  
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const master_setting_query = useQuery({
    queryKey: ["master_setting"],
    queryFn: () => fetch_data_from_api_from_query(`${ApiList.setting}/${ApiList.master_setting}`, "master_setting"),
  });



  useEffect(()=>{
    if(Array.isArray(master_setting_query?.data)){

      setCategoryButtons(master_setting_query?.data);
      let master = master_setting_query?.data.find(
        (master) => master.position === "1"
      )?.short_code;
      master = master.replace("_online", "");
      dispatch(setCatMaster(master));
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [master_setting_query?.isSuccess])

  if(master_setting_query?.data?.length === 1){
    return null
  }
  
  return (
    <div className="header-top-login-reg category-header-top-btns">
      {categoryButtons.map((button) => {
        const short_code = button.short_code.replace("_online", "");
        const selected = short_code === selectedCategory;
        // const selected = short_code === selectedBtn;
        
        return (
          <button
            value={short_code}
            key={button?.position}
            className={selected ? "selected" : "no-selected"}
            onClick={(e)=> {
                navigate('/home')
                dispatch(setCatMaster(short_code))
            }}
          >
            {button?.custom_label}
          </button>
        );
      })}
    </div>
  );
}

export default React.memo(BrowseByCategory);
